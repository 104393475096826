import React from "react";
import { connect } from "react-redux";

import { api } from "api";
import LoadMask from "Utils/LoadMask/LoadMask";
import DatosBasicosForm from "./VisualizarForm";
import { actions } from "../../../../redux/modules/recursos/recursos";
import { FILTER_LIST as filterListSkills } from "../../../../redux/modules/skills/skills";

const Visualizar = ({ match, loader, getDatosBasicos }) => {
    /* Estado */
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const { skills, loading } = state;

    /* Obtener skills y setear datos del formulario */
    React.useEffect(() => {
        initialData();
        getData();
    }, []);

    const getData = async () => {
        try {
            onLoad();
            const skills = await api.get(filterListSkills);
            onGetSkills(skills);
        } catch (error) {
        } finally {
            onLoad(false);
        }
    };

    const initialData = () => {
        const id = match.params.id;
        getDatosBasicos(id);
    };

    const onLoad = (payload = true) => {
        dispatch({ type: actionTypes.setLoading, payload });
    };

    const onGetSkills = (skills) => {
        dispatch({ type: actionTypes.setSkills, payload: skills });
    };

    const select_style = {
        control: (styles) => ({
            ...styles,
            color: "#1F4D7B",
            borderColor: "#1F4D7B",
        }),
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <h2 className="title--blue">VER RECURSO</h2>
                </div>
            </div>
            <hr />
            <div className="login-wrapper">
                <div className="card card-login w-100">
                    <LoadMask loading={loading || loader} blur>
                        <DatosBasicosForm
                            edit={false}
                            setPopupOpened={() => {}}
                            select_style={select_style}
                            handleSubmit={() => {}}
                            skills={skills}
                        />
                    </LoadMask>
                </div>
            </div>
        </React.Fragment>
    );
};

const initialState = {
    loading: false,
    skills: [],
};

const actionTypes = {
    setLoading: "SET_LOADING",
    setSkills: "SET_SKILLS",
};

const reducerObject = (state, payload) => ({
    [actionTypes.loading]: {
        ...state,
        loading: payload,
    },

    [actionTypes.setSkills]: {
        ...state,
        skills: payload,
    },
});

const reducer = (state, action) => {
    return reducerObject(state, action.payload)[action.type] || state;
};

const ms2p = (state) => {
    return {
        loader: state.recursos.loader,
    };
};

const md2p = {
    getDatosBasicos: actions.getDatosBasicos,
};

export default connect(ms2p, md2p)(Visualizar);
