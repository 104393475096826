import React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { validate, validators } from "validate-redux-form";

import { renderField, renderSelectField } from "../../Utils/renderField";

import { RenderFields } from "./RenderFields";
import { renderSkills } from "./renderSkills";

import validateFields from "./validate";

const email = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? "Correo inválido"
        : undefined;

function DatosBasicosForm({
    edit,
    setPopupOpened,
    select_style,
    handleSubmit,
    skills,
    fromModalCreate = false,
}) {
    const fields = [
        [
            // row
            {
                label: "Código",
                props: {
                    name: "codigo",
                    label: "codigo",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Código...",
                },
            },
            {
                label: "Nombre",
                props: {
                    name: "nombre",
                    label: "Nombre",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Nombre...",
                },
            },
        ],
        [
            // row
            {
                label: "Correo empresarial",
                props: {
                    name: "email_empresarial",
                    label: "Correo empresarial",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Correo empresarial...",
                    validate: email,
                },
            },
            {
                label: "Correo personal",
                props: {
                    name: "email",
                    label: "Correo personal",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Correo personal...",
                    validate: email,
                },
            },
        ],
        [
            {
                label: "Teléfono",
                props: {
                    name: "telefono",
                    label: "Teléfono",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Teléfono...",
                },
            },
            {
                label: "DPI",
                props: {
                    name: "dpi",
                    label: "DPI",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "DPI...",
                },
            },
        ],
        [
            {
                label: "Dirección",
                props: {
                    name: "direccion",
                    label: "Dirección",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Dirección...",
                },
            },
            {
                label: "Fecha de nacimiento",
                props: {
                    name: "fecha_nacimiento",
                    component: renderField,
                    className: "input--style",
                    type: "date",
                },
            },
        ],
        [
            {
                label: "NIT",
                props: {
                    name: "nit",
                    label: "NIT",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "NIT...",
                },
            },
        ],
        [
            // row
            {
                label: "Skills",
                array: true,
                props: {
                    name: "skills_experiencia",
                    component: renderSkills,
                    edit: edit,
                    skills,
                    select_style,
                    label: "Skills",
                },
            },
        ],
    ];

    return (
        <React.Fragment>
            <form
                name="RecursosCrearEditarForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <RenderFields fields={fields} />
                <div className="buttons-box">
                    {edit ? (
                        <button
                            className="btn btn-warning m-1"
                            style={{ fontSize: 15 }}
                            type="button"
                            onClick={() => setPopupOpened(true)}
                        >
                            Despido/Renuncia
                        </button>
                    ) : null}
                    {!fromModalCreate ? (
                        <Link
                            to="/recursos"
                            className="btn btn-secondary"
                            style={{ fontSize: 15 }}
                        >
                            Cancelar
                        </Link>
                    ) : null}

                    <button
                        type="submit"
                        style={{ fontSize: 15 }}
                        className="btn btn-primary m-1 align-self-center"
                    >
                        Siguiente
                    </button>
                </div>
            </form>
        </React.Fragment>
    );
}

const form = reduxForm({
    form: "RecursosCrearEditarForm", // a unique identifier for this  form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validateFields,
})(DatosBasicosForm);

/* const selector = formValueSelector("RecursosCrearEditarForm");
export default connect((state) => {
    const tipoContratacionValue = selector(state, "tipo_contratacion");
    const tipoContratoValue = selector(state, "tipo_contrato");
    const puestoValue = selector(state, "puesto") || null;

    return {
        tipoContratacionValue,
        tipoContratoValue,
        puestoValue,
    };
})(form); */
export default form;
