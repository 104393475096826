import moment from "moment";
import React, { useState, useEffect, useMemo, useRef } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import { api } from "api";

//=================== Componentes =====================
import TableData from "../Tables/TableData";
import FiltersData from "../Tables/FiltersData";
//=====================================================
//=================== CSS =============================
import "reactjs-popup/dist/index.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "../../../../../style/custom.css";
import "./reporte.css";
import { useDownloadExcel } from "react-export-table-to-excel";
//=====================================================

const Visualizar = () => {
    const [loading, setLoading] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [disenadores, setDisenadores] = useState([]);
    const [programadores, setProgramadores] = useState([]);
    const [semanas, setSemanas] = useState([]);
    const [fechas, setFechas] = useState([]);
    const [valueSearch, setValueSearch] = useState("");
    const searchInput = useRef(null);

    const tablaTotal = useRef(null);

    // Filtrar datos por tipo de proyecto
    const [tiposProyecto, setTiposProyecto] = useState([]);
    const [tipoSelected, setTipoSelected] = useState(null);

    // Filtrar por skills
    const [skills, setSkills] = useState([]);
    const [skillSelected, setSkillSelected] = useState(null);

    useEffect(() => {
        // Obtiene todos los tipos de proyectos y los pasa al formato requerido
        // El formato debe de ser un objeto { value: <id del tipo>, label: <nombre del tipo> }
        const getTipos = async () => {
            try {
                const tipos = await api.get("tipo_proyecto", { activo: true });
                const tiposFormat = tipos.results.map(tipo => ({ value: tipo.nombre, label: tipo.nombre }));
                setTiposProyecto(tiposFormat);
            } catch (err) {
                setTiposProyecto([]);
            }
        };

        const getSkills = async () => {
            try {
                const skills = await api.get("skill/filter_list");
                setSkills(skills);
            } catch (err) {
                setSkills([]);
            }
        };

        getSkills();
        getTipos();
    }, []);

    // Fin filtro por tipo de proyecto

    const { onDownload } = useDownloadExcel(
        {
            currentTableRef: tablaTotal.current,
            filename: 'reporte',
            sheet: 'disponibilidad'
        }
    )

    /*
    * Esta función filtra los recursos tomando en cuenta el nombre
    * del recurso y el tipo del proyecto en el que participa.
    */
    function filtrarRecursos(listaRecursos) {
        return () => listaRecursos.filter((recurso) => {
            const porBusqueda = Object.keys(recurso)[0]
                .toLowerCase()
                .includes(valueSearch.toLowerCase());
            const porTipo = tipoSelected ? recurso.proyecto_default === tipoSelected : true;
            const porSkill = skillSelected ? recurso.skills ? recurso.skills.find(s => s.id === skillSelected): false : true;

            return porBusqueda && porTipo && porSkill;
        });
    }

    /*
    * Filtrar programadores
    */
    const filterRecursos_P = useMemo(
        filtrarRecursos(programadores),
        [programadores, valueSearch, tipoSelected, skillSelected]
    );

    /*
    * Filtrar diseñadores
    */
    const filterRecursos_D = useMemo(
        filtrarRecursos(disenadores),
        [disenadores, valueSearch, tipoSelected, skillSelected]
    );

    const handleSearch = () => {
        setValueSearch(searchInput.current.value);
    };

    const [fechaSearch, setFechaSearch] = useState({
        startDate: new Date(moment().subtract(0, "weeks")),
        endDate: new Date(moment().add(2, "months")),
        key: "selection",
    });

    useEffect(() => {
        setLoading(true);
        api.get("/reporte/getData", {
            fecha_inicio: moment(fechaSearch.startDate).format("YYYY-MM-DD"),
            fecha_fin: moment(fechaSearch.endDate).format("YYYY-MM-DD"),
        })
            .then((res) => {
                setFechas(res.semanas_fechas);
                setSemanas(res.semanas_totales);
                setProgramadores(res.p);
                setDisenadores(res.d);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [fechaSearch]);

    return (
        <React.Fragment>
            <div className="flex row mt-2">
                <div className="col text-left">
                    <h2 className="title--blue">DISPONIBILIDAD</h2>
                </div>
                <div>
                    <button
                        className="btn btn-light mr-4"
                        style={{ borderColor: '#1F4D7B', color: '#1F4D7B', fontSize: 15, marginTop: '30px', }}
                        onClick={onDownload}
                    >
                        Exportar
                    </button>
                </div>
            </div>
            <hr />
            {/* SECCION DE FILTROS */}
            <FiltersData
                // Filtro de recursos
                valueSearch={valueSearch}
                searchInput={searchInput}
                handleSearch={handleSearch}
                // Filtro de fechas
                fechaSearch={fechaSearch}
                setFechaSearch={setFechaSearch}
                // Modal de fechas
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                // Tipos de proyecto
                tiposProyecto={tiposProyecto}
                tipoSelected={tipoSelected}
                setTipoSelected={setTipoSelected}
                skills={skills}
                setSkillSelected={setSkillSelected}
            />
            {/*FIN DE SECCION DE FILTROS */}
            <LoadMask loading={loading} light>
                <table className="card bg-transparent shadow-none" ref={tablaTotal}>
                    <div className="card mb-3 mt-3">
                        <div className="card-header card--header">
                            Programadores
                        </div>
                        <div className="card-body">
                            {filterRecursos_P.length > 0 ? (
                                <div className="tabla-resumen table--scroll">
                                    <TableData
                                        recursos={filterRecursos_P}
                                        semanas={semanas}
                                        fechas={fechas}
                                    />
                                </div>
                            ) : (
                                <div className="text-center">
                                    {loading ? (
                                        <h5 className="text-secondary">
                                            Cargando...
                                        </h5>
                                    ) : (
                                        <h5 className="text-secondary">
                                            No hay datos
                                        </h5>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <br /> <br />
                    <div className="card mb-3 mt-3">
                        <div className="card-header card--header">
                            Diseñadores
                        </div>
                        <br className="d-none" />
                        <div className="card-body">
                            {filterRecursos_D.length > 0 ? (
                                <div className="tabla-resumen table--scroll">
                                    <TableData
                                        recursos={filterRecursos_D}
                                        semanas={semanas}
                                        fechas={fechas}
                                    />
                                </div>
                            ) : (
                                <div className="text-center">
                                    {loading ? (
                                        <h5 className="text-secondary">
                                            Cargando...
                                        </h5>
                                    ) : (
                                        <h5 className="text-secondary">
                                            No hay datos
                                        </h5>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </table>
            </LoadMask>
        </React.Fragment>
    );
};

export default Visualizar;
