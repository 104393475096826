import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import { api } from "api";
import moment from "moment";

const SUBMIT = "PROYECTO_SUBMIT";
const LOADER = "PROYECTO_LOADER";
const DATA = "PROYECTO_DATA";
const FORM_DATA = "PROYECTO_FORM_DATA";
const ALL_PROJECTS = "ALL_PROJECTS";
const ENDPOINT = "proyecto";

export const FILTER_LIST = `${ENDPOINT}/filter_list`;

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setAllProjects = (all_projects) => ({
    type: ALL_PROJECTS,
    all_projects
});

export const setFormData = (formData) => ({
    type: FORM_DATA,
    formData,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit =
    (data = {}) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        api.post(`${ENDPOINT}`, data)
            .then((response) => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha guardado correctamente",
                    type: "success",
                });
                dispatch(push("/proyectos"));
            })
            .catch(() => {
                Swal.fire({
                    title: "Error",
                    text: "Ha ocurrido un error, inténtelo nuevamente.",
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const listAll = () => (dispatch) => {
    api.get("proyecto/filter_list").then((res) => {
        dispatch(setAllProjects(res))
    });
}

export const listar =
    (page = 1, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get("/user/me")
            .then((response) => {
                if (response.type == 10) {
                    params.encargado__id = response.recurso;
                }
                if(!params.encargado__id) delete params.encargado__id;
                if(!params.cliente__id) delete params.cliente__id;
                if(!params.search) delete params.search;
                api.get(`${ENDPOINT}`, { page, ...params })
                    .then((response) => {
                        dispatch(setData(response));
                        dispatch(setLoader(false));
                    })
                    .catch(() => {
                        dispatch(setLoader(false));
                    });
            })
            .catch(() => {
                dispatch(setLoader(false));
            });
    };

export const finalizarProyecto = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${ENDPOINT}/finalizar_proyecto`, { id })
        .then(() => {
            Swal.fire({
                title: "Éxito",
                text: "Proyecto finalizado exitósamente.",
                type: "success",
            });
            dispatch(push("/proyectos"));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};




export const finalizarProyecto1 = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${ENDPOINT}/finalizar_proyecto`, { id }).then(() => {
        api.put(`${ENDPOINT}/${id}`, data)
            .then(() => {
                Swal.fire({
                    title: "Éxito",
                    text: "Proyecto actualizado exitósamente.",
                    type: "success",
                });
                dispatch(push("/proyectos"));
            })
            .catch((err) => {
                console.log(err);
                Swal.fire({
                    title: "Error",
                    text: err.print
                        ? err.detail
                        : "Ha ocurrido un error, inténtelo nuevamente.",
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }).catch(err => {
        console.log(err);
        dispatch(setLoader(false));
    });
};

export const activarProyecto = (id) => (dispatch) => {
    dispatch(setLoader(true)); // Activa el loader mientras se procesa la solicitud

    api.post(`${ENDPOINT}/activar_proyecto`, { id }) // Realiza la solicitud POST al servidor
        .then(() => {
            Swal.fire({ // Muestra un mensaje de éxito al usuario
                title: "Éxito",
                text: "Proyecto activado exitósamente.",
                type: "success",
            });
            dispatch(push("/proyectos")); // Redirige al usuario a la página de proyectos
        })
        .catch((err) => {
            console.log(err); // Registra cualquier error en la consola
            Swal.fire({ // Muestra un mensaje de error al usuario
                title: "Error",
                text: err.print ? err.detail : "Ha ocurrido un error, inténtelo nuevamente. Verifica que el proyecto tenga un encargado",
                type: "error",
            });
        })
        .finally(() => {
            dispatch(setLoader(false)); // Desactiva el loader después de que se complete la solicitud
        });
};

export const pausarProyecto = (id) => (dispatch) => {
    dispatch(setLoader(true)); // Activa el loader mientras se procesa la solicitud

    api.post(`${ENDPOINT}/pausar_proyecto`, { id }) // Realiza la solicitud POST al servidor
        .then(() => {
            Swal.fire({ // Muestra un mensaje de éxito al usuario
                title: "Éxito",
                text: "Proyecto pausado exitósamente.",
                type: "success",
            });
            dispatch(push("/proyectos")); // Redirige al usuario a la página de proyectos
        })
        .catch((err) => {
            console.log(err); // Registra cualquier error en la consola
            Swal.fire({ // Muestra un mensaje de error al usuario
                title: "Error",
                text: err.print ? err.detail : "Ha ocurrido un error, inténtelo nuevamente.",
                type: "error",
            });
        })
        .finally(() => {
            dispatch(setLoader(false)); // Desactiva el loader después de que se complete la solicitud
        });
};


export const obtenerProyecto =
    (id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}/${id}`)
            .then((response) => {
                response.encargado = response.encargado ? response.encargado.id : null;
                response.pais_asueto = response.pais_asueto ? response.pais_asueto.id : null;
                if(localStorage.getItem('role') == 10) {
                    if(response.encargado) {
                        if(response.encargado != localStorage.getItem('uid')) {
                            dispatch(push("/proyectos"));
                        }
                    }
                }
                response.tipo_proyecto = response.tipo_proyecto.id;
                response.cliente = response.cliente ? response.cliente.id : null;
                dispatch(initializeForm("CrearEditarForm", response));
                dispatch(setFormData(response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const actualizarProyecto =
    (data = {}, id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.put(`${ENDPOINT}/${id}`, data)
            .then((response) => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha actualizado correctamente",
                    type: "success",
                });
                dispatch(push("/proyectos"));
            })
            .catch((err) => {
                if(err.projects && err.projects.length){
                    const projects_text = err.projects.map((p) => (
                        `${p.proyecto} - salida: ${moment(p.salida).format("DD/MM/YYYY")}`
                    ))

                    Swal.fire({
                        title: "Error",
                        html: `${err.detail}<br />${projects_text.join("<br />")}`,
                        type: "error",
                    });
                }else{
                    Swal.fire({
                        title: "Error",
                        text: err.print
                            ? err.detail
                            : "Ha ocurrido un error, inténtelo nuevamente.",
                        type: "error",
                    });
                }

            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
  

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${ENDPOINT}/${id}`)
        .then((response) => {
            Swal.fire({
                title: "Éxito",
                text: "Se ha eliminado correctamente",
                type: "success",
            });
            dispatch(listar(1, { activo: true, finalizado: false, encargado__id: null }));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const getMe = () => (dispatch) => {
    api.get("/user/me")
        .then((me) => {
            dispatch(initializeForm("profile", me));
            dispatch(setMe(me));
        })
        .catch(() => {})
        .finally(() => {});
};

export const actions = {
    listar,
    eliminar,
    onSubmit,
    obtenerProyecto,
    actualizarProyecto,
    finalizarProyecto,
    finalizarProyecto1,
    listAll,
    activarProyecto,
    pausarProyecto, 
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [FORM_DATA]: (state, { formData }) => {
        return {
            ...state,
            formData,
        };
    },
    [ALL_PROJECTS]: (state, { all_projects }) => {
        return {
            ...state,
            all_projects,
        };
    },
};

export const initialState = {
    loader: false,
    formData: {},
    data: {},
    all_projects: []
};

export default handleActions(reducers, initialState);
