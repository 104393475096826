import React from "react";

import {
    renderSelectField,
    renderNumber,
} from "../../../Utils/renderField";

import { Field } from "redux-form";

const range = (start, stop, step) =>
    Array.from(
        { length: Math.abs(stop - start) / step + 1 },
        (_, i) => start + i * step
    );

export const renderPersonal = ({
    fields,
    meta: { error },
    allPuestos = [],
    tipoFase,
    seniority = [],
    currentPlantilla = null,
    isDisabled = false,
}) => {
    const select_style = {
        control: (styles, state) => ({
            ...styles,
            color: "#1F4D7B",
            borderWidth: state.isFocused || !state.hasValue ? "1px" : "0px",
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            backgroundColor: "white",
        }),
    };

    const puestos = React.useMemo(() => {
        const puestosByPlantilla = {};
        if (currentPlantilla !== null) {
            const fasesPuestos = currentPlantilla.fases_puestos;
            fasesPuestos.forEach((p) => {
                if (!puestosByPlantilla[p.fase]) {
                    puestosByPlantilla[p.fase] = [];
                }
                const puesto = allPuestos.find(
                    (puesto) => puesto.id === p.puesto
                );

                puestosByPlantilla[p.fase].push(puesto);
            });
        }
        return puestosByPlantilla;
    }, [currentPlantilla]);

    const seniorities = React.useMemo(() => {
        const senorityByPuesto = {};
        seniority.forEach((s) => {
            if (!senorityByPuesto[s.puesto]) {
                senorityByPuesto[s.puesto] = [];
            }
            senorityByPuesto[s.puesto].push(s);
        });
        return senorityByPuesto;
    }, [seniority]);

    const tarifasSeniority = React.useMemo(() => {
        const tarifaBySeniority = {};
        seniority.forEach((s) => {
            const start = Math.round(Number(s.hora_minima));
            const stop = Math.round(Number(s.hora_maxima));
            tarifaBySeniority[s.id] = range(start, stop, 1).map((value) => ({
                value: value.toFixed(2),
                label: `$${value}`,
            }));
        });
        return tarifaBySeniority;
    }, [seniority]);

    return (
        <div className="col-10 mt-4">
            <div className="row mb-1">
                <span className="col-4 title--blue--1">Puesto asignado</span>
                <span className="col-3 title--blue--1 pl-4">Nivel</span>
                <span className="col-2 title--blue--1">Tarifa</span>
                <span className="col-2 title--blue--1">Cantidad</span>
            </div>

            {fields.length <= 0 && (
                <div className="col-12">
                    <span className="text-center">---</span>
                </div>
            )}

            <span className="text-danger">{error}</span>

            {fields.map((puesto, index, fields) => {
                const currentField = fields.get(index);

                return (
                    <div
                        className="row field--array"
                        key={index}
                        style={{
                            display:
                                currentField.fase === tipoFase
                                    ? "flex"
                                    : "none",
                        }}
                    >
                        <div className="form-group has-feedback col-4 m-0">
                            <Field
                                name={`${puesto}.puesto`}
                                select_style={select_style}
                                component={renderSelectField}
                                label={`#${index + 1}`}
                                options={allPuestos} // Todas las opciones disponibles
                                labelKey="label"
                                valueKey="value"
                                className="form-group"
                                placeholder="Puesto..."
                                extra_change={true}
                                extraChange={(value) => {
                                    currentField.seniority = null;
                                    currentField.tarifa = null;
                                }}
                                disabled={currentField.de_plantilla || isDisabled}
                            />
                        </div>
                        <div className="form-group has-feedback col-3 m-0">
                            <Field
                                name={`${puesto}.seniority`}
                                select_style={select_style}
                                component={renderSelectField}
                                label={`#${index + 1}`}
                                options={seniorities[currentField.puesto] || []}
                                labelKey="label"
                                valueKey="value"
                                className="form-group"
                                placeholder="Nivel..."
                                extra_change={true}
                                extraChange={(value) => {
                                    currentField.tarifa = null;
                                }}
                                disabled={isDisabled}
                            />
                        </div>
                        <div className="form-group has-feedback col-2 m-0">
                            <Field
                                name={`${puesto}.tarifa`}
                                select_style={select_style}
                                component={renderSelectField}
                                options={
                                    tarifasSeniority[currentField.seniority] ||
                                    []
                                }
                                labelKey="label"
                                valueKey="value"
                                className="form-group"
                                placeholder="Tarifa..."
                                disabled={isDisabled}
                            />
                        </div>
                        <div className="form-group has-feedback col-2 m-0 p-0">
                            <Field
                                name={`${puesto}.cantidad`}
                                component={renderNumber}
                                placeholder="Cantidad"
                                readOnly={isDisabled}
                            />
                        </div>
                        <div className="col d-flex justify-content-end">
                            {!currentField.de_plantilla && (
                                <button
                                    className="btn standard-action action--delete"
                                    type="button"
                                    style={{
                                        color: "rgb(246, 81, 96)",
                                        backgroundColor:
                                            "rgba(246, 81, 96, 0.3)",
                                        padding: "0",
                                    }}
                                    onClick={() => {
                                        fields.remove(index);
                                    }}
                                    disabled={isDisabled}
                                >
                                    <i className="material-icons">delete</i>
                                </button>
                            )}
                        </div>
                    </div>
                );
            })}
            <button
                type="button"
                className="btn mb-3 d-flex align-items-center justify-conten-center"
                onClick={() =>
                    fields.push({
                        fase: tipoFase,
                        cantidad: 1,
                        de_plantilla: false,
                    })
                }
                disabled={isDisabled}
            >
                <i
                    className="material-icons title--blue--1"
                    style={{ fontSize: "24px" }}
                >
                    add
                </i>
                <p
                    className="title--blue--1"
                    style={{ margin: "0", fontSize: "14px" }}
                >
                    Agregar
                </p>
            </button>
        </div>
    );
};
