import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import { Login } from './common/components/Login';
import { Home } from './common/components/Home';

import {
    Listar as ListarRecursos,
    CrearEditar as CrearEditarRecursos,
} from './common/components/Recursos';
import { Listar as ListarFasesProyecto, CrearEditar as CrearEditarFaseProyecto } from './common/components/FasesProyecto';
import { Listar as ListarTiposProyecto, CrearEditar as CrearEditarTipoProyecto } from './common/components/TipoProyecto';
import { Listar as ListarDepartamentos, CrearEditar as CrearEditarDepartamento } from './common/components/DepartamentoOrganizacion';
import { Listar as ListarPaises, CrearEditar as CrearEditarPais } from './common/components/Paises';
import { Listar as ListarAsuetos, CrearEditar as CrearEditarAsueto } from './common/components/Asuetos';
import { Listar as ListarClientes, CrearEditar as CrearEditarCliente } from './common/components/Clientes';
import { Listar as ListarPuestos, CrearEditar as CrearEditarPuesto } from './common/components/Puestos';
import { Listar as ListarUsuarios, CrearEditar as CrearEditarUsuario } from './common/components/Users';
import { Listar as ListarPermisos, CrearEditar as CrearEditarPermiso } from './common/components/Permiso';
import { Listar as ListarHistorialeconomico, CrearEditar as CrearEditarHistorialeconomico } from './common/components/HistorialEconomico';
import { Listar as ListarTiposPermiso, CrearEditar as CrearEditarTipoPermiso } from './common/components/TiposPermiso';
import { Listar as ListarSkills, CrearEditar as CrearEditarSkill } from './common/components/Skills';
import { Listar as ListarSeniority, CrearEditar as CrearEditarSeniority } from './common/components/Seniority';
import { Listar as ListarAmonestacion, CrearEditar as CrearEditarAmonestacion } from './common/components/Amonestacion';
import { Listar as ListarProyectoOperaciones, CrearEditar as CrearEditarProyectoOperaciones } from './common/components/ProyectosOperaciones';
import { Listar as ListarComplejidad } from './common/components/ComplejidadProyectos';
import { Listar as ListarActividadEntrega } from './common/components/ActividadesEntrega';
import { Listar as ListarPlantillaProyecto, CrearEditar as CrearEditarPlantillaProyecto } from './common/components/PlantillasProyectos';
import { Listar as ListarGastosAdministrativos } from './common/components/GastosAdministrativos';
import { Listar as ListarTipoContratoVacaciones, CrearEditar as EditarDiasVacaciones } from "./common/components/TipoContratoVacaciones";
import { Listar as ListarSolicitud, CrearEditar as CrearEditarSolicitud } from './common/components/SolicitudPersonal';
import { Listar as ListarHistorialInterno, CrearEditar as CrearEditarHistorialInterno } from './common/components/HistorialInterno';
import { ChangePassword } from './common/components/Profile';
import {
    Listar as ListarProyectos,
    CrearEditar as CrearEditarProyectos,
} from './common/components/Proyectos'
import {
    Visualizar as VisualizarGantt
} from './common/components/Gantt'
import {
    Visualizar as VisualizarResumen
} from './common/components/Resumen'

import ProtectedRoute from './ProtectedRoute';
import NotFound from './common/components/layout/NotFound/NotFound';

import {
    Visualizar as VisualizarReporte
} from './common/components/Reporte'

import { Visualizar as VisualizarCalendario } from './common/components/CalendarioAsuetos';

import '../assets/fonts/fonts.css';
import { Visualizar as VisulizarRecursosReportes } from "./common/components/RecursosReportes";
import { Visualizar as VisulizarPermisosReportes } from "./common/components/PermisosReportes";
import { Visualizar as VisulizarVacacionesReportes } from "./common/components/PermisosVacacionesReportes";
import { Visualizar as VisualizarBirthdays } from "./common/components/BirthdaysMonth";

import { Listar as ListarRecursosContacto, Visualizar as VisualizarRecursos } from './common/components/RecursosContacto';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';
require('../style/index.css');

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <ProtectedRoute access={[0]} exact path="/" component={Home} />
                <ProtectedRoute access={[0]} exact path="/change-password" component={ChangePassword} />
                <ProtectedRoute access={[1]} exact path="/users" component={ListarUsuarios} />
                <ProtectedRoute access={[1]} exact path="/users/crear" component={CrearEditarUsuario} />
                <ProtectedRoute access={[1]} exact path="/users/:id/editar" component={CrearEditarUsuario} />

                <ProtectedRoute access={[1, 6, 11]} exact path="/recursos" component={ListarRecursos} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/recursos/crear" component={CrearEditarRecursos} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/recursos/:id/editar" component={CrearEditarRecursos} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/recursos/reportes" component={VisulizarRecursosReportes} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/historial_interno" component={ListarHistorialInterno} /> 
                <ProtectedRoute access={[1, 6, 11]} exact path="/historial_interno/:id/historial" component={CrearEditarHistorialInterno} />  

                <ProtectedRoute access={[1, 10, 11]} exact path="/proyectos/crear" component={CrearEditarProyectos} />
                <ProtectedRoute access={[1, 10, 11]} exact path="/proyectos" component={ListarProyectos} />
                <ProtectedRoute access={[1, 10, 11]} exact path="/proyectos/:id/editar/:tipo" component={CrearEditarProyectos} />
                <ProtectedRoute access={[1, 10, 11]} exact path="/gantt" component={VisualizarGantt} />
                <ProtectedRoute access={[0]} exact path="/ruta-trabajo" component={VisualizarResumen} />
                <ProtectedRoute access={[1]} exact path="/fases-proyecto" component={ListarFasesProyecto} />
                <ProtectedRoute access={[1]} exact path="/fases-proyecto/crear" component={CrearEditarFaseProyecto} />
                <ProtectedRoute access={[1]} exact path="/fases-proyecto/:id/editar" component={CrearEditarFaseProyecto} />
                <ProtectedRoute access={[1]} exact path="/tipos-proyecto" component={ListarTiposProyecto} />
                <ProtectedRoute access={[1]} exact path="/tipos-proyecto/crear" component={CrearEditarTipoProyecto} />
                <ProtectedRoute access={[1]} exact path="/tipos-proyecto/:id/editar" component={CrearEditarTipoProyecto} />

                <ProtectedRoute access={[1, 6, 11]} exact path="/departamentos" component={ListarDepartamentos} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/departamentos/crear" component={CrearEditarDepartamento} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/departamentos/:id/editar" component={CrearEditarDepartamento} />

                <ProtectedRoute access={[1]} exact path="/paises" component={ListarPaises} />
                <ProtectedRoute access={[1]} exact path="/paises/crear" component={CrearEditarPais} />
                <ProtectedRoute access={[1]} exact path="/paises/:id/editar" component={CrearEditarPais} />

                <ProtectedRoute access={[1, 6, 11]} exact path="/historial_economico" component={ListarHistorialeconomico} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/historial_economico/:id/historial" component={CrearEditarHistorialeconomico} />

                <ProtectedRoute access={[1, 6, 11]} exact path="/asuetos" component={ListarAsuetos} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/asuetos/crear" component={CrearEditarAsueto} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/asuetos/:id/editar" component={CrearEditarAsueto} />

                <ProtectedRoute access={[1, 6, 11]} exact path="/permisos" component={ListarPermisos} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/permisos/crear" component={CrearEditarPermiso} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/permisos/:id/editar" component={CrearEditarPermiso} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/permisos/reportes" component={VisulizarPermisosReportes} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/vacaciones/reportes" component={VisulizarVacacionesReportes} />

                <ProtectedRoute access={[1, 6, 11]} exact path="/calendario_asuetos" component={VisualizarCalendario} />

                <ProtectedRoute access={[1, 6, 11]} exact path="/tipos_permiso" component={ListarTiposPermiso} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/tipos_permiso/crear" component={CrearEditarTipoPermiso} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/tipos_permiso/:id/editar" component={CrearEditarTipoPermiso} />

                <ProtectedRoute access={[1, 10, 11]} exact path="/clientes" component={ListarClientes} />
                <ProtectedRoute access={[1, 10, 11]} exact path="/clientes/crear" component={CrearEditarCliente} />
                <ProtectedRoute access={[1, 10, 11]} exact path="/clientes/:id/editar" component={CrearEditarCliente} />

                <ProtectedRoute access={[1, 10, 11]} exact path="/recursos_contacto" component={ListarRecursosContacto} />
                <ProtectedRoute access={[1, 10, 11]} exact path="/recursos_contacto/:id/" component={VisualizarRecursos} />

                <ProtectedRoute access={[1, 6, 11]} exact path="/puestos" component={ListarPuestos} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/puestos/crear" component={CrearEditarPuesto} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/puestos/:id/editar" component={CrearEditarPuesto} />

                <ProtectedRoute access={[1, 6, 11]} exact path="/skills" component={ListarSkills} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/skills/crear" component={CrearEditarSkill} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/skills/:id/editar" component={CrearEditarSkill} />

                <ProtectedRoute access={[1, 6, 11]} exact path="/seniority" component={ListarSeniority} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/seniority/crear" component={CrearEditarSeniority} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/seniority/:id/editar" component={CrearEditarSeniority} />

                <ProtectedRoute access={[1, 6, 11]} exact path="/amonestaciones" component={ListarAmonestacion} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/amonestaciones/crear" component={CrearEditarAmonestacion} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/amonestaciones/:id/editar" component={CrearEditarAmonestacion} />

                <ProtectedRoute access={[1, 6, 11]} exact path="/birthdays/" component={VisualizarBirthdays} />

                <ProtectedRoute access={[1]} exact path="/solicitud_personal" component={ListarSolicitud} />
                <ProtectedRoute access={[1]} exact path="/solicitud_personal/crear" component={CrearEditarSolicitud} />
                <ProtectedRoute access={[1]} exact path="/solicitud_personal/:id/" component={CrearEditarSolicitud} />
                <ProtectedRoute access={[1]} exact path="/solicitud_personal/:id/editar" component={CrearEditarSolicitud} />

                <ProtectedRoute access={[1, 6, 11]} exact path="/reclutamiento" component={(props) => <ListarSolicitud {...props} isRRHH={true}/>} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/reclutamiento/:id/" component={(props) => <CrearEditarSolicitud {...props} isRRHH={true}/>} />

                <ProtectedRoute access={[1, 6, 11]} exact path="/tipo_contrato_vacaciones" component={ListarTipoContratoVacaciones} />
                <ProtectedRoute access={[1, 6, 11]} exact path="/tipo_contrato_vacaciones/:id/editar" component={EditarDiasVacaciones} />

                <ProtectedRoute access={[1, 5]} path="/complejidad_proyectos" component={ListarComplejidad} />

                <ProtectedRoute access={[1, 5]} path="/actividades_entrega" component={ListarActividadEntrega} />

                <ProtectedRoute access={[1, 5]} exact path="/plantillas_proyectos" component={ListarPlantillaProyecto} />
                <ProtectedRoute access={[1, 5]} exact path="/plantillas_proyectos/crear" component={CrearEditarPlantillaProyecto} />
                <ProtectedRoute access={[1, 5]} exact path="/plantillas_proyectos/:id/editar" component={CrearEditarPlantillaProyecto} />

                <ProtectedRoute access={[1, 5, 8]} exact path="/proyectos_operaciones" component={ListarProyectoOperaciones} />
                <ProtectedRoute access={[1, 5, 8]} exact path="/proyectos_operaciones/crear" component={CrearEditarProyectoOperaciones} />
                <ProtectedRoute access={[1, 5, 8]} exact path="/proyectos_operaciones/:id/editar" component={CrearEditarProyectoOperaciones} />

                <ProtectedRoute access={[1, 5]} path="/gastos_administrativos" component={ListarGastosAdministrativos} />

                <ProtectedRoute access={[0]} exact path="/disponibilidad" component={VisualizarReporte} />
                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
