import React from "react";
import { FieldArray } from "redux-form";

import { renderPersonal } from "../Renders/renderPersonal";

import LoadMask from "../../../Utils/LoadMask/LoadMask";

const ConfiguracionPersonal = (props) => {
    const {
        fases,
        seniority,
        puestos,
        currentPlantilla,
        change,
        isDisabled = false,
    } = props;
    const [loader, setLoader] = React.useState(false);
    const [faseSelected, setFaseSelected] = React.useState(
        fases[0] ? fases[0].id : 1
    );

    const handleSelectChange = (e) => {
        setFaseSelected(parseInt(e.target.value));
    };

    return (
        <LoadMask loading={loader} light>
            <div className="mt-3">
                <h5 className="title--blue--1">Configuración de personal</h5>
                <div className="card-form d-flex flex-column mt-4 justify-content-center align-items-center">
                    <div className="col-12 display--fieldset">
                        {fases.map((f) => (
                            <label className="container--1" key={f.id}>
                                {f.nombre}
                                <input
                                    type="radio"
                                    value={f.id}
                                    name="fases_proyecto_choice"
                                    className="input--style"
                                    onChange={handleSelectChange}
                                    checked={faseSelected === f.id}
                                />
                            </label>
                        ))}
                    </div>
                    <FieldArray
                        name="personal"
                        component={renderPersonal}
                        tipoFase={faseSelected}
                        allPuestos={puestos}
                        setLoader={setLoader}
                        seniority={seniority}
                        currentPlantilla={currentPlantilla}
                        change={change}
                        rerenderOnEveryChange={true}
                        isDisabled={isDisabled}
                    />
                </div>
            </div>
        </LoadMask>
    );
};

export { ConfiguracionPersonal };
