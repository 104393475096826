import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import { Link } from "react-router-dom";
import { TableHeaderColumn } from "react-bootstrap-table";
import Swal from "sweetalert2";

import moment from "moment";

const ESTADOS = {
    GUARDADO: { value: 1, label: "Guardados" },
    RECLUTAMIENTO: { value: 2, label: "Inicio Reclutamiento" },
    CONTRATADO: { value: 3, label: "Contratados" },
    RECHAZADO: { value: 4, label: "Rechazados" },
};

class Listar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            params: {
                activo: true,
                estado: props.isRRHH
                    ? ESTADOS.RECLUTAMIENTO.value
                    : ESTADOS.GUARDADO.value,
            },
        };
    }

    componentDidMount() {
        this.props.listar(this.state.page, this.state.params);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.params !== this.state.params) {
            const page = 1;
            this.setState({ page });
            this.props.listar(page, this.state.params);
        }
    }

    onPageChange = (page) => {
        this.props.listar(page, this.state.params);
        this.setState({ page });
    };

    enviarReclutamiento = (id) => {
        Swal.fire({
            title: "Reclutamiento",
            text: "¿Desea aperturar esta solicitud?",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Aperturar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                this.props.reclutamiento({ id });
                this.onPageChange(1);
            }
        });
    };

    trClassFormat(row, rowIndex) {
        const oddOrEven = rowIndex % 2 === 0 ? "tr-odd" : "tr-even";
        return `tr-table ${oddOrEven}`;
    }

    render() {
        const { data, loader, eliminar, listar, isRRHH = false } = this.props;
        return (
            <React.Fragment>
                <div className="flex row mt-2">
                    <div className="col-12">
                        <h2 className="title--blue">LISTADO DE SOLICITUDES</h2>
                    </div>
                </div>
                <hr />

                <div className="row justify-content-between">
                    <div>
                        {isRRHH
                            ? null
                            : Object.values(ESTADOS).map((s) => (
                                  <button
                                      key={s.label}
                                      disabled={loader}
                                      style={{ fontSize: 15 }}
                                      type="button"
                                      className={`btn m-3 ${
                                          this.state.params.estado === s.value
                                              ? "btn-outline-primary"
                                              : "btn-secondary"
                                      }`}
                                      onClick={() =>
                                          this.setState({
                                              params: {
                                                  activo: true,
                                                  estado: s.value,
                                              },
                                          })
                                      }
                                  >
                                      {s.label}
                                  </button>
                              ))}
                    </div>
                    {isRRHH ? null : (
                        <div>
                            <Link
                                to="/solicitud_personal/crear"
                                className="btn btn-primary m-3"
                                style={{ fontSize: 15 }}
                            >
                                Nueva solicitud
                            </Link>
                        </div>
                    )}
                </div>
                <Grid
                    data={data}
                    loading={loader}
                    page={this.state.page}
                    onPageChange={this.onPageChange}
                    trClassName={this.trClassFormat}
                >
                    <TableHeaderColumn
                        dataField="puesto_label"
                        dataSort
                        dataAlign="center"
                        width="20%"
                    />
                    <TableHeaderColumn
                        dataAlign="center"
                        dataField="tipo_proyecto_label"
                        dataSort
                    />
                    <TableHeaderColumn
                        dataField="tipo_plaza_label"
                        dataSort
                        dataAlign="center"
                    />
                    <TableHeaderColumn
                        dataField="solicitante_label"
                        dataSort
                        dataAlign="center"
                    />
                    <TableHeaderColumn
                        dataField="modificado"
                        dataSort
                        dataFormat={(cell) => moment(cell).format("l")}
                        dataAlign="center"
                    />

                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        dataFormat={(cell, row) => {
                            let props = {};
                            const extraActionsConfig = [];
                            if (
                                row.estado === ESTADOS.GUARDADO.value ||
                                row.estado === ESTADOS.RECHAZADO.value
                            ) {
                                const onDelete = (id) => () => {
                                    eliminar(id);
                                    listar(this.state.page, this.state.params);
                                };

                                extraActionsConfig.push({
                                    type: "delete",
                                    props: {
                                        key: "borrar_solicitud",
                                        onDelete: onDelete(cell),
                                        backgroundColor: "#CA0000",
                                        color: "#FFF",
                                    },
                                });

                                extraActionsConfig.push({
                                    type: "link",
                                    props: {
                                        key: "editar_solicitud",
                                        backgroundColor: "#1F4D7B",
                                        color: "#FFF",
                                        icon: "edit",
                                        to: `solicitud_personal/${cell}/editar`,
                                    },
                                });
                            }

                            if (
                                row.estado === ESTADOS.RECLUTAMIENTO.value ||
                                row.estado === ESTADOS.CONTRATADO.value
                            ) {
                                extraActionsConfig.push({
                                    type: "link",
                                    props: {
                                        key: "ver_solicitud",
                                        backgroundColor: "#2684E2",
                                        color: "#FFF",
                                        icon: "description",
                                        to: isRRHH
                                            ? `reclutamiento/${cell}/`
                                            : `solicitud_personal/${cell}/`,
                                    },
                                });
                            }

                            if (row.estado === ESTADOS.GUARDADO.value) {
                                extraActionsConfig.push({
                                    type: "button",
                                    props: {
                                        key: "enviar_reclutamiento",
                                        backgroundColor: "#0DA140",
                                        color: "#FFF",
                                        icon: "send",
                                        handleClick: () =>
                                            this.enviarReclutamiento(cell),
                                    },
                                });
                            }

                            const actions = standardActions(
                                { ...props },
                                extraActionsConfig
                            );
                            return actions(cell, row);
                        }}
                    />
                </Grid>
            </React.Fragment>
        );
    }
}

export default Listar;
