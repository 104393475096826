import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import { Link } from "react-router-dom";
import { TableHeaderColumn } from "react-bootstrap-table";
import FiltersData from "./FiltersData";
import moment from "moment";

class Listar extends Component {
    state = {
        page: 1,
        params: {
            activo: true,
        },
    };

    async componentDidMount() {
        this.props.listar(this.state.page, this.state.params);
        this.props.obtenerTipos();
        this.props.obtenerColaboradores();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.params !== this.state.params) {
            const page = 1
            this.setState({ page });
            this.props.listar(page, this.state.params);
        }
    }

    onPageChange = (page) => {
        this.props.listar(page, this.state.params);
        this.setState({ page });
    };

    onChangeParams = (newParam) => {
        let params = this.state.params;
        if (!newParam.value) {
            delete params[newParam.name];
            params = {
                ...params,
            };
        } else {
            params = {
                ...params,
                [newParam.name]: newParam.value,
            };
        }
        this.setState({ params });
    };

    render() {
        const { data, loader, eliminar, tipos, colaboradores, recursosLoader } =
            this.props;
        return (
            <React.Fragment>
                <div className="flex row mt-2">
                    <div className="col-12">
                        <h2 className="title--blue">LISTA DE AMONESTACIONES</h2>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <FiltersData
                        onChangeParams={this.onChangeParams.bind(this)}
                        colaboradores={colaboradores}
                        tipos={tipos}
                        currentParams={this.state.params}
                    />
                    <div
                        className="col-12 col-md-3"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Link
                            to="/amonestaciones/crear"
                            className="btn btn-primary mt-4"
                            style={{ fontSize: 15, borderRadius: "5px" }}
                        >
                            Nueva amonestación
                        </Link>
                    </div>
                </div>
                <Grid
                    data={data}
                    loading={loader || recursosLoader}
                    page={this.state.page}
                    onPageChange={this.onPageChange}
                >
                    <TableHeaderColumn dataField="colaborador_label" dataSort width="40%">
                        Colaborador
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="tipo_label" dataSort>
                        Tipo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        dataFormat={(cell, row) => {
                            const m = moment(cell);
                            return m.format("l");
                        }}
                    >
                        Fecha
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        dataSort
                        isKey
                        dataFormat={(cell, row) => {
                            let props = {};
                            props.editar = "amonestaciones";
                            props.eliminar = eliminar;
                            const actions = standardActions({ ...props });
                            return actions(cell, row);
                        }}
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
            </React.Fragment>
        );
    }
}

export default Listar;
