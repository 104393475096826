import React from "react";
import moment from "moment";

export const Holidays = ({ holidays = [] }) => {
    return (
        <div className="row mb-3">
            <div className="col-12">
                <h4 className="title--blue--1">Próximos asuetos</h4>
            </div>
            <hr />

            <div className="col-12">
                {holidays.length > 0 ? (
                    holidays.map((h) => {
                        const start = moment(h.fecha_inicio).format("LL");
                        const end = moment(h.fecha_fin).format("LL");
                        const date =
                            start === end ? start : `${start} al ${end}`;
                        return (
                            <div className="mb-4" key={h.id}>
                                <p className="m-0">
                                    {h.nombre} - {date}
                                    <span className="badge badge-primary mx-1">
                                        {h.pais.nombre}
                                    </span>
                                </p>
                                {h.proyectos.map((p) => (
                                    <span
                                        className="badge badge-dark m-1"
                                        key={p.id}
                                    >
                                        {p.nombre}
                                    </span>
                                ))}
                            </div>
                        );
                    })
                ) : (
                    <span className="badge badge-secondary p-2">
                        Sin asuetos en los próximos 30 días
                    </span>
                )}
            </div>
        </div>
    );
};
