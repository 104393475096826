import React from "react";

import { renderSelectField } from "../../Utils/renderField";

import { Field } from "redux-form";

export const renderRecursos = ({ fields, meta: { error }, recursos = [] }) => {
    const select_style = {
        control: (styles, state) => ({
            ...styles,
            color: "#1F4D7B",
            borderWidth: state.isFocused || !state.hasValue ? "1px" : "0px",
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            backgroundColor: "white",
        }),
    };

    return (
        <div className="col-10">
            {fields.length <= 0 && (
                <div className="col-12">
                    <span className="text-center">---</span>
                </div>
            )}

            <span className="text-danger">{error}</span>

            {fields.map((recurso, index, fields) => {
                return (
                    <div className="row field--array bg-white border-0" key={index}>
                        <div className="form-group has-feedback col-10 m-0">
                            <Field
                                name={recurso}
                                select_style={select_style}
                                component={renderSelectField}
                                options={recursos}
                                labelKey="label"
                                valueKey="value"
                                className="form-group"
                                placeholder="Recursos..."
                            />
                        </div>
                        <div className="col d-flex justify-content-end">
                            <button
                                className="btn standard-action action--delete"
                                type="button"
                                style={{
                                    color: "rgb(246, 81, 96)",
                                    backgroundColor: "rgba(246, 81, 96, 0.3)",
                                    padding: "0",
                                }}
                                onClick={() => fields.remove(index)}
                            >
                                <i className="material-icons">delete</i>
                            </button>
                        </div>
                    </div>
                );
            })}
            <button
                type="button"
                className="btn mb-3 d-flex align-items-center justify-conten-center"
                onClick={() => fields.push(null)}
            >
                <i
                    className="material-icons title--blue--1"
                    style={{ fontSize: "24px" }}
                >
                    add
                </i>
                <p
                    className="title--blue--1"
                    style={{ margin: "0", fontSize: "14px" }}
                >
                    Agregar
                </p>
            </button>
        </div>
    );
};
