import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/departamento_organizacion/departamento_organizacion';
import { actions as actionsRecursos } from '../../../../redux/modules/recursos/recursos';
import CrearEditar from './CrearEditar';

const ms2p = (state) => {
  return {
      ...state.departamento_organizacion,
      recursoLoader: state.recursos.loader,
      recursos: state.recursos.filter,
  };
};

const md2p = { ...actions, obtenerRecursos: actionsRecursos.filter };

export default connect(ms2p, md2p)(CrearEditar);
