import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import "./Historial.css";

export const Historial = ({ historial }) => {
    const [show, setShow] = React.useState(false);

    const mountedStyle = {
        animation: "inAnimation 250ms ease-in",
    };
    const unmountedStyle = {
        animation: "outAnimation 270ms ease-out",
        animationFillMode: "forwards",
    };

    const rotateArrowStyle = {
        transform: "rotate(90deg)",
    };

    return (
        <React.Fragment>
            <h5 className="text--blue--1 mt-4 mb-0">
                Historial de cambios
                <button
                    className="btn"
                    onClick={() => setShow((prev) => !prev)}
                    type="button"
                >
                    <i
                        className="material-icons"
                        style={show ? rotateArrowStyle : {}}
                    >
                        arrow_right
                    </i>
                </button>
            </h5>
            <div
                className="historial-container"
                style={show ? mountedStyle : unmountedStyle}
            >
                <BootstrapTable
                    data={historial}
                    tableHeaderClass="historial-header"
                    tableBodyClass="historial-body"
                    bordered={false}
                    options={{
                        noDataText: "---",
                    }}
                >
                    <TableHeaderColumn
                        dataField="creado"
                        dataFormat={(cell) => {
                            return moment(cell).format("L");
                        }}
                        dataAlign="center"
                    />
                    <TableHeaderColumn
                        dataField="creado"
                        dataFormat={(cell) => {
                            return moment(cell).format("hh:mm A");
                        }}
                        dataAlign="center"
                    />
                    <TableHeaderColumn
                        dataField="descripcion"
                        dataAlign="center"
                    />
                    <TableHeaderColumn
                        dataField="id"
                        isKey={true}
                        dataFormat={(cell, row) => {
                            return `${row.usuario.first_name} ${row.usuario.last_name}`;
                        }}
                        dataAlign="center"
                    />
                </BootstrapTable>
            </div>
        </React.Fragment>
    );
};
