import React from "react";
import { Line, Bar } from "react-chartjs-2";

export const ChartLine = ({ title, total, lastUpdate, data }) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: false,
                text: "Recursos",
            },
        },
    };

    return (
        <div className="card mb-3 mt-3 card--reportes mx-auto">
            <div className="header-info">
                <h6 className="font-weight-bold m-0">{title}</h6>
            </div>
            <div className="chart-details">
                <p className="total-title">{total}</p>
                <p className="update-info">{lastUpdate}</p>
            </div>
            <div className="card-body">
                <Line data={data} options={options} height={115}/>
            </div>
        </div>
    );
};
