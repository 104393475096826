import React from "react";

import { renderSelectField, renderNumber } from "../../../Utils/renderField";

import { Field } from "redux-form";

export const renderRequisitos = ({
    fields,
    meta: { error },
    actividades = [],
    show = true,
    fases = [],
    isDisabled = false,
}) => {
    const select_style = {
        control: (styles, state) => ({
            ...styles,
            color: state.isDisabled ? "black" : "#1F4D7B",
            borderWidth: state.isFocused || !state.hasValue ? "1px" : "0px",
            backgroundColor: "inherit",
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            backgroundColor: "white",
        }),
    };

    return (
        <div
            className="col-10 mt-4"
            style={{
                display: show ? "block" : "none",
            }}
        >
            <div className="row mb-1">
                <span className="col-3 title--blue--1">Fase</span>
                <span className="col-5 title--blue--1">Actividad</span>
                <span className="col-2 title--blue--1 p-0">Total de horas</span>
            </div>

            {fields.length <= 0 && (
                <div className="col-12">
                    <span className="text-center">---</span>
                </div>
            )}

            <span className="text-danger">{error}</span>

            {fields.map((requisito, index) => {
                const currentField = fields.get(index);
                return (
                    <div className="row field--array bg-white" key={index}>
                        <div className="form-group has-feedback col-3 m-0 pl-0">
                            <Field
                                name={`${requisito}.fase`}
                                select_style={select_style}
                                component={renderSelectField}
                                label={`#${index + 1}`}
                                options={fases}
                                labelKey="nombre"
                                valueKey="id"
                                className="form-group"
                                placeholder="Fase..."
                                disabled={isDisabled}
                            />
                        </div>
                        <div className="form-group has-feedback col-5 m-0 pl-0">
                            <Field
                                name={`${requisito}.actividad`}
                                select_style={select_style}
                                component={renderSelectField}
                                label={`#${index + 1}`}
                                options={actividades}
                                labelKey="label"
                                valueKey="value"
                                className="form-group"
                                placeholder="Actividad..."
                                disabled={
                                    currentField.de_plantilla || isDisabled
                                }
                            />
                        </div>
                        <div className="form-group has-feedback col-2 m-0 p-0">
                            <Field
                                name={`${requisito}.total_horas`}
                                component={renderNumber}
                                suffix=" hrs"
                                label={`Total de horas`}
                                placeholder="Total de horas..."
                                readOnly={isDisabled}
                            />
                        </div>
                        <div className="col d-flex justify-content-end">
                            {!currentField.de_plantilla ? (
                                <button
                                    className="btn standard-action action--delete"
                                    type="button"
                                    style={{
                                        color: "rgb(246, 81, 96)",
                                        backgroundColor:
                                            "rgba(246, 81, 96, 0.3)",
                                        padding: "0",
                                    }}
                                    onClick={() => fields.remove(index)}
                                    disabled={isDisabled}
                                >
                                    <i className="material-icons">delete</i>
                                </button>
                            ) : null}
                        </div>
                    </div>
                );
            })}

            <button
                type="button"
                className="btn mb-3 d-flex align-items-center justify-conten-center"
                onClick={() => fields.push({ de_plantilla: false })}
                disabled={isDisabled}
            >
                <i
                    className="material-icons title--blue--1"
                    style={{ fontSize: "24px" }}
                >
                    add
                </i>
                <p
                    className="title--blue--1"
                    style={{ margin: "0", fontSize: "14px" }}
                >
                    Agregar
                </p>
            </button>
        </div>
    );
};
