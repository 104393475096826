import React from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import { api } from "api";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { BIRTHDAYS } from "../../../../redux/modules/recursos/recursos";

const localizer = momentLocalizer(moment);

export const Visualizar = ({}) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { reports = {}, loading, currentDate } = state;

    React.useEffect(() => {
        getReports(currentDate);
    }, [currentDate]);

    const getReports = async (date) => {
        try {
            onLoad();
            const reports = await api.get(BIRTHDAYS, 
            //     {
            //     birthday: moment().month() + 1,
            // }
        );
            onGetReports(reports);
        } catch (e) {
            console.error(e);
        }
    };


    const onLoad = () => dispatch({ type: actionTypes.loading });
    const onGetReports = (reports = {}) => {
        dispatch({ type: actionTypes.setReports, payload: reports });
    };

    const onNavigate = (date) => {
        dispatch({ type: actionTypes.setDate, payload: date });
    };

    const events = Object.entries(reports).map(([date, recursos]) => {
        return recursos.map((recurso) => {
            const localDate = moment(date).toDate(); 
            return {
                title: `${recurso.nombre} - ${recurso.codigo}`,
                start: localDate,
                end: localDate,
                allDay: true,
            };
        });
    }).flat();

    // Obtener el mes actual basado en currentDate
    const currentMonth = moment(currentDate).format("MMMM");

    return (
        <LoadMask loading={loading} blur={true}>
            <div className="flex row mt-2">
                <div className="col-12">
                    <h2 className="title--blue">Cumpleaños de {currentMonth} </h2>
                    
                </div>
            </div>
            <hr />
            <div className="row w-100 h-100 mx-auto">
                <Calendar
                    views={["month"]}
                    localizer={localizer}
                    date= {currentDate}
                    defaultView="month"
                    events={events}
                    style={{ height: "100vh", width: "100%" }}
                    onNavigate={onNavigate} 
                />
            </div>
        </LoadMask>
    );
};

const initialState = {
    loading: false,
    reports: {},
    currentDate: new Date(),
};

const actionTypes = {
    loading: "LOADING",
    setReports: "SET_REPORTS",
    setDate: "SET_DATE",
};

const reducerObject = (state, payload) => ({
    [actionTypes.setReports]: {
        ...state,
        reports: payload,
        loading: false,
    },
    [actionTypes.loading]: {
        ...state,
        loading: true,
    },
    [actionTypes.setDate]: {
        ...state,
        currentDate: payload,
    },
});

const reducer = (state, action) => {
    return reducerObject(state, action.payload)[action.type] || state;
};
