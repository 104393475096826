import LoadMask from "Utils/LoadMask/LoadMask";
import CrearEditarForm from "./CrearEditarForm";
import React, { Component } from "react";
import { api } from "api";

class CrearEditar extends Component {
    state = {
        edit: false,
        loader: true,
        id: null,
        paises:[],
    };

    componentDidMount() {
        api.get("pais/filter_list").then((paises)=>{
            this.setState({paises});
        });
        this.setState({ loader: false });
        const edit = this.props.match.params.id ? true : false;
        this.setState({ edit });
        if (edit == true) {
            const id = this.props.match.params.id;
            this.setState({ id });
            this.props.obtenerCliente(id);
        }
    }

    onSubmit = (data) => {
        const { edit, id } = this.state;
        const { onSubmit, actualizarCliente } = this.props;
        if (edit) {
            actualizarCliente(data, id);
        } else {
            onSubmit(data);
        }
    };

    render() {
        const { loader } = this.props;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h2 className="title--blue">
                            {this.state.edit ? "EDITAR" : "CREAR"} CLIENTE
                        </h2>
                    </div>
                </div>
                <hr />
                <div className="login-wrapper">
                    <div className="card card-login w-100">
                        <LoadMask loading={loader || this.state.loader} light>
                            <CrearEditarForm
                                onSubmit={this.onSubmit}
                                paises={this.state.paises}
                            />
                        </LoadMask>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CrearEditar;
