import React from "react";
import moment from "moment";
import Popup from "reactjs-popup";
import Info_Recurso from "./Info_Recurso";

const Ficha_Recurso = ({
    open,
    onClose,
    dataRecurso,
    dias,
    fechaSelect,
    index_recurso,
}) => {
    const porcentajes = dataRecurso[fechaSelect] || [];
    return (
        <Popup modal open={open} onClose={onClose} repositionOnResize={true}>
            <div className="card pb-2">
                <div className="card-header card--header">
                    Ficha del{" "}
                    {dataRecurso.puesto == 2 ? "programador" : "diseñador"}
                </div>
                <div
                    className="card-body tabla-resumen table--scroll"
                    style={{ paddingBottom: "0px" }}
                >
                    <Info_Recurso
                        dataRecurso={dataRecurso}
                        index_recurso={index_recurso}
                    />
                    {dias ? (
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th className="text--blue--1">Fecha</th>
                                    {dias.map((dia, index) => (
                                        <th key={index} className="text--grey">
                                            {moment(dia).format("DD/MM/YYYY")}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center text--blue--1 text--blueBold">
                                        %
                                    </td>
                                    {porcentajes.length > 0
                                        ? porcentajes.map(
                                              (porcentaje, index) => (
                                                  <td
                                                      key={index}
                                                      className="text-center text--grey"
                                                  >
                                                      {`${porcentaje}%`}
                                                  </td>
                                              )
                                          )
                                        : "---"}
                                </tr>
                            </tbody>
                        </table>
                    ) : null}
                </div>
            </div>
        </Popup>
    );
};

export default Ficha_Recurso;
