import React from "react";

import { renderFilePicker } from "../../../Utils/renderField";

import { Field } from "redux-form";

const openNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
};

export const renderFiles = ({
    fields,
    meta: { error },
    edit,
    isDisabled = false,
}) => {
    return (
        <div>
            <div>
                <button
                    type="button"
                    className="btn btn-dark p-1 mb-3"
                    onClick={() => fields.push({})}
                    disabled={isDisabled}
                >
                    <i className="material-icons">add</i>
                </button>
            </div>
            <span className="text-danger">{error}</span>
            <div className="row">
                {fields.map((documento, index, fields2) => {
                    const current = fields2.get(index);
                    return (
                        <div className="d-flex col-6 mb-3" key={index}>
                            <div className="form-group has-feedback mb-1 col-8">
                                <Field
                                    name={`${documento}.archivo`}
                                    component={renderFilePicker}
                                    photo={current.photo || null}
                                    className="form-control"
                                    types={[
                                        "application/pdf",
                                        "image/jpeg",
                                        "image/png",
                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                    ]}
                                    disabled={isDisabled}
                                />
                                {edit && current.photo && current.id > 0 ? (
                                    <button
                                        type="button"
                                        className="btn btn-dark"
                                        disabled={!current.photo}
                                        onClick={() =>
                                            openNewTab(current.photo)
                                        }
                                    >
                                        Ver archivo
                                    </button>
                                ) : null}
                            </div>
                            <div className="col-2 m-0 p-0">
                                <button
                                    className="btn btn-danger p-0 ml-3"
                                    type="button"
                                    onClick={() => fields.remove(index)}
                                    disabled={isDisabled}
                                >
                                    <i className="material-icons">delete</i>
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
