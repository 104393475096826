import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import {
    renderField,
    renderSelectField,
    renderCurrency,
    renderNumber,
} from "../../Utils/renderField";

const CrearEditarForm = (props) => {
    const { handleSubmit, puestos } = props;
    return (
        <form
            name="CrearEditarForm"
            className="form-validate mb-lg"
            onSubmit={handleSubmit}
        >
            <div className="row">
                <div className="form-group has-feedback col-6">
                    <label htmlFor="nombre">Nombre</label>
                    <Field
                        name="nombre"
                        label="Nombre"
                        component={renderField}
                        type="text"
                        className="input--style"
                    />
                </div>
                <div className="form-group has-feedback col-6">
                    <label htmlFor="puesto">Puesto</label>
                    <Field
                        name="puesto"
                        label="Puesto"
                        component={renderSelectField}
                        options={puestos}
                        className="input--style"
                        labelKey="label"
                        valueKey="value"
                        select_style={{
                            control: (styles) => ({
                                ...styles,
                                color: "#1F4D7B",
                                borderColor: "#1F4D7B",
                            }),
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group has-feedback col-6">
                    <label htmlFor="salario_minimo">Salario mínimo</label>
                    <Field
                        name="salario_minimo"
                        label="Salario mínimo"
                        component={renderCurrency}
                        className="input--style"
                        prefix="$ "
                    />
                </div>
                <div className="form-group has-feedback col-6">
                    <label htmlFor="salario_maximo">Salario máximo</label>
                    <Field
                        name="salario_maximo"
                        label="Salario máximo"
                        component={renderCurrency}
                        className="input--style"
                        prefix="$ "
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group has-feedback col-6">
                    <label htmlFor="hora_minima">Tarifa mínima</label>
                    <Field
                        name="hora_minima"
                        component={renderNumber}
                        className="input--style"
                        prefix="$ "
                        suffix=".00"
                    />
                </div>
                <div className="form-group has-feedback col-6">
                    <label htmlFor="hora_maxima">Tarifa máxima</label>
                    <Field
                        name="hora_maxima"
                        component={renderNumber}
                        className="input--style"
                        prefix="$ "
                        suffix=".00"
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group has-feedback col-6">
                    <label htmlFor="hora_default">Hora default</label>
                    <Field
                        name="hora_default"
                        label="Hora default"
                        component={renderNumber}
                        className="input--style"
                        suffix=" hrs"
                    />
                </div>
            </div>
            <div className="buttons-box">
                <Link
                    to="/seniority"
                    className="btn btn-secondary"
                    style={{ fontSize: 15 }}
                >
                    Regresar
                </Link>
                <button
                    type="submit"
                    style={{ fontSize: 15 }}
                    className="btn btn-primary m-1 align-self-center"
                >
                    Guardar
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "CrearEditarForm", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()("Este campo es requerido"),
            puesto: validators.exists()("Este campo es requerido"),
            salario_minimo: validators.exists()("Este campo es requerido"),
            salario_maximo: validators.exists()("Este campo es requerido"),
            hora_minima: validators.exists()("Este campo es requerido"),
            hora_maxima: validators.exists()("Este campo es requerido"),
            hora_default: validators.exists()("Este campo es requerido"),
        });
    },
})(CrearEditarForm);
