import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import { proyectos_operaciones_estados } from "../../../../utility/constants";
import FiltersData from "./FiltersData";

import moment from "moment";

const { EN_PROCESO, EN_REVISION, APROBADO, VENTA_GANADA, VENTA_PERDIDA } =
    proyectos_operaciones_estados;

const estados = [
    { label: "En proceso", value: EN_PROCESO },
    { label: "En revisión", value: EN_REVISION },
    { label: "Aprobado", value: APROBADO },
    { label: "Venta ganada", value: VENTA_GANADA },
    { label: "Venta perdida", value: VENTA_PERDIDA },
];

class Listar extends Component {
    state = {
        page: 1,
        search: "",
        BreakLine: {
            whiteSpace: "normal",
        },
        params: {
            activo: true,
            estado: estados[0].value,
        },
    };

    componentDidMount() {
        this.props.listar(this.state.page, this.state.params);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.params !== prevState.params) {
            this.props.listar(this.state.page, this.state.params);
        }
    }

    onPageChange = (page) => {
        this.props.listar(page, this.state.params);
        this.setState({ page });
    };


    // Define la función para manejar cambios en los parámetros de búsqueda
    onChangeParams = (newParams) => {
        const { fecha_inicio, fecha_fin } = newParams;
        // Verificar si las fechas son de tipo Date y convertirlas a string si es necesario
        const formattedParams = {
            ...newParams,
            fecha_inicio: fecha_inicio instanceof Date ? moment(fecha_inicio).format("YYYY-MM-DD") : fecha_inicio,
            fecha_fin: fecha_fin instanceof Date ? moment(fecha_fin).format("YYYY-MM-DD") : fecha_fin,
        };
        // Actualizar el estado con los nuevos parámetros formateados
        this.setState({ params: formattedParams }, () => {
            // Después de actualizar los parámetros, llama directamente a this.props.listar
            this.props.listar(this.state.page, this.state.params);
        });
    };
    
    

    render() {
        const { data, loader, eliminar, draft, clearDraft, enviarAOperaciones } =
            this.props;
        return (
            <React.Fragment>
                <div className="flex row mt-2">
                    <div className="col-12">
                        <h2 className="title--blue">LISTA DE PROYECTOS</h2>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div
                        className="col-12 d-flex justify-content-end mb-3"
                    >
                        {draft ? (
                            <button
                                onClick={() => {
                                    this.props.history.push(
                                        "/proyectos_operaciones/crear"
                                    );
                                }}
                                className="btn btn-primary mt-4 mr-4"
                                style={{ fontSize: 15, borderRadius: "5px" }}
                            >
                                Continuar...
                            </button>
                        ) : null}
                        <button
                            onClick={() => {
                                clearDraft();
                                this.props.history.push(
                                    "/proyectos_operaciones/crear"
                                );
                            }}
                            className="btn btn-primary mt-4"
                            style={{ fontSize: 15, borderRadius: "5px" }}
                        >
                            Nuevo proyecto
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-8 display--fieldset">
                        {estados.map((estado) => (
                            <label className="container--1" key={estado.value}>
                                {estado.label}
                                <input
                                    disabled={loader}
                                    type="radio"
                                    name="proyectos_operaciones"
                                    value={estado.value}
                                    checked={
                                        this.state.params.estado ===
                                        estado.value
                                    }
                                    onChange={() =>
                                        this.setState({
                                            params: {
                                                activo: true,
                                                estado: estado.value,
                                            },
                                        })
                                    }
                                />
                            </label>
                        ))}
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-12 col-md-6"
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                    >
                     <input
                        placeholder="Buscar..."
                        type="text"
                        className="form-control mt-4 input--style"
                        value={this.state.search}
                        onKeyDown={(key) => {
                            if (key.key == "Enter") {
                                const { page, search, params: { fecha_inicio, fecha_fin } } = this.state;
                                let filterParams = { search, estado: this.state.params.estado };
                                if (fecha_inicio) filterParams.fecha_inicio = fecha_inicio;
                                if (fecha_fin) filterParams.fecha_fin = fecha_fin;
                                this.props.listar(page, filterParams);
                            }
                        }}
                        onChange={(tx) => {
                            this.setState({ search: tx.target.value });
                        }}
                        style={{
                            color: "black",
                            fontSize: 15,
                            width: "55%",
                            float: "right",
                        }}
                    />
                    </div>
                        {/* coso filtro */}
                        <FiltersData
                            onChangeParams={this.onChangeParams}
                            currentParams={this.state.params}
                        />
                         {/* fin coso filtro */}
                </div>
                <Grid
                    data={data}
                    loading={loader}
                    page={this.state.page}
                    onPageChange={this.onPageChange}
                >
                    <TableHeaderColumn dataField="nombre" dataSort>
                        Nombre
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cliente"
                        dataSort
                        dataFormat={(cell, row) => `${cell.nombre}`}
                    >
                        Cliente
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="plantilla"
                        dataSort
                        dataFormat={(cell, row) =>
                            `${cell.complejidad.descripcion}`
                        }
                    >
                        Complejidad
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="plantilla"
                        dataSort
                        dataFormat={(cell, row) => `${cell.nombre}`}
                    >
                        Plantilla
                    </TableHeaderColumn>

                    {/* se agrega una nueva columna, esta aparece en base a la fase seleccionada y muestra la fecha correspondiente */}

                    <TableHeaderColumn
                        dataField="fecha_ingreso"
                        dataSort
                        dataFormat={(cell, row) => {
                            let value = "---";
                            switch (this.state.params.estado) {
                                case EN_PROCESO:
                                    value = row.fecha_ingreso_en_proceso;
                                    break;
                                case EN_REVISION:
                                    value = row.fecha_ingreso_en_revision;
                                    break;
                                case APROBADO:
                                    value = row.fecha_ingreso_aprobado;
                                    break;
                                case VENTA_GANADA:
                                    value = row.fecha_ingreso_venta_ganada;
                                    break;
                                case VENTA_PERDIDA:
                                    value = row.fecha_ingreso_venta_perdida;
                                    break;
                                default:
                                    break;
                            }
                            // Formateo de la fecha si existe
                            if (value) {
                                const date = moment(value, "YYYY-MM-DD HH:mm:ss.SSSSSS");
                                value = date.format("DD/MM/YYYY");
                            }
                            return <span className="m-0 text-center">{value}</span>;
                        }}
                    >
                        Fecha de ingreso
                    </TableHeaderColumn>


                    {/* se agrega una nueva columna, esta solo aparece si la venta esta ganada */}

                    {this.state.params.estado === VENTA_GANADA && (
                        <TableHeaderColumn
                            dataField="enviado_a_operaciones"
                            dataSort
                            dataFormat={(cell, row) => (cell ? "Sí" : "No")}
                        >
                            Enviado a Operaciones
                        </TableHeaderColumn>
                    )}

                    <TableHeaderColumn
                        dataField="id"
                        dataSort
                        isKey
                        dataFormat={(cell, row) => {
                            let props = {};
                            props.editar = "proyectos_operaciones";
                            props.eliminar = eliminar;
                            // Agrega el botón "Enviar a Operaciones" si el estado es VENTA_GANADA
                            if (this.state.params.estado === VENTA_GANADA) {
                                props.enviarAOperaciones = enviarAOperaciones;
                            }
                            const actions = standardActions({ ...props });
                            return actions(cell, row);
                        }}
                    >
                        Acciones
                    </TableHeaderColumn>

                    

                </Grid>
            </React.Fragment>
        );
    }

}

export default Listar;
