import React from "react";
import { FieldArray } from "redux-form";

import { renderRequisitos } from "../Renders/renderRequisitos";
import { renderGastos } from "../Renders/renderGastos";
import { renderModulos } from "../Renders/renderModulos";
import {
    calculateModulos,
    calculateActividades,
    calculateGastos,
} from "../FormSteps/calculations";

const Modulos = (props) => {
    const {
        actividades,
        gastos,
        fases,
        crearActividad,
        currentPlantilla,
        actividadesValue,
        gastosValue,
        modulosValue,
        isDisabled = false,
    } = props;
    const modulos = [
        { nombre: "Módulos", id: 1 },
        { nombre: "Requisitos de la entrega", id: 2 },
        { nombre: "Gastos administrativos", id: 3 },
    ];
    const [moduloSelected, setModuloSelected] = React.useState(
        modulos[0] ? modulos[0].id : 1
    );

    const handleSelectChange = (e) => {
        setModuloSelected(parseInt(e.target.value));
    };

    const totalModulos = React.useMemo(() => {
        return calculateModulos(modulosValue);
    }, [modulosValue]);

    const totalActividades = React.useMemo(() => {
        return calculateActividades(actividadesValue);
    }, [actividadesValue]);

    const totalGastos = React.useMemo(() => {
        return Number(calculateGastos(gastosValue)).toFixed(2);
    }, [gastosValue]);

    return (
        <div className="mt-3">
            <h5 className="title--blue--1"></h5>
            <div className="d-flex flex-column mt-4 justify-content-center align-items-center">
                <div className="col-12 display--fieldset">
                    {modulos.map((modulo) => (
                        <label className="container--1 w-25" key={modulo.id}>
                            {modulo.nombre}
                            <input
                                type="radio"
                                value={modulo.id}
                                name="modulos_proyecto_choice"
                                className="input--style"
                                onChange={handleSelectChange}
                                checked={moduloSelected === modulo.id}
                            />
                        </label>
                    ))}
                </div>
                <div className="mx-auto mt-3 d-flex justify-content-center flex-wrap">
                    <div className="card-form p-3 m-1">
                        <span className="title--blue--1">Total módulos:</span>
                        <p className="m-0">{totalModulos} hrs</p>
                    </div>
                    <div className="card-form p-3 m-1">
                        <span className="title--blue--1">Total requisitos</span>
                        <p className="m-0">{totalActividades} hrs</p>
                    </div>
                    <div className="card-form p-3 m-1">
                        <span className="title--blue--1">Total gastos:</span>
                        <p className="m-0">${totalGastos}</p>
                    </div>
                </div>
                <FieldArray
                    name="modulos"
                    component={renderModulos}
                    actividades={actividades}
                    show={moduloSelected === 1}
                    fases={fases}
                    crearActividad={crearActividad}
                    currentPlantilla={currentPlantilla}
                    isDisabled={isDisabled}
                />
                <FieldArray
                    name="actividades"
                    component={renderRequisitos}
                    actividades={actividades}
                    show={moduloSelected === 2}
                    fases={fases}
                    isDisabled={isDisabled}
                />
                <FieldArray
                    name="gastos"
                    component={renderGastos}
                    gastos={gastos}
                    show={moduloSelected === 3}
                    isDisabled={isDisabled}
                />
            </div>
        </div>
    );
};

export { Modulos };
