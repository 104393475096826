import React from "react";
import "../../../../../style/custom.css";
import Swal from "sweetalert2";

const Finalizar = (props) => {

    const submit = () => {
        for(let i = 0; i < props.horas.length; i++) {
            if(props.horas[i].horas_ejecutadas == '') {
                Swal.fire({
                    title: "¡Atención!",
                    text: 'Por favor, llene todas las horas.',
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Aceptar",
                });
                return;
            }
        }
        props.finalizarProyecto(props.finalizar_data);
    }

    return (
        <React.Fragment>
            <div className="card-header card--header">
                Finalizar proyecto
            </div>
            <table className="table resources--table mt-4">
                <thead className="thead--blue">
                    <tr>
                        <th>Fase</th>
                        <th>Horas programadas</th>
                        <th>Horas ejecutadas</th>
                    </tr>
                </thead>
                <tbody>
                    {props.horas.map((e, i) => (
                        <tr key={i} className={`${e.horas_planificadas < parseFloat(e.horas_ejecutadas) ? 'tr--red' : null}`}>
                            <td>{e.nombre}</td>
                            <td>{e.horas_planificadas}</td>
                            <td>
                                <input
                                    name={`${i}_horas_ejecutadas`}
                                    id={`${i}_horas_ejecutadas`}
                                    className="form-control input--style"
                                    type="number"
                                    placeholder="0"
                                    onChange={(e) =>
                                        props.updateArray1(
                                            i,
                                            e.target.value,
                                            "horas_ejecutadas"
                                        )
                                    }
                                    value={e.horas_ejecutadas}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="d-flex justify-content-center">
                <button onClick={() =>  submit()} type="button" className="btn btn-info">
                    Finalizar
                </button>
            </div>
        </React.Fragment>
    );
};

export default Finalizar;
