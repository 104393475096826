import React from "react";

import {
    renderSelectField,
    renderNumber,
    renderFieldCheck,
    renderSwitch,
} from "../../../Utils/renderField";

import { Field } from "redux-form";

export const renderFases = ({
    fields,
    meta: { error, touched, submitFailed },
    puestos = [],
    tipoFase,
}) => {
    const select_style = {
        control: (styles, state) => ({
            ...styles,
            color: "#1F4D7B",
            borderWidth: state.isFocused || !state.hasValue ? "1px" : "0px",
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            backgroundColor: "white",
        }),
    };

    return (
        <div className="col-10 mt-4">
            <div className="row mb-1">
                <span className="col-5 title--blue--1">Puesto asignado</span>
                <span className="col-3 title--blue--1 p-0">
                    Horas semanales
                </span>
                <span className="col title--blue--1 p-0">¿Suma semana?</span>
            </div>

            {fields.length <= 0 && (
                <div className="col-12">
                    <span className="text-center">---</span>
                </div>
            )}

            {(touched || submitFailed) && error && (
                <span className="text-danger">{error}</span>
            )}

            {fields.map((fase, index, fields) => {
                const currentField = fields.get(index);

                return (
                    <div
                        className="row field--array"
                        key={index}
                        style={{
                            display:
                                currentField.fase !== tipoFase
                                    ? "none"
                                    : "flex",
                        }}
                    >
                        <div className="form-group has-feedback col-5 m-0">
                            <Field
                                name={`${fase}.puesto`}
                                select_style={select_style}
                                component={renderSelectField}
                                label={`#${index + 1}`}
                                options={puestos}
                                labelKey="label"
                                valueKey="value"
                                className="form-group"
                                placeholder="Puesto..."
                            />
                        </div>
                        <div className="form-group has-feedback col-3 m-0 p-0">
                            <Field
                                name={`${fase}.horas_semanales`}
                                component={renderNumber}
                                suffix=" hrs"
                                label={`Horas`}
                                placeholder="Horas..."
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-center form-group has-feedback col m-0 p-0">
                            <Field
                                name={`${fase}.suma_semana`}
                                component={renderSwitch}
                            />
                        </div>
                        <div className="col d-flex justify-content-end">
                            <button
                                className="btn standard-action action--delete"
                                type="button"
                                style={{
                                    color: "rgb(246, 81, 96)",
                                    backgroundColor: "rgba(246, 81, 96, 0.3)",
                                    padding: "0",
                                }}
                                onClick={() => fields.remove(index)}
                            >
                                <i className="material-icons">delete</i>
                            </button>
                        </div>
                    </div>
                );
            })}
            <button
                type="button"
                className="btn mb-3 d-flex align-items-center justify-conten-center"
                onClick={() =>
                    fields.push({ fase: tipoFase, suma_semana: true })
                }
            >
                <i
                    className="material-icons title--blue--1"
                    style={{ fontSize: "24px" }}
                >
                    add
                </i>
                <p
                    className="title--blue--1"
                    style={{ margin: "0", fontSize: "14px" }}
                >
                    Agregar
                </p>
            </button>
        </div>
    );
};
