import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import Swal from 'sweetalert2';
import { api } from "api";

const LOADER = 'PUESTO_LOADER';
const DATA = "PUESTO_DATA";
const FILTER = "PUESTO_FILTER";
const SUBMIT = "PUESTO_SUBMIT";
const FILTER_ALL = "PUESTO_FILTER_ALL";
const FILTER_DEPARTMENT = "PUESTO_FILTER_DEPARTMENT";   

const ENDPOINT = "puesto";

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setData = data => ({
    type: DATA,
    data
});

export const setFilter = filter => ({
    type: FILTER,
    filter
})

export const setFilterAll = filterAll => ({
    type: FILTER_ALL,
    filterAll
})

export const setFilterDepartment = filterDepartment => ({
    type: FILTER_DEPARTMENT,
    filterDepartment
})  

// ------------------------------------
// Actions
// ------------------------------------
export const onSubmit = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post(`${ENDPOINT}`, data).then(() => {
        Swal.fire({
            title: 'Éxito',
            text: 'Se ha guardado correctamente',
            type: 'success',
        })
        dispatch(push("/puestos"));
    }).catch(() => {
        Swal.fire({
            title: 'Error',
            text: 'Ha ocurrido un error, inténtelo nuevamente.',
            type: 'error',
        })
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listar = (page=1, params={}) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${ENDPOINT}`, { page, ...params }).then(response => {
        dispatch(setData(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const obtenerPuesto = (id=null) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${ENDPOINT}/${id}`).then(response => {
        dispatch(initializeForm('CrearEditarForm', response));
    })
        .catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actualizarPuesto = (data={}, id=null) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${ENDPOINT}/${id}`, data).then(() => {
        Swal.fire({
            title: 'Éxito',
            text: 'Se ha actualizado correctamente',
            type: 'success',
        })
        dispatch(push("/puestos"));
    }).catch(() => {
        Swal.fire({
            title: 'Error',
            text: 'Ha ocurrido un error, inténtelo nuevamente.',
            type: 'error',
        })
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${ENDPOINT}/${id}`).then(() => {
        Swal.fire({
            title: 'Éxito',
            text: 'Se ha eliminado correctamente',
            type: 'success',
        })
    })
        .catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const filter = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${ENDPOINT}/filter_list`).then(response => {
        dispatch(setFilter(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const filterAll = (params = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${ENDPOINT}/select_list`, params).then(response => {
        dispatch(setFilterAll(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const filterDepartment =  (departamentoId) => {
    return async (dispatch) => {
      try {
        const response = await api.get(`${ENDPOINT}/select_list?departamento=${departamentoId}`);
        dispatch({ type: 'SET_PUESTOS', payload: response });
        return response;
      } catch (error) {
        console.error('Error fetching puestos:', error);
        throw error;
      }
    };
  };



export const listAll = () => (dispatch) => {
    api.get(`${ENDPOINT}/filter_list`).then(response => {
        dispatch(setFilterAll(response));
    });
}   


export const actions = {
    onSubmit,
    eliminar,
    obtenerPuesto,
    actualizarPuesto,
    listar,
    filter,
    filterAll,
    filterDepartment,
    listAll,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [FILTER]: (state, { filter }) => {
        return {
            ...state,
            filter,
        };
    },
    [FILTER_ALL]: (state, { filterAll }) => {
        return {
            ...state,
            filterAll,
        };
    },
    [FILTER_DEPARTMENT]: (state, { filterDepartment }) => {
        return {
            ...state,
            filterDepartment,
        };
    },
};

export const initialState = {
    loader: false,
    data: [],
    filter: [],
    filterAll: [],
    filterDepartment: [],
};

export default handleActions(reducers, initialState);
