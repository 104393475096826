import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import validate from "./validate";
import { DatosProyecto } from "../Secciones/DatosProyecto";
import { Modulos } from "../Secciones/Modulos";
import { PlantillaSummary } from "../Secciones/PlantillaSummary";
import { proyectos_operaciones_estados } from "../../../../../utility/constants";

const FirstStep = (props) => {
    const {
        handleSubmit,
        clientes,
        plantillas,
        obtenerPlantilla,
        tipos_proyectos,
        currentPlantilla,
        gastos,
        actividades,
        fases,
        crearActividad,
        actividadesValue,
        gastosValue,
        modulosValue,
        isDisabled,
        nextStepNoSubmit = () => {},
        onSubmit,
    } = props;

    const submit = () => {
        return isDisabled ? handleSubmit(nextStepNoSubmit) : handleSubmit;
    };

    return (
        <form
            name="ProyectoOperacionesForm"
            className="form-validate mb-lg"
            onSubmit={submit()}
        >
            <div className="d-flex align-items-center justify-content-end">
                <Link
                    to="/proyectos_operaciones"
                    className="btn"
                    style={{ fontSize: 15 }}
                >
                    Salir
                </Link>
                <button
                    type="submit"
                    style={{ fontSize: 15 }}
                    className="btn btn-primary m-1 align-self-center"
                >
                    {isDisabled ? "Siguiente" : "Guardar y avanzar"}
                </button>
            </div>
            <DatosProyecto
                clientes={clientes}
                plantillas={plantillas}
                obtenerPlantilla={obtenerPlantilla}
                tipos_proyectos = {tipos_proyectos}
                isDisabled={isDisabled}
            />
            <PlantillaSummary currentPlantilla={currentPlantilla} />
            <Modulos
                gastos={gastos}
                actividades={actividades}
                fases={fases}
                crearActividad={crearActividad}
                currentPlantilla={currentPlantilla}
                actividadesValue={actividadesValue}
                gastosValue={gastosValue}
                modulosValue={modulosValue}
                isDisabled={isDisabled}
            />
        </form>
    );
};

//  Los campos que se envían para la actualización
export const fieldsToUpdate = [
    "nombre",
    "cliente",
    "codigo",
    "plantilla",
    "modulos",
    "actividades",
    "gastos",
    "tipo_proyecto",
];

const form = reduxForm({
    form: "ProyectoOperacionesForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(FirstStep);

const selector = formValueSelector("ProyectoOperacionesForm");
export default connect((state) => {
    const actividadesValue = selector(state, "actividades") || [];
    const gastosValue = selector(state, "gastos") || [];
    const modulosValue = selector(state, "modulos") || [];
    const estadoValue = selector(state, "estado");
    const isDisabled =
        estadoValue === proyectos_operaciones_estados.APROBADO ||
        estadoValue === proyectos_operaciones_estados.VENTA_GANADA ||
        estadoValue === proyectos_operaciones_estados.VENTA_PERDIDA;

    return {
        actividadesValue,
        gastosValue,
        modulosValue,
        isDisabled,
    };
})(form);
