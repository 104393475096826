import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/gastos_administrativos/gastos_administrativos';
import Listar from './Listar';


const ms2p = (state) => {
  return {
    ...state.gastos_administrativos,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Listar);
