import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import { Link } from "react-router-dom";

class Listar extends Component {
    state = {
        page: 1,
        search: "",
        params: {
            activo: true,
        },
    };

    componentDidMount() {
        this.props.listar(this.state.page, this.state.params);
    }

    onPageChange = (page) => {
        this.props.listar(page, this.state.params);
        this.setState({ page });
    };

    render() {
        const { data, loader } = this.props;
        return (
            <React.Fragment>
                <div className="flex row mt-2">
                    <div className="col-12">
                        <h2 className="title--blue">DÍAS DE VACACIONES</h2>
                    </div>
                </div>
                <hr />
                <Grid
                    data={data}
                    loading={loader}
                    page={this.state.page}
                    onPageChange={this.onPageChange}
                >
                    <TableHeaderColumn dataField="nombre" dataSort>
                        Nombre
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="dias_vacaciones" dataSort>
                        Días
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        dataSort
                        isKey
                        dataFormat={(cell, row) => {
                            let props = {};
                            props.editar = "tipo_contrato_vacaciones";
                            const actions = standardActions({ ...props });
                            return actions(cell, row);
                        }}
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
            </React.Fragment>
        );
    }
}

export default Listar;
