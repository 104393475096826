import React from "react";
import { FieldArray } from "redux-form";

import { renderRequisitos } from "./renderRequisitos";

const RequisitosEntrega = (props) => {
    return (
        <div className="mt-3">
            <h5 className="title--blue--1">Requisitos de entrega</h5>
            <hr />
            <div className="d-flex flex-column mt-4 justify-content-center align-items-center">
                <FieldArray
                    name="requisitos_actividades"
                    component={renderRequisitos}
                    actividades={props.actividades}
                    fases={props.fases}
                    change={props.change}
                />
            </div>
        </div>
    );
};

export { RequisitosEntrega };
