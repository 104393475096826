import React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { validate, validators } from "validate-redux-form";
import {
    renderField,
    renderFilePicker,
    renderDayPicker,
    renderSelectField,
    renderNumber,
    renderFieldRadio,
} from "../../Utils/renderField";

import moment from "moment";

const CrearEditarForm = (props) => {
    const {
        handleSubmit,
        tiposPermiso = [],
        recursos = [],
        edit,
        documentoUrl,
        change,
        tipo,
        isDefault,
        isHoraDefault,
        horaDiaValue,
        tipoPermisoValue,
        fechaInicioValue,
        fechaFinValue,
        asuetosGuatemala,
        jefes,
    } = props;

    const types = ["application/pdf", "image/jpeg", "image/png"];

    const openNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    React.useEffect(() => {
        if (isDefault) {
            change("tipo", "vacaciones");
        }

        if (isHoraDefault) {
            change("hora_dia", "dias");
        }

        if (tipo === "vacaciones") {
            change("tipo_permiso", 1);
        } else {
            change("tipo_permiso", tipoPermisoValue || null);
        }
    }, [isDefault, isHoraDefault, tipo]);

    const isHoliday = (dateValue, holidays) => {
        const date = moment(dateValue);
        return holidays.some((holiday) => {
            const start = moment(holiday.start);
            const end = moment(holiday.end);
            return date >= start && date <= end;
        });
    };

    const getWorkingDays = (startValue, endValue) => {
        const start = moment(startValue);
        const end = moment(endValue);
        let days = 0;
        while (start <= end) {
            if (
                start.day() !== 0 &&
                start.day() !== 6 &&
                !isHoliday(start, asuetosGuatemala)
            ) {
                days++;
            }
            start.add(1, "days");
        }
        return days;
    };

    const contadorDias = () => {
        let value = "---";
        if (fechaInicioValue && fechaFinValue) {
            const days = getWorkingDays(fechaInicioValue, fechaFinValue);
            const label = days === 1 ? "día hábil" : "días hábiles";
            value = `${days} ${label}`;
        }
        return <span className="title--blue--1">{value}</span>;
    };

    return (
        <form
            name="CrearEditarForm"
            className="form-validate mb-lg"
            onSubmit={handleSubmit}
        >
            <div className="row">
                <div className="form-group has-feedback col-4">
                    <Field
                        name="tipo"
                        label="Vacaciones"
                        value={"vacaciones"}
                        component={renderFieldRadio}
                        className="input--style"
                        type="radio"
                    />
                </div>

                <div className="form-group has-feedback col-4">
                    <Field
                        name="tipo"
                        label="Permiso"
                        value={"permiso"}
                        component={renderFieldRadio}
                        className="input--style"
                        type="radio"
                    />
                </div>
            </div>

            <div className="row">
                <div
                    className={`form-group has-feedback col-6 ${
                        tipo === "vacaciones" ? "hidden-form-input" : ""
                    }`}
                    style={{ zIndex: 3 }}
                >
                    <label htmlFor="tipo_permiso">Tipo Permiso</label>
                    <Field
                        name="tipo_permiso"
                        label="Tipo Permiso"
                        component={renderSelectField}
                        select_style={{
                            control: (styles) => ({
                                ...styles,
                                color: "#1F4D7B",
                                borderColor: "#1F4D7B",
                            }),
                        }}
                        options={tiposPermiso}
                        labelKey="label"
                        valueKey="value"
                        className="input--style"
                    />
                </div>
            </div>
            <div className="row">
                <div
                    className={`form-group has-feedback col-4 ${
                        tipo === "vacaciones" ? "hidden-form-input" : ""
                    }`}
                >
                    <Field
                        name="hora_dia"
                        label="Días"
                        value={"dias"}
                        component={renderFieldRadio}
                        className="input--style"
                        type="radio"
                    />
                </div>

                <div
                    className={`form-group has-feedback col-4 ${
                        tipo === "vacaciones" ? "hidden-form-input" : ""
                    }`}
                >
                    <Field
                        name="hora_dia"
                        label="Horas"
                        value={"horas"}
                        component={renderFieldRadio}
                        className="input--style"
                        type="radio"
                    />
                </div>
            </div>

            <div className="row">
                <div className="form-group has-feedback col-4">
                    <label htmlFor="fecha_inicio">Fecha inicio</label>
                    <Field
                        name="fecha_inicio"
                        label="Fecha inicio"
                        component={renderDayPicker}
                        className="input--style"
                    />
                </div>

                <div
                    className={`form-group has-feedback col-4 ${
                        tipo === "permiso" && horaDiaValue === "horas"
                            ? "hidden-form-input"
                            : ""
                    }`}
                >
                    <label htmlFor="fecha_fin">Fecha fin</label>
                    <Field
                        name="fecha_fin"
                        label="Fecha fin"
                        component={renderDayPicker}
                        className="input--style"
                    />
                </div>

                {tipo === "vacaciones" ||
                (tipo === "permiso" && horaDiaValue === "dias") ? (
                    <div
                        className="col-2"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {contadorDias()}
                    </div>
                ) : null}

                <div
                    className={`form-group has-feedback col-4 ${
                        tipo === "vacaciones" || horaDiaValue === "dias"
                            ? "hidden-form-input"
                            : ""
                    }`}
                >
                    <label htmlFor="horas">Horas</label>
                    <Field
                        name="horas"
                        label="Horas"
                        component={renderNumber}
                        className="input--style"
                        suffix=" Hora(s)"
                    />
                </div>
            </div>
            <div className="row">
                <div
                    className="form-group has-feedback col-6"
                    style={{ zIndex: 2 }}
                >
                    <label htmlFor="pais">Colaborador</label>
                    <Field
                        name="colaborador"
                        label="Colaborador"
                        component={renderSelectField}
                        select_style={{
                            control: (styles) => ({
                                ...styles,
                                color: "#1F4D7B",
                                borderColor: "#1F4D7B",
                            }),
                        }}
                        options={recursos}
                        labelKey="label"
                        valueKey="value"
                        className="input--style"
                        disabled={edit}
                    />
                </div>

                <div
                    className="form-group has-feedback col-6"
                    style={{ zIndex: 2 }}
                >
                    <label htmlFor="pais">Autorizado por</label>
                    <Field
                        name="autorizado_por"
                        label="Autorizado por"
                        component={renderSelectField}
                        select_style={{
                            control: (styles) => ({
                                ...styles,
                                color: "#1F4D7B",
                                borderColor: "#1F4D7B",
                            }),
                        }}
                        options={jefes}
                        labelKey="label"
                        valueKey="value"
                        className="input--style"
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group has-feedback col-12">
                    <label htmlFor="fecha_fin">Adjuntar documento</label>
                    <Field
                        name="documento"
                        label="Adjuntar documento"
                        component={renderFilePicker}
                        types={types}
                        className="input--style"
                        photo={documentoUrl ? documentoUrl : ""}
                    />
                </div>
            </div>
            {edit ? (
                <div className="row">
                    <div className="col-12 col-md-4">
                        <button
                            type="button"
                            className="btn btn-dark"
                            disabled={!documentoUrl}
                            onClick={() => openNewTab(documentoUrl)}
                        >
                            Ver documento
                        </button>
                    </div>
                </div>
            ) : null}
            <div className="buttons-box">
                <Link
                    to="/permisos"
                    className="btn btn-secondary"
                    style={{ fontSize: 15 }}
                >
                    Regresar
                </Link>
                <button
                    type="submit"
                    style={{ fontSize: 15 }}
                    className="btn btn-primary m-1 align-self-center"
                >
                    Guardar
                </button>
            </div>
        </form>
    );
};

const form = reduxForm({
    form: "CrearEditarForm", // a unique identifier for this form
    validate: (data) => {
        if (data.tipo === "vacaciones" || data.hora_dia === "dias") {
            data.horas = 1;
        }

        if (data.tipo === "permiso" && data.hora_dia === "horas") {
            data.fecha_fin = data.fecha_inicio;
        }

        return validate(data, {
            tipo_permiso: validators.exists()("Este campo es requerido"),
            fecha_inicio: validators.exists()("Este campo es requerido"),
            fecha_fin: validators.exists()("Este campo es requerido"),
            horas: validators.exists()("Este campo es requerido"),
            colaborador: validators.exists()("Este campo es requerido"),
            autorizado_por: validators.exists()("Este campo es requerido"),
        });
    },
})(CrearEditarForm);

const selector = formValueSelector("CrearEditarForm");
export default connect((state) => {
    const documentoUrl = selector(state, "documento_url") || "";
    let tipo = selector(state, "tipo") || null;
    const horaDiaValue = selector(state, "hora_dia") || null;
    const tipoPermisoValue = selector(state, "tipo_permiso") || null;
    const fechaInicioValue = selector(state, "fecha_inicio") || null;
    const fechaFinValue = selector(state, "fecha_fin") || null;

    let isDefault = false;
    if (!tipo) isDefault = true;
    let isHoraDefault = false;
    if (!horaDiaValue) isHoraDefault = true;
    return {
        documentoUrl,
        tipo,
        isDefault,
        horaDiaValue,
        isHoraDefault,
        tipoPermisoValue,
        fechaInicioValue,
        fechaFinValue,
    };
})(form);
