import React from "react";
import { NavList } from "./NavList";
import { Link } from "react-router-dom";

const SideBar2 = (props) => {
    const { toggleOpen, navToggle, logOut } = props;
    const role = localStorage.getItem("role");

    return (
        <aside
            className={`main-sidebar px-0 col-12 col-md-3 col-lg-2 ${
                toggleOpen ? "" : "open"
            }`}
        >
            <div className="main-navbar">
                <nav className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0 navbar navbar-light">
                    <a href="#" className="w-100 mr-0 navbar-brand">
                        <div className="d-table m-auto">
                            <img
                                id="main-logo"
                                className="d-inline-block align-top mr-1"
                                src={require("assets/img/logo.png")}
                                alt="Logo"
                            />
                        </div>
                    </a>
                    <a
                        className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                        onClick={navToggle}
                    >
                        <i className="material-icons"></i>
                    </a>
                </nav>
            </div>
            <div className="nav-wrapper">
                <NavList
                    items={items}
                    navToggle={navToggle}
                    role={role}
                    first={true}
                    logOut={logOut}
                />
            </div>
        </aside>
    );
};

const items = [
    // Home
    {
        to: "/",
        icon: "home",
        label: "Inicio",
    },
    // Proyecto
    {
        label: "Proyecto",
        icon: "book",
        allow: [1, 10, 11],
        items: [
            {
                to: "/proyectos",
                icon: "book",
                label: "Listado",
                allow: [1, 10, 11],
            },
            {
                to: "/clientes",
                icon: "supervisor_account",
                label: "Clientes",
                allow: [1, 10, 11],
            },
            {
                to: "/gantt",
                icon: "waterfall_chart",
                label: "Gantt",
                allow: [1, 10, 11],
            },
            {
                to: "/recursos_contacto",
                icon: "group",
                label: "Listado de colaboradores",
                allow: [1, 10, 11],
            },
            {
                to: "/solicitud_personal",
                icon: "person_add",
                label: "Solicitud de personal",
                allow: [1],
            },
        ],
    },
    // Presupuesto
    {
        label: "Presupuesto",
        icon: "account_balance_wallet",
        allow: [1, 5, 8],
        items: [
            {
                to: "/proyectos_operaciones",
                icon: "work",
                label: "Proyectos",
                allow: [1, 5, 8],
            },
            {
                label: "Conf. Presupuestos",
                icon: "settings",
                allow: [1, 5],
                items: [
                    {
                        to: "/plantillas_proyectos",
                        icon: "description",
                        label: "Plantilla del proyecto",
                        allow: [1, 5],
                    },
                    {
                        to: "/complejidad_proyectos",
                        icon: "watch_later",
                        label: "Complejidad de proyectos",
                        allow: [1, 5],
                    },
                    {
                        to: "/actividades_entrega",
                        icon: "local_post_office",
                        label: "Actividades de entrega",
                        allow: [1, 5],
                    },
                    {
                        to: "/gastos_administrativos",
                        icon: "payment",
                        label: "Gastos administrativos",
                        allow: [1, 5],
                    },
                ],
            },
        ],
    },
    // Administración
    {
        label: "Administración",
        icon: "settings",
        allow: [1],
        items: [
            {
                to: "/fases-proyecto",
                icon: "assignment",
                label: "Fases Proyecto",
            },
            {
                to: "/tipos-proyecto",
                icon: "assignment",
                label: "Tipos Proyecto",
            },
            {
                to: "/paises",
                icon: "public",
                label: "Paises",
            },
        ],
    },
    // Reportes
    {
        label: "Reportes",
        icon: "poll",
        items: [
            {
                to: "/ruta-trabajo",
                icon: "table_chart",
                label: "Ruta de trabajo",
            },
            {
                to: "/disponibilidad",
                icon: "assignment",
                label: "Disponibilidad",
            },
        ],
    },
    // Recursos humanos
    {
        label: "Recursos Humanos",
        icon: "group",
        allow: [1, 6, 11],
        items: [
            {
                to: "/recursos",
                icon: "group",
                label: "Listado de colaboradores",
                allow: [1, 6, 11],
            },
            {   to: "/historial_interno",
                icon: "history",
                label: "Historial interno",
                allow: [1, 6, 11],
            },
            {
                to: "/birthdays",
                icon: "cake",
                label: "Cumpleaños del mes",
                allow: [1, 6, 11],
            },
            {
                to: "/historial_economico",
                icon: "attach_money",
                label: "Historial economico",
                allow: [1, 6, 11],
            },
            {
                to: "/asuetos",
                icon: "view_list",
                label: "Asuetos",
                allow: [1, 6, 11],
            },
            {
                to: "/permisos",
                icon: "assignment_ind",
                label: "Permisos",
                allow: [1, 6, 11],
            },
            {
                to: "/amonestaciones",
                icon: "report",
                label: "Amonestaciones",
                allow: [1, 6, 11],
            },
            {
                to: "/calendario_asuetos",
                icon: "event",
                label: "Calendario Asuetos",
                allow: [1, 6, 11],
            },
            {
                to: "/reclutamiento",
                icon: "person_add",
                label: "Solicitud de personal",
                allow: [1, 6, 11]
            },
            {
                label: "Reportes",
                icon: "bar_chart",
                allow: [1, 6, 11],
                items: [
                    {
                        to: "/recursos/reportes",
                        icon: "group",
                        label: "Índice de retención y rotación",
                        allow: [1, 6, 11],
                    },
                    {
                        to: "/permisos/reportes",
                        icon: "assignment_ind",
                        label: "Permisos",
                        allow: [1, 6, 11],
                    },
                    {
                        to: "/vacaciones/reportes",
                        icon: "assignment_ind",
                        label: "Vacaciones",
                        allow: [1, 6, 11],
                    },
                ],
            },
            {
                label: "Conf. RRHH",
                icon: "settings",
                allow: [1, 6],
                items: [
                    {
                        to: "/tipo_contrato_vacaciones",
                        icon: "assignment_ind",
                        label: "Días de vacaciones",
                        allow: [1, 6],
                    },
                    {
                        to: "/tipos_permiso",
                        icon: "assignment_ind",
                        label: "Tipos permiso",
                        allow: [1, 6],
                    },
                    {
                        to: "/departamentos",
                        icon: "assignment",
                        label: "Departamentos",
                        allow: [1, 6],
                    },
                    {
                        to: "/puestos",
                        icon: "contact_mail",
                        label: "Puestos",
                        allow: [1, 6],
                    },
                    {
                        to: "/skills",
                        icon: "emoji_symbols",
                        label: "Skills",
                        allow: [1, 6],
                    },
                    {
                        to: "/seniority",
                        icon: "fitness_center",
                        label: "Seniority",
                        allow: [1, 6],
                    },
                ],
            },
        ],
    },
    // Usuarios
    {
        label: "Usuarios",
        icon: "people_alt",
        items: [
            {
                to: "/users",
                icon: "people_alt",
                label: "Listado",
                allow: [1],
            },
            {
                to: "/change-password",
                icon: "lock_open",
                label: "Cambiar contraseña",
            },
            {
                to: "/login",
                icon: "lock",
                label: "Log Out",
                logout: true,
            },
        ],
    },
];

export default SideBar2;
