import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import LoadMask from "Utils/LoadMask/LoadMask";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { api } from "api";
import FiltersData from "./FiltersData";
import { Modal } from "../../Utils/Modal";
import { BootstrapTable } from "react-bootstrap-table";

const localizer = momentLocalizer(moment);

const colors = [
    "#FF731D",
    "#FFC069",
    "#5F9DF7",
    "#1746A2",
    "#194350",
    "#425F57",
];

const CalendarioAsuetos = (props) => {
    const [currentDay, setCurrentDay] = React.useState("");
    const [recursos, setRecursos] = React.useState([]);
    const [openModal, setOpenModal] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [colorsPais, setColorsPais] = React.useState({});

    // Paises
    const [paises, setPaises] = React.useState([]);
    React.useEffect(() => {
        const getPaises = async () => {
            setLoading(true);
            try {
                const paisesData = await api.get("pais/filter_list");
                let i = 0;
                const withColors = [];
                const colorsToPais = {};
                // Adding colors to paises
                for (let j = 0; j < paisesData.length; j++) {
                    if (i === colors.length) i = 0;
                    withColors.push({
                        ...paisesData[j],
                        color: colors[i],
                    });
                    colorsToPais[paisesData[j].label] = colors[i];
                    i++;
                }
                setColorsPais(colorsToPais);
                setPaises(withColors);
            } catch (error) {
                setPaises([]);
            }
            setLoading(false);
        };

        getPaises();
    }, []);

    // Filters
    const [filters, setFilters] = React.useState({
        fecha_inicio: moment()
            .startOf("year")
            .subtract(30, "d")
            .format("YYYY-MM-DD"),
        fecha_fin: moment().endOf("year").add(30, "d").format("YYYY-MM-DD"),
    });

    const handleSelect = (e) => {
        if (e.length > 0) {
            const queryPaises = e.reduce((prev, current) => {
                if (prev === "") {
                    return `${current.value}`;
                }
                return `${prev}&pais=${current.value}`;
            }, "");

            setFilters({
                ...filters,
                pais: queryPaises,
            });
        } else {
            const tmp = filters;
            delete tmp.pais;
            setFilters({
                ...tmp,
            });
        }
    };

    const setRange = (date) => {
        const year = moment(date).year();
        const currentYear = moment(filters.fecha_inicio).year() + 1;

        if (year !== currentYear) {
            setFilters({
                ...filters,
                fecha_inicio: moment(date)
                    .startOf("year")
                    .subtract(30, "d")
                    .format("YYYY-MM-DD"),
                fecha_fin: moment(date)
                    .endOf("year")
                    .add(30, "d")
                    .format("YYYY-MM-DD"),
            });
        }
    };

    // Events
    const [events, setEvents] = React.useState([]);
    const getEvents = async () => {
        setLoading(true);
        try {
            const data = await api.get("asueto/get_events", filters);
            const allDayData = data.map((e) => ({
                ...e,
                allDay: true,
                start: new Date(`${e.start}T06:00`), // To manage local time
                end: new Date(`${e.end}T06:00`),
            }));
            setEvents(allDayData);
        } catch (error) {
            setEvents([]);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        getEvents();
    }, [filters]);

    // Calendar
    const handleNavigate = (date) => {
        _setCurrentDate(date);
        setRange(moment(date).format("YYYY-MM-DD"));
    };

    const getRecursosByAsueto = async (paises, day) => {
        try {
            setLoading(true);
            const response = await api.post("asueto/get_recursos", {
                paises,
                dia: `${day.getFullYear()}-${
                    day.getMonth() + 1
                }-${day.getDate()}`,
            });
            setRecursos(response);
            setCurrentDay(moment(day).format("LL"));
            setOpenModal(true);
        } catch (error) {
            setRecursos([]);
        }
        setLoading(false);
    };

    const handleSelectSlot = ({ slots }) => {
        if (slots.length === 1) {
            // Get events in that day
            const day = slots[0];
            day.setUTCHours(12, 0, 0, 0);
            const paises = events
                .filter((event) => {
                    const start = new Date(event.start.getTime());
                    start.setUTCHours(12, 0, 0, 0);
                    const end = new Date(event.end.getTime());
                    end.setUTCHours(12, 0, 0, 0);
                    return start <= day && day <= end;
                })
                .map((e) => e.pais.id);

            if (paises.length > 0) getRecursosByAsueto(paises, day);
        }
    };

    const eventPropGetter = React.useCallback(
        (event, start, end, isSelected) => {
            const style = {
                borderRadius: "0px",
            };

            if (colorsPais[event.pais.nombre]) {
                style.backgroundColor = colorsPais[event.pais.nombre];
            }
            return { style };
        },
        [colorsPais]
    );

    const [currentDate, _setCurrentDate] = React.useState(new Date());
    const setCurrentDate = (newDate) => {
        handleNavigate(newDate);
    };

    /* const currentDate = React.useMemo(() => {
        return new Date(`${month}-01T06:00`);
    }, [month]); */

    return (
        <React.Fragment>
            <LoadMask loading={loading} blur={true}>
                <div className="row my-2 d-flex justify-content-center align-items-center">
                    <FiltersData
                        paises={paises}
                        handleSelect={handleSelect}
                        setCurrentDate={setCurrentDate}
                        currentDate={currentDate}
                    />
                </div>
                <div className="row w-100 h-100 mx-auto">
                    <Calendar
                        views={["month"]}
                        localizer={localizer}
                        date={currentDate}
                        defaultView="month"
                        events={events}
                        style={{ height: "100vh", width: "100%" }}
                        onNavigate={handleNavigate}
                        onSelectSlot={handleSelectSlot}
                        eventPropGetter={eventPropGetter}
                        selectable
                        popup
                    />
                </div>
                {openModal && (
                    <Modal setOpenModal={setOpenModal}>
                        <div className="modal-asuetos--header">
                            <div className="text-info">
                                <h3 className="title--blue--1">
                                    Personal de goza de asueto
                                </h3>
                                <h4 className="text--blue--1">{currentDay}</h4>
                            </div>
                            <button
                                onClick={() => setOpenModal(false)}
                                className="btn-asuetos btn text--blue--1"
                            >
                                <p>Cerrar</p>
                                <i className="material-icons">close</i>
                            </button>
                        </div>
                        <BootstrapTable
                            tableContainerClass="content--table--1"
                            data={recursos}
                            options={{
                                noDataText: "No hay datos",
                            }}
                        >
                            <TableHeaderColumn
                                dataField="nombre"
                                dataSort
                                isKey
                                width="40%"
                            >
                                Nombre
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="cliente" dataSort>
                                Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="pais"
                                dataSort
                                dataFormat={(cell, row) => {
                                    return (
                                        <span
                                            style={{
                                                backgroundColor:
                                                    colorsPais[cell] || "white",
                                                color: colorsPais[cell]
                                                    ? "white"
                                                    : "black",
                                                borderRadius: "5px",
                                                padding: "5px",
                                            }}
                                        >
                                            {cell}
                                        </span>
                                    );
                                }}
                            >
                                País
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Modal>
                )}
            </LoadMask>
        </React.Fragment>
    );
};

export default CalendarioAsuetos;
