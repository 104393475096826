import LoadMask from "Utils/LoadMask/LoadMask";
import CrearEditarForm from './CrearEditarForm';
import React, { Component } from 'react';
import { api } from "api";

import { SELECT_LIST as selectListDepartamentos } from "redux/modules/departamento_organizacion/departamento_organizacion";

class CrearEditar extends Component {

    state = {
        tipos_proyecto: [],
        tipos_contratacion: [],
        departamentos_organizacion: [],
        departamentos_guatemala: [],
        edit: false,
        loader: true,
        id: null,
        skills: [],
        tipos_contrato: [],
        nivelesIngles: [],
    }

    componentDidMount(){
        this.props.limpiarForm();
        api.get(selectListDepartamentos).then(response_d => {
            this.setState({ departamentos_organizacion: response_d });
        })

        api.get("recurso/niveles_ingles").then(nivelesIngles => {
            this.setState({ nivelesIngles });
        })

        api.get("recurso/departamentos_guatemala").then(departamentos_guatemala => {
            this.setState({ departamentos_guatemala });
        })

        api.get("skill/filter_list").then(skills => {
            this.setState({ skills });
        })

        api.get("recurso/tipos_contrato").then(tipos_contrato => {
            this.setState({ tipos_contrato });
        })

        api.get("tipo_proyecto", { activo: true }).then(response2 => {
            this.setState({ tipos_proyecto: response2.results })
            api.get("tipo_contratacion", { activo: true }).then(response3 => {
                this.setState({ tipos_contratacion: response3.results, loader: false })
                if(!this.props.fromModalCreate){
                    const edit = this.props.match.params.id ? true : false
                    this.setState({ edit })
                    if(edit == true){
                        const id = this.props.match.params.id
                        this.setState({ id })
                        this.props.obtenerRecurso(id)
                    }
                }
            })
        })
    }

    onSubmit = (data) => {
        const { edit, id } = this.state;
        const { onSubmit, actualizarRecurso, createNewAddSolicitud, fromModalCreate, tipoAsignacionValue, callbackModal } = this.props;
        if (edit) {
            actualizarRecurso(data, id);
        } else {
            if(fromModalCreate){
                createNewAddSolicitud({ data, tipoAsignacion: tipoAsignacionValue, callback: callbackModal })
            }else {
                onSubmit(data);
            }
        }
    }

    render() {
        const { loader, seniorityLoader, obtenerPuestos, puestos, puestosLoader } = this.props

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h2 className="title--blue">{this.state.edit ? "EDITAR" : "CREAR"} RECURSO</h2>
                    </div>
                </div>
                <hr />
                <div className="login-wrapper">
                    <div className="card card-login w-100">
                        <LoadMask loading={puestosLoader || seniorityLoader || loader || this.state.loader} light>
                            <CrearEditarForm
                                fromModalCreate={this.props.fromModalCreate}
                                onSubmit={this.onSubmit}
                                puestos={puestos}
                                tipos_proyecto={this.state.tipos_proyecto}
                                tipos_contratacion={this.state.tipos_contratacion}
                                departamentos_organizacion={this.state.departamentos_organizacion}
                                departamentos_guatemala={this.state.departamentos_guatemala}
                                edit={this.state.edit}
                                renunciaDespido={this.props.renunciaDespido}
                                id={this.state.id}
                                skills={this.state.skills}
                                tipos_contrato={this.state.tipos_contrato}
                                seniority={this.props.seniority}
                                obtenerSeniority={this.props.obtenerSeniority}
                                nivelesIngles={this.state.nivelesIngles}
                                obtenerPuestos={obtenerPuestos}
                            />
                        </LoadMask>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CrearEditar
