import React from "react";
import { createPortal } from "react-dom";
import "./Modal.css";

const Modal = (props) => {
    return createPortal(
        <div
            className="modal-app"
            onClick={(e) => {
                if (props.setOpenModal && e.target === e.currentTarget) {
                    props.setOpenModal(false);
                }
            }}
        >
            <div className="modal--content">{props.children}</div>
        </div>,
        document.getElementById("modal")
    );
};

export { Modal };
