import React from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import validate from "./validate";
import { ConfiguracionPersonal } from "../Secciones/ConfiguracionPersonal";
import { ConfiguracionPrecio } from "../Secciones/ConfiguracionPrecio";
import { ArchivosProyecto } from "../Secciones/ArchivosProyecto";
import { EnlacesProyecto } from "../Secciones/EnlacesProyecto";
import { proyectos_operaciones_estados } from "../../../../../utility/constants";

const SecondStep = (props) => {
    const {
        handleSubmit,
        previusStep,
        fases,
        puestos,
        seniority,
        currentPlantilla,
        change,
        edit,
        isDisabled,
        nextStepNoSubmit = () => {},
    } = props;

    const submit = () => {
        return isDisabled ? handleSubmit(nextStepNoSubmit) : handleSubmit;
    };

    return (
        <form
            name="ProyectoOperacionesForm"
            className="form-validate mb-lg"
            onSubmit={submit()}
        >
            <div className="d-flex align-items-center justify-content-end">
                <button
                    type="button"
                    style={{ fontSize: 15 }}
                    className="btn"
                    onClick={previusStep}
                >
                    Regresar
                </button>
                <button
                    type="submit"
                    style={{ fontSize: 15 }}
                    className="btn btn-primary m-1 align-self-center"
                >
                    {isDisabled ? "Siguiente" : "Guardar y avanzar"}
                </button>
            </div>
            <ConfiguracionPersonal
                fases={fases}
                puestos={puestos}
                seniority={seniority}
                currentPlantilla={currentPlantilla}
                change={change}
                isDisabled={isDisabled}
            />
            <ConfiguracionPrecio isDisabled={isDisabled} />
            <ArchivosProyecto edit={edit} isDisabled={isDisabled} />
            <EnlacesProyecto isDisabled={isDisabled} />
        </form>
    );
};

//  Los campos que se envían para la actualización
export const fieldsToUpdate = [
    "personal",
    "impuestos_adicionales",
    "margen_negocio",
    "ganancia_adicional",
    "comision_venta",
    "archivos",
    "enlaces",
];

const form = reduxForm({
    form: "ProyectoOperacionesForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(SecondStep);

const selector = formValueSelector("ProyectoOperacionesForm");
export default connect((state) => {
    const estadoValue = selector(state, "estado");
    const isDisabled =
        estadoValue === proyectos_operaciones_estados.APROBADO ||
        estadoValue === proyectos_operaciones_estados.VENTA_GANADA ||
        estadoValue === proyectos_operaciones_estados.VENTA_PERDIDA;

    return {
        isDisabled,
    };
})(form);
