import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/amonestaciones/amonestaciones";
import { actions as actionsRecursos } from "../../../../redux/modules/recursos/recursos";
import CrearEditar from "./CrearEditar";

const ms2p = (state) => {
    return {
        ...state.amonestaciones,
        colaboradores: state.recursos.filter,
        recursosLoader: state.recursos.loader,
    };
};

const md2p = { ...actions, obtenerColaboradores: actionsRecursos.filter };

export default connect(ms2p, md2p)(CrearEditar);
