import LoadMask from "Utils/LoadMask/LoadMask";
import CrearEditarForm from "./CrearEditarForm";
import React, { Component } from "react";

class CrearEditar extends Component {
    state = {
        edit: false,
        loader: true,
        id: null,
    };

    async componentDidMount() {
        this.props.obtenerColaboradores();
        this.props.obtenerTipos();
        this.setState({ loader: false });
        const edit = this.props.match.params.id ? true : false;
        this.setState({ edit });
        if (edit == true) {
            const id = this.props.match.params.id;
            this.setState({ id });
            this.props.obtenerAmonestacion(id);
        }
    }

    onSubmit = (data) => {
        const { edit, id } = this.state;
        const { onSubmit, actualizarAmonestacion } = this.props;
        if (edit) {
            actualizarAmonestacion(data, id);
        } else {
            onSubmit(data);
        }
    };

    render() {
        const { loader, tipos, colaboradores, recursosLoader } = this.props;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h2 className="title--blue">
                            {this.state.edit ? "EDITAR" : "CREAR"} AMONESTACION
                        </h2>
                    </div>
                </div>
                <hr />
                <div className="login-wrapper">
                    <div className="card card-login w-100">
                        <LoadMask
                            loading={
                                recursosLoader || loader || this.state.loader
                            }
                            light
                        >
                            <CrearEditarForm
                                onSubmit={this.onSubmit}
                                tipos={tipos}
                                edit={this.state.edit}
                                colaboradores={colaboradores}
                            />
                        </LoadMask>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CrearEditar;
