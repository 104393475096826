import React from "react";
import Popup from "reactjs-popup";
import { api } from "api";
import { renderSelectField } from "../../../Utils/renderField";
import LoadMask from "Utils/LoadMask/LoadMask";
import { CrearEditar as CrearRecurso } from "../../../Recursos/CrearEditar";

import { Field } from "redux-form";

export const AprobarForm = ({
    open,
    tipoAsignacion,
    puesto,
    closeModal,
    onSubmit,
    contratado,
}) => {
    const [resources, setResources] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        const getResources = async () => {
            setLoading(true);
            try {
                const response = await api.get("recurso/filter_list", {
                    puesto,
                });
                setResources(response);
            } catch (error) {
                setResources([]);
            } finally {
                setLoading(false);
            }
        };

        if (open) {
            getResources();
        }
    }, [open]);
    return (
        <Popup
            open={open}
            modal
            closeOnDocumentClick={false}
            className={`${
                tipoAsignacion === "2" ? "modal-form-recursos" : "modal-form"
            }`}
            onClose={closeModal}
        >
            {/* Interna */}
            {tipoAsignacion === "1" ? (
                <LoadMask loading={loading} light>
                    <div className="d-flex aling-items-center justify-content-between mb-3">
                        <h4 className="title--blue--1">ASIGNAR RECURSO</h4>
                        <button
                            className="btn"
                            onClick={closeModal}
                            type="button"
                        >
                            <i
                                className="material-icons"
                                style={{ fontSize: "1rem", color: "#CCCCCC" }}
                            >
                                close
                            </i>
                        </button>
                    </div>
                    <Field
                        name={"recurso"}
                        select_style={{}}
                        component={renderSelectField}
                        options={resources}
                        labelKey={"label"}
                        valueKey={"value"}
                        className={"input--style"}
                        placeholder={"Recurso..."}
                    />
                    <div className="d-flex align-items-center justify-content-end mt-4">
                        <button
                            className="col-5 btn btn-secondary mx-2 px-4"
                            onClick={closeModal}
                            type="button"
                            style={{ fontSize: "1rem", fontWeight: "700" }}
                        >
                            Volver
                        </button>
                        <button
                            className="col-5 btn btn-success mx-2 px-4"
                            onClick={onSubmit}
                            type="button"
                            style={{ fontSize: "1rem", fontWeight: "700" }}
                        >
                            Asignar Recurso
                        </button>
                    </div>
                </LoadMask>
            ) : null}
            {/* Externa */}
            {tipoAsignacion === "2" ? (
                <CrearRecurso
                    fromModalCreate={true}
                    tipoAsignacionValue={tipoAsignacion}
                    callbackModal={contratado}
                />
            ) : null}
        </Popup>
    );
};
