import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/solicitud_personal/solicitud_personal";
import { actions as actionsSeniority } from '../../../../redux/modules/seniority/seniority';
import CrearEditar from "./CrearEditar";

const ms2p = (state) => {
    return {
        ...state.solicitud_personal,
        seniority: state.seniority.filter,
        seniorityLoader: state.seniority.loader,
    };
};

const md2p = {
    ...actions,
    obtenerSeniority: actionsSeniority.filter
};

export default connect(ms2p, md2p)(CrearEditar);
