import { handleActions } from 'redux-actions';
import { api } from "api";

const LOADER = 'ESTADISTICA_LOADER';
const LOADER1 = 'ESTADISTICA_LOADER1';
const LOADER2 = 'ESTADISTICA_LOADER2';
const LOADER3 = 'ESTADISTICA_LOADER3';
const DATA = "ESTADISTICA_DATA";
const HORAS = "HORAS";
const CLIENTES = "CLIENTES";
const FINALIZADOS = "FINALIZADOS";
const ENDPOINT = "statistics";

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setLoader1 = loader1 => ({
    type: LOADER1,
    loader1,
});

export const setLoader2 = loader2 => ({
    type: LOADER2,
    loader2,
});

export const setLoader3 = loader3 => ({
    type: LOADER3,
    loader3,
});

export const setData = data => ({
    type: DATA,
    data
});

export const setHoras = horas => ({
    type: HORAS,
    horas
});

export const setClientes = clientes => ({
    type: CLIENTES,
    clientes
});

export const setFinalizados = finalizados => ({
    type: FINALIZADOS,
    finalizados
});

// ------------------------------------
// Actions
// ------------------------------------

export const listar = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${ENDPOINT}/get_dashboard`).then(response => {
        dispatch(setData(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const getHoras = (fecha_inicio = null, fecha_fin = null) => (dispatch) => {
    dispatch(setLoader1(true));
    const params = {}
    if(fecha_inicio) params["fecha_inicio"] = fecha_inicio;
    if(fecha_fin) params["fecha_fin"] = fecha_fin;
    api.get(`${ENDPOINT}/get_horas_atraso`, params).then(response => {
        dispatch(setHoras(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader1(false));
    });
};

export const getClientes = () => (dispatch) => {
    dispatch(setLoader2(true));
    api.get(`${ENDPOINT}/get_clients_info`).then(response => {
        dispatch(setClientes(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader2(false));
    });
};

export const getFinalizados = () => (dispatch) => {
    dispatch(setLoader3(true));
    api.get(`${ENDPOINT}/get_horas_extra`).then(response => {
        dispatch(setFinalizados(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader3(false));
    });
};

export const actions = {
    listar,
    getHoras,
    getClientes,
    getFinalizados
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LOADER1]: (state, { loader1 }) => {
        return {
            ...state,
            loader1,
        };
    },
    [LOADER2]: (state, { loader2 }) => {
        return {
            ...state,
            loader2,
        };
    },
    [LOADER3]: (state, { loader3 }) => {
        return {
            ...state,
            loader3,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [HORAS]: (state, { horas }) => {
        return {
            ...state,
            horas
        }
    },
    [CLIENTES]: (state, { clientes }) => {
        return {
            ...state,
            clientes
        }
    },
    [FINALIZADOS]: (state, { finalizados }) => {
        return {
            ...state,
            finalizados
        }
    }
};

export const initialState = {
    loader: false,
    loader1: false,
    loader2: false,
    loader3: false,
    data: false,
    horas: [],
    clientes: [],
    finalizados: []
};

export default handleActions(reducers, initialState);
