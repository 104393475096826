import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/puestos/puestos';
import Listar from './Listar';


const ms2p = (state) => {
  return {
    ...state.puestos,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Listar);