import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { renderField, renderSelectField } from "../../Utils/renderField";

const CrearEditarForm = (props) => {
    const { handleSubmit, fases, complejidades } = props;

    return (
        <form
            name="CrearEditarForm"
            className="form-validate mb-lg"
            onSubmit={handleSubmit}
        >
            <div className="row w-70">
                <div className="form-group has-feedback col-12">
                    <label htmlFor="descripcion">Descripción</label>
                    <Field
                        name="descripcion"
                        label="Descripción"
                        component={renderField}
                        type="text"
                        className="input--style"
                        placeholder="Descripción..."
                    />
                </div>
            </div>
            <div className="row w-70">
                <div className="form-group has-feedback col-12">
                    <label htmlFor="fase">Fase</label>
                    <Field
                        name="fase"
                        label="Fase"
                        component={renderSelectField}
                        options={fases}
                        labelKey="nombre"
                        valueKey="id"
                        className="form-control"
                        placeholder="Fase de proyecto..."
                        select_style={{
                            control: (styles) => ({
                                ...styles,
                                color: "#1F4D7B",
                                borderColor: "#1F4D7B",
                            }),
                        }}
                    />
                </div>
            </div>
            <div className="row w-70">
                <div className="form-group has-feedback col-12">
                    <label htmlFor="complejidad">Complejidad</label>
                    <Field
                        name="complejidad"
                        label="Complejidad"
                        component={renderSelectField}
                        options={complejidades}
                        labelKey="label"
                        valueKey="value"
                        className="form-control"
                        placeholder="Complejidad de proyecto..."
                        select_style={{
                            control: (styles) => ({
                                ...styles,
                                color: "#1F4D7B",
                                borderColor: "#1F4D7B",
                            }),
                        }}
                    />
                </div>
            </div>
            <div className="buttons-box">
                <Link
                    to="/actividades_entrega"
                    className="btn"
                    style={{ fontSize: 15 }}
                >
                    Cancelar
                </Link>
                <button
                    type="submit"
                    style={{ fontSize: 15 }}
                    className="btn btn-primary m-1 align-self-center"
                >
                    Guardar
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "CrearEditarForm", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            descripcion: validators.exists()("Este campo es requerido"),
            fase: validators.exists()("Este campo es requerido"),
            complejidad: validators.exists()("Este campo es requerido"),
        });
    },
})(CrearEditarForm);
