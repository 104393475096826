export const user_types = [
    {
        label: 'Administrador',
        value: 1
    },
    {
        label: 'Vendedor',
        value: 5
    },
    {
        label: 'Project manager',
        value: 10
    },
    {
        label: 'Recursos Humanos',
        value: 6
    },
    {
        label: 'Finanzas',
        value: 7
    },
    {
        label: 'Operador de ventas',
        value: 8,
    },
    {
        label: 'Asistente de Operaciones',
        value: 11
    }
];

export const exit_type = [
    {
        label: "Renuncia",
        value: 1
    },
    {
        label: "Despido",
        value: 2
    }
];

export const items_page = [
    {
        label: '10',
        value: '10'
    },
    {
        label: '25',
        value: '25'
    },
    {
        label: '50',
        value: '50'
    },
];

export const proyectos_operaciones_estados = {
    EN_PROCESO: "EN_PROCESO",
    EN_REVISION: "EN_REVISION",
    APROBADO: "APROBADO",
    VENTA_GANADA: "VENTA_GANADA",
    VENTA_PERDIDA: "VENTA_PERDIDA",
};
