import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/recursos/recursos';
import { actions as actionsSeniority } from '../../../../redux/modules/seniority/seniority';
import { actions as actionsPuestos } from '../../../../redux/modules/puestos/puestos';
import CrearEditar from './CrearEditar';


const ms2p = (state) => {
  return {
    ...state.recursos,
    seniority: state.seniority.filter,
    seniorityLoader: state.seniority.loader,
    puestos: state.puestos.filterAll,
    puestosLoader: state.puestos.loader,
  };
};

const md2p = { ...actions, obtenerSeniority: actionsSeniority.filter, obtenerPuestos: actionsPuestos.filterAll };

export default connect(ms2p, md2p)(CrearEditar);
