import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/tipo_contrato_vacaciones/tipo_contrato_vacaciones";
import CrearEditar from "./CrearEditar";

const ms2p = (state) => {
    return {
        ...state.tipo_contrato_vacaciones,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearEditar);
