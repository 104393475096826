import React, { Component } from "react";
import { RecursoPreview } from "../../Recursos/Listar/RecursoPreview";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import { Link } from "react-router-dom";        
import Select from "react-select";


import moment from "moment";
import ReactExport from 'react-data-export';
import './Listado.css';

class Listar extends Component {
    state = {
        page: 1,
        search: "",
        BreakLine: {
            whiteSpace: 'normal',
        },
        params: {
            // activo: true,
        },
    };

    componentDidMount() {   
        this.props.listar(this.state.page, this.state.params);
        
    }

    onPageChange = (page) => {
        this.props.listar(page, this.state.params);
        this.setState({ page });
    }


    render(){
        const { data, loader, eliminar} = this.props;

        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="title--blue">Historial Interno</h2>
                    </div>
                </div>
                <hr />
                <div className="flex row mt-4">
                    <div className="col-12 col-md-6" style={{display: 'flex', justifyContent: 'flex-start'}}>
                        <input
                            placeholder="Buscar..."
                            type="text"
                            className="form-control mt-4 input--style"
                            value={this.state.search}
                            onKeyPress={(key) => {
                                if (key.key == "Enter") {
                                    const { page, search } = this.state;
                                    this.props.listar(page, { search });
                                }
                            }}
                            onChange={(tx) => {
                                this.setState({ search: tx.target.value });
                                if (tx.target.value.length ==0){
                                    this.props.listar(1, this.state.params);
                                }
                            }}
                            style={{
                                color: "black",
                                fontSize: 15,
                                width: "55%",
                                float: "right",
                            }}
                        />
                    </div>
                </div> 
                 <Grid
                        data={data}
                        loading={loader}   
                        page={this.state.page}
                        page_size={this.state.page_size}
                        onPageChange={this.onPageChange}
                        className ="mb-3"
                    >
                        <TableHeaderColumn 
                            width='100' 
                            dataField="codigo" 
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                            Código
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width='200'
                            dataField="nombre"
                            dataSort
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                            dataFormat={(cell, row) => {
                                return (
                                    <RecursoPreview recurso={row}/>
                                )
                            }}
                        >
                            Nombre
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="puesto"
                            dataSort
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                            width='100'
                        
                        >
                            Puesto
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="id"
                            isKey   
                            dataSort
                            width='100'
                            dataFormat={(cell, row) => (
                                <Link to={`/historial_interno/${row.id}/historial`} className="btn btn-primary">
                                    Agregar Ascenso
                                </Link>
                            )}
                        >
                            Acciones
                        </TableHeaderColumn>
                            
                    </Grid>
                    
            </React.Fragment>
        );

    }
}
export default Listar;


