import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import Swal from "sweetalert2";
import { api } from "api";

const SUBMIT = "USER_SUBMIT";
const LOADER = "USER_LOADER";
const DATA = "USER_DATA";
const GET = "GET_DATA";

const ENDPOINT = "user";

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setGet = (get_data) => ({
    type: GET,
    get_data,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit =
    (data = {}) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        api.post(`${ENDPOINT}`, data)
            .then((response) => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha guardado correctamente",
                    type: "success",
                });
                dispatch(push("/users"));
            })
            .catch(() => {
                Swal.fire({
                    title: "Error",
                    text: "Ha ocurrido un error, inténtelo nuevamente.",
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const changePassword = (data) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get("/user/me")
        .then((res) => {
            data.id = res.id;
            api.post(`${ENDPOINT}/change_password`, data)
                .then(() => {
                    dispatch(setLoader(false));
                    Swal.fire({
                        title: "Éxito",
                        text: "Se ha guardado correctamente",
                        type: "success",
                    });
                    dispatch(push("/"));
                })
                .catch(() => {
                    dispatch(setLoader(false));
                    Swal.fire({
                        title: "Error",
                        text: "Ha ocurrido un error, inténtelo nuevamente.",
                        type: "error",
                    });
                })
        })
        .catch(() => {
            dispatch(setLoader(false));
        });
};

export const listar =
    (page = 1, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}`, { page, ...params })
            .then((response) => {
                dispatch(setData(response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const get =
    (id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}/${id}`)
            .then((response) => {
                dispatch(initializeForm("CrearEditarForm", response));
                dispatch(setGet(response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const update =
    (data = {}, id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.put(`${ENDPOINT}/${id}`, data)
            .then(() => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha actualizado correctamente",
                    type: "success",
                });
                dispatch(push("/users"));
            })
            .catch((err) => {
                console.log(err);
                Swal.fire({
                    title: "Error",
                    text: err.detail,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${ENDPOINT}/${id}`)
        .then(() => {
            Swal.fire({
                title: "Éxito",
                text: "Se ha eliminado correctamente",
                type: "success",
            });
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const actions = {
    listar,
    eliminar,
    onSubmit,
    get,
    update,
    changePassword,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GET]: (state, { get_data }) => {
        return {
            ...state,
            get_data,
        };
    },
};

export const initialState = {
    loader: false,
    data: false,
    get_data: {},
};

export default handleActions(reducers, initialState);
