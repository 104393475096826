import React from "react";
import locale from "date-fns/locale/es";
import { DateRange } from "react-date-range";
import Popup from "reactjs-popup";
import Select from "react-select";
import moment from "moment";

const FiltersData = ({
    // ==================== Propiedades =====================
    // Filtro de recursos
    valueSearch,
    searchInput,
    handleSearch,
    // Filtro de fechas
    fechaSearch,
    setFechaSearch,
    // Modal de fechas
    openPopup,
    setOpenPopup,
    // Tipos de proyecto
    tiposProyecto,
    setTipoSelected,
    skills,
    setSkillSelected,
}) => {
    return (
        <div className="card mb-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-md-3 ml-lg-2">
                        <label htmlFor="puesto" className="text-dark">
                            Buscar
                        </label>
                        <br />
                        <input
                            placeholder="Buscar..."
                            type="text"
                            className="form-control input--style input__reporte"
                            value={valueSearch}
                            ref={searchInput}
                            onChange={handleSearch}
                        />
                    </div>
                    &nbsp;
                    <div className="col-12 col-md-2 ml-lg-2">
                        <label htmlFor="puesto" className="text-dark">
                            Fechas
                        </label>
                        <br />
                        <button
                            style={{ height: "36px" }}
                            className="btn btn-light button--primary button--widthFull"
                            onClick={() => setOpenPopup(true)}
                        >
                            {moment(fechaSearch.startDate).format("DD/MM/YYYY")}
                            &nbsp;-&nbsp;
                            {moment(fechaSearch.endDate).format("DD/MM/YYYY")}
                        </button>
                        <Popup
                            open={openPopup}
                            onClose={() => setOpenPopup(false)}
                            modal
                            repositionOnResize={true}
                        >
                            <div className="card p-3">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                        <DateRange
                                            editableDateInputs={true}
                                            ranges={[fechaSearch]} // initial values
                                            onChange={(item) =>
                                                setFechaSearch(item.selection)
                                            }
                                            moveRangeOnFirstSelection={false}
                                            locale={locale}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Popup>
                    </div>
                    <div className="col-12 col-md-3 ml-4" style={{ zIndex: 200 }}>
                        <label htmlFor="tipo" className="text-dark">
                            Tipo de proyecto
                        </label>
                        <br />
                        <Select
                            name="tipo"
                            placeholder="Tipo de proyecto"
                            isSearchable
                            isClearable
                            options={tiposProyecto}
                            onChange={(e) => {
                                if (e) setTipoSelected(e.value);
                                else {
                                    setTipoSelected(null);
                                }
                            }}
                            styles={{
                                control: (styles) => ({
                                ...styles,
                                color: "#1F4D7B",
                                borderColor: "#1F4D7B",
                            }),}}
                        />
                    </div>
                    <div className="col-12 col-md-3 ml-4">
                        <label htmlFor="skill" className="text-dark">
                            Skills
                        </label>
                        <br />
                        <Select
                            name="skill"
                            placeholder="Skill..."
                            isSearchable
                            isClearable
                            options={skills}
                            onChange={(e) => {
                                if (e) setSkillSelected(e.value);
                                else {
                                    setSkillSelected(null);
                                }
                            }}
                            styles={{
                                control: (styles) => ({
                                ...styles,
                                color: "#1F4D7B",
                                borderColor: "#1F4D7B",
                            }),}}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FiltersData;
