import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import { Link } from "react-router-dom";
import Select from "react-select";
// import { items_page } from "../../../../utility/constants";
import moment from "moment";
import ReactExport from 'react-data-export';
import './Listado.css';
import { RecursoPreview } from "./RecursoPreview";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Listar extends Component {
    state = {
        page: 1,
        search: "",
        BreakLine: {
            whiteSpace: "normal",
        },
        params: {
            activo: true,
            despedido: false,
            renuncia: false,
            page_size: "99999",
            proyecto: null,
            cliente: null,
            puesto: null,
        },
    };

    componentDidMount() {
        this.props.getRole();
        this.props.getClientes();
        this.props.getProyectos();
        this.props.getPuestos();
        this.props.proyectFilter(this.state.page, this.state.params);
        //Ordenar los colaboradores por fecha fin en forma descendente si cuentan con fecha fin
        /*this.props.proyectFilter(this.state.page, this.state.params)
            .then(() => {
                this.props.data.results.sort((a, b) => {
                    if (a.fecha_fin && b.fecha_fin) {
                        return new Date(b.fecha_fin) - new Date(a.fecha_fin);
                    }
                });

            });   */    
        
    }

    onPageChange = (page) => {
        this.props.proyectFilter(page, this.state.params);
        this.setState({ page });
    };

    radioChange = (params) => {
        params.page_size = this.state.params.page_size;
        params.proyecto = this.state.params.proyecto;
        params.cliente = this.state.params.cliente;
        params.puesto = this.state.params.puesto;
        this.setState({ params, page: 1, search: "" });
        this.props.proyectFilter(1, params);
    };

    renderCliente(cliente) {
        return { __html: !cliente ? "No hay clientes asociados." : cliente }
    }

    render() {
        const { data, loader, eliminar, renunciaDespido, reactivar } = this.props;
        const role = this.props.user.me.type;
        const clientes = this.props.clientes.all_clients;
        const proyectos = this.props.proyectos.all_projects;
        const puestos = this.props.puestos.filterAll;
        const select_style = {
            control: (styles) => ({
                ...styles,
                color: "#1F4D7B",
                borderColor: "#1F4D7B",
            }),
        };
        let multiDataSet = []
        if (data.export) {
            if (data.export.length > 0) {
                multiDataSet = [
                    {
                        columns: [
                            { title: "Código", width: { wpx: 80 }, style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } },
                            { title: "Nombre", width: { wpx: 210 }, style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } },
                            { title: "Email", width: { wpx: 200 }, style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } },
                            { title: "Puesto", width: { wpx: 100 }, style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } },
                            { title: "Contratación", width: { wpx: 90 }, style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } },
                            { title: "Tipo proyecto", width: { wpx: 120 }, style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } },
                            { title: "Fecha inicio", width: { wpx: 90 }, style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } },
                            { title: "Fecha fin", width: { wpx: 90 }, style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } },
                            { title: "Clientes", width: { wpx: 210 }, style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } },
                            { title: "Proyectos", width: { wpx: 210 }, style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } },
                        ],
                        data: data.export.map((dato) => {
                            let proyectos = "";
                            let clientes = "";
                            dato.proyectos.forEach(e => {
                                if (e.proyecto != null) {
                                    proyectos += ("\n-" + e.proyecto);
                                };
                            });
                            dato.proyectos.forEach(e => {
                                if (e.cliente != null) clientes += ("\n-" + e.cliente);
                            });
                            return [
                                { value: dato.codigo },
                                { value: dato.nombre },
                                { value: dato.email },
                                { value: dato.puesto.nombre },
                                { value: dato.tipo_contratacion.nombre },
                                { value: dato.proyecto_default.nombre },
                                { value: moment(dato.fecha_inicio).format("DD/MM/YYYY") },
                                { value: moment(dato.fecha_fin).format("DD/MM/YYYY") },
                                { value: clientes },
                                { value: proyectos },
                            ]
                        })
                    }
                ]
            }
        }

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h2 className="title--blue">LISTADO DE RECURSOS</h2>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-12 col-md-6" />
                    <div className="col-12 col-md-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {role == 1 || role == 6 ? (
                            <React.Fragment>
                                <ExcelFile element={<button className="btn btn-light mt-4"
                                    style={{ borderColor: '#1F4D7B', color: '#1F4D7B', fontSize: 15 }}>Exportar</button>} filename="recursos">
                                    <ExcelSheet dataSet={multiDataSet} name="Recursos Activos" />
                                </ExcelFile>
                                <Link to="/recursos/crear" className="btn btn-primary mt-4" style={{ fontSize: 15, marginLeft: '15px' }}>
                                    Nuevo recurso
                                </Link>
                            </React.Fragment>
                        ) : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-5 display--fieldset">
                        <label className="container--1">Activos
                            <input
                                disabled={this.props.loader}
                                type="radio"
                                name="recursos"
                                value="activo"
                                checked={
                                    this.state.params.activo &&
                                    !this.state.params.despedido &&
                                    !this.state.params.renuncia
                                }
                                onChange={() =>
                                    this.radioChange({
                                        activo: true,
                                        despedido: false,
                                        renuncia: false,
                                    })
                                }
                            />

                        </label>
                        <label className="container--1">Inactivos
                            <input
                                disabled={this.props.loader}
                                type="radio"
                                name="recursos"
                                value="inactivos"
                                checked={!this.state.params.activo && !this.state.params.despedido && !this.state.params.renuncia}
                                onChange={() =>
                                    this.radioChange({
                                        activo: false,
                                        despedido: false,
                                        renuncia: false,
                                    })
                                }
                            />

                        </label>
                        <label className="container--1">Despedidos
                            <input
                                disabled={this.props.loader}
                                type="radio"
                                name="recursos"
                                values="despedidos"
                                checked={!this.state.params.activo && this.state.params.despedido && !this.state.params.renuncia}
                                onChange={() =>
                                    this.radioChange({
                                        activo: false,
                                        despedido: true,
                                        renuncia: false,
                                    })
                                }
                            />

                        </label>
                        <label className="container--1">Renuncias
                            <input
                                disabled={this.props.loader}
                                type="radio"
                                name="recursos"
                                values="renuncias"
                                checked={this.state.params.renuncia && !this.state.params.activo && !this.state.params.despedido}
                                onChange={() =>
                                    this.radioChange({
                                        activo: false,
                                        renuncia: true,
                                        despedido: false,
                                    })
                                }
                            />

                        </label>
                    </div>
                </div>
                <div className="flex row mt-4">
                    <div className="col-12 col-md-3">
                        <input
                            placeholder="Buscar..."
                            type="text"
                            className="form-control input--style"
                            value={this.state.search}
                            onKeyPress={(key) => {
                                if (key.key == "Enter") {
                                    this.setState({ page: 1 });
                                    this.props.proyectFilter(1, {
                                        search: this.state.search,
                                        ...this.state.params,
                                    });
                                }
                            }}
                            onChange={(tx) => {
                                this.setState({ search: tx.target.value });
                                if (tx.target.value.length == 0) {
                                    this.props.proyectFilter(1, this.state.params);
                                }
                            }}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <Select
                            styles={select_style}
                            name="cliente"
                            placeholder="Cliente"
                            isSearchable={true}
                            isClearable={true}
                            options={clientes}
                            onChange={(e) => {
                                const { params } = this.state;
                                if (e) {
                                    params.cliente = e.value;
                                } else {
                                    delete params.cliente;
                                }
                                this.setState({ params });
                                this.props.proyectFilter(1, { ...params });
                            }}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <Select
                            styles={select_style}
                            name="proyecto"
                            placeholder="Proyecto"
                            isSearchable={true}
                            isClearable={true}
                            options={proyectos}
                            onChange={(e) => {
                                const { params } = this.state;
                                if (e) {
                                    params.proyecto = e.value;
                                } else {
                                    delete params.proyecto;
                                }
                                this.setState({ params });
                                this.props.proyectFilter(1, { ...params });
                            }}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <Select
                            styles={select_style}
                            name="puesto"
                            placeholder="Puesto"
                            isSearchable={true}
                            isClearable={true}
                            options={puestos}
                            onChange={(e) => {
                                const { params } = this.state;
                                if (e) {
                                    params.puesto = e.value;
                                } else {
                                    delete params.puesto;
                                }
                                this.setState({ params });
                                this.props.proyectFilter(1, { ...params });
                            }}
                        />
                    </div>
                    {/* <div className="col-12 col-md-2">
                        <Select
                            styles={select_style}
                            name="page_size"
                            placeholder="Items por página"
                            isSearchable={false}
                            isClearable={false}
                            options={items_page}
                            defaultValue={items_page[0]}
                            onChange={(e) => {
                                const { params } = this.state;
                                params.page_size = e.value;
                                this.setState({ params });
                                this.props.proyectFilter(1, { ...params });
                            }}
                        />
                    </div>  */}
                </div>
                {data.contadores ? <div className="flex row mt-4">
                    <div className="col-12">
                        <p style={{ fontWeight: 'bold', marginBottom: '0' }}>Cantidad por puestos:</p>
                        <ul>
                            {data.contadores.map((e, i) => (
                                <li key={i} style={{ color: '#1F4D7B', float: 'left', marginRight: '10%' }}>
                                    <span style={{ fontWeight: 'bold' }}>{e.puesto}: </span>{e.cantidad}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div> : null}
                {data ? <Grid
                        data={data}
                        loading={loader}
                        page={this.state.page}
                        page_size={this.state.params.page_size}
                        onPageChange={this.onPageChange}
                        pagination={false}
                        className="mb-3" 
                    >
                        <TableHeaderColumn width='100' dataField="codigo" tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                            Código
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width='200'
                            dataField="nombre"
                            dataSort
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                            dataFormat={(cell, row) => {
                                return (
                                    <RecursoPreview recurso={row}/>
                                )
                            }}
                        >
                            Nombre
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_contratacion"
                            dataSort
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                            dataFormat={(cell, row) => {
                                return cell ? cell.nombre : "---";
                            }}
                        >
                            Contratación
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="puesto"
                            dataSort
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                            width='100'
                            dataFormat={(cell, row) => {
                                return cell ? cell.nombre : "---";
                            }}
                        >
                            Puesto
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proyecto_default"
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                            dataSort
                            dataFormat={(cell, row) => {
                                return cell ? cell.nombre : "---";
                            }}
                        >
                            Tipo proyecto
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_inicio"
                            dataSort
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                            dataFormat={(cell, row) => {
                                if(!cell) return 0;
                                const start = cell ? moment(cell) : moment();
                                const today = moment();

                                const diffMonths = today.diff(start, "month");

                                const years = Math.floor(diffMonths / 12);
                                const months = diffMonths % 12;

                                const yearsLabel = years > 0 ? `${years} ${years > 1 ? "años" : "año"} y ` : "";
                                const monthsLabel = `${months} ${months !== 1 ? "meses" : "mes"}`;

                                const value = `${yearsLabel}${monthsLabel}`;
                                return value;
                            }}
                        >
                            Antigüedad
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proyectos"
                            dataSort
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                            dataFormat={(cell, row) => {
                                let cliente = "";
                                return cell && cell.length > 0 ? (
                                    <React.Fragment>
                                    {cell.map((proyecto) => {
                                        if(proyecto.cliente && cliente.includes(proyecto.cliente) == false) {
                                            cliente += `<div key={proyecto.id} style={{wordWrap: "break-word",fontSize: 11,color: "black",}}>
                                                        ○ ${proyecto.cliente}
                                                    </div>`;
                                        }
                                    })}
                                    <div style={{ wordWrap: "break-word" }} dangerouslySetInnerHTML={this.renderCliente(cliente)} />
                                    </React.Fragment>
                                ) : (
                                    <center style={{ fontSize: 11 }}>
                                        No hay clientes asociados.
                                    </center>
                                );
                            }}
                        >
                            Clientes
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="proyectos"
                            dataSort
                            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                            dataFormat={(cell, row) => {
                                return cell && cell.length > 0 ? (
                                    <div style={{ wordWrap: "break-word" }}>
                                        {cell.map((proyecto) => {
                                            return (
                                                <div
                                                    key={proyecto.id}
                                                    style={{
                                                        wordWrap: "break-word",
                                                        fontSize: 11,
                                                        color: proyecto.fecha_fin_retraso
                                                            ? "red"
                                                            : "black",
                                                    }}
                                                >
                                                    ○ {proyecto.proyecto}
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <center style={{ fontSize: 11 }}>
                                        No hay proyectos activos
                                    </center>
                                );
                            }}
                        >
                            Proyectos
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataSort
                            isKey
                            dataFormat={(cell, row) => {
                                let props = {};
                                if (row.activo == true) {
                                    if (role == 1 || role == 6)
                                        props.editar = "recursos";
                                    if (role == 1 || role == 6) props.eliminar = eliminar;
                                } else if(row.despedido == true) {
                                    if (role == 1 || role == 6) {
                                        props.finalizar = renunciaDespido;
                                        props.params = this.state.params;
                                        props.eliminar = eliminar;

                                        //coso
                                        props.reactivar = reactivar;
                                    }
                                } else if(row.renuncia == true) {
                                    if (role == 1 || role == 6) {
                                        props.finalizar = renunciaDespido;
                                        props.params = this.state.params;
                                        props.eliminar = eliminar;
                                        //coso
                                        props.reactivar = reactivar;
                                    }
                                } else if(row.activo == false) {
                                    if (role == 1 || role == 6) {
                                        props.params = this.state.params;
                                        props.finalizar = renunciaDespido;
                                        //coso
                                        props.reactivar = reactivar;
                                    }

                                }

                                const actions = standardActions({ ...props });
                                return actions(cell, row);
                            }}
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid> : null}
            </React.Fragment>
        );
    }
}

export default Listar;
