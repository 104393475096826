import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Field, reduxForm, initialize } from 'redux-form';
import { renderNumber, renderDayPicker } from '../../Utils/renderField';

const validateForm = values => {
    const errors = {};
    if (!values.salario) {
        errors.salario = 'Campo requerido';
    } else if (values.salario < 0) {
        errors.salario = 'El salario no puede ser negativo';
    }
    if (!values.fecha_inicio) {
        errors.fecha_inicio = 'Campo requerido';
    }
    return errors;
};

const CrearEditarForm = (props) => {
    const { handleSubmit, codigo_empleado } = props;

    useEffect(() => {
        // Inicializar el valor del campo oculto
        props.initialize({ codigo_empleado });
    }, [codigo_empleado]);

    return (
        <form name="CrearEditarForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className='row'>
                <div className="form-group has-feedback col-6">
                    <label htmlFor="nombre">Salario</label>
                    <Field 
                        name="salario" 
                        label="salario" 
                        component={renderNumber} 
                        className="input--style"
                        type="number"  
                    />
                </div>
                <div className="form-group has-feedback col-6">
                    <label htmlFor="fecha_inicio">Fecha de aplicación</label>
                    <Field 
                        name="fecha_inicio" 
                        label="fecha_inicio" 
                        component={renderDayPicker}
                        className="input--style" 
                     />
                </div>
                <input type="hidden" name="codigo_empleado" />
            </div>
            <div className="buttons-box">
                <Link
                    to="/historial_economico"
                    className="btn btn-secondary"
                    style={{ fontSize: 15 }}
                >
                    Regresar
                </Link>
                <button type="submit" style={{ fontSize: 15 }} className="btn btn-primary m-1 align-self-center">Agregar</button>
            </div>
        </form>
    );
};

// Conectar el formulario con Redux y aplicar validaciones
export default reduxForm({
    form: 'CrearEditarForm',
    validate: validateForm
})(CrearEditarForm);