import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/permisos/permisos';
import { actions as actionsDepartamentos } from "../../../../redux/modules/departamento_organizacion/departamento_organizacion";
import CrearEditar from './CrearEditar';

const ms2p = (state) => {
  return {
    ...state.permisos,
    jefes: state.departamento_orgnizacion.jefes,
    jefesLoader: state.departamento_orgnizacion.loader,
  };
};

const md2p = { ...actions, obtenerJefes: actionsDepartamentos.getJefes };

export default connect(ms2p, md2p)(CrearEditar);
