import React from "react";
import { Chart, registerables } from 'chart.js'
import { Doughnut, Bar } from 'react-chartjs-2';

Chart.register(...registerables);

const Recursos = (props) => {

    let data_struct = null;
    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'center'
            },
            title: {
                display: false,
                text: ''
            },
            subtitle: {
                display: false,
                text: 'Custom Chart Subtitle'
            }
        },
        responsive: true,
        scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true
            },
        },
    };
    if(props.data) {
        data_struct = props.data.chart;
    }

    return (
        <React.Fragment>
            <div className="col-12 col-md-6" style={props.align}>
                <div className="card mb-3 mt-3 recurso--card w-100 mx-auto" style={{boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)"}}>
                    <div className="card-header card--header">
                        <h6 style={{ fontWeight: 'bold' }}>{props.title} {props.data.total}</h6>
                    </div>
                    <div className="card-body">
                        <Bar data={data_struct} options={options} width={100} height={100} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Recursos;
