import React from "react";
import { connect } from "react-redux";
import { submit, isPristine, isInvalid } from "redux-form";

const ProgressBarButton = ({
    children,
    dispatch,
    disabled,
    className,
    currentStep,
    step,
    setStep,
    pristine,
    invalid,
    edit = false,
}) => {
    const handleSubmit = () => {
        if (currentStep === step) return;
        if (edit && pristine) {
            setStep(step);
        } else if (currentStep === step - 1) {
            dispatch(submit("ProyectoOperacionesForm"));
        } else if (currentStep > step && (!edit || !invalid)) {
            setStep(step);
        }
    };

    return (
        <button
            type="button"
            disabled={disabled}
            className={className}
            onClick={handleSubmit}
        >
            {children}
        </button>
    );
};

export default connect((state) => ({
    pristine: isPristine("ProyectoOperacionesForm")(state),
    invalid: isInvalid("ProyectoOperacionesForm")(state),
}))(ProgressBarButton);
