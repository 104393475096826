import React from 'react';
import './HistorialAumentos.css';

const HistorialAumentos = ({ historialData }) => {
    return (
        <div className="historial-container">
            <h2>Historial de Aumentos</h2>
            <table className="historial-table">
                <thead>
                    <tr>
                        <th>Salario</th>
                        <th>Fecha de aplicación</th>
                        <th>Fecha de finalización</th>
                    </tr>
                </thead>
                <tbody>
                    {historialData.map((item) => (
                        <tr key={item.id}>
                            <td>{item.tipo_moneda} {item.salario}</td>
                            <td>{item.fecha_inicio}</td>
                            <td>{item.fecha_fin ? item.fecha_fin : 'A la fecha'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


export default HistorialAumentos;
