import React from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import { Chart, registerables } from "chart.js";
import { api } from "api";
import moment from "moment";
import { ChartLine } from "./ChartLine";
import { InformationCard } from "./InformationCard";
import totalSVG from "../../../../../assets/img/total-colab.svg";
import activosSVG from "../../../../../assets/img/activos-colab.svg";
import despidosSVG from "../../../../../assets/img/despidos-colab.svg";
import renunciasSVG from "../../../../../assets/img/renuncias-colab.svg";

Chart.register(...registerables);

export const Visualizar = () => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    React.useEffect(() => {
        getReports();
    }, [state.year]);

    const getReports = async () => {
        try {
            onLoad();
            const reports = await api.get("recurso/reportes", {
                year: state.year,
            });
            onGetReports(reports);
            const now = moment();
            onUpdate(now.format("DD/MM/YYYY hh:mm A"));
        } catch (e) {
            console.error(e);
        }
    };

    const onLoad = () => dispatch({ type: actionTypes.loading });
    const onGetReports = (reports = {}) => {
        dispatch({ type: actionTypes.setReports, payload: reports });
    };

    const onUpdate = (value) =>
        dispatch({ type: actionTypes.lastUpdate, payload: value });

    const onChangeYear = (year) => {
        if (Number(year) >= 2000 && Number(year) <= 2100) {
            dispatch({ type: actionTypes.changeYear, payload: year });
        }
    };

    const { reports = {}, loading, last_update } = state;

    const {
        movimientos_por_mes = { labels: [], datasets: [] },
        altas_bajas_mes = { labels: [], datasets: [] },
        totales = {},
        totales_altas_bajas = {},
        rotacion = 0,
        retencion = 0,
    } = reports;

    const { total = 0, activos = 0, despidos = 0, renuncias = 0 } = totales;
    const {
        altas = 0,
        altas_mes = 0,
        bajas = 0,
        bajas_mes = 0,
    } = totales_altas_bajas;

    const getPart = (total, partial) => {
        if (total < 1) return "0%";
        const part = Number((partial * 100) / total);
        return `${part.toFixed()}%`;
    };

    const totalsList = [
        {
            svgUrl: totalSVG,
            alt: "total de colaboradores",
            color: "#000",
            totalText: "Total colab.:",
            value: total,
        },
        {
            svgUrl: activosSVG,
            alt: "Colaboradores activos",
            color: "#4DCA00",
            totalText: "Colaboradores activos:",
            value: activos,
        },
        {
            svgUrl: despidosSVG,
            alt: "Despidos",
            color: "#CA0000",
            totalText: "Despidos:",
            value: despidos,
        },
        {
            svgUrl: renunciasSVG,
            alt: "Renuncias",
            color: "#007BFF",
            totalText: "Renuncias:",
            value: renuncias,
        },
    ];

    const altasBajasList = [
        {
            color: "#2684E2",
            totalText: "Bajas/Mes",
            value: bajas_mes,
        },
        {
            color: "#F4781E",
            totalText: "Bajas/Año",
            value: bajas,
        },
        {
            color: "#0DA140",
            totalText: "Altas/Mes",
            value: altas_mes,
        },
        {
            color: "#670269",
            totalText: "Altas/Año",
            value: altas,
        },
    ];

    return (
        <LoadMask loading={loading} blur={true}>
            <div className="flex row mt-2">
                <div className="col-12">
                    <h2 className="title--blue">Índice de retención y rotación</h2>
                    <div className="d-flex alig-items-center justify-content-center title--blue">
                        <button className="btn" onClick={() => onChangeYear(state.year - 1)}>
                            <i className="material-icons">navigate_before</i>
                        </button>
                        <span className="px-5">{state.year}</span>
                        <button className="btn" onClick={() => onChangeYear(state.year + 1)}>
                            <i className="material-icons">navigate_next</i>
                        </button>
                    </div>
                </div>
            </div>
            <hr />
            <InformationCard>
                {totalsList.map((item) => (
                    <li key={item.alt}>
                        <img src={item.svgUrl} alt={item.alt} />
                        <span
                            className="label-information"
                            style={{ color: item.color }}
                        >
                            {item.totalText}
                        </span>
                        <span className="label-totals">
                            {item.value} ({getPart(total, item.value)})
                        </span>
                    </li>
                ))}
            </InformationCard>
            <ChartLine
                title={`Índice de retención de colaboradores: ${retencion}%`}
                total={`Total de colaboradores: ${total}`}
                lastUpdate={`Última actualización: ${last_update}`}
                data={movimientos_por_mes}
            />
            <InformationCard>
                {altasBajasList.map((item) => (
                    <li key={item.totalText}>
                        <span
                            className="label-information"
                            style={{ color: item.color }}
                        >
                            {item.totalText}
                        </span>
                        <span className="label-totals">
                            {item.value} ({getPart(total, item.value)})
                        </span>
                    </li>
                ))}
            </InformationCard>
            <ChartLine
                title={`Índice de rotación de colaboradores: ${rotacion}%`}
                total={`Total de colaboradores: ${total}`}
                lastUpdate={`Última actualización: ${last_update}`}
                data={altas_bajas_mes}
            />
        </LoadMask>
    );
};

const initialState = {
    loading: false,
    reports: {},
    last_update: "",
    year: new Date().getFullYear(),
};

const actionTypes = {
    loading: "LOADING",
    setReports: "SET_REPORTS",
    lastUpdate: "LAST_UPDATE",
    changeYear: "CHANGE_YEAR",
};

const reducerObject = (state, payload) => ({
    [actionTypes.setReports]: {
        ...state,
        reports: payload,
        loading: false,
    },
    [actionTypes.loading]: {
        ...state,
        loading: true,
    },
    [actionTypes.lastUpdate]: {
        ...state,
        last_update: payload,
    },
    [actionTypes.changeYear]: {
        ...state,
        year: payload,
    },
});

const reducer = (state, action) => {
    return reducerObject(state, action.payload)[action.type] || state;
};
