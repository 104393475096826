import React from "react";
import { FieldArray } from "redux-form";
import { renderEnlaces } from "../Renders/renderEnlaces";

const EnlacesProyecto = ({ isDisabled = false }) => {
    return (
        <div className="mt-3 mb-3">
            <h5 className="title--blue--1">Enlaces</h5>
            <div className="card-form">
                <div className="row">
                    <div className="col-12">
                        <FieldArray
                            component={renderEnlaces}
                            name="enlaces"
                            rerenderOnEveryChange={true}
                            isDisabled={isDisabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export { EnlacesProyecto };
