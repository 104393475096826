import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/historial_interno/historial_interno';
import CrearEditar from './CrearEditar';
import { historial } from '../../../../redux/modules/historial_interno/historial_interno';
import { actions as actionsPuestos} from '../../../../redux/modules/puestos/puestos';

const ms2p = (state) => {
    return {
        ...state.historial_interno,
        historialData: state.historial_interno.data,
        puestos: state.puestos.filterAll,
        puestosLoader: state.puestos.loader,   
    };
};


const md2p = { ...actions, loadHistorial: historial, obtenerPuestos: actionsPuestos.filterAll};

export default connect(ms2p, md2p)(CrearEditar);