import React from "react";
import { Field } from "redux-form";
import {
    renderField,
    renderSelectField,
    renderNumber,
} from "../../../Utils/renderField";

const mesesGarantia = [
    { value: 1, label: "1 mes" },
    { value: 2, label: "2 meses" },
    { value: 3, label: "3 meses" },
    { value: 4, label: "4 meses" },
    { value: 5, label: "5 meses" },
    { value: 6, label: "6 meses" },
    { value: 7, label: "7 meses" },
    { value: 8, label: "8 meses" },
    { value: 9, label: "9 meses" },
    { value: 10, label: "10 meses" },
    { value: 11, label: "11 meses" },
    { value: 12, label: "12 meses" },
    { value: 13, label: "13 meses" },
    { value: 14, label: "14 meses" },
    { value: 15, label: "15 meses" },
    { value: 16, label: "16 meses" },
    { value: 17, label: "17 meses" },
    { value: 18, label: "18 meses" },
    { value: 19, label: "19 meses" },
    { value: 20, label: "20 meses" },
    { value: 21, label: "21 meses" },
    { value: 22, label: "22 meses" },
    { value: 23, label: "23 meses" },
    { value: 24, label: "24 meses" },
];

const ConfiguracionGeneral = ({ complejidades, totalHorasValue, change }) => {
    React.useEffect(() => {
        change("total_horas_garantia", totalHorasValue);
    }, [totalHorasValue]);

    return (
        <div>
            <h5 className="title--blue--1">Configuración general</h5>
            <hr />
            <div className="row">
                <div className="form-group has-feedback col-6">
                    <label htmlFor="nombre">Nombre de la plantilla</label>
                    <Field
                        name="nombre"
                        label="Nombre de la plantilla"
                        component={renderField}
                        type="text"
                        className="input--style"
                        placeholder="Nombre..."
                    />
                </div>
                <div className="form-group has-feedback col-6">
                    <label htmlFor="complejidad">Complejidad de proyecto</label>
                    <Field
                        name="complejidad"
                        label="Complejidad de proyecto"
                        component={renderSelectField}
                        options={complejidades}
                        className="input--style"
                        labelKey="label"
                        valueKey="value"
                        placeholder="Complejidades..."
                        select_style={{
                            control: (styles) => ({
                                ...styles,
                                color: "#1F4D7B",
                                borderColor: "#1F4D7B",
                            }),
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group has-feedback col-6">
                    <label htmlFor="part_margen_error">% Margen de error</label>
                    <Field
                        name="part_margen_error"
                        label="% Margen de error"
                        component={renderNumber}
                        className="input--style"
                        suffix="%"
                        placeholder="%"
                    />
                </div>

                <div className="form-group has-feedback col-3">
                    <label htmlFor="part_margen_error">% Más probable</label>
                    <Field
                        name="part_probable"
                        component={renderNumber}
                        className="input--style"
                        suffix="%"
                        placeholder="%"
                    />
                </div>

                <div className="form-group has-feedback col-3">
                    <label htmlFor="part_pesimista">% Pesimista</label>
                    <Field
                        name="part_pesimista"
                        label="% Pesimista"
                        component={renderNumber}
                        className="input--style"
                        suffix="%"
                        placeholder="%"
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group has-feedback col-6">
                    <label htmlFor="meses_garantia">Cant. Meses garantía</label>
                    <Field
                        name="meses_garantia"
                        label="Cant. Meses garantía"
                        component={renderSelectField}
                        options={mesesGarantia}
                        className="input--style"
                        labelKey="label"
                        valueKey="value"
                        select_style={{
                            control: (styles) => ({
                                ...styles,
                                color: "#1F4D7B",
                                borderColor: "#1F4D7B",
                            }),
                        }}
                        placeholder="Meses..."
                    />
                </div>
                <div className="form-group has-feedback col-6">
                    <label htmlFor="horas_mes_garantia">
                        Cant. Horas mensuales de garantía
                    </label>
                    <Field
                        name="horas_mes_garantia"
                        label="Cant. Horas mensuales de garantía"
                        component={renderNumber}
                        className="input--style"
                        suffix=" hrs"
                        placeholder="hrs"
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group has-feedback col-6">
                    <label htmlFor="total_horas_garantia">
                        Total de horas de garantía
                    </label>
                    <Field
                        name="total_horas_garantia"
                        label="Total de horas de garantía"
                        component={renderNumber}
                        className="input--style"
                        suffix=" hrs"
                        readOnly={true}
                        placeholder="Total..."
                    />
                </div>

                <div className="form-group has-feedback col-6">
                    <label htmlFor="part_descuento_autorizado">
                        % Descuento autorizado
                    </label>
                    <Field
                        name="part_descuento_autorizado"
                        label="% Descuento autorizado"
                        component={renderNumber}
                        className="input--style"
                        suffix="%"
                        placeholder="%"
                    />
                </div>
            </div>
        </div>
    );
};

export { ConfiguracionGeneral };
