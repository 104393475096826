import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import Swal from "sweetalert2";
import { api } from "api";

const LOADER = "TIPO_CONTRATO_VACACIONES_LOADER";
const DATA = "TIPO_CONTRATO_VACACIONES_DATA";
const SUBMIT = "TIPO_CONTRATO_VACACIONES_SUBMIT";

const ENDPOINT = "tipo_contrato_vacaciones";

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

// ------------------------------------
// Actions
// ------------------------------------

export const listar =
    (page = 1, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}`, { page, ...params })
            .then((response) => {
                dispatch(setData(response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const obtenerTipoContratoVacaciones =
    (id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}/${id}`)
            .then((response) => {
                dispatch(initializeForm("CrearEditarForm", response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const actualizarTipoContratoVacaciones =
    (data = {}, id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.put(`${ENDPOINT}/${id}`, data)
            .then(() => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha actualizado correctamente",
                    type: "success",
                });
                dispatch(push("/tipo_contrato_vacaciones"));
            })
            .catch((error) => {
                const textError =
                    error && error.non_field_errors
                        ? error.non_field_errors[0]
                        : "Ha ocurrido un error, inténtelo nuevamente.";
                Swal.fire({
                    title: "Error",
                    text: textError,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const actions = {
    obtenerTipoContratoVacaciones,
    actualizarTipoContratoVacaciones,
    listar,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {},
};

export default handleActions(reducers, initialState);
