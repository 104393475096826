import React from "react";

import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { renderFilePicker } from "../../Utils/renderField";
import { RenderFields } from "./RenderFields";
import validateFields from "./validate";

function CurriculumForm({
    prevStep,
    handleSubmit,
    edit,
    cvPersonalUrl,
    cvEmpresaUrl,
    fotoDpiUrl,
    fotoPerfilUrl,
}) {
    const types = ["application/pdf", "image/jpeg", "image/png"];

    const fieldsFirst = [
        [
            // row
            {
                label: "Foto Perfil",
                props: {
                    name: "foto_perfil",
                    label: "Foto Perfil",
                    component: renderFilePicker,
                    className: "input--style",
                    photo: edit ? fotoPerfilUrl : "",
                    types,
                },
            },
            {
                label: "Foto DPI",
                props: {
                    name: "foto_dpi",
                    label: "Foto DPI",
                    component: renderFilePicker,
                    className: "input--style",
                    photo: edit ? fotoDpiUrl : "",
                    types,
                },
            },
        ],
    ];

    const fields = [
        [
            // row
            {
                label: "CV Empresa",
                props: {
                    name: "cv_empresa",
                    label: "CV Empresa",
                    component: renderFilePicker,
                    className: "input--style",
                    photo: edit ? cvEmpresaUrl : "",
                    types: [
                        ...types,
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    ],
                },
            },
            {
                label: "CV Personal",
                props: {
                    name: "cv_personal",
                    label: "CV Personal",
                    component: renderFilePicker,
                    className: "input--style",
                    photo: edit ? cvPersonalUrl : "",
                    types,
                },
            },
        ],
    ];

    const openNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <React.Fragment>
            <form
                name="RecursosCrearEditarForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <RenderFields fields={fieldsFirst} />
                {edit ? (
                    <React.Fragment>
                        <div className="row text-center">
                            <div className="col-12 col-md-6">
                                <button
                                    type="button"
                                    className="btn btn-dark"
                                    disabled={!fotoPerfilUrl}
                                    onClick={() => openNewTab(fotoPerfilUrl)}
                                >
                                    Ver foto perfil
                                </button>
                            </div>
                            <div className="col-12 col-md-6">
                                <button
                                    type="button"
                                    className="btn btn-dark"
                                    disabled={!fotoDpiUrl}
                                    onClick={() => openNewTab(fotoDpiUrl)}
                                >
                                    Ver foto DPI
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                ) : null}
                <RenderFields fields={fields} />
                {edit ? (
                    <React.Fragment>
                        <div className="row text-center">
                            <div className="col-12 col-md-6">
                                <button
                                    type="button"
                                    className="btn btn-dark"
                                    disabled={!cvEmpresaUrl}
                                    onClick={() => openNewTab(cvEmpresaUrl)}
                                >
                                    Ver CV Empresa
                                </button>
                            </div>
                            <div className="col-12 col-md-6">
                                <button
                                    type="button"
                                    className="btn btn-dark"
                                    disabled={!cvPersonalUrl}
                                    onClick={() => openNewTab(cvPersonalUrl)}
                                >
                                    Ver CV Personal
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                ) : null}
                <div className="buttons-box">
                    <button
                        onClick={prevStep}
                        type="button"
                        className="btn btn-secondary"
                        style={{ fontSize: 15 }}
                    >
                        Regresar
                    </button>
                    <button
                        type="submit"
                        style={{ fontSize: 15 }}
                        className="btn btn-primary m-1 align-self-center"
                    >
                        Guardar
                    </button>
                </div>
            </form>
        </React.Fragment>
    );
}

// export { CurriculumForm };

const form = reduxForm({
    form: "RecursosCrearEditarForm", // a unique identifier for this  form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validateFields,
})(CurriculumForm);

const selector = formValueSelector("RecursosCrearEditarForm");
export default connect((state) => {
    const cvPersonalUrl = selector(state, "cv_personal_url") || "";
    const cvEmpresaUrl = selector(state, "cv_empresa_url") || "";
    const fotoDpiUrl = selector(state, "foto_dpi_url") || "";
    const fotoPerfilUrl = selector(state, "foto_perfil_url") || "";
    return {
        cvPersonalUrl,
        cvEmpresaUrl,
        fotoDpiUrl,
        fotoPerfilUrl,
    };
})(form);
