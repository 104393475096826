import React from "react";

import { renderSelectField, renderCurrency } from "../../../Utils/renderField";

import { Field } from "redux-form";

export const renderGastos = ({
    fields,
    meta: { error },
    gastos = [],
    show = true,
    isDisabled = false,
}) => {
    const select_style = {
        control: (styles, state) => ({
            ...styles,
            color: "#1F4D7B",
            borderWidth: state.isFocused || !state.hasValue ? "1px" : "0px",
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            backgroundColor: "white",
        }),
    };

    return (
        <div
            className="col-10 mt-4"
            style={{
                display: show ? "block" : "none",
            }}
        >
            <div className="row mb-1">
                <span className="col-6 title--blue--1">Descripción</span>
                <span className="col-3 title--blue--1 p-0">Total</span>
            </div>

            {fields.length <= 0 && (
                <div className="col-12">
                    <span className="text-center">---</span>
                </div>
            )}

            <span className="text-danger">{error}</span>

            {fields.map((gasto, index, fields) => {
                const currentField = fields.get(index);

                return (
                    <div className="row field--array bg-white" key={index}>
                        <div className="form-group has-feedback col-6 m-0">
                            <Field
                                name={`${gasto}.gasto`}
                                select_style={select_style}
                                component={renderSelectField}
                                label={`#${index + 1}`}
                                options={gastos}
                                labelKey="label"
                                valueKey="value"
                                className="form-group"
                                placeholder="Gastos..."
                                extra_change={true}
                                extraChange={(value) => {
                                    const gasto = gastos.find(
                                        (g) => g.value === value
                                    );
                                    if (gasto) currentField.total = gasto.monto;
                                }}
                                disabled={isDisabled}
                            />
                        </div>
                        <div className="form-group has-feedback col-3 m-0 p-0">
                            <Field
                                name={`${gasto}.total`}
                                component={renderCurrency}
                                prefix="$"
                                label={`Total`}
                                placeholder="$"
                                readOnly={true}
                            />
                        </div>
                        <div className="col d-flex justify-content-end">
                            <button
                                className="btn standard-action action--delete"
                                type="button"
                                style={{
                                    color: "rgb(246, 81, 96)",
                                    backgroundColor: "rgba(246, 81, 96, 0.3)",
                                    padding: "0",
                                }}
                                onClick={() => fields.remove(index)}
                                disabled={isDisabled}
                            >
                                <i className="material-icons">delete</i>
                            </button>
                        </div>
                    </div>
                );
            })}
            <button
                type="button"
                className="btn mb-3 d-flex align-items-center justify-conten-center"
                onClick={() => fields.push({})}
                disabled={isDisabled}
            >
                <i
                    className="material-icons title--blue--1"
                    style={{ fontSize: "24px" }}
                >
                    add
                </i>
                <p
                    className="title--blue--1"
                    style={{ margin: "0", fontSize: "14px" }}
                >
                    Agregar
                </p>
            </button>
        </div>
    );
};
