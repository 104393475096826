import React from "react";

const PlantillaSummary = (props) => {
    const currentPlantilla = props.currentPlantilla || {};
    const {
        complejidad = { descripcion: "" },
        part_margen_error,
        meses_garantia,
        horas_mes_garantia,
        total_horas_garantia,
        part_descuento_autorizado,
        porcentajes = [],
    } = currentPlantilla;
    const { descripcion } = complejidad;

    const fields = [
        {
            title: "Complejidad",
            value: descripcion,
        },
        {
            title: "Porcentaje margen de error",
            value: part_margen_error,
            suffix: "%",
        },
        {
            title: "Meses de garantía",
            value: meses_garantia,
            suffix: " meses",
        },
        {
            title: "Horas de garantía al mes",
            value: horas_mes_garantia,
            suffix: " hrs",
        },
        {
            title: "Total horas garantía",
            value: total_horas_garantia,
            suffix: " hrs",
        },
        {
            title: "Descuento autorizado",
            value: part_descuento_autorizado,
            suffix: "%",
        },
    ];

    return (
        <div className="card-form  mt-4 mb-6 ">
            <h5 className="title--blue--1 mt-2 mb-5">Resumen de plantilla</h5>
            {props.currentPlantilla ? (
                
                <div className="row mt-0 mb-0">
                    <div className="form-group has-feedback col-md-5 pl-5 pr-0">
                        {fields.map((f) => (
                            <div className="mb-3" key={f.title}>
                                <span className="m-0">{f.title}:</span>
                                <hr className="mt-0 mb-0 pl-n1 pr-n1"/>
                            </div>
                           
                        ))}
                        {porcentajes.map((p) => (
                            <div className="mb-3" key={p.label}>
                                <span className="m-0">
                                    Porcentaje {p.label}:
                                </span>
                                <hr className="mt-0 mb-0 pl-n1 pr-n1"/>
                            </div>
                        ))}
                    </div>
                    <div className="form-group has-feedback col-md-6 pl-0 pr-0">
                        {fields.map((f) => (
                            <div className="mb-3" key={f.title}>
                                <p className="m-0 title--blue--1">{f.value}{f.suffix}</p>
                                <hr className="mt-0 mb-0 pl-n1 pr-n1"/>
                            </div>
                        ))}
                        {porcentajes.map((p) => (
                            <div className="mb-3" key={p.label}>
                                <p className="m-0 title--blue--1">{p.porcentaje}%</p>
                                <hr className=" mt-0 mb-0 pl-n1 pr-n1"/>
                            </div>
                        ))}
                    </div>
                </div>
              
            ) : (
                <p className="title--blue--1 text-center">
                    Seleccione una plantilla
                </p>
            )}
        </div>
    );


};

export { PlantillaSummary };


