import LoadMask from "Utils/LoadMask/LoadMask";
import CrearEditarForm from "./CrearEditarForm";
import React, { Component } from "react";

class CrearEditar extends Component {
    state = {
        id: null,
    };

    componentDidMount() {
        const id = this.props.match.params.id;
        this.setState({ id });
        this.props.obtenerTipoContratoVacaciones(id);
    }

    onSubmit = (data) => {
        const { id } = this.state;
        this.props.actualizarTipoContratoVacaciones(data, id);
    };

    render() {
        const { loader } = this.props;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h2 className="title--blue">
                            EDITAR TIEMPO DE VACACIONES
                        </h2>
                    </div>
                </div>
                <hr />
                <div className="login-wrapper">
                    <div className="card card-login w-100">
                        <LoadMask loading={loader} light>
                            <CrearEditarForm onSubmit={this.onSubmit} />
                        </LoadMask>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CrearEditar;
