import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm, change as changeForm } from "redux-form";
import Swal from "sweetalert2";
import { api } from "api";
import { NotificationManager } from "react-notifications";

const LOADER = "ACTIVIDAD_ENTREGA_LOADER";
const LOADER_EDITAR = "ACTIVIDAD_ENTREGA_LOADER_EDITAR";
const DATA = "ACTIVIDAD_ENTREGA_DATA";
const SUBMIT = "ACTIVIDAD_ENTREGA_SUBMIT";
const FASES = "OBTENER_FASES";
const COMPLEJIDADES = "OBTENER_COMPLEJIDADES";
const FILTER = "ACTIVIDAD_ENTREGA_FILTER"

const ENDPOINT = "actividad_entrega";

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setLoaderEditar = (loaderEditar) => ({
    type: LOADER_EDITAR,
    loaderEditar,
});

export const setFases = (fases) => ({
    type: FASES,
    fases
});

export const setComplejidades = (complejidades) => ({
    type: COMPLEJIDADES,
    complejidades
});

export const setFilter = (filter) => ({
    type: FILTER,
    filter
});

// ------------------------------------
// Actions
// ------------------------------------
export const onSubmit =
    (data = {}) =>
    (dispatch, getStore) => {
        dispatch(setLoaderEditar(true));
        api.post(`${ENDPOINT}`, data)
            .then(() => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha guardado correctamente",
                    type: "success",
                    showConfirmButton: false,
                    timer: 500,
                });
                dispatch(push("/actividades_entrega"));
            })
            .catch((error) => {
                const textError = error && error.nombre
                ? "\nEsta actividad ya existe"
                : "Ha ocurrido un error, inténtelo nuevamente."
                ;
                Swal.fire({
                    title: "Error",
                    text: textError,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoaderEditar(false));
            });
    };

export const listar =
    (page = 1, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}`, { page, ...params })
            .then((response) => {
                dispatch(setData(response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const obtenerActividadEntrega =
    (id = null) =>
    (dispatch) => {
        dispatch(setLoaderEditar(true));
        api.get(`${ENDPOINT}/${id}`)
            .then((response) => {
                dispatch(initializeForm("CrearEditarForm", response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoaderEditar(false));
            });
    };

export const actualizarActividadEntrega =
    (data = {}, id = null) =>
    (dispatch) => {
        dispatch(setLoaderEditar(true));
        api.put(`${ENDPOINT}/${id}`, data)
            .then(() => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha actualizado correctamente",
                    type: "success",
                    showConfirmButton: false,
                    timer: 500,
                });
                dispatch(push("/actividades_entrega"));
            })
            .catch((error) => {
                const textError = error && error.nombre
                ? "\nEste actividad ya existe"
                : "Ha ocurrido un error, inténtelo nuevamente."
                ;
                Swal.fire({
                    title: "Error",
                    text: textError,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoaderEditar(false));
            });
    };

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${ENDPOINT}/${id}`)
        .then(() => {
            Swal.fire({
                title: "Éxito",
                text: "Se ha eliminado correctamente",
                type: "success",
            });
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const obtenerFases = () => (dispatch) => {
    dispatch(setLoaderEditar(true));
    api.get("fase_proyecto/filter_list")
        .then((response) => {
            dispatch(setFases(response));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoaderEditar(false));
        });
};

export const obtenerComplejidades = () => (dispatch) => {
    dispatch(setLoaderEditar(true));
    api.get("complejidad_proyecto/filter_list")
        .then((response) => {
            dispatch(setComplejidades(response));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoaderEditar(false));
        });
};


export const obtenerFilterList = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${ENDPOINT}/filter_list`)
        .then((response) => {
            dispatch(setFilter(response));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const create =
    (data = {}, load = false, form) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.post(`${ENDPOINT}`, data)
            .then((response) => {
                NotificationManager.success("Actividad creada con éxito", "Éxito");
                if (load) {
                    obtenerFilterList()(dispatch);
                    dispatch(changeForm(form.name, form.field, response.id));
                }
            })
            .catch((error) => {
                const textError = error && error.nombre
                ? "\nEsta actividad ya existe"
                : "Ha ocurrido un error, inténtelo nuevamente."
                ;
                NotificationManager.error(textError, "Error");
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const actions = {
    onSubmit,
    eliminar,
    obtenerActividadEntrega,
    actualizarActividadEntrega,
    listar,
    obtenerFases,
    obtenerComplejidades,
    obtenerFilterList,
    create,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LOADER_EDITAR]: (state, { loaderEditar }) => {
        return {
            ...state,
            loaderEditar,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [FASES]: (state, { fases }) => {
        return {
            ...state,
            fases,
        }
    },
    [COMPLEJIDADES]: (state, { complejidades }) => {
        return {
            ...state,
            complejidades,
        }
    },
    [FILTER]: (state, { filter }) => {
        return {
            ...state,
            filter,
        }
    },
};

export const initialState = {
    loader: false,
    loaderEditar: false,
    data: {},
    fases: [],
    complejidades: [],
    filter: [],
};

export default handleActions(reducers, initialState);
