import React from "react";
import DayPicker from "../../Utils/DayPicker";

const handleDateChange = (dateKey, date, onChangeParams, currentParams) => {
    onChangeParams({
      ...currentParams,
      [dateKey]: date,
    });
  };
  
  const FiltersData = ({ onChangeParams, currentParams }) => {
    return (
      <React.Fragment>
        <div className="col-2">
          <label htmlFor="fecha_inicio" className="text-dark">
            Fecha inicio
          </label>
          <br />
          <div style={{ display: "flex" }}>
            <DayPicker
              onChange={(date) => handleDateChange('fecha_inicio', date, onChangeParams, currentParams)}
              value={currentParams.fecha_inicio || ""}
            />
            {currentParams.fecha_inicio && (
              <button
                className="btn"
                style={{ padding: 0 }}
                onClick={() => handleDateChange('fecha_inicio', undefined, onChangeParams, currentParams)}
              >
                <i className="material-icons">clear</i>
              </button>
            )}
          </div>
        </div>
  
        <div className="col-2">
          <label htmlFor="fecha_fin" className="text-dark">
            Fecha fin
          </label>
          <br />
          <div style={{ display: "flex" }}>
            <DayPicker
              onChange={(date) => handleDateChange('fecha_fin', date, onChangeParams, currentParams)}
              value={currentParams.fecha_fin || ""}
            />
            {currentParams.fecha_fin && (
              <button
                className="btn"
                style={{ padding: 0 }}
                onClick={() => handleDateChange('fecha_fin', undefined, onChangeParams, currentParams)}
              >
                <i className="material-icons">clear</i>
              </button>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };
  
  export default FiltersData;
  