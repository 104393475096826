import LoadMask from "Utils/LoadMask/LoadMask";
import CrearEditarForm from "./CrearEditarForm";
import React, { Component } from "react";
import { api } from "api";

class CrearEditar extends Component {
    state = {
        edit: false,
        loader: false,
        id: null,
        proyectos: [],
        tipos_proyecto: [],
        clientes: [],
        paises: [],
        puestos: [],
        niveles_ingles: [],
        tipos_contrato: [],
        tipos_plaza: [],
        prioridades: [],
        skills: [],
        seniority: [],
        nivelesAcademicos: [],
        monedas: [],
        modalidades: [],
    };

    async getSeniority(params = {}) {
        this.setState({ loader: true });
        try {
            const seniority = await api.get("seniority/filter_list", params);
            this.setState({ seniority });
        } catch (error) {
            this.setState({ seniority: [] });
        } finally {
            this.setState({ loader: false });
        }
    }

    getResoruces() {
        this.setState({ loader: true });
        const promises = [
            api.get("proyecto/filter_list"),
            api.get("tipo_proyecto/filter_list"),
            api.get("cliente/filter_list"),
            api.get("pais/filter_list"),
            api.get("puesto/select_list"),
            api.get("recurso/niveles_ingles"),
            api.get("skill/filter_list"),
            api.get("solicitud_personal/info"),
        ];
        Promise.all(promises)
            .then((values) => {
                const [
                    proyectos,
                    tipos_proyecto,
                    clientes,
                    paises,
                    puestos,
                    niveles_ingles,
                    skills,
                    info,
                ] = values;

                this.setState({
                    proyectos,
                    tipos_proyecto,
                    clientes,
                    paises,
                    puestos,
                    niveles_ingles,
                    skills,
                    tipos_contrato: info.tipos_contrato,
                    tipos_plaza: info.tipos_plaza,
                    prioridades: info.prioridades,
                    nivelesAcademicos: info.niveles_academicos,
                    monedas: info.monedas,
                    modalidades: info.modalidades,
                });
            })
            .catch((error) => console.error(error))
            .finally(() => this.setState({ loader: false }));
    }

    componentDidMount() {
        this.getResoruces();
        const edit = this.props.match.params.id ? true : false;
        this.setState({ edit });
        if (edit == true) {
            const id = this.props.match.params.id;
            this.setState({ id });
            this.props.obtenerSolicitudPersonal(id);
        }
    }

    onSubmit = (data, revision = false) => {
        const { edit, id } = this.state;
        const { onSubmit, actualizarSolicitudPersonal } = this.props;
        if (edit) {
            actualizarSolicitudPersonal(data, id);
        } else {
            onSubmit(data, revision);
        }
    };

    callSubmit = ({ data = {}, callback, isRRHH = false }) => {
        const { edit, id } = this.state;
        if (edit) {
            callback({ id, data, isRRHH });
        }
    };

    reclutamiento = () => {
        this.callSubmit({ callback: this.props.reclutamiento });
    };

    contratado = (data = {}) => {
        /* Recurso contratado */
        this.callSubmit({
            data,
            callback: this.props.contratado,
            isRRHH: this.props.isRRHH,
        });
    };

    rechazado = (data = {}) => {
        /* Motivo del rechazo y descripción */
        this.callSubmit({
            data,
            callback: this.props.rechazado,
            isRRHH: this.props.isRRHH,
        });
    };

    render() {
        const { loader, user, isRRHH = false } = this.props;
        const {
            proyectos,
            tipos_proyecto,
            clientes,
            paises,
            puestos,
            niveles_ingles,
            tipos_contrato,
            tipos_plaza,
            prioridades,
            skills,
            nivelesAcademicos,
            monedas,
            modalidades,
            edit,
        } = this.state;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-between flex-wrap">
                        <h2 className="title--blue">SOLICITUD DE PERSONAL</h2>
                        {edit ? (
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="mx-5">
                                    <span className="text--blue--1">
                                        Solicitante:
                                    </span>{" "}
                                    {user.nombre}
                                </span>
                                <span className="mx-5">
                                    <span className="text--blue--1">
                                        Fecha de la solicitud:{" "}
                                    </span>
                                    {user.fecha}
                                </span>
                            </div>
                        ) : null}
                    </div>
                </div>
                <hr />
                <div className="login-wrapper">
                    <div className="w-100">
                        <LoadMask loading={loader || this.state.loader} light>
                            <CrearEditarForm
                                onSubmit={this.onSubmit}
                                reclutamiento={this.reclutamiento}
                                contratado={this.contratado}
                                rechazado={this.rechazado}
                                edit={this.state.edit}
                                proyectos={proyectos}
                                tipos_proyecto={tipos_proyecto}
                                clientes={clientes}
                                paises={paises}
                                puestos={puestos}
                                niveles_ingles={niveles_ingles}
                                tipos_contrato={tipos_contrato}
                                tipos_plaza={tipos_plaza}
                                prioridades={prioridades}
                                skills={skills}
                                obtenerSeniority={this.getSeniority.bind(this)}
                                seniority={this.state.seniority}
                                nivelesAcademicos={nivelesAcademicos}
                                monedas={monedas}
                                modalidades={modalidades}
                                isRRHH={isRRHH}
                            />
                        </LoadMask>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CrearEditar;
