import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import Swal from "sweetalert2";
import { api } from "api";

const LOADER = "SKILL_LOADER";
const DATA = "SKILL_DATA";
const SUBMIT = "SKILL_SUBMIT";

const ENDPOINT = "skill";

export const FILTER_LIST = `${ENDPOINT}/filter_list`;

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

// ------------------------------------
// Actions
// ------------------------------------
export const onSubmit =
    (data = {}) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        api.post(`${ENDPOINT}`, data)
            .then(() => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha guardado correctamente",
                    type: "success",
                });
                dispatch(push("/skills"));
            })
            .catch(() => {
                Swal.fire({
                    title: "Error",
                    text: "Ha ocurrido un error, inténtelo nuevamente.",
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const listar =
    (page = 1, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}`, { page, ...params })
            .then((response) => {
                dispatch(setData(response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const obtenerSkill =
    (id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}/${id}`)
            .then((response) => {
                dispatch(initializeForm("CrearEditarForm", response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const actualizarSkill =
    (data = {}, id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.put(`${ENDPOINT}/${id}`, data)
            .then(() => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha actualizado correctamente",
                    type: "success",
                });
                dispatch(push("/skills"));
            })
            .catch(() => {
                Swal.fire({
                    title: "Error",
                    text: "Ha ocurrido un error, inténtelo nuevamente.",
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${ENDPOINT}/${id}`)
        .then(() => {
            Swal.fire({
                title: "Éxito",
                text: "Se ha eliminado correctamente",
                type: "success",
            });
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const actions = {
    onSubmit,
    eliminar,
    obtenerSkill,
    actualizarSkill,
    listar,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {},
};

export default handleActions(reducers, initialState);
