import React from "react";

import { renderNumber, renderField } from "../../../Utils/renderField";
import { Field } from "redux-form";

export const renderModulos = ({
    fields,
    meta: { error, form },
    show = true,
    currentPlantilla,
    crearActividad,
    isDisabled = false,
    descripcionValue, // Nuevo prop para el valor de la descripción
}) => {


    const calculateTiempoIdeal = (tiempoEstimadoValue) => {
        if (!currentPlantilla) currentPlantilla = {};
        const { part_probable = 0, part_pesimista = 0 } = currentPlantilla;
        const tiempoEstimado = Number(tiempoEstimadoValue);
        const probable = Number(part_probable) / 100;
        const pesimista = Number(part_pesimista) / 100;
    
        const tiempoMasProbable = (tiempoEstimado + tiempoEstimado * probable);
        const tiempoPesimista = (tiempoEstimado + tiempoEstimado * pesimista);
    
        const estimado = (tiempoEstimado + 4 * tiempoMasProbable + tiempoPesimista) / 6;
        const varianza = Math.pow((tiempoPesimista - tiempoEstimado) / 6, 2);
    
        const tiempoIdeal = Math.round(estimado + 2 * varianza);
        return tiempoIdeal;
    };

    const select_style = {
        control: (styles, state) => ({
            ...styles,
            color: "#1F4D7B",
            borderWidth: state.isFocused || !state.hasValue ? "1px" : "0px",
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            backgroundColor: "white",
        }),
    };

    return (
        <div
            className="col-10 mt-4"
            style={{
                display: show ? "block" : "none",
            }}
        >
            <div className="row mb-1">
                <span className="col-7 title--blue--1">Nombre modulo</span>
                <span className="col-2 title--blue--1 p-0">
                    Horas estimadas
                </span>
                <span className="col-2 title--blue--1 p-0">Horas ideales</span>
            </div>
            {fields.length <= 0 && (
                <div className="col-12">
                    <span className="text-center">---</span>
                </div>
            )}

            <span className="text-danger">{error}</span>

            {fields.map((modulo, index) => {
                const currentField = fields.get(index);
                return (
                    <div className="row field--array bg-white" key={index}>
                        <div className="form-group has-feedback col-7 m-0 pl-0">
                            {/* Cambio a campo libre */}
                            <Field
                                name={`${modulo}.actividad`}
                                component={renderField}
                                placeholder="Nombre del módulo"
                                disabled={isDisabled}
                            />
                        </div>
                        <div className="form-group has-feedback col-2 m-0 p-0">
                            <Field
                                name={`${modulo}.horas_estimadas`}
                                component={renderNumber}
                                suffix=" hrs"
                                label={`Horas estimadas`}
                                placeholder="hrs"
                                onChange={(values) => {
                                    const value = Object.entries(values)
                                        .filter(
                                            (v) => v[0] !== "preventDefault"
                                        )
                                        .map((v) => v[1])
                                        .join("");

                                    const tiempoIdeal =
                                        calculateTiempoIdeal(value);
                                    currentField.horas_ideales = tiempoIdeal;
                                }}
                                readOnly={isDisabled}
                            />
                        </div>
                        <div className="form-group has-feedback col-2 m-0 p-0">
                            <Field
                                name={`${modulo}.horas_ideales`}
                                component={renderNumber}
                                suffix=" hrs"
                                label={`Horas ideales`}
                                placeholder="hrs"
                                readOnly={true}
                            />
                        </div>
                        <div className="col d-flex justify-content-end">
                            <button
                                className="btn standard-action action--delete"
                                type="button"
                                style={{
                                    color: "rgb(246, 81, 96)",
                                    backgroundColor: "rgba(246, 81, 96, 0.3)",
                                    padding: "0",
                                }}
                                onClick={() => fields.remove(index)}
                                disabled={isDisabled}
                            >
                                <i className="material-icons">delete</i>
                            </button>
                        </div>
                    </div>
                );
            })}

            <button
                type="button"
                className="btn mb-3 d-flex align-items-center justify-conten-center"
                onClick={() => fields.push({ fase: 2 })}
                disabled={isDisabled}
            >
                <i
                    className="material-icons title--blue--1"
                    style={{ fontSize: "24px" }}
                >
                    add
                </i>
                <p
                    className="title--blue--1"
                    style={{ margin: "0", fontSize: "14px" }}
                >
                    Agregar
                </p>
            </button>
        </div>
    );
};
