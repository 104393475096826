import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { BootstrapTable } from "react-bootstrap-table";
import moment from "moment";
import usersIcon from "../../../../../assets/img/icon _users.svg";
import noPhothoImage from "../../../../../assets/img/avatar-placeholder.png";

export const ColaboradoresCard = ({
    colaboradores,
    onRowClick,
    title,
    field,
    fieldTitle,
    dataFormat = (cell) => cell,
}) => {
    return (
        <div className="card mb-3 mt-3 card--reportes mx-auto">
            <div className="header-info">
                <h6 className="font-weight-bold m-0">{title}</h6>
            </div>
            <div className="card-body px-4">
                <BootstrapTable
                    tableContainerClass="content-table-permisos"
                    tableBodyClass="table--body"
                    tableHeaderClass="table--header"
                    data={colaboradores}
                    options={{
                        noDataText: "No hay datos",
                        onRowClick: onRowClick,
                    }}
                    tableStyle={{
                        border: "hidden",
                    }}
                >
                    <TableHeaderColumn
                        dataField="foto_perfil"
                        dataFormat={(cell) => {
                            const photo = cell ? cell : noPhothoImage;
                            return (
                                <picture className="d-flex align-items-center justify-content-start pl-4">
                                    <img
                                        src={photo}
                                        width="50px"
                                        height="50px"
                                        style={{
                                            borderRadius: "50%",
                                            border: "none",
                                        }}
                                        alt="foto de perfil"
                                    />
                                </picture>
                            );
                        }}
                        width="15%"
                        thStyle={{ textAlign: "left" }}
                    >
                        <picture className="d-flex align-items-center justify-content-start pl-4">
                            <img src={usersIcon} />
                        </picture>
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        isKey
                        dataFormat={(cell, row) => {
                            return (
                                <React.Fragment>
                                    <p className="m-0">{row.nombre}</p>
                                    <p
                                        className="m-0"
                                        style={{ fontSize: ".9rem" }}
                                    >
                                        {row.puesto}
                                    </p>
                                </React.Fragment>
                            );
                        }}
                        width="35%"
                        thStyle={{ textAlign: "left", padding: "10px" }}
                    >
                        Nombre
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField={field}
                        dataSort
                        width="25%"
                        thStyle={{ textAlign: "center" }}
                        tdStyle={{ textAlign: "center" }}
                        dataFormat={dataFormat}
                    >
                        {fieldTitle}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="ultimo_permiso"
                        dataSort
                        dataFormat={(cell) => {
                            let value = "---";
                            if (cell) {
                                const date = moment(cell);
                                value = date.format("DD/MM/YYYY");
                            }
                            return <p className="m-0 text-center">{value}</p>;
                        }}
                        width="25%"
                        thStyle={{ textAlign: "center" }}
                    >
                        Fecha último solicitado
                    </TableHeaderColumn>
                </BootstrapTable>
                <div className="d-flex align-items-center justify-content-end mt-3">
                    <Link
                        to="/permisos/crear"
                        className="btn btn-primary px-4"
                        style={{
                            fontSize: "1.1rem",
                            borderRadius: "10px",
                        }}
                    >
                        + Nuevo permiso
                    </Link>
                </div>
            </div>
        </div>
    );
};
