import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { renderField, renderNumber } from "../../Utils/renderField";

const CrearEditarForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form
            name="CrearEditarForm"
            className="form-validate mb-lg"
            onSubmit={handleSubmit}
        >
            <div className="row">
                <div className="form-group has-feedback col-6">
                    <label htmlFor="nombre">Nombre</label>
                    <Field
                        name="nombre"
                        label="Nombre"
                        component={renderField}
                        type="text"
                        className="input--style"
                        readonly={true}
                    />
                </div>
                <div className="form-group has-feedback col-6">
                    <label htmlFor="dias_vacaciones">Días de vacaciones</label>
                    <Field
                        name="dias_vacaciones"
                        component={renderNumber}
                        className="input--style"
                        suffix={" días"}
                    />
                </div>
            </div>
            <div className="buttons-box">
                <Link
                    to="/tipo_contrato_vacaciones"
                    className="btn btn-secondary"
                    style={{ fontSize: 15 }}
                >
                    Regresar
                </Link>
                <button
                    type="submit"
                    style={{ fontSize: 15 }}
                    className="btn btn-primary m-1 align-self-center"
                >
                    Guardar
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "CrearEditarForm", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            dias_vacaciones: validators.number({ min: 0 })(
                "Valor mayor o igual a 0 requerido"
            ),
        });
    },
})(CrearEditarForm);
