import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import Swal from 'sweetalert2';
import { api } from "api";

const SUBMIT = 'CLIENTE_SUBMIT';
const LOADER = 'CLIENTE_LOADER';
const DATA = "CLIENTE_DATA";
const ALL_CLIENTS = "ALL_CLIENTS";

const ENDPOINT = "cliente";

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setData = data => ({
    type: DATA,
    data
});

export const setAllClients = all_clients => ({
    type: ALL_CLIENTS,
    all_clients
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post(`${ENDPOINT}`, data).then((response) => {
        Swal.fire({
            title: 'Éxito',
            text: 'Se ha guardado correctamente',
            type: 'success',
        })
        dispatch(push("/clientes"));
    }).catch(() => {
        Swal.fire({
            title: 'Error',
            text: 'Ha ocurrido un error, inténtelo nuevamente.',
            type: 'error',
        })
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listAll = () => (dispatch) => {
    api.get("cliente/filter_list").then((res) => {
        dispatch(setAllClients(res))
    });
}

export const listar = (page=1, params={}) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${ENDPOINT}`, { page, ...params }).then(response => {
        dispatch(setData(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const obtenerCliente = (id=null) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${ENDPOINT}/${id}`).then(response => {
        dispatch(initializeForm('CrearEditarForm', response));
    })
        .catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actualizarCliente = (data={}, id=null) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${ENDPOINT}/${id}`, data).then(response => {
        Swal.fire({
            title: 'Éxito',
            text: 'Se ha actualizado correctamente',
            type: 'success',
        })
        dispatch(push("/clientes"));
    }).catch(() => {
        Swal.fire({
            title: 'Error',
            text: 'Ha ocurrido un error, inténtelo nuevamente.',
            type: 'error',
        })
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${ENDPOINT}/${id}`).then(() => {
        Swal.fire({
            title: 'Éxito',
            text: 'Se ha eliminado correctamente',
            type: 'success',
        })
    })
        .catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const getMe = () => (dispatch) => {
    api.get('/user/me').then(me => {
        dispatch(initializeForm('profile', me));
        dispatch(setMe(me));
    })
        .catch(() => {
    }).finally(() => {});
};


export const actions = {
    listar,
    eliminar,
    onSubmit,
    obtenerCliente,
    actualizarCliente,
    listAll
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ALL_CLIENTS]: (state, { all_clients }) => {
        return {
            ...state,
            all_clients,
        };
    },
};

export const initialState = {
    loader: false,
    data: false,
    all_clients: []
};

export default handleActions(reducers, initialState);
