import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import { Link } from "react-router-dom";
import { TableHeaderColumn } from "react-bootstrap-table";
import FiltersData from "./FiltersData";
import { api } from "api";
import moment from "moment";

class Listar extends Component {
    state = {
        page: 1,
        params: {
            activo: true,
        },
        colaboradores: [],
        tipos_permiso: [],
        asuetos: [],
    };

    async componentDidMount() {
        if (this.props.currentColaboradorFilter) {
            const currentYear = moment().startOf('year').format("YYYY-MM-DD");
            const params = {
                activo: true,
                colaborador: this.props.currentColaboradorFilter,
                fecha_inicio: currentYear,
            };
            this.setState({ params });
            this.props.listar(1, params);
            this.props.clearFilter();
        } else {
            this.props.listar(this.state.page, this.state.params);
        }
        try {
            const colaboradores = await api.get("recurso/filter_list");
            const tipos_permiso = await api.get("tipo_permiso/filter_list");
            const asuetos = await api.get("asueto/get_events", {
                pais_nombre: "Guatemala",
            });
            this.setState({ colaboradores, tipos_permiso, asuetos });
        } catch (error) {}
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.params !== this.state.params) {
            const page = 1
            this.setState({ page });
            this.props.listar(page, this.state.params);
        }
    }

    onPageChange = (page) => {
        this.props.listar(page, this.state.params);
        this.setState({ page });
    };

    onChangeParams = (newParam) => {
        let params = this.state.params;
        if (!newParam.value) {
            delete params[newParam.name];
            params = {
                ...params,
            };
        } else {
            params = {
                ...params,
                [newParam.name]: newParam.value,
            };
        }
        this.setState({ params });
    };

    isHoliday = (dateValue, holidays) => {
        const date = moment(dateValue);
        return holidays.some((holiday) => {
            const start = moment(holiday.start);
            const end = moment(holiday.end);
            return date >= start && date <= end;
        });
    };

    getWorkingDays = (startValue, endValue) => {
        const start = moment(startValue);
        const end = moment(endValue);
        let days = 0;
        while (start <= end) {
            if (
                start.day() !== 0 &&
                start.day() !== 6 &&
                !this.isHoliday(start, this.state.asuetos)
            ) {
                days++;
            }
            start.add(1, "days");
        }
        return days;
    };

    render() {
        const { data, loader, eliminar } = this.props;
        return (
            <React.Fragment>
                <div className="flex row mt-2">
                    <div className="col-12">
                        <h2 className="title--blue">LISTA DE PERMISOS</h2>
                    </div>
                </div>
                <hr />

                <div className="row">
                    <FiltersData
                        onChangeParams={this.onChangeParams.bind(this)}
                        colaboradores={this.state.colaboradores}
                        tipos_permiso={this.state.tipos_permiso}
                        currentParams={this.state.params}
                    />
                    <div
                        className="col-2"
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Link
                            to="/permisos/crear"
                            className="btn btn-primary mt-4"
                            style={{ fontSize: 15, borderRadius: "5px" }}
                        >
                            Nuevo permiso
                        </Link>
                    </div>
                </div>
                <Grid
                    data={data}
                    loading={loader}
                    page={this.state.page}
                    onPageChange={this.onPageChange}
                >
                    <TableHeaderColumn dataField="tipo_permiso" dataSort>
                        Tipo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="colaborador"
                        dataSort
                        width="40%"
                    >
                        Colaborador
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="horas"
                        dataSort
                        dataFormat={(cell, row) => {
                            if (row.hora_dia && row.hora_dia === "horas") {
                                return `${cell} horas`;
                            } else if (
                                row.hora_dia &&
                                row.hora_dia === "dias"
                            ) {
                                return `${this.getWorkingDays(
                                    row.fecha_inicio,
                                    row.fecha_fin
                                )} día(s)`;
                            } else {
                                return "---";
                            }
                        }}
                    >
                        Tiempo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha_inicio"
                        dataSort
                        dataFormat={(cell, row) => {
                            const m = moment(cell);
                            return m.format("l");
                        }}
                    >
                        Fecha inicio
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha_fin"
                        dataSort
                        dataFormat={(cell, row) => {
                            const m = moment(cell);
                            return m.format("l");
                        }}
                    >
                        Fecha fin
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        dataSort
                        isKey
                        dataFormat={(cell, row) => {
                            let props = {};
                            props.editar = "permisos";
                            props.eliminar = eliminar;
                            const actions = standardActions({ ...props });
                            return actions(cell, row);
                        }}
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
            </React.Fragment>
        );
    }
}

export default Listar;
