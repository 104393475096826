import moment from "moment";
import React, { Component } from "react";
import "gantt-task-react/dist/index.css";
import LoadMask from "Utils/LoadMask/LoadMask";
import Select from "react-select";
import Switch from "react-switch";
import { api } from "api";
import { Gantt } from "gantt-task-react";
import { DateRange } from "react-date-range";
import locale from "date-fns/locale/es";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

class Visualizar extends Component {
    state = {
        hide: {},
        vistas: [
            { value: "Day", label: "Día" },
            { value: "Week", label: "Semana" },
            { value: "Month", label: "Mes" },
        ],
        vista_por: {
            value: "Week",
            label: "Semanas",
        },
        recursos: [],
        tipos_proyecto: [],
        sticky_filters: false,
        filters: {
            proyectos__recursos__recurso__id: null, // PARA FILTRAR POR RECURSO
            proyectos__encargado__id: null, // PARA FILTRAR POR ENCARGADO
            id: null, // PARA FILTRAR POR TIPO DE PROYECTO
        },
        loader: true,
        popup_dates_open: false,
        date_range: {},
    };

    componentDidMount() {
        api.get("recurso/all", { activo: true, despedido: false, renuncia: false }).then((response) => {
            response.forEach((result) => {
                result.value = result.id;
                result.label = result.nombre;
            });
            this.setState({ recursos: response });
            api.get("tipo_proyecto", { activo: true }).then((response) => {
                response.results.forEach((result) => {
                    result.value = result.id;
                    result.label = result.nombre;
                });
                this.setState({
                    tipos_proyecto: response.results,
                    loader: false,
                });
                /* 
                    SE CALCULAN LAS FECHAS INICIALES
                    RESPECTO A LA ACTUAL UN MES ANTES PARA 4 ADELANTE
                */
                const inicio = moment().subtract(2, "weeks");
                const fin = moment().add(4, "months");
                const fechas_range = [
                    inicio.format("YYYY-MM-DD"),
                    fin.format("YYYY-MM-DD"),
                ];
                this.setState({
                    date_range: {
                        startDate: new Date(inicio),
                        endDate: new Date(fin),
                        key: "selection",
                    },
                    fechas_range,
                    filters: {
                        ...this.state.filters,
                        fechas_range,
                    },
                });
                //this.props.getData()
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filters != this.state.filters) {
            const params = {};
            Object.keys(this.state.filters).forEach((key) => {
                if (this.state.filters[key] !== null) {
                    params[key] = this.state.filters[key].value
                        ? this.state.filters[key].value
                        : this.state.filters[key];
                }
            });
            this.props.getData(params);
        }
    }

    handleSelect = (range) => {
        const inicio = range.selection.startDate,
            fin = range.selection.endDate;
        this.setState({
            date_range: {
                startDate: inicio,
                endDate: fin,
                key: range.selection.key,
            },
            filters: {
                ...this.state.filters,
                fechas_range: [
                    moment(inicio).format("YYYY-MM-DD"),
                    moment(fin).format("YYYY-MM-DD"),
                ],
            },
        });
    };

    closeDatePopup = () => {
        this.setState({ popup_dates_open: false });
    };

    formatDate(date) {
        const date_split = date.split("-");
        const day = parseInt(date_split[2]);
        const month = parseInt(date_split[1]) - 1;
        const year = parseInt(date_split[0]);
        return new Date(year, month, day);
    }

    getTasksData = (proyectos) => {
        const response = [];
        const hide = {};
        var colorvar = "";

        if (this.state.filters.fechas_range) {
            if (this.state.filters.fechas_range.length > 0) {
                const fecha_inicio_range = this.formatDate(this.state.filters.fechas_range[0]);
                const fecha_fin_range = this.formatDate(this.state.filters.fechas_range[1]);
                proyectos.forEach((proyecto) => {
                    if (this.state.hide[`${proyecto.id}`] == undefined)
                        hide[`${proyecto.id}`] = true;
                    /*proyecto.fecha_fin_retraso === null ? colorvar= '#FFbb54' : colorvar= '#ff0000'*/                    
                    // const start_date = moment(proyecto.fecha_inicio_ejecucion).isAfter(
                    //     this.state.filters.fechas_range[0]
                    // )
                    //     ? moment(proyecto.fecha_inicio_ejecucion)
                    //     : moment(this.state.filters.fechas_range[0]);
                    // const end_date = moment(proyecto.fecha_fin_ejecucion).isBefore(
                    //     this.state.filters.fechas_range[1]
                    // )
                    //     ? moment(proyecto.fecha_fin_ejecucion)
                    //     : moment(this.state.filters.fechas_range[1]);
                    const fecha_inicio_ejecucion = this.formatDate(proyecto.fecha_inicio_ejecucion);
                    const fecha_fin_ejecucion = this.formatDate(proyecto.fecha_fin_ejecucion);
                    const start_date = fecha_inicio_ejecucion.getTime() >= fecha_inicio_range.getTime() && fecha_inicio_ejecucion.getTime() <= fecha_fin_range.getTime() ? fecha_inicio_ejecucion : fecha_inicio_range;
                    const end_date = fecha_fin_ejecucion.getTime() >= fecha_inicio_range.getTime() && fecha_fin_ejecucion.getTime() <= fecha_fin_range.getTime() ? fecha_fin_ejecucion : fecha_fin_range;

                    response.push({
                        start: start_date,
                        end: end_date,
                        name: `${proyecto.nombre} / ${proyecto.encargado}`,
                        id: proyecto.id,
                        type: "project",
                        progress: proyecto.progreso,
                        isDisabled: true,
                        hideChildren: this.state.hide[`${proyecto.id}`],
                        styles: {
                            progressColor: "#FFbb54",
                            progressSelectedColor: "#ff9e0d",
                        },
                    });
                    proyecto.tareas.forEach((tarea) => {
                        tarea.fecha_fin_retraso === null
                            ? (colorvar = "#3d3d3d")
                            : (colorvar = "#ff0000");
                        // const start_date1 = moment(tarea.fecha_inicio).isAfter(
                        //     this.state.filters.fechas_range[0]
                        // )
                        //     ? moment(tarea.fecha_inicio)
                        //     : moment(this.state.filters.fechas_range[0]);
                        // const end_date1 = moment(tarea.fecha_fin).isBefore(
                        //     this.state.filters.fechas_range[1]
                        // )
                        //     ? moment(tarea.fecha_fin)
                        //     : moment(this.state.filters.fechas_range[1]);
                        
                        const fecha_inicio = this.formatDate(tarea.fecha_inicio);
                        const fecha_fin = this.formatDate(tarea.fecha_fin);
                        const start_date1 = fecha_inicio.getTime() >= fecha_inicio_range.getTime() && fecha_inicio.getTime() <= fecha_fin_range.getTime() ? fecha_inicio : fecha_inicio_range;
                        const end_date1 = fecha_fin.getTime() >= fecha_inicio_range.getTime() && fecha_fin.getTime() <= fecha_fin_range.getTime() ? fecha_fin : fecha_fin_range;
            
                        response.push({
                            end: end_date1,
                            start: start_date1,
                            name: `${tarea.recurso.nombre}`,
                            id: `T-${tarea.id}`,
                            type: "task",
                            project: proyecto.id,
                            progress: tarea.progreso,
                            isDisabled: false,
                            styles: {
                                progressColor: colorvar,
                                /* progressColor: '#3d3d3d', */
                                progressSelectedColor: "black",
                            },
                        });
                    });
                });
                if (Object.keys(hide).length > 0)
                    this.setState({ hide: { ...this.state.hide, ...hide } });
            }
        }
        //console.log(response);
        return response;
    };

    render() {
        const { gantt, loader } = this.props;
        const sticky_props = {};
        if (this.state.sticky_filters) {
            sticky_props.position = "sticky";
            sticky_props.top = 110;
            sticky_props.zIndex = 1000;
            sticky_props.borderWidth = 3;
            sticky_props.borderColor = "black";
            sticky_props.borderStyle = "solid";
        }
        const select_style = {
            control: (styles) => ({
                ...styles,
                color: "#1F4D7B",
                borderColor: "#1F4D7B",
            }),
        };
        return (
            <React.Fragment>
                <div className="flex row mt-2">
                    <div className="col-12">
                        <h2 className="title--blue">GANTT</h2>
                    </div>
                </div>
                <hr />
                {/* SECCION DE FILTROS */}
                <div
                    className="flex row mt-2 p-4"
                    style={{
                        ...sticky_props,
                        backgroundColor: "white",
                        borderRadius: 10,
                        margin: "0px",
                        boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)"
                    }}
                >
                    <div className="col-1 ml-2">
                        <label htmlFor="puesto">&nbsp;</label>
                        <div className="d-flex align-items-center">
                            <Switch
                                onColor="#007bff"
                                height={18}
                                width={36}
                                onChange={(value) => {
                                    this.setState({ sticky_filters: value });
                                }}
                                checked={this.state.sticky_filters}
                            />
                        </div>
                    </div>
                    <div className="col-2 ml-2">
                        <label htmlFor="puesto">Vista por</label>
                        <Select
                            styles={select_style}
                            isClearable={false}
                            isSearchable={true}
                            options={this.state.vistas}
                            onChange={(e) => {
                                this.setState({ vista_por: e });
                            }}
                            value={this.state.vista_por}
                        />
                    </div>
                    <div className="col-2 ml-2">
                        <label htmlFor="puesto">Recurso</label>
                        <Select
                            styles={select_style}
                            isClearable={true}
                            isSearchable={true}
                            options={this.state.recursos}
                            onChange={(e) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        proyectos__recursos__recurso__id: e,
                                    },
                                });
                            }}
                            value={
                                this.state.filters
                                    .proyectos__recursos__recurso__id
                            }
                        />
                    </div>
                    <div className="col-2 ml-2">
                        <label htmlFor="puesto">Encargado</label>
                        <Select
                            styles={select_style}
                            isClearable={true}
                            isSearchable={true}
                            options={this.state.recursos.filter((recurso) =>
                                recurso.puesto.nombre
                                    .toUpperCase()
                                    .includes("SCRUM")
                            )}
                            onChange={(e) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        proyectos__encargado__id: e,
                                    },
                                });
                            }}
                            value={this.state.filters.proyectos__encargado__id}
                        />
                    </div>
                    <div className="col-2 ml-2">
                        <label htmlFor="puesto">Tipo de proyecto</label>
                        <Select
                            styles={select_style}
                            isClearable={true}
                            isSearchable={true}
                            options={this.state.tipos_proyecto}
                            onChange={(e) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        id: e,
                                    },
                                });
                            }}
                            value={this.state.filters.id}
                        />
                    </div>
                    <div className="col-2 ml-2">
                        <label htmlFor="puesto">Fechas</label>
                        <br />
                        <button
                            style={{borderColor: '#1F4D7B'}}
                            className="btn btn-light"
                            onClick={() => {
                                this.setState({ popup_dates_open: true });
                            }}
                        >
                            {moment(this.state.date_range.startDate).format(
                                "DD/MM/YYYY"
                            )}
                            &nbsp;-&nbsp;
                            {moment(this.state.date_range.endDate).format(
                                "DD/MM/YYYY"
                            )}
                        </button>
                        <Popup
                            open={this.state.popup_dates_open}
                            onClose={this.closeDatePopup}
                        >
                            <div className="row">
                                <div className="col" />
                                <div className="col">
                                    <DateRange
                                        editableDateInputs={true}
                                        ranges={[this.state.date_range]}
                                        onChange={this.handleSelect}
                                        locale={locale}
                                    />
                                </div>
                                <div className="col" />
                            </div>
                        </Popup>
                    </div>
                </div>
                {/* INICIO DEL GANTT */}

                <LoadMask loading={loader || this.state.loader || !gantt} light>
                    {gantt.length > 0 && loader == false ? gantt.map((tipo_proyecto) => {
                        const tasks = this.getTasksData(tipo_proyecto.proyectos);
                        return (
                            <React.Fragment key={`${tipo_proyecto.id} - ${tipo_proyecto.nombre}`} >
                                {tasks.length > 0 ? (
                                    <React.Fragment>
                                        <div className="card mb-3 mt-3" style={{boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)"}}>
                                            <div key={tipo_proyecto.nombre} className="card-header card--header">
                                                {tipo_proyecto.nombre}
                                            </div>
                                            <div className="card-body">
                                                <Gantt
                                                    columnWidth={150}
                                                    key={tipo_proyecto.id}
                                                    tasks={tasks}
                                                    locale="es"
                                                    viewMode={
                                                        this.state.vista_por
                                                            .value
                                                    }
                                                    onExpanderClick={(task) => {
                                                        const hide =
                                                            this.state.hide;
                                                        hide[`${task.id}`] =
                                                            !hide[`${task.id}`];
                                                        this.setState({ hide });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ) : null}
                            </React.Fragment>
                        );
                    }) : null}
                </LoadMask>
            </React.Fragment>
        );
    }
}

export default Visualizar;
