import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/solicitud_personal/solicitud_personal";
import Listar from "./Listar";

const ms2p = (state) => {
    return {
        ...state.solicitud_personal,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Listar);
