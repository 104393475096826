import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/clientes/clientes';
import CrearEditar from './CrearEditar';

const ms2p = (state) => {
  return {
    ...state.clientes,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearEditar);
