import React from "react";
import Select from "react-select";
import DayPicker from "../../Utils/DayPicker";

const FiltersData = ({ paises, years, onChangeParams, currentParams }) => {
    const select_style = {
        control: (styles) => ({
            ...styles,
            color: "#1F4D7B",
            borderColor: "#1F4D7B",
        }),
    };

    return (
        <React.Fragment>
            <div className="col-12 col-md-2">
                <label htmlFor="tipo" className="text-dark">
                    Filtrar por años
                </label>
                <br />
                <Select
                    select_style={select_style}
                    name="year"
                    placeholder="Año"
                    isSearchable
                    isClearable
                    options={years}
                    onChange={(e) => {
                        if (e) {
                            onChangeParams({ name: "year", value: e.value });
                        } else {
                            onChangeParams({ name: "year", value: null });
                        }
                    }}
                />
            </div>
            <div className="col-12 col-md-3">
                <label htmlFor="tipo" className="text-dark">
                    País
                </label>
                <br />
                <Select
                    select_style={select_style}
                    name="pais"
                    placeholder="País"
                    isSearchable
                    isClearable
                    options={paises}
                    onChange={(e) => {
                        if (e) {
                            onChangeParams({ name: "pais", value: e.value });
                        } else {
                            onChangeParams({ name: "pais", value: null });
                        }
                    }}
                />
            </div>

            <div className="col-12 col-md-2">
                <label htmlFor="fecha_inicio" className="text-dark">
                    Fecha inicio
                </label>
                <br />
                <div style={{ display: "flex" }}>
                    <DayPicker
                        onChange={(date) => {
                            onChangeParams({
                                name: "fecha_inicio",
                                value: date,
                            });
                        }}
                        value={currentParams.fecha_inicio || ""}
                    />
                    <button
                        className="btn"
                        style={{ padding: 0 }}
                        onClick={() =>
                            onChangeParams({
                                name: "fecha_inicio",
                                value: "",
                            })
                        }
                    >
                        <i className="material-icons">clear</i>
                    </button>
                </div>
            </div>

            <div className="col-12 col-md-2">
                <label htmlFor="fecha_inicio" className="text-dark">
                    Fecha fin
                </label>
                <br />
                <div style={{ display: "flex" }}>
                    <DayPicker
                        onChange={(date) => {
                            onChangeParams({ name: "fecha_fin", value: date });
                        }}
                        value={currentParams.fecha_fin || ""}
                    />
                    <button
                        className="btn"
                        style={{ padding: 0 }}
                        onClick={() =>
                            onChangeParams({
                                name: "fecha_fin",
                                value: "",
                            })
                        }
                    >
                        <i className="material-icons">clear</i>
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default FiltersData;
