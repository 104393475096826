import React from "react";

import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";

import {
    renderField,
    renderSelectField,
    renderCurrency,
    renderFieldRadio,
    renderNumber,
} from "../../Utils/renderField";

import { RenderFields } from "./RenderFields";

import { api } from "api";

import validateFields from "./validate";

function UbicacionSaludForm({
    select_style,
    prevStep,
    handleSubmit,
    esExtranjero,
    esGuatemalteco,
    nacionalidadValue,
    monedaPrefix,
    departamentos_guatemala,
    departamentoValue,
    puestos,
    tipos_proyecto,
    tipos_contratacion,
    departamentos_organizacion,
    tipos_contrato,
    tipoContratacionValue,
    tipoContratoValue,
    change,
    obtenerSeniority,
    puestoValue,
    seniority,
    salarioValue,
    seniorityValue,
    departamentoOrganizacionValue,
    obtenerPuestos,
    edit,
})
{
    const contratoOptions = React.useMemo(() => {
        if (tipoContratacionValue === 1) {
            return tipos_contrato.filter((tc) => tc.value !== "PROYECTO");
        }
        return tipos_contrato.filter((tc) => tc.value === "PROYECTO");
    }, [tipoContratacionValue]);

    React.useEffect(() => {
        if (tipoContratacionValue === 2) {
            change("tipo_contrato", "PROYECTO");
        } else if (
            tipoContratacionValue === 1 &&
            tipoContratoValue === "PROYECTO"
        ) {
            change("tipo_contrato", "");
        }
    }, [tipoContratacionValue]);

    /* Departamento, puesto, seniority */

    React.useEffect(() => {
        obtenerPuestos({ departamento: departamentoOrganizacionValue });
    }, [departamentoOrganizacionValue]);


    React.useEffect(() => {
        if (puestoValue) {
            obtenerSeniority({ puesto: puestoValue });
        }
    }, [puestoValue]);

    // Obtener municipios
    const [municipios, setMunicipios] = React.useState([]);

    const hideSenioritySalario = React.useMemo(() => {
        const currentSeniority = seniority.find(
            (s) => s.value === seniorityValue
        );
        if (!currentSeniority) return false;
        const label = currentSeniority.label;
        return ["NA", "N/A", "na", "n/a"].includes(label);
    }, [seniorityValue]);

    React.useEffect(() => {
        const obtenerMunicipios = async () => {
            try {
                const response = await api.get("recurso/municipios_guatemala", {
                    departamento: departamentoValue,
                });
                setMunicipios(response);
            } catch (error) {
                setMunicipios([]);
                change("municipio", null);
            }
        };

        if (esGuatemalteco && departamentoValue) {
            obtenerMunicipios();
        } else {
            setMunicipios([]);
        }
    }, [departamentoValue]);

    React.useEffect(() => {
        if (salarioValue && monedaPrefix !== " ") {
            /*
             * Buscar en seniority según el rango de salarios
             */
            const value = monedaPrefix.includes("GTQ")
                ? salarioValue / 7.89
                : salarioValue;

            let currentSeniority = seniority.find((s) => {
                return (
                    value >= Number(s.salario_minimo) &&
                    value <= Number(s.salario_maximo)
                );
            });

            if (currentSeniority) {
                change("seniority_salario", currentSeniority.value);
            } else change("seniority_salario", null);
        }
    }, [salarioValue, monedaPrefix, seniority]);

    const fields = [
        [
            // row
            {
                label: "Departamento/Organización",
                props: {
                    name: "departamento_organizacion",
                    label: "Departamento/Organización",
                    options: departamentos_organizacion,
                    component: renderSelectField,
                    select_style,
                    labelKey: "label",
                    valueKey: "value",
                    className: "form-control",
                    placeholder: "Departamento/Organización...",
                    extra_change: true,
                    disabled: edit,
                    extraChange: () => {
                        change("puesto", null);
                        change("seniority_puesto", null);
                    },
                },
            },
            {
                label: "Puesto",
                props: {
                    name: "puesto",
                    label: "Puesto",
                    options: puestos,
                    component: renderSelectField,
                    select_style,
                    labelKey: "label",
                    valueKey: "value",
                    className: "form-control",
                    placeholder: "Puesto...",
                    extra_change: true,
                    disabled: edit, 
                    extraChange: () => {
                        change("seniority_puesto", null);
                    },
                },
            },
        ],
        [
            {
                label: "Seniority (Opcional)",
                props: {
                    name: "seniority_puesto",
                    label: "Seniority (Opcional)",
                    component: renderSelectField,
                    select_style,
                    labelKey: "label",
                    valueKey: "value",
                    options: seniority,
                    isClearable: true,
                    className: "input--style",
                    placeholder: "Seniority...",
                },
            },
            {
                label: "Tipo de proyecto",
                props: {
                    name: "proyecto_default",
                    label: "Tipo de proyecto",
                    options: tipos_proyecto,
                    component: renderSelectField,
                    select_style,
                    labelKey: "nombre",
                    valueKey: "id",
                    className: "form-control",
                    placeholder: "Tipo de proyecto...",
                },
            },
        ],
        [
            {
                label: "Fecha inicio labores",
                props: {
                    name: "fecha_inicio",
                    label: "Fecha inicio labores",
                    component: renderField,
                    className: "input--style",
                    type: "date",
                },
            },
        ],
        [
            // row
            {
                label: "Tipo de contratación",
                props: {
                    name: "tipo_contratacion",
                    label: "Tipo de contratación",
                    options: tipos_contratacion,
                    component: renderSelectField,
                    select_style,
                    labelKey: "nombre",
                    valueKey: "id",
                    className: "form-control",
                    placeholder: "Tipo de contratación...",
                },
            },
            {
                label: "Tipo contrato",
                className: `col-12 col-md-6 ${
                    tipoContratacionValue === 2 && "hidden-form-input"
                }`,
                props: {
                    name: "tipo_contrato",
                    label: "Tipo contrato",
                    options: contratoOptions,
                    component: renderSelectField,
                    select_style,
                    labelKey: "label",
                    valueKey: "value",
                    className: "form-control",
                    placeholder: "Tipo contrato...",
                },
            },
        ],
        [
            {
                label: "",
                className: "col-12 col-md-2", // For elements inside row
                props: {
                    name: "nacionalidad",
                    label: "Guatemalteco",
                    value: "guatemalteco",
                    component: renderFieldRadio,
                    className: "form-control",
                    type: "radio",
                },
            },

            {
                label: "",
                className: "col-12 col-md-2", // For elements inside row
                props: {
                    name: "nacionalidad",
                    label: "Extranjero",
                    value: "extranjero",
                    component: renderFieldRadio,
                    className: "form-control",
                    type: "radio",
                },
            },
        ],
        [
            // row
            {
                label: esExtranjero ? "Estado (País)" : "",
                className: `col-12 col-md-4 ${
                    !esExtranjero && "hidden-form-input"
                }`,
                props: {
                    name: "estado",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Estado (País)...",
                },
            },
            /* Para los guatemaltecos se muestra Departamento y Municipio
             * y para los extranjeros solo el input de país.
             */
            {
                label: esGuatemalteco ? "Departamento" : "",
                className: `col-12 col-md-4 ${
                    !esGuatemalteco && "hidden-form-input"
                }`,
                props: {
                    name: "departamento",
                    label: "Departamento",
                    select_style,
                    component: renderSelectField,
                    options: departamentos_guatemala,
                    labelKey: "name",
                    valueKey: "code",
                    isClearable: true,
                    className: "input--style",
                    placeholder: "Departamento...",
                },
            },
            {
                label: esGuatemalteco ? "Municipio" : "",
                className: `col-12 col-md-4 ${
                    !esGuatemalteco && "hidden-form-input"
                }`,
                props: {
                    name: "municipio",
                    label: "Municipio",
                    select_style,
                    component: renderSelectField,
                    options: municipios,
                    labelKey: "name",
                    valueKey: "code",
                    isClearable: true,
                    className: "input--style",
                    placeholder: "Municipio...",
                },
            },
            {
                label: esExtranjero ? "País" : "",
                className: `col-12 col-md-4 ${
                    !esExtranjero && "hidden-form-input"
                }`,
                props: {
                    name: "pais",
                    label: "Pais",
                    type: esExtranjero ? "text" : "hidden",
                    component: esExtranjero ? renderField : "input",
                    className: "input--style",
                    placeholder: "País...",
                },
            },
        ],
        [
            {
                label: "Tipo moneda",
                className: "col-12 col-md-4",
                props: {
                    name: "tipo_moneda",
                    label: "Tipo moneda",
                    component: renderSelectField,
                    select_style,
                    className: "input--style",
                    options: [
                        {
                            symbol: "USD (Dólar estadounidense)",
                            value: "USD",
                        },
                        { symbol: "GTQ (Quetzal)", value: "GTQ" },
                    ],
                    labelKey: "symbol",
                    valueKey: "value",
                    placeholder: "Tipo moneda...",
                },
            },
        ],
        [
            // row
            {
                label: "Salario",
                className: "col-12 col-md-4",
                props: {
                    name: "salario",
                    label: "Salario",
                    component: renderCurrency,
                    prefix: monedaPrefix,
                    className: "input--style",
                    placeholder: "Salario...",
                    disabled: edit,
                },
            },
            
            {
                label: "Pretención",
                className: "col-12 col-md-4",
                props: {
                    name: "pretencion_salarial",
                    label: "Pretención",
                    component: renderCurrency,
                    prefix: monedaPrefix,
                    className: "input--style",
                    placeholder: "Pretención salarial...",
                },
            },
            {
                label: "Seniority salario",
                className: "col-12 col-md-4",
                className: `col-12 col-md-4 ${
                    hideSenioritySalario && "hidden-form-input"
                }`,
                props: {
                    name: "seniority_salario",
                    label: "Seniority salario",
                    select_style,
                    component: renderSelectField,
                    options: seniority,
                    labelKey: "label",
                    valueKey: "value",
                    disabled: true,
                    className: "input--style",
                    placeholder: "Seniority salario...",
                },
            },
        ],
    ];

    React.useEffect(() => {
        if (esExtranjero) {
            change("departamento", "");
            change("municipio", "");
            //change("pais", "");
        }

        if (esGuatemalteco) {
            change("pais", "");
            change("estado", "");
            // change("departamento", "");
            // change("municipio", "");
        }
    }, [nacionalidadValue]);

    return (
        <React.Fragment>
            <form
                name="RecursosCrearEditarForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <RenderFields fields={fields} />
                <div className="buttons-box">
                    <button
                        onClick={prevStep}
                        type="button"
                        className="btn btn-secondary"
                        style={{ fontSize: 15 }}
                    >
                        Regresar
                    </button>
                    <button
                        type="submit"
                        style={{ fontSize: 15 }}
                        className="btn btn-primary m-1 align-self-center"
                    >
                        Siguiente
                    </button>
                </div>
            </form>
        </React.Fragment>
    );
}

// export { UbicacionSaludForm };


const form = reduxForm({
    form: "RecursosCrearEditarForm", // a unique identifier for this  form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validateFields,
})(UbicacionSaludForm);

const selector = formValueSelector("RecursosCrearEditarForm");
export default connect((state) => {
    const departamentoValue = selector(state, "departamento");
    const nacionalidadValue = selector(state, "nacionalidad");
    let monedaPrefix = selector(state, "tipo_moneda");
    monedaPrefix = monedaPrefix ? `${monedaPrefix} ` : " ";
    const esExtranjero = nacionalidadValue === "extranjero";
    const esGuatemalteco = nacionalidadValue === "guatemalteco";
    const tipoContratacionValue = selector(state, "tipo_contratacion");
    const tipoContratoValue = selector(state, "tipo_contrato");
    const puestoValue = selector(state, "puesto") || null;
    const salario = selector(state, "salario") || 0;
    const seniorityValue = selector(state, "seniority_puesto") || null;
    const departamentoOrganizacionValue = selector(
        state,
        "departamento_organizacion"
    );

    return {
        nacionalidadValue,
        esExtranjero,
        esGuatemalteco,
        monedaPrefix,
        departamentoValue,
        tipoContratacionValue,
        tipoContratoValue,
        puestoValue,
        salarioValue: Number(salario),
        seniorityValue,
        departamentoOrganizacionValue,
    };
})(form);
