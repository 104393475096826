import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import { Link } from "react-router-dom";

class Listar extends Component {
    state = {
        page: 1,
        search: "",
        BreakLine: {
            whiteSpace: "normal",
        },
        params: {
            // Aquí puedes agregar parámetros de búsqueda específicos para el historial económico, si los tienes
        },
    };

    componentDidMount() {
        this.props.listar(this.state.page, this.state.params);
    }

    onPageChange = (page) => {
        this.props.listar(page, this.state.params);
        this.setState({ page });
    };

    render() {
        const { data, loader, eliminar } = this.props;
        console.log("data")
        console.log(data)


        return (
            <React.Fragment>
                <div className="flex row mt-2">
                    <div className="col-12">
                        <h2 className="title--blue">LISTA DE HISTORIAL ECONÓMICO</h2>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-12 col-md-6" style={{display: 'flex', justifyContent: 'flex-start'}}>
                        <input
                            placeholder="Buscar..."
                            type="text"
                            className="form-control mt-4 input--style"
                            value={this.state.search}
                            onKeyPress={(key) => {
                                if (key.key == "Enter") {
                                    const { page, search } = this.state;
                                    this.props.listar(page, { search });
                                }
                            }}
                            onChange={(tx) => {
                                this.setState({ search: tx.target.value });
                            }}
                            style={{
                                color: "black",
                                fontSize: 15,
                                width: "55%",
                                float: "right",
                            }}
                        />
                    </div>
                </div>
                <Grid
                    data={data} // Usar solo los datos activos
                    loading={loader}
                    page={this.state.page}
                    onPageChange={this.onPageChange}
                >
                    {/* columnas */}
                    <TableHeaderColumn dataField="codigo" dataSort>
                        Código
                    </TableHeaderColumn>

                    <TableHeaderColumn dataField="nombre" dataSort>
                        nombre del empleado
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataField="salario"
                        dataSort
                        dataFormat={(cell, row) => `${cell} ${row.tipo_moneda}`}
                    >
                        Salario
                    </TableHeaderColumn>


                    <TableHeaderColumn
                        dataField="id"
                        dataSort
                        isKey
                        dataFormat={(cell, row) => (
                            <Link to={`/historial_economico/${row.id}/historial`} className="btn btn-primary">
                                Agregar Aumento
                            </Link>
                        )}
                    >
                        Acciones
                    </TableHeaderColumn>


                </Grid>
            </React.Fragment>
        );
    }
}

export default Listar;
