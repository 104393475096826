import LoadMask from "../../Utils/LoadMask/LoadMask";
import CrearEditarForm from './CrearEditarForm';        
import React, { Component } from 'react';
import { api } from "api";
import HistorialAscensos from "./HistorialAscensos";    
import { historial } from "../../../../redux/modules/historial_interno/historial_interno";
import { connect } from 'react-redux';
import { actions as actionsPuestos} from '../../../../redux/modules/puestos/puestos';
import { SELECT_LIST as selectListDepartamentos } from "redux/modules/departamento_organizacion/departamento_organizacion";
import puestos from "../../../../redux/modules/puestos/puestos";


 class CrearEditar extends Component {

    state = {
        departamentos_organizacion: [], 
        puestos: [],
        selectedDepartamento: null,
        edit: false,
        loader: false,
        id: null,
        params:{
        }
    }
    

    componentDidMount(){
        api.get(selectListDepartamentos).then(response_d => {
            this.setState({ departamentos_organizacion: response_d });
        });
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.historialData !== this.props.historialData &&
            JSON.stringify(prevProps.historialData) !== JSON.stringify(this.props.historialData)) {
            this.loadData();
        }
    }   

    loadData() {
        const { page, params } = this.state;
        const { match } = this.props;
        const id = match.params.id; // Obtén el ID de los parámetros de la URL
        this.props.loadHistorial(id, page, params);
      
   }

    handleDepartamentoChange = (departamentoId) => {
        this.props.obtenerPuestos(departamentoId).then(response => {
        this.setState({ puestos: response });
        }).catch(error => {
        console.error('Error fetching puestos:', error);
        });
    };

   onSubmit = (data) => {
       const { onSubmit } = this.props;
       onSubmit(data);
   };

    

   render() {
    const { loader } = this.state;
    const { match, historialData, obtenerPuestos, puestos, puestosLoader } = this.props;
    console.log("Leyendo datos de redux")
    console.log(historialData)
    

  
    if (!Array.isArray(historialData)) {
        return null; 
    }
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <h2 className="title--blue">Crear Ascenso</h2>
                </div>
            </div>
            <hr />
            <div className="login-wrapper mb-4">
                <div className="card card-login w-100">
                    <LoadMask loading={puestosLoader || loader ||loader} light>
                        <CrearEditarForm
                            onSubmit={this.onSubmit}
                            codigo_recurso={match.params.id}
                            departamentos_organizacion={this.state.departamentos_organizacion}
                            puestos={puestos}
                            onDepartamentoChange={this.handleDepartamentoChange}
                            obtenerPuestos={obtenerPuestos}
                        />
                        
                    </LoadMask>
                </div>
            </div>
            <h2 className="title--blue">Historial de Ascenso</h2>
            <hr />
            <div className="login-wrapper">   
                <div className="card card-login w-100"> 
                    <HistorialAscensos historialData={historialData} />
                    
                </div>
            </div>
        </React.Fragment>
    );
}

}
export default CrearEditar;