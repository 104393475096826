import React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { validate, validators } from "validate-redux-form";
import {
    renderTextArea,
    renderFilePicker,
    renderDayPicker,
    renderSelectField,
} from "../../Utils/renderField";

const CrearEditarForm = (props) => {
    const { handleSubmit, edit, documentoUrl, tipos, colaboradores } = props;
    const types = ["application/pdf", "image/jpeg", "image/png"];
    const openNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <form
            name="CrearEditarForm"
            className="form-validate mb-lg"
            onSubmit={handleSubmit}
        >
            <div className="row">
                <div
                    className="form-group has-feedback col-6"
                    style={{ zIndex: 2 }}
                >
                    <label htmlFor="tipo">Tipo de amonestación</label>
                    <Field
                        name="tipo"
                        component={renderSelectField}
                        select_style={{
                            control: (styles) => ({
                                ...styles,
                                color: "#1F4D7B",
                                borderColor: "#1F4D7B",
                            }),
                        }}
                        options={tipos}
                        labelKey="label"
                        valueKey="value"
                        className="input--style"
                    />
                </div>
                <div
                    className="form-group has-feedback col-6"
                    style={{ zIndex: 2 }}
                >
                    <label htmlFor="colaborador">Colaborador</label>
                    <Field
                        name="colaborador"
                        component={renderSelectField}
                        select_style={{
                            control: (styles) => ({
                                ...styles,
                                color: "#1F4D7B",
                                borderColor: "#1F4D7B",
                            }),
                        }}
                        options={colaboradores}
                        labelKey="label"
                        valueKey="value"
                        className="input--style"
                        disabled={edit}
                    />
                </div>
            </div>

            <div className="row">
                <div className="form-group has-feedback col-6">
                    <label htmlFor="fecha">Fecha</label>
                    <Field
                        name="fecha"
                        component={renderDayPicker}
                        className="input--style"
                    />
                </div>
                <div className="form-group has-feedback col-6">
                    <label htmlFor="comentario">Comentario</label>
                    <Field
                        name="comentario"
                        component={renderTextArea}
                        className="input--style"
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group has-feedback col-12">
                    <label htmlFor="fecha_fin">Adjuntar documento</label>
                    <Field
                        name="documento"
                        component={renderFilePicker}
                        types={types}
                        className="input--style"
                        photo={documentoUrl ? documentoUrl : ""}
                    />
                </div>
            </div>
            {edit ? (
                <div className="row">
                    <div className="col-12 col-md-4">
                        <button
                            type="button"
                            className="btn btn-dark"
                            disabled={!documentoUrl}
                            onClick={() => openNewTab(documentoUrl)}
                        >
                            Ver documento
                        </button>
                    </div>
                </div>
            ) : null}
            <div className="buttons-box">
                <Link
                    to="/amonestaciones"
                    className="btn btn-secondary"
                    style={{ fontSize: 15 }}
                >
                    Regresar
                </Link>
                <button
                    type="submit"
                    style={{ fontSize: 15 }}
                    className="btn btn-primary m-1 align-self-center"
                >
                    Guardar
                </button>
            </div>
        </form>
    );
};

const form = reduxForm({
    form: "CrearEditarForm", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            fecha: validators.exists()("Este campo es requerido"),
            tipo: validators.exists()("Este campo es requerido"),
            colaborador: validators.exists()("Este campo es requerido"),
            comentario: validators.length({ min: 1, max: 255 })(
                "Entre 1 y 255 caracteres"
            ),
        });
    },
})(CrearEditarForm);

const selector = formValueSelector("CrearEditarForm");
export default connect((state) => {
    const documentoUrl = selector(state, "documento_url") || "";
    return {
        documentoUrl,
    };
})(form);
