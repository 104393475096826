import React from "react";
import { Field, FieldArray } from "redux-form";

function RenderFields({ fields }) {
    return (
        <React.Fragment>
            {fields.map((row, index) => (
                <div className="row" key={index}>
                    {row.map((field) => {
                        return (
                            <div
                                className={`form-group has-feedback ${
                                    field.className
                                        ? field.className
                                        : "col-12 col-md-6"
                                }`}
                                key={field.props.label || field.label}
                                style={
                                    field.styleContainer
                                        ? field.styleContainer
                                        : {}
                                }
                            >
                                <label htmlFor={field.props.name}>
                                    {field.label}
                                </label>
                                {field.array ? (
                                    <FieldArray {...field.props} />
                                ) : (
                                    <Field {...field.props} />
                                )}
                            </div>
                        );
                    })}
                </div>
            ))}
        </React.Fragment>
    );
}

export { RenderFields };
