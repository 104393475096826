import { validate, validators } from "validate-redux-form";
import {
    validateArrayNoRepeatedField,
    validatePersonalFases,
} from "../../../Utils/Validators";

const validateFields = (data) => {
    const errorsValidate = validate(data, {
        nombre: validators.exists()("Este campo es requerido"),
        cliente: validators.exists()("Este campo es requerido"),
        codigo: validators.exists()("Este campo es requerido"),
        plantilla: validators.exists()("Este campo es requerido"),
        personal: [
            {
                puesto: validators.exists()("Este campo es requerido"),
                seniority: validators.exists()("Este campo es requerido"),
                tarifa: validators.number({ min: 0 })(
                    "Valor mayor o igual a 0 requerido"
                ),
                cantidad: validators.number({ min: 1 })(
                    "Valor mayor o igual a 1 requerido"
                ),
            },
        ],
        modulos: [
            {
                actividad: validators.exists()("Este campo es requerido"),
                fase: validators.exists()("Este campo es requerido"),
                horas_estimadas: validators.number({ min: 0 })(
                    "Valor mayor o igual a 0 requerido"
                ),
                horas_ideales: validators.number({ min: 0 })(
                    "Valor mayor o igual a 0 requerido"
                ),
            },
        ],

        gastos: [
            {
                gasto: validators.exists()("Este campo es requerido"),
                total: validators.number({ min: 0 })(
                    "Valor mayor o igual a 0 requerido"
                ),
            },
        ],

        actividades: [
            {
                actividad: validators.exists()("Este campo es requerido"),
                fase: validators.exists()("Este campo es requerido"),
                total_horas: validators.number({ min: 0 })(
                    "Valor mayor o igual a 0 requerido"
                ),
            },
        ],

        impuestos_adicionales: validators.exists()("Este campo es requerido"),
        margen_negocio: validators.exists()("Este campo es requerido"),
        ganancia_adicional: validators.exists()("Este campo es requerido"),
        comision_venta: validators.exists()("Este campo es requerido"),
    });

    let manualErrors = {
        ...validatePersonalFases({
            data,
            fieldname: "personal",
            min: 1,
            emptyMessage: "No hay personal",
            message: "Puestos repetidos en",
            fields: ["fase", "seniority"],
            fase: "fase_label",
        }),
        ...validateArrayNoRepeatedField({
            data,
            fieldname: "modulos",
            min: 1,
            emptyMessage: "No hay modulos",
            message: "Modulos repetidos",
            fields: ["actividad"],
        }),
        ...validateArrayNoRepeatedField({
            data,
            fieldname: "actividades",
            min: 1,
            emptyMessage: "No hay actividades",
            message: "Actividades repetidas",
            fields: ["actividad"],
        }),
        ...validateArrayNoRepeatedField({
            data,
            fieldname: "gastos",
            min: 0,
            emptyMessage: "",
            message: "Gastos repetidos",
            fields: ["gasto"],
        }),
    };

    const validateArchivos = [];
    if (data.archivos) {
        data.archivos.forEach((archivo) => {
            if (!archivo.id) {
                validateArchivos.push(
                    validate(archivo, {
                        archivo: validators.exists()("Agregue un archivo"),
                    })
                );
            } else {
                validateArchivos.push({});
            }
        });
    }

    return { ...errorsValidate, ...manualErrors, archivos: validateArchivos };
};

export default validateFields;
