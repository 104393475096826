import React from "react";

import { Link } from "react-router-dom";

import { renderField } from "../../Utils/renderField";

import { RenderFields } from "../../Recursos/CrearEditar/RenderFields";
import { RenderSkills } from "./RenderSkills";

export function DatosBasicosForm({ skills }) {
    const select_style = {
        control: (styles) => ({
            ...styles,
            color: "#1F4D7B",
            borderColor: "#1F4D7B",
            backgroundColor: "#e3e9ee",
        }),
        singleValue: (styles) => ({
            ...styles,
            color: "#1F4D7B",
        }),
    };

    const fields = [
        [
            // row
            {
                label: "Código",
                props: {
                    name: "codigo",
                    label: "codigo",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Código...",
                },
            },
            {
                label: "Nombre",
                props: {
                    name: "nombre",
                    label: "Nombre",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Nombre...",
                },
            },
        ],
        [
            // row
            {
                label: "Correo empresarial",
                props: {
                    name: "email_empresarial",
                    label: "Correo empresarial",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Correo empresarial...",
                },
            },
            {
                label: "Correo personal",
                props: {
                    name: "email",
                    label: "Correo personal",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Correo personal...",
                },
            },
        ],
        [
            {
                label: "Teléfono",
                props: {
                    name: "telefono",
                    label: "Teléfono",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Teléfono...",
                },
            },
        ],
        [
            // row
            {
                label: "",
                array: true,
                className: "row mx-auto",
                props: {
                    name: "skills_experiencia",
                    component: RenderSkills,
                    skills,
                    select_style,
                    label: "Skills",
                },
            },
        ],
    ];

    return (
        <React.Fragment>
            <form
                name="RecursosCrearEditarForm"
                className="form-validate mb-lg"
            >
                <fieldset disabled>
                    <RenderFields fields={fields} />
                </fieldset>
                <div className="buttons-box">
                    <Link
                        to="/recursos_contacto"
                        className="btn btn-primary"
                        style={{ fontSize: 15 }}
                    >
                        Regresar
                    </Link>
                </div>
            </form>
        </React.Fragment>
    );
}
