import React from 'react';
import './HistorialAscensos.css';


const HistorialAscensos = ({ historialData }) => {
    return (
        <div className="historial-container">
            <table className="historial-table">
                <thead>
                    <tr>
                        <th>Departamento</th>
                        <th>Puesto</th>
                        <th>Fecha de aplicación</th>
                        <th>Fecha de finalización</th>
                    </tr>
                </thead>
                <tbody>
                    {historialData.map((item) => (
                        <tr key={item.id}>
                            <td>{item.departamento_organizacion_nombre}</td>
                            <td>{item.puesto_nombre}</td>
                            <td>{item.fecha_inicio}</td>
                            <td>{item.fecha_final ? item.fecha_final : 'A la fecha'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


export default HistorialAscensos;
