import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/users/users';
import { actions as recursos } from '../../../../redux/modules/recursos/recursos';
import CrearEditar from './CrearEditar';

const ms2p = (state) => {
  return {
    ...state.users,
    recursos: { ...state.recursos }
  };
};

const md2p = { ...actions, getAllResources: recursos.getAll };

export default connect(ms2p, md2p)(CrearEditar);