import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { validate, validators, array } from "validate-redux-form";

import { ConfiguracionGeneral } from "./Secciones/ConfiguracionGeneralForm";
import { FasesProyecto } from "./Secciones/FasesProyectoForm";
import { RequisitosEntrega } from "./Secciones/RequisitosEntregaForm";
import { validateFieldArray } from "../../Utils/Validators";

const CrearEditarForm = (props) => {
    const {
        handleSubmit,
        complejidades,
        totalHorasValue,
        change,
        actividades,
        fases,
        puestos,
    } = props;
    return (
        <form
            name="CrearEditarForm"
            className="form-validate mb-lg"
            onSubmit={handleSubmit}
        >
            <ConfiguracionGeneral
                complejidades={complejidades}
                totalHorasValue={totalHorasValue}
                change={change}
            />
            <FasesProyecto fases={fases} puestos={puestos} />
            <RequisitosEntrega
                actividades={actividades}
                fases={fases}
                change={change}
            />
            <div className="buttons-box">
                <Link
                    to="/plantillas_proyectos"
                    className="btn"
                    style={{ fontSize: 15 }}
                >
                    Salir
                </Link>
                <button
                    type="submit"
                    style={{ fontSize: 15 }}
                    className="btn btn-primary m-1 align-self-center"
                >
                    Guardar
                </button>
            </div>
        </form>
    );
};

const form = reduxForm({
    form: "CrearEditarForm", // a unique identifier for this form

    validate: (data) => {
        const errorsValidate = validate(data, {
            nombre: validators.exists()("Este campo es requerido"),
            complejidad: validators.exists()("Este campo es requerido"),
            part_qa_desarollo: validators.number({ min: 0 })(
                "Valor mayor o igual a 0 requerido"
            ),
            part_qa_pruebas: validators.number({ min: 0 })(
                "Valor mayor o igual a 0 requerido"
            ),
            part_backlog: validators.number({ min: 0 })(
                "Valor mayor o igual a 0 requerido"
            ),
            part_margen_error: validators.number({ min: 0 })(
                "Valor mayor o igual a 0 requerido"
            ),
            part_probable: validators.number({ min: 0 })(
                "Valor mayor o igual a 0 requerido"
            ),
            part_pesimista: validators.number({ min: 0 })(
                "Valor mayor o igual a 0 requerido"
            ),
            meses_garantia: validators.exists()("Este campo es requerido"),
            horas_mes_garantia: validators.number({ min: 0 })(
                "Valor mayor o igual a 0 requerido"
            ),
            total_horas_garantia: validators.exists()(
                "Este campo es requerido"
            ),
            part_descuento_autorizado: validators.number({ min: 0 })(
                "Valor mayor o igual a 0 requerido"
            ),
            requisitos_actividades: [
                {
                    actividad: validators.exists()("Este campo es requerido"),
                    horas: validators.exists()("Este campo es requerido"),
                    fase: validators.exists()("Este campo es requerido"),
                    suma_semana: validators.exists()("Este campo es requerido"),
                },
            ],
            fases_puestos: [
                {
                    puesto: validators.exists()("Este campo es requerido"),
                    horas_semanales: validators.exists()(
                        "Este campo es requerido"
                    ),
                },
            ],
            porcentajes: [
                {
                    porcentaje: validators.number({ min: 0 })(
                        "Valor mayor o igual a 0 requerido"
                    ),
                },
            ],
        });

        let manualErrors = {};
        manualErrors = {
            ...validateFieldArray({
                data,
                fieldname: "requisitos_actividades",
                min: 1,
                message: "No hay actividades",
            }),
            ...validateFieldArray({
                data,
                fieldname: "fases_puestos",
                min: 1,
                message: "No hay puestos",
            }),
        };

        const porcentajes = data.porcentajes;
        if (porcentajes) {
            let error = false;
            const fases = [];
            porcentajes.forEach((porcentaje) => {
                if (
                    porcentaje.porcentaje > 0 &&
                    (!data.fases_puestos ||
                        !data.fases_puestos.find(
                            (puesto) => puesto.fase === porcentaje.fase
                        ))
                ) {
                    error = true;
                    fases.push(porcentaje.label);
                }
            });

            if (error) {
                errorsValidate.porcentajes = {
                    ...errorsValidate.porcentajes,
                    _error: `Agregar puestos a ${fases.join(", ")}.`,
                };
            }
        }

        return { ...errorsValidate, ...manualErrors };
    },
})(CrearEditarForm);

const selector = formValueSelector("CrearEditarForm");
export default connect((state) => {
    const meses = selector(state, "meses_garantia") || 0;
    const horas = selector(state, "horas_mes_garantia") || 0;
    const totalHorasValue = meses * horas;
    return {
        totalHorasValue,
    };
})(form);
