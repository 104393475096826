import LoadMask from "Utils/LoadMask/LoadMask";
import CrearEditarForm from "./CrearEditarForm";
import React, { Component } from "react";
import { Link } from "react-router-dom";

class CrearEditar extends Component {
    state = {
        edit: false,
        loader: true,
        id: null,
    };

    componentDidMount() {
        this.setState({ loader: false });
        const edit = this.props.match.params.id ? true : false;
        this.setState({ edit });
        if (edit == true) {
            const id = this.props.match.params.id;
            this.setState({ id });
            this.props.obtenerComplejidadProyecto(id);
        }
    }

    onSubmit = (data) => {
        const { edit, id } = this.state;
        const { onSubmit, actualizarComplejidadProyecto, listar } = this.props;
        if (edit) {
            actualizarComplejidadProyecto(data, id);
        } else {
            onSubmit(data);
        }
    };

    render() {
        const { loaderEditar } = this.props;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12 px-4 d-flex align-items-center justify-content-between">
                        <h6 className="title--blue--1">
                            {this.state.edit ? "Editar" : "Nueva"} complejidad
                            de proyecto
                        </h6>
                        <Link className="btn" to="/complejidad_proyectos">
                            <i className="material-icons">close</i>
                        </Link>
                    </div>
                </div>
                <hr className="mt-0"/>
                <div className="login-wrapper">
                    <div className="w-100 px-5">
                        <LoadMask
                            loading={loaderEditar || this.state.loader}
                            light
                        >
                            <CrearEditarForm onSubmit={this.onSubmit} />
                        </LoadMask>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CrearEditar;
