import React, { useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { NavLink } from "react-router-dom";

const Finalizados = (props) => {

    const [show_popup, setShowPopup] = useState(false);
    const [data_popup, setDataPopup] = useState([]);

    return (
        <React.Fragment>
            <Popup open={show_popup} modal onClose={() => {setShowPopup(false); setDataPopup([])}}>
            <div className="row" style={{overflowX: 'auto'}}>
                    <div className="col-12">
                        {data_popup.length > 0 ? <table className="content--table table--fixed" style={{margin: '0', borderRadius: '0'}}>
                            <thead className="thead--fixed">
                                <tr>
                                    <th scope="col" className='min--width'>Nombre</th>
                                    <th scope="col" className='min--width'>Horas extra</th>
                                </tr>
                            </thead>
                            <tbody className='tbody--fixed'>
                                {data_popup.map((e, i) => (
                                    <tr key={i}>
                                        <td className='min--width'>
                                            <NavLink className="nav--link--color" to={"/proyectos/" + e.id + "/editar/recursos" } exact>
                                                { e.nombre }
                                            </NavLink>
                                        </td>
                                        <td className='min--width'>{e.horas_extra ? e.horas_extra : '0'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> : <React.Fragment>
                        <div className="card-header card--header">Proyectos</div>
                        <h6 style={{color: "black", marginTop: "1%", marginLeft: "1%"}}>No hay proyectos</h6>
                            </React.Fragment>}
                    </div>
                </div>
            </Popup>
            <div style={{overflowX: 'auto'}}>
                <table className="content--table">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Finalizados a tiempo</th>
                            <th>Finalizados con atraso {"("}horas extra{")"}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th style={{backgroundColor: "#D4DBED"}}></th>
                            <th style={{backgroundColor: "#D4DBED"}}>
                                <div style={{display: "flex", justifyContent: "space-around" }}>
                                    <div>Cantidad</div>
                                    <div>Detalle</div>
                                </div>
                            </th>
                            <th style={{backgroundColor: "#D4DBED"}}>
                                <div style={{display: "flex", justifyContent: "space-around" }}>
                                    <div>Cantidad</div>
                                    <div>Detalle</div>
                                </div>
                            </th>
                        </tr>
                        {props.data.map((e, i) => (
                            <tr key={i}>
                                <td>{e.encargado}</td>
                                <td>
                                    <div style={{display: "flex", justifyContent: "space-around" }}>
                                        <div>{e.cantidad_a_tiempo}</div>
                                        <div>
                                            <button className="btn btn-info" onClick={() => {setShowPopup(true); setDataPopup(e.proyectos_a_tiempo)}}>
                                                <i className="material-icons" style={{fontSize: '10px'}}>visibility</i>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div style={{display: "flex", justifyContent: "space-around" }}>
                                        <div>{e.cantidad_atraso}</div>
                                        <div>
                                            <button className="btn btn-info" onClick={() => {setShowPopup(true); setDataPopup(e.proyectos_atrasados)}}>
                                                <i className="material-icons" style={{fontSize: '10px'}}>visibility</i>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )

};

export default Finalizados;