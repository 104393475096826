import React from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import validate from "./validate";
import Swal from "sweetalert2";
import { calulateSummary } from "./calculations";
import { proyectos_operaciones_estados } from "../../../../../utility/constants";

const ThirdStep = (props) => {
    const {
        handleSubmit,
        previusStep,
        currentPlantilla,
        personalValue = [],
        modulosValue = [],
        actividadesValue = [],
        gastosValue = [],
        impuestosAdicionales,
        margenNegocio,
        gananciaAdicional,
        comisionVenta,
        estadoValue,
        edit,
        onSubmit,
        checkCreate,
        aprobarProyecto,
        enviarRevision,
        ventaGanadaPerdida,
    } = props;

    const role = Number(localStorage.getItem("role"));

    const summaries = React.useMemo(
        () =>
            calulateSummary({
                currentPlantilla,
                personalValue,
                modulosValue,
                gastosValue,
                actividadesValue,
                impuestosAdicionales,
                margenNegocio,
                gananciaAdicional,
                comisionVenta,
            }),
        [modulosValue, actividadesValue, personalValue]
    );

    const askAlert =
        ({ message, confirmText, callback }) =>
        () => {
            Swal.fire({
                title: message,
                showCancelButton: true,
                confirmButtonText: confirmText,
                cancelButtonText: "Cancelar",
            }).then((result) => {
                console.log(result);
                if (result.value) {
                    callback();
                }
            });
        };

    return (
        <form name="ProyectoOperacionesForm" className="form-validate mb-lg">
            <div className="d-flex align-items-center justify-content-end">
                <button
                    type="button"
                    style={{ fontSize: 15 }}
                    className="btn"
                    onClick={previusStep}
                >
                    Regresar
                </button>
                <button
                    type="button"
                    style={{ fontSize: 15 }}
                    className="btn btn-primary m-1 align-self-center"
                    onClick={handleSubmit(onSubmit)}
                >
                    {edit ? "Finalizar" : "Guardar"}
                </button>
            </div>
            <div
                className="card-form pb-5 mb-2 mx-auto"
                style={{
                    maxWidth: "1000px",
                }}
            >
                {summaries.map((summary) => (
                    <div className="project-summary" key={summary.title}>
                        <h5>{summary.title}</h5>
                        <hr />
                        {Object.values(summary.values).map((row) => (
                            <div
                                className={`project-summary--row ${
                                    row.highlight ? "highlight" : ""
                                } ${row.className || ""}`}
                                key={row.label}
                            >
                                <h6>{row.label}: </h6>
                                <p>
                                    {`${summary.formatter.format(row.value)}${
                                        summary.suffix
                                    }`}
                                </p>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className="d-flex align-items-center justify-content-center mb-5">
                {/* Revisión sin crear */}
                {!edit ? (
                    <button
                        type="button"
                        style={{ fontSize: 15 }}
                        className="btn btn-primary m-1 align-self-center"
                        onClick={askAlert({
                            message: "¿Desea enviar este proyecto a revisión?",
                            confirmText: "Enviar",
                            callback: handleSubmit(checkCreate),
                        })}
                    >
                        Guardar y enviar a revisión
                    </button>
                ) : null}
                {/* Revisión editando */}
                {edit &&
                estadoValue &&
                estadoValue == proyectos_operaciones_estados.EN_PROCESO ? (
                    <button
                        type="button"
                        style={{ fontSize: 15 }}
                        className="btn btn-primary m-1 align-self-center"
                        onClick={askAlert({
                            message: "¿Desea enviar este proyecto a revisión?",
                            confirmText: "Enviar",
                            callback: handleSubmit(enviarRevision),
                        })}
                    >
                        Enviar a revisión
                    </button>
                ) : null}
                {/* Aprobar */}
                {edit &&
                estadoValue &&
                estadoValue == proyectos_operaciones_estados.EN_REVISION &&
                (role === 1 || role === 5) ? (
                    <button
                        type="button"
                        style={{ fontSize: 15 }}
                        className="btn btn-primary m-3 align-self-center"
                        onClick={askAlert({
                            message: "¿Desea aprobar este proyecto?",
                            confirmText: "Aprobar",
                            callback: handleSubmit(aprobarProyecto),
                        })}
                    >
                        Aprobar proyecto
                    </button>
                ) : null}
                {estadoValue === proyectos_operaciones_estados.APROBADO ? (
                    <button
                        type="button"
                        style={{ fontSize: 15 }}
                        className="btn btn-success m-3 align-self-center"
                        onClick={askAlert({
                            message: "¿Marcar como venta ganada?",
                            confirmText: "Confirmar",
                            callback: handleSubmit(() =>
                                ventaGanadaPerdida(true)
                            ),
                        })}
                    >
                        Venta ganada
                    </button>
                ) : null}
                {estadoValue === proyectos_operaciones_estados.APROBADO ? (
                    <button
                        type="button"
                        style={{ fontSize: 15 }}
                        className="btn btn-warning m-3 align-self-center"
                        onClick={askAlert({
                            message: "¿Marcar como venta perdida?",
                            confirmText: "Confirmar",
                            callback: handleSubmit(() =>
                                ventaGanadaPerdida(false)
                            ),
                        })}
                    >
                        Venta perdida
                    </button>
                ) : null}
            </div>
        </form>
    );
};

const form = reduxForm({
    form: "ProyectoOperacionesForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(ThirdStep);

const selector = formValueSelector("ProyectoOperacionesForm");
export default connect((state) => {
    const personalValue = selector(state, "personal") || [];
    const modulosValue = selector(state, "modulos") || [];
    const actividadesValue = selector(state, "actividades") || [];
    const gastosValue = selector(state, "gastos") || [];
    const impuestosAdicionales = selector(state, "impuestos_adicionales");
    const margenNegocio = selector(state, "margen_negocio");
    const gananciaAdicional = selector(state, "ganancia_adicional");
    const comisionVenta = selector(state, "comision_venta");
    const estadoValue = selector(state, "estado");
    return {
        personalValue,
        modulosValue,
        actividadesValue,
        gastosValue,
        impuestosAdicionales,
        margenNegocio,
        gananciaAdicional,
        comisionVenta,
        estadoValue,
    };
})(form);
