import React from "react";

export const RenderSkills = ({
    fields,
    meta: { error },
    isDisabled = false,
}) => {
    return (
        <React.Fragment>
            {fields.map((skill, index, fields) => {
                const currentSkill = fields.get(index);
                return (
                    <div
                        className="solicitud-personal--skill"
                        key={skill}
                    >
                        <span>
                            {currentSkill.label}
                            {" - "}
                        </span>
                        <span>
                            {currentSkill.experiencia}{" "}
                            {Number(currentSkill.experiencia) === 1
                                ? "mes"
                                : "meses"}
                        </span>
                        {!isDisabled ? (
                            <button
                                className="btn p-0"
                                type="button"
                                onClick={() => fields.remove(index)}
                            >
                                <i className="material-icons">cancel</i>
                            </button>
                        ) : null}
                    </div>
                );
            })}
        </React.Fragment>
    );
};
