import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import Swal from 'sweetalert2';
import { api } from "api";

const LOADER = 'GANTT_LOADER';
const DATA = "GANTT_DATA";
const GANTT = "GANTT";
const SCRUM_DATA = "SCRUM_DATA";
const FASES = "FASES";
const SEMANAS = "SEMANAS";
const MUESTRA = "MUESTRA";
const ENDPOINT = "gantt"

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setData = data => ({
    type: DATA,
    data
})

export const setGantt = gantt => ({
    type: GANTT,
    gantt
})

export const setScrumData = scrumData => ({
    type: SCRUM_DATA,
    scrumData
})

export const setFases = fases => ({
    type: FASES,
    fases
})

export const setSemanas = semanas => ({
    type: SEMANAS,
    semanas
});

export const setMuestra = muestra => ({
    type: MUESTRA,
    muestra
});

// ------------------------------------
// Actions
// ------------------------------------

export const getData = (params={}) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${ENDPOINT}/getData`, params).then(response => {
        dispatch(setGantt(response));
    })
        .catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const getAllData = (params={}) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${ENDPOINT}/getAllData`, params).then(response => {
        dispatch(setData(response.projectData));
        dispatch(setScrumData(response.scrumData));
        dispatch(setFases(response.fases));
        dispatch(setSemanas(response.semanas_global));
        dispatch(setMuestra(response.muestra));
    })
        .catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actions = {
    getData,
    getAllData,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SCRUM_DATA]: (state, { scrumData }) => {
        return {
            ...state,
            scrumData,
        };
    },
    [FASES]: (state, { fases }) => {
        return {
            ...state,
            fases,
        };
    },
    [SEMANAS]: (state, { semanas }) => {
        return {
            ...state,
            semanas,
        };
    },
    [MUESTRA]: (state, { muestra }) => {
        return {
            ...state,
            muestra,
        };
    },
    [GANTT]: (state, { gantt }) => {
        return {
            ...state,
            gantt
        }
    }
};

export const initialState = {
    loader: false,
    data: [],
    scrumData: [],
    fases: [],
    semanas: [],
    muestra: 0,
    gantt: []
};

export default handleActions(reducers, initialState);
