import React from "react";

import { renderSelectField, renderNumber } from "../../Utils/renderField";

import { Field } from "redux-form";

export const RenderSkills = ({
    fields,
    meta: { error },
    skills,
    select_style,
}) => {
    return fields.map((skill, index) => (
        <React.Fragment key={skill}>
            <div className="col-6 col-md-3 col-xl-2 m-0 mb-1">
                <Field
                    name={`${skill}.skill`}
                    select_style={select_style}
                    component={renderSelectField}
                    options={skills}
                    labelKey="label"
                    valueKey="value"
                    className="form-group"
                    placeholder="Skill..."
                    disabled={true}
                />
            </div>
            <div className="col-4 col-md-2 col-xl-1 m-0 p-0">
                <Field
                    name={`${skill}.experiencia`}
                    type="text"
                    component={renderNumber}
                    suffix=" años"
                    label={`Experiencia`}
                    placeholder="Años..."
                    readOnly={true}
                    className="input--style"
                />
            </div>
        </React.Fragment>
    ));
};
