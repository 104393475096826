import React from 'react';
import { Link } from "react-router-dom";
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSwitch } from '../../Utils/renderField';

const CrearEditarForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form name="CrearEditarForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className='row'>
                <div className="form-group has-feedback col-6">
                    <label htmlFor="nombre">Nombre</label>
                    <Field name="nombre" label="Nombre" component={renderField} type="text" className="input--style" />
                </div>
                <div className="col-6">
                    <div className="form-group has-feedback row">
                        <label htmlFor="lleva_porcentaje" className="col-4">¿Lleva porcentaje?</label>
                        <Field
                            name="lleva_porcentaje"
                            component={renderSwitch}
                        />
                    </div>

                    
                    <div className="form-group has-feedback row">
                        <label htmlFor="aparece_proyecto" className="col-4">¿Aparece en proyectos?</label>
                        <Field
                            name="aparece_proyecto"
                            component={renderSwitch}
                        />
                    </div>
                </div>
            </div>
            <div className="buttons-box">
                <Link
                    to="/fases-proyecto"
                    className="btn btn-secondary"
                    style={{ fontSize: 15 }}
                >
                    Regresar
                </Link>
                <button type="submit" style={{ fontSize: 15 }} className="btn btn-primary m-1 align-self-center">Guardar</button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'CrearEditarForm',
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
        });
    },

})(CrearEditarForm);

