import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import Swal from "sweetalert2";
import { api } from "api";
import { NotificationManager } from "react-notifications";

const LOADER = "PLANTILLA_PROYECTO_LOADER";
const DATA = "PLANTILLA_PROYECTO_DATA";
const SUBMIT = "PLANTILLA_PROYECTO_SUBMIT";
const FILTER = "PLANTILLA_PROYECTO_FILTER";

const ENDPOINT = "plantilla_proyecto";

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setFilter = (filter) => ({
    type: FILTER,
    filter,
});

// ------------------------------------
// Actions
// ------------------------------------
export const onSubmit =
    (data = {}) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        api.post(`${ENDPOINT}`, data)
            .then(() => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha creado correctamente",
                    type: "success",
                    showConfirmButton: false,
                    timer: 500,
                });
                dispatch(push("/plantillas_proyectos"));
            })
            .catch((error) => {
                const textError =
                    error && error.non_field_errors
                        ? error.non_field_errors[0]
                        : "Ha ocurrido un error, inténtelo nuevamente.";
                Swal.fire({
                    title: "Error",
                    text: textError,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const listar =
    (page = 1, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}`, { page, ...params })
            .then((response) => {
                dispatch(setData(response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const obtenerPlantillaProyecto =
    (id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}/${id}`)
            .then((response) => {
                response.complejidad = response.complejidad ? response.complejidad.id : null;
                dispatch(initializeForm("CrearEditarForm", response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const actualizarPlantillaProyecto =
    (data = {}, id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.put(`${ENDPOINT}/${id}`, data)
            .then(() => {
                NotificationManager.success("Cambios guardados", "Éxito");
            })
            .catch((error) => {
                const textError =
                    error && error.non_field_errors
                        ? error.non_field_errors[0]
                        : "Ha ocurrido un error, inténtelo nuevamente.";
                Swal.fire({
                    title: "Error",
                    text: textError,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${ENDPOINT}/${id}`)
        .then(() => {
            Swal.fire({
                title: "Éxito",
                text: "Se ha eliminado correctamente",
                type: "success",
            });
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};


export const filter =
    (params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}/filter_list`, params)
            .then((response) => {
                dispatch(setFilter(response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const actions = {
    onSubmit,
    eliminar,
    obtenerPlantillaProyecto,
    actualizarPlantillaProyecto,
    listar,
    filter
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [FILTER]: (state, { filter }) => {
        return {
            ...state,
            filter,
        };
    },
};

export const initialState = {
    loader: false,
    data: {},
    filter: [],
};

export default handleActions(reducers, initialState);
