import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/statistics/statistics';
import { actions as actionsAsuetos } from '../../../redux/modules/asuetos/asuetos';
import Home from './Home';


const ms2p = (state) => {
  return {
    ...state.statistics,
    holidays: state.asuetos.holidays,
    loaderHolidays: state.asuetos.loaderHolidays,
  };
};

const md2p = { ...actions, getHolidays: actionsAsuetos.getHolidays };

export default connect(ms2p, md2p)(Home);
