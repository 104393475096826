import React from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, formValueSelector, Field, FormSection } from "redux-form";
import { validate, validators } from "validate-redux-form";

import { PrincipalSection } from "./PrincipalSection";
import { FaseSection } from "./FaseSection";
import { validateFieldArray } from "../../Utils/Validators";
import { Historial } from "./Historial/Historial";
import { RechazoForm } from "./RechazoForm/RechazoForm";
import { AprobarForm } from "./AprobarForm/AprobarForm";
import { renderFieldRadio } from "../../Utils/renderField";

const GUARDADO = 1;
const RECLUTAMIENTO = 2;
const CONTRATADO = 3;
const RECHAZADO = 4;

const PROYECTO = 1;
const CONTRATO = 2;

const PLANILLA = "PLANILLA";

const CrearEditarForm = (props) => {
    const {
        handleSubmit,
        onSubmit,
        reclutamiento,
        contratado,
        rechazado,

        estadoValue,
        isDisabled = false,
        edit,
        proyectos,
        tipos_proyecto,
        clientes,
        paises,
        puestos,
        niveles_ingles,
        tipos_contrato,
        tipos_plaza,
        prioridades,
        skills,
        seniority,
        nivelesAcademicos,
        monedas,
        modalidades,
        skillsValue,
        change,
        array,
        clearFields,
        puestoValue,
        obtenerSeniority,

        initialized,
        pristine,

        historial,
        seniorityValue,
        contratoValue,
        modalidadValue,
        monedaValue,

        motivoLabel,
        descripcionRechazo,
        tipoAsignacionValue,

        isRRHH,
    } = props;

    const [openReject, setOpenReject] = React.useState(false);
    const closeRejectModal = () => setOpenReject(false);

    const [openApprove, setOpenApprove] = React.useState(false);
    const closeApproveModal = () => setOpenApprove(false);

    React.useEffect(() => {
        if (puestoValue) {
            if (!(initialized && pristine)) {
                change("seniority", null);
            }
            obtenerSeniority({ puesto: puestoValue });
        }
    }, [puestoValue]);

    // Si el tipo de contrato no es PROYECTO, no deben haber fases
    React.useEffect(() => {
        if (contratoValue !== PROYECTO) {
            array.removeAll("fases");
        }

        if (contratoValue === PROYECTO) {
            clearFields(false, false, "hora_entrada", "hora_salida");
            change("modalidad", "N/A");
        } else if (contratoValue === CONTRATO && modalidadValue === "N/A") {
            change("modalidad", null);
        }

        if (modalidadValue === PLANILLA) {
            clearFields(false, false, "duracion_contrato");
        }
    }, [contratoValue, modalidadValue]);

    //Obtener el rango salarial sugerido
    const rangoSugerido = React.useMemo(() => {
        if (seniorityValue) {
            const value = Number(seniorityValue);
            const current = seniority.find((s) => s.value === value);
            let range = "";
            if (current) {
                range = `Rango salarial sugerido Q ${current.salario_minimo} - Q ${current.salario_maximo}`;
            }
            return range;
        } else {
            return "";
        }
    }, [seniorityValue, seniority]);


    /* Obtener modalidades por tipo de contrato */
    const modalidadesContrato = React.useMemo(() => {
        if (contratoValue === 1)
            return modalidades.filter((m) => m.value === "N/A");

        return modalidades.filter((m) => m.value !== "N/A");
    }, [contratoValue]);

    const submitButtons = [
        /*
         * Crear una nueva solicitud o actiualizar una que esté guardada
         */
        {
            key: 1,
            label: "Guardar solicitud",
            show:
                !edit || estadoValue === GUARDADO || estadoValue === RECHAZADO,
            className: "btn-primary",
            handleClick: handleSubmit((data) => onSubmit(data)),
        },
        /*
         * Crear una nueva solicitud y enviarla a revisión inmediatamente
         */
        {
            key: 2,
            label: "Aperturar solicitud",
            show: !edit,
            className: "btn-success",
            handleClick: handleSubmit((data) => onSubmit(data, true)),
        },
        /*
         * Apreturar una solicitud que esté guardada.
         */
        {
            key: 3,
            label: "Aperturar solicitud",
            show: edit && estadoValue === GUARDADO,
            className: "btn-success",
            handleClick: handleSubmit(reclutamiento),
        },
        /*
         * Rechazar una solicitud que está en reclutamiento.
         */
        {
            key: 4,
            label: "Rechazar solicitud",
            show: edit && estadoValue === RECLUTAMIENTO && isRRHH,
            className: "btn-danger",
            handleClick: () => setOpenReject(true),
        },
        /*
         * Aprobar una solicitud que está en reclutamiento.
         */
        {
            key: 5,
            label: "Aprobar solicitud",
            show: edit && estadoValue === RECLUTAMIENTO && isRRHH,
            className: "btn-success",
            handleClick: () => {
                if (tipoAsignacionValue) {
                    setOpenApprove(true);
                }
            },
        },
    ];

    return (
        <form
            name="CrearEditarFormSolicitudPersonal"
            className="form-validate mb-lg form-disable"
        >
            {estadoValue === RECHAZADO ? (
                <div
                    style={{
                        maxWidth: "900px",
                    }}
                >
                    <h4 className="title--blue--1">Rechazado</h4>
                    <span>
                        <strong className="title--blue--1">Motivo:</strong>{" "}
                        {motivoLabel}
                    </span>
                    <p className="title--blue--1 m-0">Descripción:</p>
                    <p>{descripcionRechazo}</p>
                </div>
            ) : null}
            <PrincipalSection
                isDisabled={isDisabled}
                edit={edit}
                proyectos={proyectos}
                tipos_proyecto={tipos_proyecto}
                clientes={clientes}
                paises={paises}
                puestos={puestos}
                niveles_ingles={niveles_ingles}
                tipos_contrato={tipos_contrato}
                tipos_plaza={tipos_plaza}
                prioridades={prioridades}
                skills={skills}
                seniority={seniority}
                nivelesAcademicos={nivelesAcademicos}
                skillsValue={skillsValue}
                change={change}
                array={array}
                monedas={monedas}
                rangoSugerido={rangoSugerido}
                modalidadesContrato={modalidadesContrato}
                modalidadValue={modalidadValue}
                monedaValue={monedaValue}
                contratoValue={contratoValue}
            />
            {contratoValue === PROYECTO ? (
                <FaseSection
                    isDisabled={isDisabled}
                    monedaValue={monedaValue}
                />
            ) : null}

            {edit ? <Historial historial={historial} /> : null}

            {estadoValue === RECLUTAMIENTO && isRRHH ? (
                <div className="d-flex">
                    <h5 className="text--blue--1">Asignación de recurso</h5>
                    <div className="ml-4">
                        <Field
                            name="tipo_asignacion"
                            component={renderFieldRadio}
                            label="Interna"
                            value="1"
                            type="radio"
                        />
                    </div>
                    <div className="ml-4">
                        <Field
                            name="tipo_asignacion"
                            component={renderFieldRadio}
                            label="Externa"
                            value="2"
                            type="radio"
                        />
                    </div>
                </div>
            ) : null}

            <div className="row align-items-center justify-content-between p-3">
                <Link
                    to="/solicitud_personal"
                    className="btn btn-secondary col-2"
                    style={{ fontSize: "1rem", fontWeight: "700" }}
                >
                    Volver
                </Link>
                <div>
                    {submitButtons.map((btn) => {
                        if (!btn.show) return null;
                        return (
                            <button
                                className={`btn mx-2 px-4 ${btn.className}`}
                                key={btn.key}
                                onClick={btn.handleClick}
                                style={{ fontSize: "1rem", fontWeight: "700" }}
                                type="button"
                            >
                                {btn.label}
                            </button>
                        );
                    })}
                </div>
            </div>
            {estadoValue === RECLUTAMIENTO ? (
                <RechazoForm
                    open={openReject}
                    closeModal={closeRejectModal}
                    onSubmit={handleSubmit((values) => {
                        rechazado(values.form_rechazar);
                    })}
                />
            ) : null}

            {estadoValue === RECLUTAMIENTO ? (
                <AprobarForm
                    tipoAsignacion={tipoAsignacionValue}
                    open={openApprove}
                    closeModal={closeApproveModal}
                    puesto={puestoValue}
                    onSubmit={handleSubmit((values) => {
                        const { tipo_asignacion, recurso } = values;
                        contratado({ tipo_asignacion, recurso });
                    })}
                    contratado={contratado}
                />
            ) : null}
        </form>
    );
};

const validateSeniority = (seniorityOptions, formData) => {
    const errors = {};

    // Verificar si el campo seniority está presente en el formulario
    if ('seniority' in formData) {
        // Verificar si la lista de opciones de seniority está vacía
        if (!seniorityOptions || seniorityOptions.length === 0) {
            // Si la lista está vacía y no se ha seleccionado seniority, no se muestra ningún error
            return errors;
        }

        // Verificar si se ha seleccionado un seniority en el formulario
        const selectedSeniority = formData.seniority;
        if (selectedSeniority === null || selectedSeniority === undefined) {
            // Si no se ha seleccionado seniority, mostrar el mensaje de error
            errors.seniority = "Debe seleccionar un seniority";
        }
    }

    return errors;
};



const form = reduxForm({
    form: "CrearEditarFormSolicitudPersonal", // a unique identifier for this form
    validate: (data, props) => {
        const errors = validate(data, {
            proyecto: validators.exists()("Este campo es requerido"),
            puesto: validators.exists()("Este campo es requerido"),
            nivel_academico: validators.exists()("Este campo es requerido"),
            nivel_ingles: validators.exists()("Este campo es requerido"),
            tipo_contrato: validators.exists()("Este campo es requerido"),
            modalidad: validators.exists()("Este campo es requerido"),
            duracion_contrato: validators.exists()("Este campo es requerido"),
            tipo_plaza: validators.exists()("Este campo es requerido"),
            prioridad: validators.exists()("Este campo es requerido"),
            fecha_limite_contratacion: validators.exists()(
                "Este campo es requerido"
            ),
            hora_entrada: validators.exists()("Este campo es requerido"),
            hora_salida: validators.exists()("Este campo es requerido"),
            moneda: validators.exists()("Este campo es requerido"),
            descripcion: validators.length({ min: 1 })(
                "Este campo es requerido"
            ),
            atribuciones_puesto: validators.length({ min: 1 })(
                "Este campo es requerido"
            ),
            comentarios: validators.length({ min: 1 })(
                "Este campo es requerido"
            ),
            proceso_contratacion: validators.length({ min: 1 })(
                "Este campo es requerido"
            ),
            max_salario: validators.number({ min: 0 })(
                "Valor mayor o igual a 0 requerido"
            ),
            min_salario: validators.number({ min: 0 })(
                "Valor mayor o igual a 0 requerido"
            ),

            fases: [
                {
                    nombre: validators.exists()("Este campo es requerido"),
                    fase: validators.exists()("Este campo es requerido"),
                    porcentaje: validators.number({ min: 0, max: 100 })(
                        "Valor entre 0 y 100 requerido"
                    ),
                    pago: validators.number({ min: 0 })(
                        "Valor mayor o igual a 0 requerido"
                    ),
                },
            ],
            form_rechazar: {
                motivo_rechazo: validators.exists()("Este campo es requerido"),
                /* descripcion_rechazo: validators.length({ min: 1 })(
                    "Este campo es requerido"
                ), */
            },
        });

        if (data.modalidad === PLANILLA) {
            delete errors.duracion_contrato;
        }

        if (data.tipo_contrato === PROYECTO) {
            delete errors.hora_entrada;
            delete errors.hora_salida;
        }

        const seniorityErrors = validateSeniority(props.seniority, data);

        const fasesErrors = validateFieldArray({
            data,
            fieldname: "fases",
            min: 1,
            message: "No hay fases",
        });

        return { ...errors, ...seniorityErrors, ...fasesErrors };
    },
})(CrearEditarForm);

const selector = formValueSelector("CrearEditarFormSolicitudPersonal");
export default connect((state) => {
    const puestoValue = selector(state, "puesto");
    const skillsValue = selector(state, "skills") || [];
    const estadoValue = selector(state, "estado") || null;
    const isDisabled =
        estadoValue === RECLUTAMIENTO || estadoValue === CONTRATADO;
    const contratoValue = selector(state, "tipo_contrato");
    const historial = selector(state, "historial");
    const seniorityValue = selector(state, "seniority") || null;
    const motivoLabel = selector(state, "motivo_rechazo");
    const descripcionRechazo = selector(state, "descripcion_rechazo");
    const tipoAsignacionValue = selector(state, "tipo_asignacion") || null;
    const modalidadValue = selector(state, "modalidad");
    const monedaValue = selector(state, "moneda");

    return {
        puestoValue,
        skillsValue,
        isDisabled,
        estadoValue,
        historial,
        seniorityValue,
        motivoLabel,
        descripcionRechazo,
        tipoAsignacionValue,
        contratoValue,
        modalidadValue,
        monedaValue,
    };
})(form);
