import React from "react";
import { Field } from "redux-form";

const RenderRow = ({ fields }) => {
    return (
        <div className="row mb-4">
            {fields.map((field) => {
                return (
                    <div
                        className={`form-group has-feedback ${
                            field.className ? field.className : "col-12"
                        }`}
                        key={field.label}
                        style={field.styleContainer ? field.styleContainer : {}}
                    >
                        <label htmlFor={field.props.name}>{field.label}</label>
                        <Field {...field.props} />
                    </div>
                );
            })}
        </div>
    );
};

export { RenderRow };
