import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/plantillas_proyectos/plantillas_proyectos";
import { actions as actionsComplejidad } from "../../../../redux/modules/complejidad_proyectos/complejidad_proyectos";
import { actions as actionsActividades } from "../../../../redux/modules/actividades_entrega/actividades_entrega";
import { actions as actionsFasesProyecto } from "../../../../redux/modules/fases_proyecto/fases_proyecto";
import { actions as actionsPuestos } from "../../../../redux/modules/puestos/puestos";
import CrearEditar from "./CrearEditar";

const ms2p = (state) => {
    return {
        ...state.plantillas_proyectos,
        complejidades: state.complejidad_proyectos.select,
        loaderComplejidades: state.complejidad_proyectos.loader,
        actividades: state.actividades_entrega.filter,
        loaderActividades: state.actividades_entrega.loader,
        fases: state.fases_proyecto.filter,
        loaderFases: state.fases_proyecto.loader,
        puestos: state.puestos.filterAll,
        loaderPuestos: state.puestos.loader,
    };
};

const md2p = {
    ...actions,
    obtenerComplejidades: actionsComplejidad.obtenerSelectList,
    obtenerActividades: actionsActividades.obtenerFilterList,
    obtenerFases: actionsFasesProyecto.obtenerFilterList,
    obtenerPuestos: actionsPuestos.filterAll,
};

export default connect(ms2p, md2p)(CrearEditar);
