import LoadMask from "Utils/LoadMask/LoadMask";
import CrearEditarForm from "./CrearEditarForm";
import React, { Component } from "react";

class CrearEditar extends Component {
    state = {
        edit: false,
        loader: true,
        id: null,
    };

    async componentDidMount() {
        this.props.obtenerFases();
        this.props.obtenerComplejidades();
        this.props.obtenerActividades();
        this.props.obtenerPuestos();
        this.setState({ loader: false });
        const edit = this.props.match.params.id ? true : false;
        this.setState({ edit });
        if (edit == true) {
            const id = this.props.match.params.id;
            this.setState({ id });
            this.props.obtenerPlantillaProyecto(id);
        }
    }

    onSubmit = (data) => {
        const { edit, id } = this.state;
        const { onSubmit, actualizarPlantillaProyecto } = this.props;
        if (edit) {
            actualizarPlantillaProyecto(data, id);
        } else {
            onSubmit(data);
        }
    };

    render() {
        const {
            loader,
            complejidades,
            loaderComplejidades,
            loaderActividades,
            actividades,
            fases,
            loaderFases,
            puestos,
            loaderPuestos,
        } = this.props;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h2 className="title--blue">
                            {this.state.edit ? "EDITAR" : "CREAR"} PLANTILLA DE
                            PROYECTO
                        </h2>
                    </div>
                </div>
                <hr />
                <div className="login-wrapper">
                    <div className="card card-login w-100">
                        <LoadMask
                            loading={
                                loaderPuestos ||
                                loaderFases ||
                                loaderActividades ||
                                loaderComplejidades ||
                                loader ||
                                this.state.loader
                            }
                            light
                        >
                            <CrearEditarForm
                                onSubmit={this.onSubmit}
                                complejidades={complejidades}
                                actividades={actividades}
                                fases={fases}
                                puestos={puestos}
                            />
                        </LoadMask>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CrearEditar;
