import LoadMask from "Utils/LoadMask/LoadMask";
import CrearEditarForm from "./CrearEditarForm";
import React, { Component } from "react";
import { api } from "api";

import { SELECT_LIST as selectListDepartamentos } from "redux/modules/departamento_organizacion/departamento_organizacion";

class CrearEditar extends Component {
    state = {
        edit: false,
        loader: true,
        id: null,
        departamentos: [],
    };

    async getDepartamentos() {
        try {
            this.setState({ loader: true });
            const response = await api.get(selectListDepartamentos);
            this.setState({ departamentos: response });
        } catch (error) {
            this.setState({ departamentos: [] });
        } finally {
            this.setState({ loader: false });
        }
    }

    componentDidMount() {
        this.getDepartamentos();
        const edit = this.props.match.params.id ? true : false;
        this.setState({ edit });
        if (edit == true) {
            const id = this.props.match.params.id;
            this.setState({ id });
            this.props.obtenerPuesto(id);
        }
    }

    onSubmit = (data) => {
        const { edit, id } = this.state;
        const { onSubmit, actualizarPuesto } = this.props;
        if (edit) {
            actualizarPuesto(data, id);
        } else {
            onSubmit(data);
        }
    };

    render() {
        const { loader } = this.props;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h2 className="title--blue">
                            {this.state.edit ? "EDITAR" : "CREAR"} PUESTO
                        </h2>
                    </div>
                </div>
                <hr />
                <div className="login-wrapper">
                    <div className="card card-login w-100">
                        <LoadMask loading={loader || this.state.loader} light>
                            <CrearEditarForm
                                onSubmit={this.onSubmit}
                                departamentos={this.state.departamentos}
                            />
                        </LoadMask>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CrearEditar;
