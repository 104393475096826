import React from "react";
import Popup from "reactjs-popup";
import { Field, FormSection } from "redux-form";

import LoadMask from "Utils/LoadMask/LoadMask";
import {
    renderSelectField,
    renderTextAreaWithStyle,
} from "../../../Utils/renderField";
import { MOTIVOS_RECHAZO as motivosRechazoUrl} from "../../../../../redux/modules/solicitud_personal/solicitud_personal";

import { api } from "api";


const FORM_NAME = "form_rechazar";

export const RechazoForm = ({ open, closeModal, onSubmit }) => {
    // Obtener los motivos de rechazo
    const [motivos, setMotivos] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const obtenerMotivos = async () => {
        try {
            setLoading(true);
            const motivosResponse = await api.get(motivosRechazoUrl);
            setMotivos(motivosResponse);
        } catch (error) {
            setMotivos([]);
        }finally{
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (open) {
            obtenerMotivos();
        }
    }, [open]);

    return (
        <Popup
            open={open}
            modal
            closeOnDocumentClick={false}
            className="modal-form"
            onClose={closeModal}
        >
            <FormSection name={FORM_NAME}>
                <LoadMask loading={loading} light>
                    <div className="d-flex aling-items-center justify-content-between mb-3">
                        <h4 className="title--blue--1">RECHAZAR SOLICITUD</h4>
                        <button
                            className="btn"
                            onClick={closeModal}
                            type="button"
                        >
                            <i
                                className="material-icons"
                                style={{ fontSize: "1rem", color: "#CCCCCC" }}
                            >
                                close
                            </i>
                        </button>
                    </div>
                    <div className="row">
                        <div className="form-group has-feedback col-12">
                            <label htmlFor="">Motivo de rechazo</label>
                            <Field
                                name="motivo_rechazo"
                                component={renderSelectField}
                                select_style={{
                                    control: (styles) => ({
                                        ...styles,
                                        color: "#1F4D7B",
                                        borderColor: "#1F4D7B",
                                    }),
                                }}
                                options={motivos}
                                labelKey="label"
                                valueKey="value"
                                className="form-group"
                                placeholder="Motivo..."
                            />
                        </div>
                        <div className="form-group has-feedback col-12">
                            <label htmlFor="descripcion_rechazo">
                                Comentarios
                            </label>
                            <Field
                                name="descripcion_rechazo"
                                component={renderTextAreaWithStyle}
                                className="input--style"
                                placeholder="Descripción..."
                                rows={5}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                        <button
                            className="col-5 btn btn-secondary mx-2 px-4"
                            onClick={closeModal}
                            type="button"
                            style={{ fontSize: "1rem", fontWeight: "700" }}
                        >
                            Volver
                        </button>
                        <button
                            className="col-5 btn btn-danger mx-2 px-4"
                            onClick={onSubmit}
                            type="button"
                            style={{ fontSize: "1rem", fontWeight: "700" }}
                        >
                            Rechazar solicitud
                        </button>
                    </div>
                </LoadMask>
            </FormSection>
        </Popup>
    );
};
