import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/complejidad_proyectos/complejidad_proyectos';
import CrearEditar from './CrearEditar';
import { Modal } from '../../Utils/Modal';

const ms2p = (state) => {
  return {
    ...state.complejidad_proyectos,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearEditar);
