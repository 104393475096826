import React from "react";
import { FieldArray } from "redux-form";
import { RenderFases } from "./RenderFases";

export const FaseSection = ({ isDisabled = false , monedaValue}) => {
    return (
        <React.Fragment>
            <h5 className="text--blue--1 my-4">Fase</h5>
            <div className="card card-login">
                <div className="row">
                    <FieldArray
                        name="fases"
                        component={RenderFases}
                        isDisabled={isDisabled}
                        monedaValue={monedaValue}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};
