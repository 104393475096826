import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import {
    initialize as initializeForm,
    destroy as destroyForm,
    change as changeFormField,
} from "redux-form";
import Swal from "sweetalert2";
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LOADER = "PROYECTO_OPERACIONES_LOADER";
const DATA = "PROYECTO_OPERACIONES_DATA";
const SUBMIT = "PROYECTO_OPERACIONES_SUBMIT";
const PLANTILLA = "PROYECTO_OPERACIONES_PLANTILLA";
const DRAFT = "PROYECTO_OPERACIONES_DRAFT";

const ENDPOINT = "proyecto_operaciones";

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setCurrentPlantilla = (currentPlantilla) => ({
    type: PLANTILLA,
    currentPlantilla,
});

export const setDraft = (draft) => ({
    type: DRAFT,
    draft,
});

// ------------------------------------
// Actions
// ------------------------------------
export const onSubmit =
    (data = {}, revision = false) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const endpoint = revision ? `${ENDPOINT}/create_send_to_check` : ENDPOINT;
        api.post(endpoint, data)
            .then(() => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha guardado correctamente",
                    type: "success",
                    showConfirmButton: false,
                    timer: 500,
                });
                dispatch(setDraft(null));
                dispatch(destroyForm("ProyectoOperacionesForm"));
                dispatch(push("/proyectos_operaciones"));
            })
            .catch((error) => {
                let textError =
                    error && error.non_field_errors
                        ? error.non_field_errors[0]
                        : "Ha ocurrido un error, inténtelo nuevamente.";

                if (error) {
                    textError = Object.entries(error).reduce(
                        (textError, currentError) => {
                            return textError + currentError[0] + ": "+ currentError[1].join(". ") + "\n";
                        },
                        ""
                    );
                }
                Swal.fire({
                    title: "Error",
                    text: textError,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const listar =
    (page = 1, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}`, { page, ...params })
            .then((response) => {
                dispatch(setData(response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const obtenerProyectoOperaciones =
    (id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}/${id}`)
            .then((response) => {
                response.cliente = response.cliente
                    ? response.cliente.id
                    : null;
                response.plantilla = response.plantilla
                    ? response.plantilla.id
                    : null;

                if(response.archivos){
                    response.archivos = response.archivos.map(archivo => ({
                        id: archivo.id,
                        photo: archivo.archivo,
                    }));
                }
                dispatch(initializeForm("ProyectoOperacionesForm", response));
                obtenerPlantilla(response.plantilla, false)(dispatch);
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const actualizarProyecto =
    (data = {}, id = null) =>
    async (dispatch) => {
        dispatch(setLoader(true));
        let correct = false;
        try {
            await api.patch(`${ENDPOINT}/${id}`, data);
            NotificationManager.success("Cambios guardados", "Actualizado", 1000);
            correct = true;
        } catch (error) {
            correct = false;
            let textError =
            error && error.non_field_errors
                ? error.non_field_errors[0]
                : "Ha ocurrido un error, inténtelo nuevamente.";

            if (error) {
                textError = Object.values(error).reduce(
                    (textError, currentError) => {
                        return textError + currentError.join(". ") + "\n";
                    },
                    ""
                );
            }

            Swal.fire({
                title: "Error",
                text: textError,
                type: "error",
            });
        }
        finally{
            dispatch(setLoader(false));
        }

        return correct;
    };

export const actualizarProyectoOperaciones =
    (data = {}, id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.put(`${ENDPOINT}/${id}`, data)
            .then(() => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha actualizado correctamente",
                    type: "success",
                    showConfirmButton: false,
                    timer: 500,
                });
                dispatch(destroyForm("ProyectoOperacionesForm"));
                dispatch(push("/proyectos_operaciones"));
            })
            .catch((error) => {
                let textError =
                    error && error.non_field_errors
                        ? error.non_field_errors[0]
                        : "Ha ocurrido un error, inténtelo nuevamente.";

                if (error) {
                    textError = Object.values(error).reduce(
                        (textError, currentError) => {
                            return textError + currentError.join(". ") + "\n";
                        },
                        ""
                    );
                }

                Swal.fire({
                    title: "Error",
                    text: textError,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };


export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${ENDPOINT}/${id}`).then(() => {
        Swal.fire({
            title: 'Éxito',
            text: 'Se ha eliminado correctamente',
            type: 'success',
        })
    })
        .catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};



//se utiliza el endpoint junto con el metodo enviarAOperaciones para enviar proyecto a operaciones 
export const enviarAOperaciones = (id, params = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${ENDPOINT}/${id}/enviarAOperaciones`) 
        .then((response) => {
            Swal.fire({
                title: "Éxito",
                text: "Se ha enviado el proyecto a operaciones correctamente",
                type: "success",
            });

        })
        .catch(() => {
            Swal.fire({
                title: "Error",
                text: "El proyecto ya fue enviado a operaciones",
                type: "error",
            });
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const limpiarForm = () => (dispatch) => {
    dispatch(setCurrentPlantilla(null));
    dispatch(initializeForm("ProyectoOperacionesForm", {}));
};

export const obtenerPlantilla =
    (id = null, setValues = true) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`plantilla_proyecto/${id}`)
            .then((response) => {
                if (setValues){
                    const actividades = response.requisitos_actividades.map(
                        (actividad) => {
                            return {
                                ...actividad,
                                total_horas: actividad.horas,
                                de_plantilla: true,
                            };
                        }
                    );

                    const personal = response.fases_puestos.map((puesto) => ({
                        ...puesto,
                        de_plantilla: true,
                        cantidad: 1,
                    }));

                    dispatch(
                        changeFormField(
                            "ProyectoOperacionesForm",
                            "actividades",
                            actividades
                        )
                    );

                    dispatch(
                        changeFormField(
                            "ProyectoOperacionesForm",
                            "personal",
                            personal
                        )
                    );
                }
                dispatch(setCurrentPlantilla(response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };


const updateDraft = (draft) => (dispatch)  => {
    dispatch(setDraft(draft));
}

const clearDraft = () => (dispatch)  => {
    dispatch(setDraft(null));
}

const loadFromDraft = (draft) => (dispatch) => {
    if(draft && draft.plantilla){
        obtenerPlantilla(draft.plantilla, false)(dispatch);
        dispatch(initializeForm("ProyectoOperacionesForm", draft));
    }
}

const aprobarProyecto = (id = null) => async (dispatch) => {
    dispatch(setLoader(true));
    try {
        await api.put(`${ENDPOINT}/${id}/approve`,);
        Swal.fire({
            title: "Éxito",
            text: "Se ha aprobado el proyecto",
            type: "success",
            showConfirmButton: false,
            timer: 500,
        });
        dispatch(push("/proyectos_operaciones"));
    } catch (error) {
        let textError =
        error && error.non_field_errors
            ? error.non_field_errors[0]
            : "Ha ocurrido un error, inténtelo nuevamente.";

        if (error) {
            textError = Object.values(error).reduce(
                (textError, currentError) => {
                    return textError + currentError.join(". ") + "\n";
                },
                ""
            );
        }

        Swal.fire({
            title: "Error",
            text: textError,
            type: "error",
        });
    }
    finally{
        dispatch(setLoader(false));
    }
}

const enviarRevision = (id = null) => async (dispatch) => {
    dispatch(setLoader(true));
    try {
        await api.put(`${ENDPOINT}/${id}/update_send_to_check`,);
        Swal.fire({
            title: "Éxito",
            text: "Se ha enviado a revisión",
            type: "success",
            showConfirmButton: false,
            timer: 500,
        });
        dispatch(push("/proyectos_operaciones"));
    } catch (error) {
        let textError =
        error && error.non_field_errors
            ? error.non_field_errors[0]
            : "Ha ocurrido un error, inténtelo nuevamente.";

        if (error) {
            textError = Object.values(error).reduce(
                (textError, currentError) => {
                    return textError + currentError.join(". ") + "\n";
                },
                ""
            );
        }

        Swal.fire({
            title: "Error",
            text: textError,
            type: "error",
        });
    }
    finally{
        dispatch(setLoader(false));
    }
}


const ventaGanadaPerdida = (id = null, ganada = true) => async (dispatch) => {
    dispatch(setLoader(true));
    try {
        const endpoint = ganada
            ? `${ENDPOINT}/${id}/won_sale`
            : `${ENDPOINT}/${id}/lost_sale`;

        await api.put(endpoint);
        Swal.fire({
            title: "Éxito",
            text: ganada ? "Venta ganada" : "Venta perdida",
            type: ganada ? "success" : "info",
            showConfirmButton: false,
            timer: 500,
        });
        dispatch(push("/proyectos_operaciones"));
    } catch (error) {
        let textError =
        error && error.non_field_errors
            ? error.non_field_errors[0]
            : "Ha ocurrido un error, inténtelo nuevamente.";

        if (error) {
            textError = Object.values(error).reduce(
                (textError, currentError) => {
                    return textError + currentError.join(". ") + "\n";
                },
                ""
            );
        }

        Swal.fire({
            title: "Error",
            text: textError,
            type: "error",
        });
    }
    finally{
        dispatch(setLoader(false));
    }
}


export const actions = {
    onSubmit,
    eliminar,
    obtenerProyectoOperaciones,
    actualizarProyectoOperaciones,
    enviarAOperaciones,
    listar,
    limpiarForm,
    obtenerPlantilla,
    updateDraft,
    clearDraft,
    loadFromDraft,
    actualizarProyecto,
    aprobarProyecto,
    enviarRevision,
    ventaGanadaPerdida,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PLANTILLA]: (state, { currentPlantilla }) => {
        return {
            ...state,
            currentPlantilla,
        };
    },
    [DRAFT]: (state, { draft }) => {
        return {
            ...state,
            draft,
        };
    },
};

export const initialState = {
    loader: false,
    data: {},
    currentPlantilla: null,
    draft: null,
};

export default handleActions(reducers, initialState);
