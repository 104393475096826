import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logOut, getMe, getRole } from "./redux/modules/cuenta/login";

// maquetado base
import SideBar2 from "./common/components/layout/Sidebar/SideBar2"
import SideBar from "./common/components/layout/Sidebar/SideBar";
import Footer from "./common/components/layout/Footer/Footer";

import Navbar from "./common/components/layout/Navbar/Navbar";
import { VerifyLogin } from "./common/components/layout";

class PrivateRouteBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleOpen: true,
        };
    }

    navToggle = () => {
        this.setState({ toggleOpen: !this.state.toggleOpen });
    };

    isAuthenticated = () => {
        const token = localStorage.getItem("token");
        const {
            getMe,
            login: { me },
        } = this.props;
        if (!!token && !!me.username) {
            return true;
        } else if (token) {
            getMe();
            return "Verifying";
        }
        return false;
    };

    roleVerified = () => {
        const role = localStorage.getItem('role');
        if(role) {
            if(this.props.access == 0) {
                return true;
            }
            for(let i = 0; i < this.props.access.length; i++) {
                if(this.props.access[i] == role) {
                    return true;
                }
            }
            return false;
        }
        return false;
    };

    render() {
        const {
            component: Component,
            logOut,
            login: { me },
            ...rest
        } = this.props;
        const isAuthenticated = this.isAuthenticated();
        const role_verified = this.roleVerified();
        return (
            <Route
                {...rest}
                render={(props) =>
                    isAuthenticated ? (
                        isAuthenticated === true ? (
                            role_verified ? (
                                <div>
                                    <SideBar2
                                        toggleOpen={this.state.toggleOpen}
                                        navToggle={this.navToggle}
                                        logOut={logOut}
                                    />
                                    <main className="main-content p-0 col-sm-12 col-md-9 offset-md-3 col-lg-10 offset-lg-2">
                                        <div className="main-navbar bg-white sticky-top">
                                            <div className="p-0 container">
                                                <Navbar
                                                    navToggle={this.navToggle}
                                                    logOut={logOut}
                                                    user={me}
                                                />
                                            </div>
                                        </div>
                                        <div className="main-content-container px-4 container-fluid">
                                            <Component {...props} />
                                        </div>
                                        <Footer />
                                    </main>
                                </div>
                            ) : (
                                <Redirect
                                    to={{
                                        pathname: "/",
                                    }}
                                />
                            )
                        ) : (
                            <VerifyLogin />
                        )
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location },
                            }}
                        />
                    )
                }
            />
        );
    }
}

const mstp = (state) => ({ ...state });

const mdtp = { logOut, getMe };

const ProtectedRoute = connect(mstp, mdtp)(PrivateRouteBase);

export default ProtectedRoute;
