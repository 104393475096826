import React, { useState } from "react";
import { reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import Popup from "reactjs-popup";
import Eliminar from "./Eliminar";
import "reactjs-popup/dist/index.css";

import DatosBasicosForm from "./DatosBasicosForm";
import UbicacionSaludForm from "./UbicacionSaludForm";
import DatosPersonalesForm from "./DatosPersonalesForm";
import CurriculumForm from "./CurriculumForm";

const PASOS_FORMULARIO = [
    "DATOS PERSONALES",
    "DATOS DE CONTRATACION",
    "DATOS PROFESIONALES",
    "DOCUMENTOS DIGITALES",
];

const CrearEditarForm = (props) => {
    const {
        fromModalCreate = false,
        puestos,
        tipos_proyecto,
        tipos_contratacion,
        departamentos_organizacion,
        departamentos_guatemala,
        edit,
        renunciaDespido,
        id,
        onSubmit,
        skills,
        tipos_contrato,
        seniority,
        obtenerSeniority,
        nivelesIngles,
        obtenerPuestos,
    } = props;

    // Manejor de pasos en el llenado del formulario
    const [step, _setStep] = useState(1);

    const nextStep = () => {
        _setStep(step + 1);
    };
    const prevStep = () => {
        _setStep(step - 1);
    };

    const [popup_opened, setPopupOpened] = useState(false);
    const select_style = {
        control: (styles) => ({
            ...styles,
            color: "#1F4D7B",
            borderColor: "#1F4D7B",
        }),
    };

    // Elegir el formulario que se va a renderizar
    /*
     * Para agregar más formularios es necesario agregar la actualización
     * en los actions onSubmit y obtenerRecurso
     */
    const changePage = () => {
        switch (step) {
            case 1:
                return (
                    <DatosBasicosForm
                        fromModalCreate={fromModalCreate}
                        edit={edit}
                        setPopupOpened={setPopupOpened}
                        select_style={select_style}
                        onSubmit={nextStep}
                        skills={skills}
                    />
                );
            case 2:
                return (
                    <UbicacionSaludForm
                        departamentos_guatemala={departamentos_guatemala}
                        select_style={select_style}
                        prevStep={prevStep}
                        onSubmit={nextStep}
                        edit={edit}
                        puestos={puestos}
                        tipos_proyecto={tipos_proyecto}
                        tipos_contratacion={tipos_contratacion}
                        departamentos_organizacion={departamentos_organizacion}
                        tipos_contrato={tipos_contrato}
                        seniority={seniority}
                        obtenerSeniority={obtenerSeniority}
                        obtenerPuestos={obtenerPuestos}
                    />
                );
            case 3:
                return (
                    <DatosPersonalesForm
                        prevStep={prevStep}
                        onSubmit={nextStep}
                        select_style={select_style}
                        edit={edit}
                        nivelesIngles={nivelesIngles}
                    />
                );
            case 4:
                return (
                    <CurriculumForm
                        prevStep={prevStep}
                        onSubmit={onSubmit}
                        edit={edit}
                    />
                );
            default:
                return null;
        }
    };


    return (
        <React.Fragment>
            <Popup
                open={popup_opened}
                modal
                closeOnEscape={false}
                closeOnDocumentClick={false}
            >
                <Eliminar
                    id={id}
                    renunciaDespido={renunciaDespido}
                    close={setPopupOpened}
                />
            </Popup>
            <div className="step-progress-bar">
                <p>
                    <span>{`Paso ${step} de ${PASOS_FORMULARIO.length}`}</span>
                    {` / ${PASOS_FORMULARIO[step - 1]}`}
                </p>
                <div className="progress-bar-form">
                    <div
                        style={{
                            width:
                                step === 1
                                    ? "25%"
                                    : step === 2
                                    ? "50%"
                                    : step === 3
                                    ? "75%"
                                    : "100%",
                        }}
                    />
                </div>
            </div>
            {changePage()}
        </React.Fragment>
    );
};

/* export default reduxForm({
    form: "CrearEditarForm", // a unique identifier for this  form
    validate: (data) => {
        return validate(data, {
            codigo: validators.exists()("Este campo es requerido"),
            nombre: validators.exists()("Este campo es requerido"),
            email: validators.exists()("Este campo es requerido"),
            tipo_contratacion: validators.exists()("Este campo es requerido"),
            puesto: validators.exists()("Este campo es requerido"),
            proyecto_default: validators.exists()("Este campo es requerido"),
            fecha_inicio: validators.exists()("Este campo es requerido"),
        });
    },
})(CrearEditarForm); */

export default CrearEditarForm;
