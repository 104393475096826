import React from "react";
import { FieldArray, Field } from "redux-form";
import { renderField, renderNumber } from "../../../Utils/renderField";

import { renderFases } from "./renderFases";
import { renderPorcentajes } from "./renderPorcentajes";

const FasesProyecto = (props) => {
    const { fases, puestos } = props;
    /* const fases = React.useMemo(() => {
        return [
            { nombre: "Backlog", id: 1 },
            { nombre: "Desarrollo", id: 2 },
            { nombre: "QA", id: 3 },
            { nombre: "Entrega", id: 4 },
        ];
    }, []); */

    const [faseSelected, setFaseSelected] = React.useState(
        fases[0] ? fases[0].id : 1
    );

    const handleSelectChange = (e) => {
        setFaseSelected(parseInt(e.target.value));
    };

    return (
        <div className="mt-3">
            <h5 className="title--blue--1">Fases de proyecto</h5>
            <hr />
            <div className="d-flex flex-column mt-4 justify-content-center align-items-center">
                <div className="col-12 display--fieldset">
                    {fases.map((f) => (
                        <label className="container--1" key={f.id}>
                            {f.nombre}
                            <input
                                type="radio"
                                value={f.id}
                                name="fases_proyecto_choice"
                                className="input--style"
                                onChange={handleSelectChange}
                                checked={faseSelected === f.id}
                            />
                        </label>
                    ))}
                </div>
                <FieldArray
                    name="porcentajes"
                    component={renderPorcentajes}
                    tipoFase={faseSelected}
                    fases={fases}
                />
                <FieldArray
                    name="fases_puestos"
                    component={renderFases}
                    tipoFase={faseSelected}
                    puestos={puestos}
                />
            </div>
        </div>
    );
};

export { FasesProyecto };
