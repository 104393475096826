import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/recursos/recursos';
import { actions as user } from '../../../../redux/modules/cuenta/login';
import { actions as clientes } from '../../../../redux/modules/clientes/clientes';
import { actions as proyectos } from '../../../../redux/modules/proyectos/proyectos';
import { actions as puestos } from '../../../../redux/modules/puestos/puestos'; 
import Listar from './Listar';

const ms2p = (state) => {
  return {
    ...state.recursos,
    user: { ...state.login },
    clientes: { ...state.clientes },
    proyectos: { ...state.proyectos },
    puestos: { ...state.puestos },
  };
};

const md2p = { ...actions, getRole: user.getRole, getClientes: clientes.listAll, getProyectos: proyectos.listAll, getPuestos: puestos.filterAll };

export default connect(ms2p, md2p)(Listar);