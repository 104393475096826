/*
 *  Valida que el arreglo tenga un mínimo de campos
 *  data: El objeto en donde encontrar el arreglo
 *  fieldname: Nombre del arreglo
 *  min: Mínimo de elementos que debe de tener el arreglo
 *  message: mensaje de error
 */
export const validateFieldArray = ({ data, fieldname, min = 1, message }) => {
    const errors = {};
    if (!data[fieldname] || data[fieldname].length < min)
        errors[fieldname] = {
            _error: message,
        };
    return errors;
};

/*
 * Valida que no exista un campo en específico repetido
 *  data: El objeto en donde encontrar el arreglo
 *  fieldname: Nombre del arreglo
 *  min: Mínimo de elementos que debe de tener el arreglo
 *  message: mensaje de error
 *  fields: Un arreglo con los nombres de los campos cuyas combinaciones deben de ser únicas
 */
export const validateArrayNoRepeatedField = ({
    data,
    fieldname,
    min = 1,
    message = "",
    emptyMessage = "",
    fields = [],
}) => {
    const error = validateFieldArray({
        data,
        fieldname,
        min,
        message: emptyMessage,
    });
    if (Object.keys(error).length > 0) return error;
    const fieldArray = data[fieldname];
    const repeatedElements = {};
    const errors = {};

    fieldArray.forEach((f) => {
        const key = fields.length > 0 ? getKey(f, fields) : f;
        if (!repeatedElements[key]) {
            repeatedElements[key] = 1;
        } else {
            errors[fieldname] = {
                _error: message,
            };
        }
    });
    return errors;
};

/*
 * Obtiene la key de un elemento combinando todos los campos
 * obj: Es el objeto de donde se obtienen los campos
 * fields: Los campos para crear la key
 */
const getKey = (obj, fields) => {
    let invalid = false;
    let key = "";
    fields.forEach((f) => {
        if (obj[f] === undefined || obj[f] === null) {
            invalid = true;
            return;
        }
        key += `${obj[f]}`;
    });
    return invalid ? false : key;
};

/*
 * Valida que no exista un campo en específico repetido
 *  data: El objeto en donde encontrar el arreglo
 *  fieldname: Nombre del arreglo
 *  min: Mínimo de elementos que debe de tener el arreglo
 *  message: mensaje de error
 *  fields: Un arreglo con los nombres de los campos cuyas combinaciones deben de ser únicas
 */
export const validatePersonalFases = ({
    data,
    fieldname,
    min = 1,
    emptyMessage = "",
    message = "",
    fields = [],
    fase = "fase_label",
}) => {
    const error = validateFieldArray({
        data,
        fieldname,
        min,
        message: emptyMessage,
    });
    if (Object.keys(error).length > 0 || fields.length < 1) return error;

    const errors = {};
    const fieldArray = data[fieldname];
    const repeatedElements = {};
    fieldArray.forEach((f) => {
        const key = getKey(f, fields);
        if (!key) {
            return;
        } else if (!repeatedElements[key]) {
            repeatedElements[key] = {
                name: f[fase],
                count: 1,
            };
        } else {
            repeatedElements[key].count++;
        }
    });

    const repeated = Object.values(repeatedElements)
        .filter((r) => r.count > 1)
        .map((r) => r.name);

    if (repeated.length > 0) {
        errors[fieldname] = {
            _error: `${message} ${repeated.join(", ")}.`,
        };
    }

    return errors;
};
