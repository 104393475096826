import React from "react";

import { renderSelectField, renderNumber } from "../../Utils/renderField";

import { Field } from "redux-form";

export const renderSkills = ({
    fields,
    meta: { error },
    skills,
    select_style,
}) => {
    return (
        <div>
            <div>
                <button
                    type="button"
                    className="btn btn-dark p-1 mb-3"
                    onClick={() => fields.push({})}
                >
                    <i className="material-icons">add</i>
                </button>
            </div>
            {fields.length > 0 && (
                <div className="row d-flex">
                    <span className="text--blue--1 pl-4 mr-5">Skill</span>
                    <span className="text--blue--1 pl-5 ml-5">Años</span>
                </div>
            )}
            <span className="text-danger">{error}</span>
            {fields.map((skill, index) => (
                <div className="d-flex" key={index}>
                    <div className="form-group has-feedback col-4 m-0 mb-1">
                        <Field
                            name={`${skill}.skill`}
                            select_style={select_style}
                            component={renderSelectField}
                            label={`skill #${index + 1}`}
                            options={skills}
                            labelKey="label"
                            valueKey="value"
                            className="form-group"
                            placeholder="Skill..."
                            hideSelectedOptions={true}
                        />
                    </div>
                    <div className="form-group has-feedback col-4 m-0 p-0">
                        <Field
                            name={`${skill}.experiencia`}
                            type="text"
                            component={renderNumber}
                            suffix=" años"
                            label={`Experiencia`}
                            placeholder="Años..."
                        />
                    </div>
                    <div className="form-group has-feedback col-2 m-0 p-0">
                        <button
                            className="btn btn-danger p-0 ml-3"
                            type="button"
                            onClick={() => fields.remove(index)}
                        >
                            <i className="material-icons">delete</i>
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};
