import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import { Link, Route } from "react-router-dom";
import { CrearEditar } from "../CrearEditar";
import Popup from "reactjs-popup";

class Listar extends Component {
    state = {
        page: 1,
        search: "",
        BreakLine: {
            whiteSpace: "normal",
        },
        params: {
            activo: true,
        },
    };

    componentDidMount() {
        this.props.listar(this.state.page, this.state.params);
    }

    componentDidUpdate(prevProps) {
        if(
            prevProps.location.pathname !== this.props.location.pathname &&
            prevProps.location.pathname !== this.props.match.url
        ){
            this.props.listar();
        }
    }

    onPageChange = (page) => {
        this.props.listar(page, this.state.params);
        this.setState({ page });
    };

    render() {
        const { data, loader, eliminar } = this.props;
        return (
            <React.Fragment>
                <div className="flex row mt-2">
                    <div className="col-12">
                        <h2 className="title--blue">
                            LISTA DE ACTIVIDADES DE ENTREGA
                        </h2>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div
                        className="col-12 col-md-6"
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                    >
                        <input
                            placeholder="Buscar..."
                            type="text"
                            className="form-control mt-4 input--style"
                            value={this.state.search}
                            onKeyDown={(key) => {
                                if (key.key == "Enter") {
                                    const { page, search } = this.state;
                                    this.props.listar(page, { search });
                                }
                            }}
                            onChange={(tx) => {
                                this.setState({ search: tx.target.value });
                            }}
                            style={{
                                color: "black",
                                fontSize: 15,
                                width: "55%",
                                float: "right",
                            }}
                        />
                    </div>
                    <div
                        className="col-12 col-md-6"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Link
                            to="/actividades_entrega/crear"
                            className="btn btn-primary mt-4"
                            style={{ fontSize: 15, borderRadius: "5px" }}
                        >
                            Nueva actividad
                        </Link>
                    </div>
                </div>
                <Grid
                    data={data}
                    loading={loader}
                    page={this.state.page}
                    onPageChange={this.onPageChange}
                >
                    <TableHeaderColumn dataField="descripcion" dataSort>
                        Descripción
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fase"
                        dataSort
                        dataFormat={(cell, row) => {
                            return cell.nombre;
                        }}
                    >
                        Fase
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="complejidad"
                        dataSort
                        dataFormat={(cell, row) => {
                            return cell.descripcion;
                        }}
                    >
                        Complejidad
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        dataSort
                        isKey
                        dataFormat={(cell, row) => {
                            let props = {};
                            props.editar = "actividades_entrega";
                            props.eliminar = eliminar;
                            const actions = standardActions({ ...props });
                            return actions(cell, row);
                        }}
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>

                <Route
                    exact
                    path={`${this.props.match.url}/:id/editar`}
                    render={(props) => (
                        <Popup
                            open={true}
                            modal
                            onClose={() =>
                                this.props.history.push(
                                    `${this.props.match.url}`
                                )
                            }
                            closeOnDocumentClick={false}
                            className="modal-form"
                        >
                            <CrearEditar {...props} />
                        </Popup>
                    )}
                />

                <Route
                    exact
                    path={`${this.props.match.url}/crear`}
                    render={(props) => (
                        <Popup
                            open={true}
                            modal
                            onClose={() =>
                                this.props.history.push(
                                    `${this.props.match.url}`
                                )
                            }
                            closeOnDocumentClick={false}
                            className="modal-form"
                        >
                            <CrearEditar {...props} />
                        </Popup>
                    )}
                />
            </React.Fragment>
        );
    }
}

export default Listar;
