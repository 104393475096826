import React from "react";
import Select from "react-select";
import DayPicker from "../../Utils/DayPicker";

const FiltersData = ({ colaboradores, tipos, onChangeParams, currentParams }) => {
    const select_style = {
        control: (styles) => ({
            ...styles,
            color: "#1F4D7B",
            borderColor: "#1F4D7B",
        }),
    };

    return (
        <React.Fragment>
            <div className="col-12 col-md-2">
                <label htmlFor="fecha_inicio" className="text-dark">
                    Fecha inicio
                </label>
                <br />
                <div style={{ display: "flex" }}>
                    <DayPicker
                        onChange={(date) => {
                            onChangeParams({
                                name: "fecha_inicio",
                                value: date,
                            });
                        }}
                        value={currentParams.fecha_inicio || ""}
                    />
                    <button
                        className="btn"
                        style={{ padding: 0 }}
                        onClick={() =>
                            onChangeParams({
                                name: "fecha_inicio",
                                value: "",
                            })
                        }
                    >
                        <i className="material-icons">clear</i>
                    </button>
                </div>
            </div>

            <div className="col-12 col-md-2">
                <label htmlFor="fecha_inicio" className="text-dark">
                    Fecha fin
                </label>
                <br />
                <div style={{ display: "flex" }}>
                    <DayPicker
                        onChange={(date) => {
                            onChangeParams({ name: "fecha_fin", value: date });
                        }}
                        value={currentParams.fecha_fin || ""}
                    />
                    <button
                        className="btn"
                        style={{ padding: 0 }}
                        onClick={() =>
                            onChangeParams({
                                name: "fecha_fin",
                                value: "",
                            })
                        }
                    >
                        <i className="material-icons">clear</i>
                    </button>
                </div>
            </div>

            <div className="col-12 col-md-3">
                <label htmlFor="tipo" className="text-dark">
                    Colaborador
                </label>
                <br />
                <Select
                    styles={select_style}
                    name="colaborador"
                    placeholder="Colaborador"
                    isSearchable
                    isClearable
                    options={colaboradores}
                    onChange={(e) => {
                        if (e) {
                            onChangeParams({ name: "colaborador", value: e.value });
                        } else {
                            onChangeParams({ name: "colaborador", value: null });
                        }
                    }}
                />
            </div>
            <div className="col-12 col-md-2">
                <label htmlFor="tipo" className="text-dark">
                    Tipo amonestación
                </label>
                <br />
                <Select
                    select_style={select_style}
                    name="tipo"
                    placeholder="Tipo..."
                    isSearchable
                    isClearable
                    options={tipos}
                    onChange={(e) => {
                        if (e) {
                            onChangeParams({ name: "tipo", value: e.value });
                        } else {
                            onChangeParams({ name: "tipo", value: null });
                        }
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default FiltersData;
