import React from "react";
import { Popup } from "reactjs-popup";

const urlValidator = (value) => {
    try {
        new URL(value);
    } catch (error) {
        return "Url inválida";
    }
    return undefined;
};

const nameValidator = (value) => {
    console.log(value);
    return value && value.length > 0 && value.length < 180
        ? undefined
        : "Nombre inválido";
};

const UrlFormTooltip = ({ onAdd }) => {
    const [url, setUrl] = React.useState("");
    const [nombre, setNombre] = React.useState("");
    const [error, setError] = React.useState({
        nombre: undefined,
        url: undefined,
    });

    const handleSubmit = (close) => {
        const nombreError = nameValidator(nombre);
        const urlError = urlValidator(url);
        if (nombreError || urlError) {
            setError({
                nombre: nombreError,
                url: urlError,
            });
        } else {
            onAdd({
                nombre,
                url,
            });

            setUrl("");
            setNombre("");
            setError({
                nombre: undefined,
                url: undefined,
            });
            close();
        }
    };

    return (
        <Popup
            trigger={(open) => (
                <button type="button" className="btn btn-dark p-1 mb-3">
                    <i className="material-icons">add</i>
                </button>
            )}
            position="right center"
            closeOnDocumentClick
            onClose={() => {
                setUrl("");
                setNombre("");
                setError({
                    nombre: undefined,
                    url: undefined,
                });
            }}
        >
            {(close) => (
                <div className="">
                    <div className="form-group has-feedback ">
                        <label htmlFor="nombre_url_form">Nombre</label>
                        <input
                            name="nombre_url_form"
                            className="form-control input--style"
                            type="text"
                            placeholder="Nombre..."
                            onChange={(e) => setNombre(e.target.value.trim())}
                            onBlur={(e) => setNombre(e.target.value.trim())}
                        />
                        {error.nombre && (
                            <div className="text-danger">{error.nombre}</div>
                        )}
                    </div>
                    <div className="form-group has-feedback ">
                        <label htmlFor="url_form">Url</label>
                        <input
                            name="url_form"
                            className="form-control input--style"
                            type="url"
                            placeholder="https..."
                            onChange={(e) => setUrl(e.target.value)}
                            onBlur={(e) => setUrl(e.target.value)}
                        />
                        {error.url && (
                            <div className="text-danger">{error.url}</div>
                        )}
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <button
                            className="btn btn-dark"
                            onClick={() => handleSubmit(close)}
                        >
                            Agregar
                        </button>
                    </div>
                </div>
            )}
        </Popup>
    );
};

export const renderEnlaces = ({
    fields,
    meta: { error },
    isDisabled = false,
}) => {
    return (
        <div>
            {!isDisabled ? <UrlFormTooltip onAdd={fields.push} /> : null}
            <span className="text-danger">{error}</span>
            <div className="row">
                {fields.map((enlace, index) => {
                    const currentEnlace = fields.get(index);
                    return (
                        <div className="d-flex col-6 mb-3" key={index}>
                            <a
                                href={currentEnlace.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {currentEnlace.nombre}
                            </a>
                            <div className="col-2 m-0 p-0">
                                <button
                                    className="btn btn-danger p-0 ml-3"
                                    type="button"
                                    onClick={() => fields.remove(index)}
                                    disabled={isDisabled}
                                >
                                    <i
                                        className="material-icons"
                                        style={{
                                            fontSize: "16px",
                                        }}
                                    >
                                        delete
                                    </i>
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
