import React from "react";

const Info_Recurso = ({dataRecurso, index_recurso}) => {
    const skills = dataRecurso.skills || [];
    return (
        <React.Fragment>
            <div className="mb-2">
                <p className="text--blueBold mb-2">Datos personales</p>
                <div className="row">
                    <div className="col-1 d-flex justify-content-start align-items-center text--blueBold">
                        #
                    </div>
                    <div className="col d-flex justify-content-start align-items-center text--blueBold">
                        Nombre
                    </div>
                    <div className="col-1 d-flex justify-content-start align-items-center text--blueBold">
                        ID
                    </div>
                    <div className="col d-flex justify-content-start align-items-center text--blueBold">
                        Skills
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-1 d-flex justify-content-start align-items-center text--blue ">
                        {index_recurso}
                    </div>
                    <div className="col d-flex justify-content-start align-items-center text--blue ">
                        {Object.keys(dataRecurso)[0]}
                    </div>
                    <div className="col-1 d-flex justify-content-start align-items-center text--blue ">
                        {dataRecurso.id_recurso}
                    </div>
                    <div className="col d-flex justify-content-start align-items-center text--blue ">
                        {skills.length > 0
                            ? skills
                                  .map(
                                      (s) => `${s.nombre} ${s.experiencia} años`
                                  )
                                  .join(", ")
                            : "---"}
                    </div>
                </div>
                <p className="text--blueBold mb-2">Disponibilidad</p>
            </div>
        </React.Fragment>
    );
};

export default Info_Recurso;
