import React from "react";
import Select from "react-select";
import { items_page } from "../../../../utility/constants";

const FiltersData = ({
    proyectos = [],
    onChangeParams,
    onChangeSearch,
    onSearch,
    search,
}) => {
    const select_style = {
        control: (styles) => ({
            ...styles,
            color: "#1F4D7B",
            borderColor: "#1F4D7B",
        }),
    };

    return (
        <React.Fragment>
            <div className="col-12 col-md-3">
                <input
                    placeholder="Buscar..."
                    type="text"
                    className="form-control input--style"
                    value={search}
                    onKeyDown={(key) => {
                        if (key.key == "Enter") {
                            onSearch();
                        }
                    }}
                    onChange={(e) => {
                        onChangeSearch(e.target.value);
                        if (e.target.value.length == 0) {
                            onChangeParams({ name: "search", value: null });
                        }
                    }}
                />
            </div>
            <div className="col-12 col-md-4">
                <Select
                    styles={select_style}
                    name="proyecto"
                    placeholder="Proyecto"
                    isSearchable
                    isClearable
                    options={proyectos}
                    onChange={(e) => {
                        if (e) {
                            onChangeParams({
                                name: "proyecto",
                                value: e.value,
                            });
                        } else {
                            onChangeParams({
                                name: "proyecto",
                                value: null,
                            });
                        }
                    }}
                />
            </div>
            <div className="col-12 col-md-2">
                <Select
                    styles={select_style}
                    name="page_size"
                    placeholder="Items por página"
                    isSearchable={false}
                    isClearable={false}
                    options={items_page}
                    defaultValue={items_page[0]}
                    onChange={(e) => {
                        onChangeParams({
                            name: "page_size",
                            value: e.value,
                        });
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default FiltersData;
