import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Swal from "sweetalert2";
import { api } from "api";

class Listar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            search: "",
            BreakLine: {
                whiteSpace: "normal",
            },
            params: {
                activo: true,
            },
            fases: [],
            save_disabled: true,
            loader: false
        };
    }

    eliminar = (id) => {
        return () => {
            Swal.fire({
                title: "¿Eliminar?",
                text: "¡No podrá revertir esta acción!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "¡Sí, eliminar!",
                cancelButtonText: "No, cancelar",
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    api.eliminar(`departamento_organizacion/${id}`).then(() => {
                        Swal.fire({
                            title: 'Éxito',
                            text: 'Se ha eliminado correctamente',
                            type: 'success',
                        })
                        this.getData();
                    })
                }
            });
        };
    };

    saveOrder() {
        return () => {
            Swal.fire({
                title: "¿Desea guardar el orden?",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar",
                reverseButtons: true
            }).then((result) => {
                if(result.value) {
                    this.props.actualizarOrden(this.state.fases.results);
                }
            });
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        api.get("departamento_organizacion", { page: this.state.page, ...this.state.params }).then(response => {
            this.setState({fases: response});
        });
    }

    onPageChange = (page) => {
        api.get("departamento_organizacion", { page, ...this.state.params }).then(response => {
            this.setState({fases: response, page});
        });
    };

    handleDragEnd = (e) => {
        if (!e.destination) return;
        let tempData = Array.from(this.state.fases.results);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        let tempFases = this.state.fases;
        tempFases.results = tempData;
        this.setState({ fases: tempFases, save_disabled: false });
    };

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h2 className="title--blue">LISTADO DE DEPARTAMENTOS</h2>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-12 col-md-6">
                    <input
                            placeholder="Buscar..."
                            type="text"
                            className="form-control mt-4 input--style"
                            value={this.state.search}
                            onKeyPress={(key) => {
                                if (key.key == "Enter") {
                                    const { page, search, params } = this.state;
                                    params.search = search;
                                    api.get("departamento_organizacion", { page, ...params }).then(response => {
                                        this.setState({fases: response});
                                    });
                                }
                            }}
                            onChange={(tx) => {
                                this.setState({ search: tx.target.value });
                            }}
                            style={{
                                color: "black",
                                fontSize: 15,
                                width: "55%",
                                float: "left",
                            }}
                        />
                    </div>
                    <div className="col-12 col-md-6" style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <button onClick={this.saveOrder()} className="btn btn-info mt-4" style={{ fontSize: 15 }} disabled={this.state.save_disabled}>Guardar</button>
                        <Link
                            to="/departamentos/crear"
                            className="btn btn-primary mt-4 ml-3"
                            style={{ fontSize: 15 }}
                        >
                            Nuevo departamento
                        </Link>
                    </div>
                </div>
                {this.state.fases.results ? (
                        <DragDropContext onDragEnd={this.handleDragEnd}>
                            <div className="table-responsive">
                                <table className="content--table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Ordenar</th>
                                            <th scope="col">Nombre</th>
                                            <th scope="col">Acciones</th>
                                        </tr>
                                    </thead>
                                    <Droppable droppableId="droppable-1">
                                        {(provider) => (
                                            <tbody
                                                className="text-capitalize"
                                                ref={provider.innerRef}
                                                {...provider.droppableProps}
                                            >
                                                {this.state.fases.results.map(
                                                    (element, index) => (
                                                        <Draggable
                                                            key={element.id}
                                                            draggableId={
                                                                element.nombre
                                                            }
                                                            index={index}
                                                        >
                                                            {(provider) => (
                                                                <tr
                                                                    {...provider.draggableProps}
                                                                    ref={
                                                                        provider.innerRef
                                                                    }
                                                                >
                                                                    <td
                                                                        {...provider.dragHandleProps}
                                                                    >
                                                                        =
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            element.nombre
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex justify-content-center">
                                                                            <Link
                                                                                className="standard-action action--edit"
                                                                                to={`departamentos/${element.id}/editar`}
                                                                            >
                                                                                <i className="material-icons">
                                                                                    edit
                                                                                </i>
                                                                            </Link>
                                                                            <a
                                                                                className="standard-action action--delete"
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    color: "#c4183c",
                                                                                }}
                                                                                onClick={this.eliminar(
                                                                                    element.id
                                                                                )}
                                                                            >
                                                                                <i className="material-icons">
                                                                                    delete
                                                                                </i>
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    )
                                                )}
                                                {provider.placeholder}
                                            </tbody>
                                        )}
                                    </Droppable>
                                </table>
                            </div>
                        </DragDropContext>
                ) : null}
            </React.Fragment>
        );
    }
}

export default Listar;
