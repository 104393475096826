import React from 'react';
import { Link } from "react-router-dom";
import { Field, FieldArray, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';
import { renderRecursos } from './renderRecursos';
import { validateArrayNoRepeatedField} from "../../Utils/Validators";

const CrearEditarForm = (props) => {
    const { handleSubmit, recursos = [] } = props;
    return (
        <form name="CrearEditarForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className='row'>
                <div className="form-group has-feedback col-6">
                    <label htmlFor="nombre">Nombre</label>
                    <Field name="nombre" label="Nombre" component={renderField} type="text" className="input--style" />
                </div>
                <div className="col-6">
                    <label htmlFor="nombre">Jefes</label>
                    <FieldArray
                        name="jefes"
                        component={renderRecursos}
                        className="input--style"
                        recursos={recursos}
                    />
                </div>
            </div>
            <div className="buttons-box">
                <Link
                    to="/departamentos"
                    className="btn btn-secondary"
                    style={{ fontSize: 15 }}
                >
                    Regresar
                </Link>
                <button type="submit" style={{ fontSize: 15 }} className="btn btn-primary m-1 align-self-center">Guardar</button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'CrearEditarForm', // a unique identifier for this form
    validate: (data) => {
        const errors = validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
        });

        const errorJefes = []
        if(data.jefes){
            data.jefes.forEach((jefe) => {
                if(!jefe) errorJefes.push("Este campo es requerido");
                else errorJefes.push("");
            })
        }

        return {
            ...errors,
            jefes: errorJefes,
            ...validateArrayNoRepeatedField({
                data,
                fieldname: "jefes",
                min: 1,
                message: "Jefes repetidos",
                emptyMessage: "No hay jefes",
            })
        }
    },
})(CrearEditarForm);
