import React from "react";
import Select from "react-select";
import { NotificationManager } from "react-notifications";
import { Field, FieldArray } from "redux-form";
import NumberFormat from "react-number-format";

import {
    renderTextAreaWithStyle,
    renderSelectField,
    renderField,
    renderNumber,
    renderDatePicker,
    renderDayPicker,
} from "../../Utils/renderField";
import { RenderSkills } from "./RenderSkills";
import { RenderRow } from "./RenderRow";

const priorityColors = ["#F50D00", "#FECC01", "#6FDDFF", "#D8D8D8"];

export const PrincipalSection = ({
    isDisabled = false,
    edit,
    proyectos,
    tipos_proyecto,
    clientes,
    paises,
    puestos,
    niveles_ingles,
    tipos_contrato,
    tipos_plaza,
    prioridades,
    skills,
    seniority,
    nivelesAcademicos,
    monedas,
    skillsValue,
    change,
    array,
    rangoSugerido,
    modalidadesContrato,
    modalidadValue,
    monedaValue,
    contratoValue,
}) => {
    const prioritiesWithColors = React.useMemo(() => {
        const withColors = [];
        let indexColor = 0;
        for (let i = 0; i < prioridades.length; i++) {
            if (indexColor === priorityColors.length) indexColor = 0;
            withColors.push({
                ...prioridades[i],
                color: priorityColors[indexColor],
            });
            indexColor++;
        }

        return withColors;
    }, [prioridades]);

    const [currentSkill, setCurrentSkill] = React.useState({
        skill: null,
        label: null,
        experiencia: null,
    });

    const onProjectChange = (value) => {
        const project = proyectos.find((project) => project.value === value);
        if (project) {
            change("tipo_proyecto", project.tipo_proyecto);
            change("cliente", project.cliente);
            change("ubicacion_cliente", project.pais_asueto);
        }
    };

    const onAddSkill = () => {
        const { skill, experiencia } = currentSkill;
        const skillExists = skillsValue.some((sk) => sk.skill === skill);
        if (skillExists) {
            NotificationManager.error("Skill repetida", "Error", 1000);
        } else if (skill !== null && experiencia !== null) {
            array.push("skills", { ...currentSkill });
            setCurrentSkill({ skill: null, experiencia: null });
        }
    };

    const applyDisabledStyles = (condition, styles) => {
        if (condition) return styles;
        return {};
    };

    const selectStyle = {
        control: (styles, state) => {
            const disabled = applyDisabledStyles(state.isDisabled, {
                border: "none",
                backgroundColor: "#FFF",
            });

            return {
                ...styles,
                ...disabled,
                color: state.isDisabled ? "#959596" : "#1F4D7B",
                borderColor: "#1F4D7B",
            };
        },

        indicatorSeparator: (styles, state) => {
            const disabled = applyDisabledStyles(state.isDisabled, {
                backgroundColor: "#FFF",
            });

            return {
                ...styles,
                ...disabled,
            };
        },

        dropdownIndicator: (styles, state) => {
            const disabled = applyDisabledStyles(state.isDisabled, {
                color: "#FFF",
            });

            return {
                ...styles,
                ...disabled,
            };
        },

        singleValue: (styles, state) => ({
            ...state,
            color: "#959596",
        }),
    };

    const stylePrioritiesSelect = {
        ...selectStyle,
        option: (styles, { data }) => {
            const color = data.color;
            return {
                ...styles,
                color: color,
            };
        },

        singleValue: (styles, { data }) => {
            const color = data.color;
            return {
                ...styles,
                color: "#FFF",
                backgroundColor: color,
                padding: "5px",
                borderRadius: "5px",
            };
        },
    };

    const row1 = [
        {
            label: "Proyecto",
            className: "col-3",
            props: {
                name: "proyecto",
                component: renderSelectField,
                select_style: selectStyle,
                options: proyectos,
                labelKey: "label",
                valueKey: "value",
                className: "input--style",
                placeholder: "Proyecto...",
                disabled: isDisabled,
                extra_change: true,
                extraChange: onProjectChange,
            },
        },
        {
            label: "Tipo de proyecto",
            className: "col-3",
            props: {
                name: "tipo_proyecto",
                component: renderSelectField,
                select_style: selectStyle,
                options: tipos_proyecto,
                labelKey: "label",
                valueKey: "value",
                className: "input--style",
                placeholder: "Tipo...",
                disabled: true,
            },
        },

        {
            label: "Cliente",
            className: "col-3",
            props: {
                name: "cliente",
                component: renderSelectField,
                select_style: selectStyle,
                options: clientes,
                labelKey: "label",
                valueKey: "value",
                className: "input--style",
                placeholder: "Cliente...",
                disabled: true,
            },
        },
        {
            label: "Ubicación cliente",
            className: "col-3",
            props: {
                name: "ubicacion_cliente",
                component: renderSelectField,
                select_style: selectStyle,
                options: paises,
                labelKey: "label",
                valueKey: "value",
                className: "input--style",
                placeholder: "Ubicación...",
                disabled: true,
            },
        },
    ];

    const row2 = [
        {
            label: "Nombre del puesto",
            className: "col-3",
            props: {
                name: "puesto",
                component: renderSelectField,
                select_style: selectStyle,
                options: puestos,
                labelKey: "label",
                valueKey: "value",
                className: "input--style",
                placeholder: "Puesto...",
                disabled: isDisabled,
            },
        },
        {
            label: "Seniority",
            className: "col-3",
            props: {
                name: "seniority",
                component: renderSelectField,
                select_style: selectStyle,
                options: seniority,
                labelKey: "label",
                valueKey: "value",
                className: "input--style",
                placeholder: "Seniority...",
                disabled: isDisabled,
            },
        },

        {
            label: "Nivel académico",
            className: "col-3",
            props: {
                name: "nivel_academico",
                component: renderSelectField,
                select_style: selectStyle,
                options: nivelesAcademicos,
                labelKey: "label",
                valueKey: "value",
                className: "input--style",
                placeholder: "Nivel académico...",
                disabled: isDisabled,
            },
        },
        {
            label: "Nivel de inglés",
            className: "col-3",
            props: {
                name: "nivel_ingles",
                component: renderSelectField,
                select_style: selectStyle,
                options: niveles_ingles,
                labelKey: "label",
                valueKey: "value",
                className: "input--style",
                placeholder: "Nivel de inglés...",
                disabled: isDisabled,
            },
        },
    ];

    const row3 = [
        {
            label: "Tipo de contrato",
            className: "col-3",
            props: {
                name: "tipo_contrato",
                component: renderSelectField,
                select_style: selectStyle,
                options: tipos_contrato,
                labelKey: "label",
                valueKey: "value",
                className: "input--style",
                placeholder: "Contrato...",
                disabled: isDisabled,
            },
        },
        {
            label: "Modalidad",
            className: "col-3",
            props: {
                name: "modalidad",
                component: renderSelectField,
                select_style: selectStyle,
                options: modalidadesContrato,
                labelKey: "label",
                valueKey: "value",
                className: "input--style",
                placeholder: "Modalidad...",
                disabled: isDisabled,
            },
        },
        {
            label: "Tipo plaza",
            className: "col-3",
            props: {
                name: "tipo_plaza",
                component: renderSelectField,
                select_style: selectStyle,
                options: tipos_plaza,
                labelKey: "label",
                valueKey: "value",
                className: "input--style",
                placeholder: "Plaza...",
                disabled: isDisabled,
            },
        },
        {
            label: "Duración cont.",
            className: "col",
            props: {
                name: "duracion_contrato",
                component: renderNumber,
                className: "input--style",
                placeholder: modalidadValue === "PLANILLA" ? "N/A" : "meses",
                suffix: " meses",
                readOnly: isDisabled || modalidadValue === "PLANILLA",
            },
        },
    ];

    if (!isDisabled) {
        row3.push({
            label: "Prioridad",
            className: "col",
            props: {
                name: "prioridad",
                component: renderSelectField,
                select_style: stylePrioritiesSelect,
                options: prioritiesWithColors,
                labelKey: "label",
                valueKey: "value",
                className: "input--style",
                placeholder: "Prioridad...",
                disabled: isDisabled,
            },
        });
    }

    const row4 = [
        {
            label: "Fecha límite de contratación",
            className: "col-3",
            props: {
                name: "fecha_limite_contratacion",
                component: renderField,
                type: "date",
                className: "input--style",
                disabled: true,
            },
        },
        {
            label: "Horario",
            className: "col-3",
            props: {
                name: "horario_display",
                component: renderField,
                className: "input--style",
                disabled: true,
            },
        },
        {
            label: "Rango salarial",
            className: "col-3",
            props: {
                name: "rango_display",
                component: renderField,
                className: "input--style",
                disabled: true,
            },
        },
        {
            label: "Prioridad",
            className: "col",
            props: {
                name: "prioridad",
                component: renderSelectField,
                select_style: stylePrioritiesSelect,
                options: prioritiesWithColors,
                labelKey: "label",
                valueKey: "value",
                className: "input--style",
                placeholder: "Prioridad...",
                disabled: true,
            },
        },
    ];

    const row5 = [
        {
            label: "Descripción",
            className: "col-6",
            props: {
                name: "descripcion",
                component: renderTextAreaWithStyle,
                className: "input--style",
                placeholder: "Escriba aquí...",
                rows: 5,
                disabled: isDisabled,
            },
        },
        {
            label: "Atribuciones del puesto",
            className: "col-6",
            props: {
                name: "atribuciones_puesto",
                component: renderTextAreaWithStyle,
                className: "input--style",
                placeholder: "Escriba aquí...",
                rows: 5,
                disabled: isDisabled,
            },
        },
        {
            label: "Comentarios",
            className: "col-6",
            props: {
                name: "comentarios",
                component: renderTextAreaWithStyle,
                className: "input--style",
                placeholder: "Escriba aquí...",
                rows: 5,
                disabled: isDisabled,
            },
        },
        {
            label: "Proceso de contratación",
            className: "col-6",
            props: {
                name: "proceso_contratacion",
                component: renderTextAreaWithStyle,
                className: "input--style",
                placeholder: "Escriba aquí...",
                rows: 5,
                disabled: isDisabled,
            },
        },
    ];

    return (
        <div className="card card-login">
            <RenderRow fields={row1} />
            <RenderRow fields={row2} />
            <RenderRow fields={row3} />
            {isDisabled ? (
                <RenderRow fields={row4} />
            ) : (
                <div className="row mb-4">
                    <div className="form-group has-feedback col-3">
                        <label htmlFor="fecha_limite_contratacion">
                            Fecha límite de contratación
                        </label>
                        <Field
                            name={"fecha_limite_contratacion"}
                            className={"input--style"}
                            component={renderField}
                            type="date"
                            disabled={isDisabled}
                        />
                    </div>
                    <div className="col-3 row mx-0 px-0">
                        <div className="form-group has-feedback col-6">
                            <label htmlFor="hora_entrada">Hora entrada</label>
                            <Field
                                name={"hora_entrada"}
                                className={"input--style"}
                                component={renderField}
                                type="time"
                                disabled={isDisabled || contratoValue === 1}
                            />
                        </div>
                        <div className="form-group has-feedback col-6">
                            <label htmlFor="hora_salida">Hora salida</label>
                            <Field
                                name={"hora_salida"}
                                className={"input--style"}
                                component={renderField}
                                type="time"
                                disabled={isDisabled || contratoValue === 1}
                            />
                        </div>
                    </div>

                    <div className="form-group has-feedback col-3">
                        <label htmlFor="moneda">Moneda</label>
                        <Field
                            name={"moneda"}
                            component={renderSelectField}
                            select_style={selectStyle}
                            options={monedas}
                            labelKey={"label"}
                            valueKey={"value"}
                            className={"input--style"}
                            placeholder={"Moneda..."}
                            disabled={isDisabled}
                        />
                    </div>

                    <div className="col-3 p-0">
                        <div className="d-flex">
                            <div className="form-group has-feedback col m-0">
                                <label htmlFor="min_salario">
                                    Salario (mín)
                                </label>
                                <Field
                                    name={"min_salario"}
                                    className={"input--style"}
                                    component={renderNumber}
                                    prefix={`${monedaValue} ` || ""}
                                    placeholder={"mín"}
                                    readOnly={isDisabled}
                                />
                            </div>
                            <div className="form-group has-feedback col m-0">
                                <label htmlFor="min_salario">
                                    Salario (max)
                                </label>
                                <Field
                                    name={"max_salario"}
                                    className={"input--style"}
                                    component={renderNumber}
                                    prefix={`${monedaValue} ` || ""}
                                    placeholder={"max"}
                                    readOnly={isDisabled}
                                />
                            </div>
                        </div>
                        <span
                            className="col"
                            style={{
                                fontSize: ".75rem",
                            }}
                        >
                            {rangoSugerido}
                        </span>
                    </div>
                </div>
            )}

            {/* Skills */}
            <div className="row mb-4">
                {!isDisabled ? (
                    <React.Fragment>
                        <div className="form-group col-3">
                            <label>Skills</label>
                            <Select
                                styles={selectStyle}
                                isClearable={true}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                options={skills}
                                placeholder="Skills..."
                                onChange={(e) => {
                                    setCurrentSkill({
                                        ...currentSkill,
                                        skill: e ? e["value"] : null,
                                        label: e ? e["label"] : null,
                                    });
                                }}
                                value={
                                    skills.find(
                                        (sk) => sk.value === currentSkill.skill
                                    ) || ""
                                }
                                isDisabled={isDisabled}
                                menuPlacement="auto"
                            />
                        </div>

                        <div className="form-group col-3">
                            <label>Experiencia</label>
                            <NumberFormat
                                placeholder="meses"
                                className="form-control input--style"
                                decimalScale={0}
                                fixedDecimalScale
                                value={currentSkill.experiencia}
                                thousandSeparator
                                suffix=" meses"
                                onValueChange={(values) => {
                                    setCurrentSkill({
                                        ...currentSkill,
                                        experiencia: Number(values.value),
                                    });
                                }}
                                readOnly={isDisabled}
                            />
                        </div>
                        <div className="form-group d-flex align-items-end pl-0">
                            <button
                                className="btn btn-light p-0"
                                disabled={isDisabled}
                                type="button"
                                onClick={onAddSkill}
                            >
                                <i
                                    className="material-icons"
                                    style={{
                                        fontSize: "2.5rem",
                                        color: "#0DA140",
                                    }}
                                >
                                    check_box
                                </i>
                            </button>
                        </div>
                    </React.Fragment>
                ) : null}
                <div className="row col-12">
                    <FieldArray
                        name="skills"
                        component={RenderSkills}
                        isDisabled={isDisabled}
                    />
                </div>
            </div>

            <RenderRow fields={row5} />
        </div>
    );
};
