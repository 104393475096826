import moment from "moment";
import React from "react";
import Select from "react-select";

const FiltersData = ({ paises, handleSelect, currentDate, setCurrentDate }) => {
    // Select style
    const selectStyle = {
        control: (styles) => ({
            ...styles,
            color: "#1F4D7B",
            borderColor: "#1F4D7B",
        }),
        option: (styles, { data }) => {
            const color = data.color;
            return {
                ...styles,
                color: color,
            };
        },
        multiValue: (styles, { data }) => {
            const color = data.color;
            return {
                ...styles,
                color: color,
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color,
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color,
            ":hover": {
                backgroundColor: data.color,
                color: "white",
            },
        }),
    };

    const month = moment(currentDate).format("YYYY-MM");

    return (
        <React.Fragment>
            <div
                className="col-6 col-md-6"
                style={{
                    zIndex: 10,
                }}
            >
                <label htmlFor="pais">Filtrar por país</label>
                <Select
                    isMulti
                    styles={selectStyle}
                    name="pais"
                    placeholder="País"
                    isSearchable
                    isClearable
                    options={paises}
                    onChange={handleSelect}
                />
            </div>
            <div
                className="col-6 col-md-6"
                style={{
                    zIndex: 10,
                }}
            >
                <label htmlFor="mes">Elegir mes</label>
                <br />
                <input
                    className="input--style"
                    type="month"
                    name="mes"
                    value={month}
                    onChange={(e) => {
                        if (e.target.value) {
                            setCurrentDate(
                                new Date(`${e.target.value}-01T06:00`)
                            );
                        }
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default FiltersData;
