import React from "react";

export const InformationCard = ({ children }) => {
    return (
        <div className="card mb-3 mt-3 card--reportes mx-auto">
            <div className="card-body px-4">
                <ul className="list-information">{children}</ul>
            </div>
        </div>
    );
};
