import React from "react";

import { renderSelectField, renderNumber } from "../../../Utils/renderField";

import { Field } from "redux-form";

export const renderRequisitos = ({
    fields,
    meta: { error, touched, submitFailed },
    actividades = [],
    fases = [],
    change,
}) => {
    const select_style = {
        control: (styles, state) => ({
            ...styles,
            color: "#1F4D7B",
            borderWidth: state.isFocused || !state.hasValue ? "1px" : "0px",
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            backgroundColor: "white",
        }),
    };

    const changeFaseValue = (fieldName, value) => {
        change(fieldName, value);
    };

    return (
        <div className="col-10">
            <div className="row">
                <span className="col-3 title--blue--1">Actividad entrega</span>
                <span className="col-3 title--blue--1">Fase</span>
                <span className="col-3 title--blue--1 p-0">Cantidad horas</span>
            </div>
            {fields.length <= 0 && (
                <div className="col-12">
                    <span className="text-center">---</span>
                </div>
            )}
            {(touched || submitFailed) && error && (
                <span className="text-danger">{error}</span>
            )}

            {fields.map((requisito, index) => {
                return (
                    <div className="row field--array" key={index}>
                        <div className="form-group has-feedback col-3 m-0">
                            <Field
                                name={`${requisito}.actividad`}
                                select_style={select_style}
                                component={renderSelectField}
                                label={`#${index + 1}`}
                                options={actividades}
                                labelKey="label"
                                valueKey="value"
                                className="form-group"
                                placeholder="Actividad..."
                                extra_change={true}
                                extraChange={(value) => {
                                    const actividad = actividades.find(
                                        (a) => a.value === value
                                    );
                                    const fase = actividad.fase || null;
                                    changeFaseValue(`${requisito}.fase`, fase);
                                }}
                            />
                        </div>
                        <div className="form-group has-feedback col-3 m-0 pl-0">
                            <Field
                                name={`${requisito}.fase`}
                                select_style={select_style}
                                component={renderSelectField}
                                label={`#${index + 1}`}
                                options={fases}
                                labelKey="nombre"
                                valueKey="id"
                                className="form-group"
                                placeholder="Fase..."
                                defaultValue={fases[0]}
                            />
                        </div>
                        <div className="form-group has-feedback col-3 m-0 p-0">
                            <Field
                                name={`${requisito}.horas`}
                                component={renderNumber}
                                suffix=" hrs"
                                label={`Horas`}
                                placeholder="Horas..."
                            />
                        </div>
                        <div className="col d-flex justify-content-end">
                            <button
                                className="btn standard-action action--delete"
                                type="button"
                                style={{
                                    color: "rgb(246, 81, 96)",
                                    backgroundColor: "rgba(246, 81, 96, 0.3)",
                                    padding: "0",
                                }}
                                onClick={() => fields.remove(index)}
                            >
                                <i className="material-icons">delete</i>
                            </button>
                        </div>
                    </div>
                );
            })}
            <button
                type="button"
                className="btn mb-3 d-flex align-items-center justify-conten-center"
                onClick={() => fields.push({})}
            >
                <i
                    className="material-icons title--blue--1"
                    style={{ fontSize: "24px" }}
                >
                    add
                </i>
                <p
                    className="title--blue--1"
                    style={{ margin: "0", fontSize: "14px" }}
                >
                    Agregar
                </p>
            </button>
        </div>
    );
};
