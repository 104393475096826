import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/historial_economicos/historial_economicos';
import CrearEditar from './CrearEditar';
import { historial } from '../../../../redux/modules/historial_economicos/historial_economicos';

const ms2p = (state) => {
  return {
    ...state.historial_economico,
    historialData: state.historial_economicos.data,
  };
};

const md2p = { 
  ...actions,
  loadHistorial: historial,
};

export default connect(ms2p, md2p)(CrearEditar);
