import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import {
    combine,
    validate,
    validators,
    validatorFromFunction,
} from "validate-redux-form";
import { renderField } from "../../Utils/renderField";

const ChangePasswordForm = (props) => {
    const { handleSubmit } = props;
    
    return (
        <form
            name="CrearEditarForm"
            className="form-validate mb-lg"
            onSubmit={handleSubmit}
        >
            <div className="row">
                <div className="form-group has-feedback col-12">
                    <label htmlFor="old_password">Contraseña Actual</label>
                    <Field
                        name="old_password"
                        label="Contraseña Actual"
                        component={renderField}
                        type="password"
                        className="input--style"
                    />
                </div>
                <div className="form-group has-feedback col-12">
                    <label htmlFor="new_password">Nueva Contraseña</label>
                    <Field
                        name="new_password"
                        label="Nueva contraseña"
                        component={renderField}
                        type="password"
                        className="input--style"
                    />
                </div>
                <div className="form-group has-feedback col-12">
                    <label htmlFor="confirm_password">Confirmar Contraseña</label>
                    <Field
                        name="confirm_password"
                        label="Confirmar contraseña"
                        component={renderField}
                        type="password"
                        className="input--style"
                    />
                </div>
            </div>
            <div className="buttons-box">
                <Link
                    to="/users"
                    className="btn btn-secondary"
                    style={{ fontSize: 15 }}
                >
                    Regresar
                </Link>
                <button
                    type="submit"
                    style={{ fontSize: 15 }}
                    className="btn btn-primary m-1 align-self-center"
                >
                    Actualizar
                </button>
            </div>
        </form>
    );
};

export const matchPassword = (passe, confirm) =>
    validatorFromFunction(() => {
        return passe === confirm;
    });

export default reduxForm({
    form: "ChangePasswordForm", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            old_password: validators.exists()("Este campo es requerido"),
            new_password: validators.exists()("Este campo es requerido"),
            confirm_password: combine(
                validators.exists()("Este campo es requerido"),
                matchPassword(data.new_password, data.confirm_password)()(
                    "Las Contraseña no coinciden"
                )
            ),
        });
    },
})(ChangePasswordForm);
