import React from "react";
import { FieldArray } from "redux-form";
import { renderFiles } from "../Renders/renderFiles";

const ArchivosProyecto = ({ edit, isDisabled = false }) => {
    return (
        <div className="mt-3 mb-3">
            <h5 className="title--blue--1">Archivos</h5>
            <div className="card-form">
                <div className="row">
                    <div className="col-12">
                        <FieldArray
                            edit={edit}
                            component={renderFiles}
                            name="archivos"
                            rerenderOnEveryChange={true}
                            isDisabled={isDisabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export { ArchivosProyecto };
