import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import Swal from 'sweetalert2';
import { api } from "api";

const LOADER = 'TIPO_PERMISO_LOADER';
const DATA = "TIPO_PERMISO_DATA";
const SUBMIT = "TIPO_PERMISO_SUBMIT";

const ENDPOINT = "tipo_permiso";

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setData = data => ({
    type: DATA,
    data
});

// ------------------------------------
// Actions
// ------------------------------------
export const onSubmit = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post(`${ENDPOINT}`, data).then(() => {
        Swal.fire({
            title: 'Éxito',
            text: 'Se ha guardado correctamente',
            type: 'success',
        })
        dispatch(push("/tipos_permiso"));
    }).catch((error) => {
        const textError = error && error.non_field_errors
        ? error.non_field_errors[0]
        : 'Ha ocurrido un error, inténtelo nuevamente.'
        ;
        Swal.fire({
            title: 'Error',
            text: textError,
            type: 'error',
        })
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listar = (page=1, params={}) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${ENDPOINT}`, { page, ...params }).then(response => {
        dispatch(setData(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const obtenerTipoPermiso = (id=null) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${ENDPOINT}/${id}`).then(response => {
        dispatch(initializeForm('CrearEditarForm', response));
    })
        .catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actualizarTipoPermiso = (data={}, id=null) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${ENDPOINT}/${id}`, data).then(() => {
        Swal.fire({
            title: 'Éxito',
            text: 'Se ha actualizado correctamente',
            type: 'success',
        })
        dispatch(push("/tipos_permiso"));
    }).catch((error) => {
        const textError = error && error.non_field_errors
        ? error.non_field_errors[0]
        : 'Ha ocurrido un error, inténtelo nuevamente.'
        ;
        Swal.fire({
            title: 'Error',
            text: textError,
            type: 'error',
        })
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${ENDPOINT}/${id}`).then(() => {
        Swal.fire({
            title: 'Éxito',
            text: 'Se ha eliminado correctamente',
            type: 'success',
        })
    })
        .catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actions = {
    onSubmit,
    eliminar,
    obtenerTipoPermiso,
    actualizarTipoPermiso,
    listar,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
};

export const initialState = {
    loader: false,
    data: [],
};

export default handleActions(reducers, initialState);
