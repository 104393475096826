import React, { useState } from "react";
import { Field } from "redux-form";
import { renderField, renderSelectField } from "../../../Utils/renderField";

const DatosProyecto = (props) => {
    const {
        clientes,
        plantillas,
        obtenerPlantilla,
        isDisabled = false,
        tipos_proyectos,
    } = props;

    const [nombrePais, setNombrePais] = useState(null);

    
    const handleClienteChange = (e) => {

        const selectedCliente = e;
        console.log("Cliente seleccionado", selectedCliente);

        if (selectedCliente && selectedCliente.pais) {
            const nombrePais =selectedCliente.pais
            setNombrePais(nombrePais);
        } else {
            setNombrePais(null);
        }
    };


    const handlePlantillaChange = (value) => {
        if (value) {
            obtenerPlantilla(value, true);
        }
    };

    return (
        <div>
            <h5 className="title--blue--1">Datos del proyecto</h5>
            <div className="card-form">
                <div className="row">
                    <div className="form-group has-feedback col-6">
                        <label htmlFor="nombre">Nombre Proyecto</label>
                        <Field
                            name="nombre"
                            label="Nombre Proyecto"
                            component={renderField}
                            type="text"
                            className="input--style"
                            placeholder="Nombre..."
                            readonly={isDisabled}
                        />
                    </div>
                    <div className="form-group has-feedback col-6">
                        <div className="d-flex">
                            <label htmlFor="cliente">Cliente </label>
                            {nombrePais && <label htmlFor="cliente">&emsp;(País: {nombrePais})</label>}
                        </div>
                        <Field
                            name="cliente"
                            label="Cliente" 
                            component={renderSelectField}
                            options={clientes}
                            className="input--style"
                            labelKey="label"
                            valueKey="value"
                            placeholder="Cliente..."
                            select_style={{
                                control: (styles) => ({
                                    ...styles,
                                    color: "#1F4D7B",
                                    borderColor: "#1F4D7B",
                                }),
                            }}
                            disabled={isDisabled}
                            extra_change
                            objeto
                            extraChange={handleClienteChange}
                        />
                    </div>
                    

                </div>
                <div className="row">
                    <div className="form-group has-feedback col-6">
                        <label htmlFor="codigo">ID</label>
                        <Field
                            name="codigo"
                            label="ID"
                            component={renderField}
                            className="input--style"
                            placeholder="ID..."
                            readonly={isDisabled}
                        />
                    </div>
                    <div className="form-group has-feedback col-6">
                        <label htmlFor="plantilla">
                            Plantilla del proyecto
                        </label>
                        <Field
                            name="plantilla"
                            label="Plantilla del proyecto"
                            component={renderSelectField}
                            options={plantillas}
                            className="input--style"
                            labelKey="label"
                            valueKey="value"
                            placeholder="Plantilla..."
                            select_style={{
                                control: (styles) => ({
                                    ...styles,
                                    color: "#1F4D7B",
                                    borderColor: "#1F4D7B",
                                }),
                            }}
                            extra_change={true}
                            extraChange={handlePlantillaChange}
                            disabled={isDisabled}
                        />
                    </div>

                     {/* Se agrega la nueva lista desplegable para tipo proyecto */}
                    <div className="form-group has-feedback col-6">
                            <label htmlFor="tipo_proyecto">Tipo de proyecto</label>
                            <Field
                                name="tipo_proyecto"
                                label="Tipo de proyecto"
                                options={tipos_proyectos}
                                component={renderSelectField}
                                labelKey="label"
                                valueKey="value"
                                select_style={{
                                    control: (styles) => ({
                                        ...styles,
                                        color: "#1F4D7B",
                                        borderColor: "#1F4D7B",
                                    }),
                                }}
                                disabled={isDisabled}
                            />
                    </div>
                </div>
            </div>
        </div>
    );
};

export { DatosProyecto };
