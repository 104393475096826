import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/gantt/gantt';
import Visualizar from './Visualizar';
import { actions as puestos } from '../../../../redux/modules/puestos/puestos';


const ms2p = (state) => {
  return {
    ...state.gantt,
    puestos: { ...state.puestos },
  };
};

const md2p = { ...actions, filterStalls: puestos.filter };

export default connect(ms2p, md2p)(Visualizar);