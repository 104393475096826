import React from "react";
import { NavLink } from "react-router-dom";

const NavItemList = ({
    icon,
    label,
    active = false,
    items = [],
    navToggle = () => {},
    role,
    onClick = () => {},
    logOut = () => {},
    first = false,
}) => {
    let classNames = "nav-link pr-1";
    if (!first) {
        classNames += " pl-3";
    }

    return (
        <li className="nav-item user-select-none" role="button">
            <div>
                <div onClick={onClick} className={classNames}>
                    <div className="d-inline-block item-icon-wrapper">
                        <i className="material-icons title--i">{icon}</i>
                    </div>
                    <span>{label}</span>
                    <div className="d-inline-block item-icon-wrapper ml-3">
                        <i
                            className="material-icons rotate"
                            style={{ color: "black" }}
                        >
                            arrow_drop_down
                        </i>
                    </div>
                </div>
                <div className={`sublist ${classNames} pt-0 ${active ? "sublist--show" : ""}`}>
                    <NavList items={items} navToggle={navToggle} role={role} logOut={logOut}/>
                </div>
            </div>
        </li>
    );
};

const NavItemLink = ({
    to,
    navToggle = () => {},
    icon,
    label,
    first = false,
    logOut = () => {},
    logout = false,
}) => {
    let classNames = "nav-link i--h pr-1";
    if (!first) {
        classNames += " pl-3";
    }

    const onClick = logout ? logOut : navToggle;

    return (
        <li className="nav-item user-select-none" role="button">
            <NavLink
                to={to}
                exact
                className={classNames}
                activeClassName={"active"}
                onClick={onClick}
            >
                <div className="d-inline-block item-icon-wrapper">
                    <i className="material-icons">{icon}</i>
                </div>
                <span>{label}</span>
            </NavLink>
        </li>
    );
};

export const NavList = ({
    items = [],
    navToggle,
    role,
    children,
    first = false,
    logOut = () => {},
}) => {
    const [current, _setCurrent] = React.useState("");

    const setCurrent = (label) => {
        if (current === label) {
            _setCurrent("");
        } else {
            _setCurrent(label);
        }
    };

    const hasPermissions = (allowedRoles) => {
        if (!allowedRoles) return true;
        for (let allowed of allowedRoles) {
            if (Number(role) === allowed) return true;
        }
        return false;
    };

    return (
        <ul className="nav--no-borders flex-column nav">
            {items.map((item) => {
                if (!hasPermissions(item.allow)) return null;
                if (item.items)
                    return (
                        <NavItemList
                            {...item}
                            navToggle={navToggle}
                            role={role}
                            key={item.label}
                            onClick={() => setCurrent(item.label)}
                            active={item.label === current}
                            first={first}
                            logOut={logOut}
                        />
                    );
                return (
                    <NavItemLink
                        {...item}
                        navToggle={navToggle}
                        key={item.label}
                        first={first}
                        logOut={logOut}
                    />
                );
            })}
            {children}
        </ul>
    );
};
