import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import Swal from "sweetalert2";
import moment from "moment";
import { api } from "api";

const LOADER = "SOLICITUD_PERSONAL_LOADER";
const DATA = "SOLICITUD_PERSONAL_DATA";
const SUBMIT = "SOLICITUD_PERSONAL_SUBMIT";
const MOTIVOS = "SOLICITUD_PERSONAL_MOTIVOS";
const USER = "SOLICITUD_PERSONAL_USER";

const ENDPOINT = "solicitud_personal";

export const MOTIVOS_RECHAZO = `${ENDPOINT}/motivos_rechazo`;

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setMotivos = (motivos) => ({
    type: MOTIVOS,
    motivos,
});

const setInformation = (user) => ({
    type: USER,
    user,
});

// ------------------------------------
// Actions
// ------------------------------------
export const onSubmit =
    (data = {}, revision = false) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const endpoint = revision
            ? `${ENDPOINT}/crear_reclutamiento`
            : ENDPOINT;
        api.post(endpoint, data)
            .then(() => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha guardado correctamente",
                    type: "success",
                    showConfirmButton: false,
                    timer: 500,
                });
                dispatch(push("/solicitud_personal"));
            })
            .catch((error) => {
                const textError =
                    error && error.non_field_errors
                        ? error.non_field_errors[0]
                        : "Ha ocurrido un error, inténtelo nuevamente.";
                Swal.fire({
                    title: "Error",
                    text: textError,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const listar =
    (page = 1, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}`, { page, ...params })
            .then((response) => {
                dispatch(setData(response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const obtenerSolicitudPersonal =
    (id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}/${id}`)
            .then((response) => {
                const horarioDisplay = `${
                    response.hora_entrada
                        ? moment(response.hora_entrada, "hh:mm").format(
                              "hh:mm A"
                          )
                        : "--:--"
                } - ${
                    response.hora_salida
                        ? moment(response.hora_salida, "hh:mm").format(
                              "hh:mm A"
                          )
                        : "--:--"
                }`;

                response.horario_display = horarioDisplay;

                const { moneda } = response;
                const rangoDisplay = `${moneda} ${
                    response.min_salario
                        ? Number(response.min_salario).toFixed(2)
                        : "---"
                } - ${moneda} ${
                    response.max_salario
                        ? Number(response.max_salario).toFixed(2)
                        : "---"
                }`;

                response.rango_display = rangoDisplay;

                dispatch(
                    initializeForm("CrearEditarFormSolicitudPersonal", response)
                );

                dispatch(
                    setInformation({
                        nombre: response.solicitante_label,
                        fecha: moment(response.creado).format("l"),
                    })
                );
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const actualizarSolicitudPersonal =
    (data = {}, id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.put(`${ENDPOINT}/${id}`, data)
            .then(() => {
                Swal.fire({
                    title: "Actualizado",
                    text: "Cambios guardados",
                    type: "success",
                    showConfirmButton: false,
                    timer: 500,
                });
                dispatch(push("/solicitud_personal"));
            })
            .catch((error) => {
                const textError =
                    error && error.non_field_errors
                        ? error.non_field_errors[0]
                        : "Ha ocurrido un error, inténtelo nuevamente.";
                Swal.fire({
                    title: "Error",
                    text: textError,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${ENDPOINT}/${id}`)
        .then(() => {
            Swal.fire({
                title: "Éxito",
                text: "Se ha eliminado correctamente",
                type: "success",
            });
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const changeState = ({
    id = null,
    state = "",
    successMessage = "Operación realizada con éxito",
    errorMesssage = "Ha ocurrido un error, inténtelo nuevamente.",
    dispatch,
    data = {},
    isRRHH = false,
}) => {
    dispatch(setLoader(true));
    api.put(`${ENDPOINT}/${id}/${state}`, data)
        .then(() => {
            Swal.fire({
                title: "Éxito",
                text: successMessage,
                type: "success",
                showConfirmButton: false,
                timer: 500,
            });
            dispatch(push(isRRHH ? "/reclutamiento" : "/solicitud_personal"));
        })
        .catch((error) => {
            Swal.fire({
                title: "Error",
                text: errorMesssage,
                type: "error",
            });
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const reclutamiento =
    ({ id }) =>
    (dispatch) => {
        changeState({
            id,
            state: "reclutamiento",
            successMessage: "En reclutamiento",
            dispatch,
        });
    };

const contratado =
    ({ id, data = {}, isRRHH = false }) =>
    (dispatch) => {
        changeState({
            id,
            state: "contratado",
            successMessage: "Contratado",
            dispatch,
            data,
            isRRHH,
        });
    };

const rechazado =
    ({ id, data = {}, isRRHH = false }) =>
    (dispatch) => {
        changeState({
            id,
            state: "rechazado",
            successMessage: "Rechazado",
            dispatch,
            data,
            isRRHH,
        });
    };

export const actions = {
    onSubmit,
    eliminar,
    obtenerSolicitudPersonal,
    actualizarSolicitudPersonal,
    listar,
    reclutamiento,
    contratado,
    rechazado,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [USER]: (state, { user }) => {
        return {
            ...state,
            user,
        };
    },
};

export const initialState = {
    loader: false,
    data: {},
    user: { nombre: "", fecha: "" },
};

export default handleActions(reducers, initialState);
