import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

/* Generic buttons */

const ButtonLikeAction = ({
    backgroundColor,
    color,
    icon,
    handleClick = () => {},
}) => (
    <button
        className="btn d-flex align-items-center justify-content-center standard-action"
        type="button"
        style={{ backgroundColor, color }}
        onClick={handleClick}
    >
        <i className="material-icons">{icon}</i>
    </button>
);

const LinkLikeAction = ({ backgroundColor, color, icon, to = "" }) => (
    <Link
        to={to}
        style={{ backgroundColor, color }}
        className="standard-action"
    >
        <i className="material-icons">{icon}</i>
    </Link>
);

const DeleteButton = ({ onDelete, backgroundColor, color }) => {
    const handleDelete = () => {
        Swal.fire({
            title: "¿Eliminar?",
            text: "¡No podrá revertir esta acción!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "¡Sí, eliminar!",
            cancelButtonText: "No, cancelar",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                onDelete();
            }
        });
    };

    return (
        <ButtonLikeAction
            backgroundColor={backgroundColor}
            color={color}
            icon={"delete"}
            handleClick={handleDelete}
        />
    );
};

export const ExtraActions = {
    button: ButtonLikeAction,
    link: LinkLikeAction,
    delete: DeleteButton,
};
