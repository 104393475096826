import React from "react";
import { TableHeaderColumn } from "react-bootstrap-table";

import Grid from "../../Utils/Grid";
import FiltersData from "./FiltersData";
import { api } from "api";

import { standardActions } from "../../Utils/Grid/StandardActions.js";
import { FILTER_LIST as filterListProyectos } from "../../../../redux/modules/proyectos/proyectos";
import { LIST_CONTACTO as listContactoRecursos } from "../../../../redux/modules/recursos/recursos";

const Listar = ({ history }) => {
    /* Estado */
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { params, loading, data, projects, search } = state;

    /* Obtener proyectos y recursos */
    React.useEffect(() => {
        getInitialData(params);
    }, []);

    const getInitialData = async (currentParams) => {
        try {
            onLoad();
            const data = await api.get(listContactoRecursos, currentParams);
            onGetData(data);
            const projects = await api.get(filterListProyectos);
            onGetProjects(projects);
        } catch (error) {
            console.error(error);
        } finally {
            onLoad(false);
        }
    };

    /* Evitar doble petición */
    const getData = async (currentParams) => {
        try {
            onLoad();
            const data = await api.get(listContactoRecursos, currentParams);
            onGetData(data);
        } catch (error) {
        } finally {
            onLoad(false);
        }
    };

    const onSearch = () => {
        const deleteParam = search.length < 1;
        onChangeParams({
            name: "search",
            value: deleteParam ? null : search,
        });
    };

    const onChangeSearch = (payload) => {
        dispatch({ type: actionTypes.search, payload });
    };

    const onLoad = (payload = true) => {
        dispatch({ type: actionTypes.loading, payload });
    };

    const onGetData = (data) => {
        dispatch({ type: actionTypes.setData, payload: data });
    };

    const onGetProjects = (projects) => {
        dispatch({ type: actionTypes.setProjects, payload: projects });
    };

    const onChangeParams = (newParam) => {
        let currentParams = params;
        currentParams.page = 1;
        if (!newParam.value) {
            delete currentParams[newParam.name];
        } else {
            currentParams = {
                ...currentParams,
                [newParam.name]: newParam.value,
            };
        }
        dispatch({ type: actionTypes.changeParams, payload: currentParams });
        getData(currentParams);
    };

    const onPageChange = (newPage) => {
        onChangeParams({ name: "page", value: newPage });
    };

    return (
        <React.Fragment>
            <div className="flex row mt-2">
                <div className="col-12">
                    <h2 className="title--blue">RECURSOS</h2>
                </div>
            </div>
            <hr />
            <div className="row">
                <FiltersData
                    proyectos={projects}
                    onChangeParams={onChangeParams}
                    onChangeSearch={onChangeSearch}
                    onSearch={onSearch}
                    search={search}
                />
            </div>
            <Grid
                data={data}
                loading={loading}
                page={params.page}
                onPageChange={onPageChange}
                page_size={params.page_size}
            >
                <TableHeaderColumn dataField="codigo" isKey>
                    Código
                </TableHeaderColumn>
                <TableHeaderColumn dataField="nombre" dataSort width="30%">
                    Nombre
                </TableHeaderColumn>
                <TableHeaderColumn dataField="puesto" dataSort>
                    Puesto
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="proyectos"
                    dataSort
                    tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    dataFormat={(cell, row) => {
                        return cell && cell.length > 0 ? (
                            <ul
                                style={{ wordWrap: "break-word" }}
                                className="p-0 m-0"
                            >
                                {cell.map((proyecto) => {
                                    return (
                                        <li
                                            key={proyecto.id}
                                            style={{
                                                wordWrap: "break-word",
                                                fontSize: 11,
                                            }}
                                        >
                                            {proyecto.proyecto}
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <span style={{ fontSize: 11 }}>
                                No hay proyectos activos
                            </span>
                        );
                    }}
                >
                    Proyectos
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="id"
                    dataSort
                    dataFormat={(cell, row) => {
                        const actions = standardActions({
                            ver: "recursos_contacto",
                        });
                        return actions(cell, row);
                    }}
                >
                    Acciones
                </TableHeaderColumn>
            </Grid>
        </React.Fragment>
    );
};

const initialState = {
    params: {
        activo: true,
        renuncia: false,
        despedido: false,
        page: 1,
        page_size: 10,
    },
    loading: false,
    data: {},
    projects: [],
    search: "",
};

const actionTypes = {
    loading: "LOADING",
    changeParams: "CHANGE_PARAMS",
    setData: "SET_DATA",
    setProjects: "SET_PROJECTS",
    search: "SEARCH",
};

const reducerObject = (state, payload) => ({
    [actionTypes.setData]: {
        ...state,
        data: payload,
    },
    [actionTypes.loading]: {
        ...state,
        loading: payload,
    },
    [actionTypes.changeParams]: {
        ...state,
        params: payload,
    },
    [actionTypes.setProjects]: {
        ...state,
        projects: payload,
    },
    [actionTypes.search]: {
        ...state,
        search: payload,
    },
});

const reducer = (state, action) => {
    return reducerObject(state, action.payload)[action.type] || state;
};

export default Listar;
