import React from "react";
import Popup from "reactjs-popup";
import moment from "moment";

class ListItem {
    constructor(label, value) {
        this.label = label;
        this.value = value ? value : "---";
    }
}

export const RecursoPreview = ({ recurso }) => {
    const {
        foto_perfil,
        codigo,
        nombre,
        dpi,
        nacionalidad,
        salario,
        tipo_moneda,
        puesto,
        email_empresarial,
        seniority_puesto,
        telefono,
        direccion,
        fecha_nacimiento,
        proyecto_default,
        fecha_inicio,
        tipo_contratacion,
        tipo_contrato,
        estado,
        pais,
        departamento_label,
        municipio_label,
        certificados_ingles,
        foto_dpi,
        cv_empresa,
        cv_personal,
    } = recurso;

    const items = [
        new ListItem("Código", codigo),
        new ListItem("Nombre", nombre),
        new ListItem("Correo empresarial", email_empresarial),
        new ListItem("Teléfono", telefono),
        new ListItem("DPI", dpi),
        new ListItem("Dirección", direccion),
        new ListItem(
            "Fecha de nacimiento",
            moment(fecha_nacimiento).format("l")
        ),
        new ListItem("Salario", `${tipo_moneda ? tipo_moneda : ""} ${salario}`),
        new ListItem("Puesto", puesto.nombre),
        new ListItem(
            "Seniority",
            seniority_puesto ? seniority_puesto.nombre : ""
        ),
        new ListItem(
            "Tipo de proyecto",
            proyecto_default ? proyecto_default.nombre : ""
        ),
        new ListItem(
            "Fecha inicio de labores",
            moment(fecha_inicio).format("l")
        ),
        new ListItem(
            "Tipo de contratación",
            tipo_contratacion ? tipo_contratacion.nombre : ""
        ),
        new ListItem("Tipo contrato", tipo_contrato),
    ];

    if (nacionalidad === "guatemalteco") {
        items.push(new ListItem("Departamento", departamento_label));

        items.push(new ListItem("Municipio", municipio_label));
    }

    if (nacionalidad === "extranjero") {
        items.push(new ListItem("País", pais));

        items.push(new ListItem("Estado (país)", estado));
    }

    const openNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <Popup
            trigger={
                <p
                    role="button"
                    className="m-0"
                    style={{ textDecoration: "underline" }}
                >
                    {nombre}
                </p>
            }
            className="recurso-preview"
            modal
        >
            {(close) => (
                <React.Fragment>
                    <div className="row w-100 d-flex align-items-center justify-content-end">
                        <button
                            className="btn position-fixed mt-5"
                            onClick={close}
                        >
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                    {foto_perfil ? (
                        <img
                            src={foto_perfil}
                            alt="foto de perfil"
                            width="100px"
                        />
                    ) : null}
                    <section>
                        <ul className="info-list">
                            {items.map((item) => (
                                <li key={item.label}>
                                    <span className="title--blue--1">
                                        {item.label}:
                                    </span>
                                    <p className="text--blue--1 m-0 mr-3">
                                        {item.value}
                                    </p>
                                </li>
                            ))}
                            {foto_dpi ? (
                                <li>
                                    <span className="title--blue--1">
                                        Foto de DPI:
                                    </span>
                                    <button
                                        className="btn btn-link"
                                        onClick={() => openNewTab(foto_dpi)}
                                    >
                                        Ver
                                    </button>
                                </li>
                            ) : null}
                            {cv_empresa ? (
                                <li>
                                    <span className="title--blue--1">
                                        CV de la empresa:
                                    </span>
                                    <button
                                        className="btn btn-link title--blue--1"
                                        onClick={() => openNewTab(cv_empresa)}
                                    >
                                        Ver
                                    </button>
                                </li>
                            ) : null}
                            {cv_personal ? (
                                <li>
                                    <span className="title--blue--1">
                                        CV personal:
                                    </span>
                                    <button
                                        className="btn btn-link"
                                        onClick={() => openNewTab(cv_personal)}
                                    >
                                        Ver
                                    </button>
                                </li>
                            ) : null}
                        </ul>
                        {certificados_ingles ? (
                            <React.Fragment>
                                <p className="title--blue--1 m-0">
                                    Certificados de inglés
                                </p>
                                <ul className="files-list">
                                    {certificados_ingles.map(
                                        (certificado, index) => {
                                            if (certificado.archivo) {
                                                return (
                                                    <li key={certificado.id}>
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={() =>
                                                                openNewTab(
                                                                    certificado.archivo
                                                                )
                                                            }
                                                        >
                                                            Certificado{" "}
                                                            {index + 1}
                                                        </button>
                                                    </li>
                                                );
                                            }
                                            return null;
                                        }
                                    )}
                                </ul>
                            </React.Fragment>
                        ) : null}
                    </section>
                </React.Fragment>
            )}
        </Popup>
    );
};
