import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import Swal from "sweetalert2";

import { api } from "api";

// ------------------------------------

// Constants
const SUBMIT = "HISTORIAL_INTERNO_SUBMIT";
const LOADER = "HISTORIAL_INTERNO_LOADER";
const DATA = "HISTORIAL_INTERNO_DATA";
const ALL = "HISTORIAL_INTERNO_ALL";
const FILTER = "HISTORIAL_INTERNO_FILTER";

const ENDPOINT = "historial_interno";   
const ENDPOINT2 = "recurso";


export const constants = {  
     SUBMIT, 
 };

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setAll = (all) => ({
     type: ALL,
     all,
});

export const setFilter = (filter) => ({
     type: FILTER,
     filter,
 });


// ------------------------------------

// Actions

export const onSubmit = (data= {}) => (dispatch, getStore) => {
     dispatch(setLoader(true));
     api.post(`${ENDPOINT}/agregar_puesto`, data)
         .then((response) => {
             Swal.fire({ 
                 title: "Registro creado",
                 text: "El registro se ha creado exitosamente.",
                 type: "success",
             });

            //dispatch(initializeForm("HistorialInternoCrerEditarForm", {}));
            dispatch(push("/historial_interno"));
         })  
         .catch((error) => {
             let textError = "Error desconocido"; // Mensaje por defecto en caso de que no haya mensaje de error del backend
             if (error.response && error.response.data && error.response.data.error) {
                 textError = error.response.data.error; // Usar el mensaje de error del backend si está disponible
             }
             Swal.fire({
                 title: "Error",
                 text: error.error || textError || error.message,
                 type: "error",
             });
         })
         .finally(() => {
             dispatch(setLoader(false));
         });
 };

export const listar =
    (page = 1, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT2}/recursos_historial`, { page, ...params })
            .then((response) => {
                dispatch(setData(response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
};


    export const obtenerHistorialInterno =
    (id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}/${id}`)
            .then((response) => {
                dispatch(initializeForm("CrearEditarForm", response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    export const historial =
    (id, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}`, { recurso_id: id, ...params }) 
            .then((response) => {
                dispatch(setData(response));
            })
            .catch((error) => {
                console.error('Error al obtener los datos:', error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

// export const actualizar=
//     (data = {}, id = null) =>
//     (dispatch) => {
//         dispatch(setLoader(true));
//         api.put(`${ENDPOINT}/${id}`, data)
//             .then(({}) => {
//                 Swal.fire({
//                     title: "Éxito",
//                     text: "Se ha actualizado correctamente",
//                     type: "success",
//                 });
//                 dispatch(initializeForm("RecursosCrearEditarForm", {}));
//                 dispatch(push("/recursos"));
//             }) // then formData
//             .catch((error) => {
//                 let text = "Ha ocurrido un error, inténtelo nuevamente.";
//                 if (
//                     error &&
//                     error.detail &&
//                     error.detail.includes("Duplicate")
//                 ) {
//                     text = "Código ya asignado, por favor inténtelo con otro.";
//                 } else if (error && error.non_field_errors) {
//                     text = error.non_field_errors[0];
//                 }

//                 Swal.fire({
//                     title: "Error",
//                     text,
//                     type: "error",
//                 });
//             })
//             .finally(() => {
//                 dispatch(setLoader(false));
//             });
//     };

//     export const eliminar =
//     (id, params = {}) =>
//     (dispatch) => {
//         dispatch(setLoader(true));
//         api.eliminar(`${ENDPOINT}/${id}`)
//             .then((response) => {
//                 Swal.fire({
//                     title: "Éxito",
//                     text: "Se ha eliminado correctamente",
//                     type: "success",
//                 });
//                 dispatch(listar(1, params));
//             })
//             .catch(() => {})
//             .finally(() => {
//                 dispatch(setLoader(false));
//             });
//     };

export const actions = {
    listar,
    onSubmit,
    obtenerHistorialInterno,
    historial,
    // actualizar,
    // eliminar,
};  

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ALL]: (state, { all }) => {
         return {
             ...state,
             all,
         };
    },
    [FILTER]: (state, { filter }) => {
        return {
            ...state,
            filter,
        };
    },
};

export const initialState = {
    loader: false,
    data: {},
    all: [],
    filter: [],
};

export default handleActions(reducers, initialState);