import React, { Component } from "react";
import { connect } from 'react-redux';
import LoadMask from "Utils/LoadMask/LoadMask";
import CrearEditarForm from "./CrearEditarForm";
import HistorialAumentos from "./HistorialAumentos";
import { historial } from '../../../../redux/modules/historial_economicos/historial_economicos';


class CrearEditar extends Component {
    state = {
        loader: false,
        params: {}, // Define los parámetros en el estado
    };
 
    componentDidMount() {
         this.loadData();
    }
 
    componentDidUpdate(prevProps, prevState) {
        // Verifica si el historialData ha cambiado de manera significativa para cargar los datos nuevamente
        if (prevProps.historialData !== this.props.historialData &&
            JSON.stringify(prevProps.historialData) !== JSON.stringify(this.props.historialData)) {
            this.loadData();
        }
    }
    
 
    loadData() {
         const { page, params } = this.state;
         const { match } = this.props;
         const id = match.params.id; // Obtén el ID de los parámetros de la URL
         this.props.loadHistorial(id, page, params);
    }
 
    onSubmit = (data) => {
        const { onSubmit } = this.props;
        onSubmit(data);
    };
 
    render() {
        const { loader } = this.state;
        const { match, historialData } = this.props;
        console.log("Leyendo datos de redux")
        console.log(historialData)
 
        // Verificar si historialData es un array antes de llamar a map
        if (!Array.isArray(historialData)) {
            return null; // O puede mostrar un mensaje de carga, por ejemplo
        }
 
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h2 className="title--blue">CREAR HISTORIAL ECONÓMICO</h2>
                    </div>
                </div>
                <hr />
                <div className="login-wrapper">
                    <div className="card card-login w-100">
                        <LoadMask loading={loader} light>
                            <CrearEditarForm
                                onSubmit={this.onSubmit}
                                codigo_empleado={match.params.id}
                            />
                            <HistorialAumentos historialData={historialData} />
                        </LoadMask>
                    </div>
                </div>
            </React.Fragment>
        );
    }
 }
 
 export default CrearEditar;