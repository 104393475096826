import React from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import { Chart, registerables } from "chart.js";
import { api } from "api";
import moment from "moment";
import { ChartBar } from "./ChartBar";
import { ColaboradoresCard } from "./ColaboradoresCard";

Chart.register(...registerables);

export const Visualizar = ({ goToColaborador }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    React.useEffect(() => {
        getReports();
    }, []);

    const onRowClick = (row) => {
        goToColaborador(row.id);
    };

    const getReports = async () => {
        try {
            onLoad();
            const reports = await api.get("permiso/reportes");
            onGetReports(reports);
            const now = moment();
            onUpdate(now.format("DD/MM/YYYY hh:mm A"));
        } catch (e) {
            console.error(e);
        }
    };

    const { reports = {}, loading, last_update } = state;
    const {
        tipos_permisos = {},
        tipos_permisos_horas = {},
        tipos_permisos_dias = {},
        colaboradores_recurrentes = [],
        colaboradores_recurrentes_tiempo = [],
    } = reports;
    const { chart = { labels: [], datasets: [] }, total_permisos = 0 } =
        tipos_permisos;

    const {
        chart: chart_horas = { labels: [], datasets: [] },
        total_permisos: total_permisos_horas = 0,
    } = tipos_permisos_horas;

    const {
        chart: chart_dias = { labels: [], datasets: [] },
        total_permisos: total_permisos_dias = 0,
    } = tipos_permisos_dias;

    const onLoad = () => dispatch({ type: actionTypes.loading });
    const onGetReports = (reports = {}) => {
        dispatch({ type: actionTypes.setReports, payload: reports });
    };

    const onUpdate = (value) =>
        dispatch({ type: actionTypes.lastUpdate, payload: value });

    return (
        <LoadMask loading={loading} blur={true}>
            <div className="flex row mt-2">
                <div className="col-12">
                    <h2 className="title--blue">Reportes de permisos</h2>
                </div>
            </div>
            <hr />
            <ChartBar
                data={chart}
                title="Tipos de permisos"
                total={`Total de permisos: ${total_permisos}`}
                lastUpdate={`Última actualización: ${last_update}`}
            />

            <ChartBar
                data={chart_dias}
                title="Tipos de permisos por días"
                total={`Suma total: ${total_permisos_dias} días`}
                lastUpdate={`Última actualización: ${last_update}`}
            />

            <ChartBar
                data={chart_horas}
                title="Tipos de permisos por horas"
                total={`Suma total: ${total_permisos_horas} hrs`}
                lastUpdate={`Última actualización: ${last_update}`}
            />

            <ColaboradoresCard
                colaboradores={colaboradores_recurrentes}
                onRowClick={onRowClick}
                title="Colaboradores recurrentes"
                fieldTitle="Permisos"
                field="total_permisos"
            />

            <ColaboradoresCard
                colaboradores={colaboradores_recurrentes_tiempo}
                onRowClick={onRowClick}
                title="Colaboradores con más tiempo"
                fieldTitle="Tiempo"
                field="dias_permisos"
                dataFormat={(cell, row) => {
                    let diasLabel = "días";
                    let horasLabel = "horas";

                    if (cell === 1) diasLabel = "día";
                    if (row.horas_permisos === 1) horasLabel = "hora";

                    return `${cell} ${diasLabel} y ${row.horas_permisos} ${horasLabel}`;
                }}
            />
        </LoadMask>
    );
};

const initialState = {
    loading: false,
    reports: {},
    last_update: "",
};

const actionTypes = {
    loading: "LOADING",
    setReports: "SET_REPORTS",
    lastUpdate: "LAST_UPDATE",
};

const reducerObject = (state, payload) => ({
    [actionTypes.setReports]: {
        ...state,
        reports: payload,
        loading: false,
    },
    [actionTypes.loading]: {
        ...state,
        loading: true,
    },
    [actionTypes.lastUpdate]: {
        ...state,
        last_update: payload,
    },
});

const reducer = (state, action) => {
    return reducerObject(state, action.payload)[action.type] || state;
};
