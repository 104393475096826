import React from "react";

import { renderNumber } from "../../../Utils/renderField";

import { Field } from "redux-form";

export const renderPorcentajes = ({
    fields,
    meta: { error, touched, submitFailed },
    fases = [],
    tipoFase,
}) => {
    React.useEffect(() => {
        if (fields.length === 0) {
            fases.forEach((f) => {
                if (f.lleva_porcentaje)
                    fields.push({
                        fase: f.id,
                        porcentaje: 0,
                        label: f.nombre,
                    });
            });
        }
    }, [fases]);

    return (
        <div className="col-10 mt-4">
            {(touched || submitFailed) && error && (
                <span className="text-danger">{error}</span>
            )}
            {fields.map((fase, index, fields) => {
                const currentField = fields.get(index);

                return (
                    <div
                        className="row"
                        key={index}
                        style={{
                            display:
                                currentField.fase !== tipoFase
                                    ? "none"
                                    : "flex",
                        }}
                    >
                        <div className="form-group has-feedback col-3 m-0 p-0">
                            <span className="col-5 title--blue--1">
                                Porcentaje %
                            </span>
                            <Field
                                name={`${fase}.porcentaje`}
                                component={renderNumber}
                                suffix="%"
                                placeholder="%"
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
