import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/proyectos/proyectos';
import { actions as user } from '../../../../redux/modules/cuenta/login';
import CrearEditar from './CrearEditar';

const ms2p = (state) => {
  return {
    ...state.proyectos,
    user: { ...state.login }
  };
};

const md2p = { ...actions, getRole: user.getRole  };

export default connect(ms2p, md2p)(CrearEditar);