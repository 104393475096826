import React from 'react';
import { Link } from "react-router-dom";
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSelectField } from '../../Utils/renderField';

const CrearEditarForm = (props) => {
    const { handleSubmit, departamentos } = props;

    const selectStyle = {
        control: (styles) => ({
            ...styles,
            color: "#1F4D7B",
            borderColor: "#1F4D7B",
        }),
    }

    return (
        <form name="CrearEditarForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className='row'>
                <div className="form-group has-feedback col-12 col-md-6">
                    <label htmlFor="nombre">Nombre</label>
                    <Field name="nombre" label="Nombre" component={renderField} type="text" className="input--style" />
                </div>
                <div className="form-group has-feedback col-12 col-md-6">
                    <label htmlFor="fase">Departamento</label>
                    <Field
                        name="departamento"
                        component={renderSelectField}
                        options={departamentos}
                        labelKey="label"
                        valueKey="value"
                        className="form-control"
                        placeholder="Departamento..."
                        select_style={selectStyle}
                        isClearable={true}
                    />
                </div>
            </div>
            <div className="buttons-box">
                <Link
                    to="/puestos"
                    className="btn btn-secondary"
                    style={{ fontSize: 15 }}
                >
                    Regresar
                </Link>
                <button type="submit" style={{ fontSize: 15 }} className="btn btn-primary m-1 align-self-center">Guardar</button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'CrearEditarForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Este campo es requerido'),
            departamento: validators.exists()('Este campo es requerido'),
        });
    },
})(CrearEditarForm);
