import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { renderField, renderDatePicker, renderDayPicker, renderSelectField } from "../../Utils/renderField";

const CrearEditarForm = (props) => {
    const { handleSubmit, paises } = props;
    return (
        <form
            name="CrearEditarForm"
            className="form-validate mb-lg"
            onSubmit={handleSubmit}
        >
            <div className="row">
                <div className="form-group has-feedback col-6">
                    <label htmlFor="nombre">Nombre del asueto</label>
                    <Field
                        name="nombre"
                        label="Nombre"
                        component={renderField}
                        type="text"
                        className="input--style"
                    />
                </div>

                <div className="form-group has-feedback col-6">
                    <label htmlFor="pais">País</label>
                    <Field
                        name="pais"
                        label="País"
                        component={renderSelectField}
                        select_style={{
                            control: (styles) => ({
                                ...styles,
                                color: "#1F4D7B",
                                borderColor: "#1F4D7B",
                            }),
                        }}
                        options={paises}
                        labelKey="label"
                        valueKey="value"
                        className="input--style"
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group has-feedback col-6">
                    <label htmlFor="fecha_inicio">Fecha inicio</label>
                    <Field
                        name="fecha_inicio"
                        label="Fecha inicio"
                        component={renderDayPicker}
                        className="input--style"
                    />
                </div>

                <div className="form-group has-feedback col-6">
                    <label htmlFor="fecha_final">Fecha final</label>
                    <Field
                        name="fecha_fin"
                        label="Fecha final"
                        component={renderDayPicker}
                        className="input--style"
                    />
                </div>
            </div>
            <div className="buttons-box">
                <Link
                    to="/asuetos"
                    className="btn btn-secondary"
                    style={{ fontSize: 15 }}
                >
                    Regresar
                </Link>
                <button
                    type="submit"
                    style={{ fontSize: 15 }}
                    className="btn btn-primary m-1 align-self-center"
                >
                    Guardar
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "CrearEditarForm", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()("Este campo es requerido"),
            pais: validators.exists()("Este campo es requerido"),
            fecha_inicio: validators.exists()("Este campo es requerido"),
            fecha_fin: validators.exists()("Este campo es requerido"),
        });
    },
})(CrearEditarForm);
