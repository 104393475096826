import React from "react";

import {
    renderSelectField,
    renderNumber,
    renderField,
} from "../../Utils/renderField";

import { Field } from "redux-form";

export const RenderFases = ({
    fields,
    meta: { error },
    isDisabled = false,
    monedaValue,
}) => {
    /* Eliminar fila y cambiar numero de fila */
    const removeRow = (rowIndex) => {
        let rowNumber = 1;
        fields.forEach((row, index) => {
            if (rowIndex === index) return;
            const current = fields.get(index);
            current.numero = rowNumber;
            rowNumber++;
        });
        fields.remove(rowIndex);
    };
    return (
        <div className="col-12 mt-4">
            <div className="row mb-1">
                <span className="col-2 title--blue--1">No de fase</span>
                <span className="col-3 title--blue--1">Nombre de fase</span>
                <span className="col-3 title--blue--1">Porcentaje</span>
                <span className="col-3 title--blue--1">Pago en dólares</span>
            </div>

            <span className="text-danger">{error}</span>

            {fields.map((fase, index) => {
                const currentField = fields.get(index);
                return (
                    <div className="row bg-white" key={fase}>
                        <div className="form-group has-feedback col-2">
                            {currentField.numero}
                        </div>
                        <div className="form-group has-feedback col-3">
                            <Field
                                name={`${fase}.nombre`}
                                component={renderField}
                                type="text"
                                placeholder="Fase..."
                                disabled={isDisabled}
                                className="input--style"
                            />
                        </div>
                        <div className="form-group has-feedback col-3">
                            <Field
                                name={`${fase}.porcentaje`}
                                component={renderNumber}
                                suffix="%"
                                placeholder="%"
                                readOnly={isDisabled}
                                className="input--style"
                            />
                        </div>
                        <div className="form-group has-feedback col-3">
                            <Field
                                name={`${fase}.pago`}
                                component={renderNumber}
                                prefix={`${monedaValue || ""} `}
                                placeholder={monedaValue || "..."}
                                readOnly={isDisabled}
                                className="input--style"
                            />
                        </div>
                        <div className="col d-flex justify-content-end">
                            {!isDisabled ? (
                                <button
                                    className="btn standard-action action--delete"
                                    type="button"
                                    style={{
                                        color: "rgb(246, 81, 96)",
                                        backgroundColor:
                                            "rgba(246, 81, 96, 0.3)",
                                        padding: "0",
                                    }}
                                    onClick={() => removeRow(index)}
                                >
                                    <i className="material-icons">delete</i>
                                </button>
                            ) : null}
                        </div>
                    </div>
                );
            })}
            {!isDisabled ? (
                <button
                    type="button"
                    className="btn mb-3 d-flex align-items-center justify-conten-center"
                    onClick={() => fields.push({ numero: fields.length + 1 })}
                >
                    <i
                        className="material-icons title--blue--1"
                        style={{ fontSize: "24px" }}
                    >
                        add
                    </i>
                    <p
                        className="title--blue--1"
                        style={{ margin: "0", fontSize: "14px" }}
                    >
                        Agregar
                    </p>
                </button>
            ) : null}
        </div>
    );
};
