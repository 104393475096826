import React from "react";
import { Field } from "redux-form";
import { renderNumber, renderCurrency } from "../../../Utils/renderField";

const ConfiguracionPrecio = ({ isDisabled = false }) => {
    return (
        <div className="mt-3">
            <h5 className="title--blue--1">Configuración de precio</h5>
            <div className="card-form">
                <div className="row">
                    <div className="form-group has-feedback col-3">
                        <label htmlFor="impuestos_adicionales">
                            Impuestos adicionales
                        </label>
                        <Field
                            name="impuestos_adicionales"
                            component={renderNumber}
                            className="input--style py-3"
                            placeholder="%"
                            suffix="%"
                            readOnly={isDisabled}
                        />
                    </div>
                    <div className="form-group has-feedback col-3">
                        <label htmlFor="margen_negocio">
                            Margen de negociación
                        </label>
                        <Field
                            name="margen_negocio"
                            component={renderNumber}
                            className="input--style py-3"
                            placeholder="%"
                            suffix="%"
                            readOnly={isDisabled}
                        />
                    </div>
                    <div className="form-group has-feedback col-3">
                        <label htmlFor="ganancia_adicional">
                            Ganancia adicional
                        </label>
                        <Field
                            name="ganancia_adicional"
                            component={renderNumber}
                            className="input--style py-3"
                            placeholder="%"
                            suffix="%"
                            readOnly={isDisabled}
                        />
                    </div>
                    <div className="form-group has-feedback col-3">
                        <label htmlFor="comision_venta">
                            Comisión de venta
                        </label>
                        <Field
                            name="comision_venta"
                            component={renderNumber}
                            decimalScale={2}
                            className="input--style py-3"
                            placeholder="%"
                            suffix="%"
                            readOnly={isDisabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export { ConfiguracionPrecio };
