const FASES = {
    BACKLOG: 1,
    DISENO: 5,
    DESAROLLO: 2,
    QA: 4,
    AJUSTES_QA: 8,
    SERVIDOR: 7,
    IMPLEMENTACION: 3,
    DOCUMENTACION: 6,
};

const PUESTOS = {
    SCRUM_MASTER: 1,
    PROGRAMADOR: 2,
    DISENADOR: 3,
    QA: 4,
    SYS_ADMIN: 5,
};

const numberFormatter = new Intl.NumberFormat("es-GT", {
    style: "decimal",
    maximumFractionDigits: 2,
});
const currencyFormatter = new Intl.NumberFormat("es-GT", {
    style: "currency",
    currency: "USD",
    currencyDisplay: "narrowSymbol",
    maximumFractionDigits: 2,
});

export const calulateSummary = ({
    currentPlantilla,
    personalValue = [],
    modulosValue = [],
    gastosValue = [],
    actividadesValue = [],
    impuestosAdicionales,
    margenNegocio,
    gananciaAdicional,
    comisionVenta,
}) => {
    /*
     * Cálculos previos
     * Horas SCRUM MASTER: Horas semanales * cantidad_scrums
     * Horas DISEÑO: Horas semanales * cantidad_diseñadores
     * cantidad Desarollo por semana $: tarifa * horas_semanales * cantidad
     */
    if (!currentPlantilla) currentPlantilla = {};
    const { fases_puestos = [] } = currentPlantilla;
    let horasSemanalesScrum = 0;
    let horasSemanalesDisenador = 0;

    personalValue.forEach((personalAsignado) => {
        const puestoPlantilla = fases_puestos.find(
            (fasePuesto) =>
                Number(personalAsignado.puesto) === fasePuesto.puesto &&
                personalAsignado.fase === fasePuesto.fase
        );

        if (!puestoPlantilla) return;

        if (Number(personalAsignado.puesto) === PUESTOS.SCRUM_MASTER) {
            horasSemanalesScrum +=
                puestoPlantilla.horas_semanales *
                Number(personalAsignado.cantidad);
        }

        if (Number(personalAsignado.puesto) === PUESTOS.DISENADOR) {
            horasSemanalesDisenador +=
                puestoPlantilla.horas_semanales *
                Number(personalAsignado.cantidad);
        }
    });

    const [horas, semanas] = calculateTime({
        modulosValue,
        currentPlantilla,
        horasSemanalesDisenador,
        horasSemanalesScrum,
        actividadesValue,
        personalValue,
    });

    const finanzas = calculateFinances({
        horas,
        semanas,
        currentPlantilla,
        gastosValue,
        personalValue,
        impuestosAdicionales,
        margenNegocio,
        gananciaAdicional,
        comisionVenta,
    });

    return [
        {
            title: "Horas",
            values: horas,
            suffix: " hrs",
            formatter: numberFormatter,
        },
        {
            title: "Finanzas",
            values: finanzas,
            suffix: "",
            formatter: currencyFormatter,
        },
        {
            title: "Semanas",
            values: semanas,
            suffix: " semanas",
            formatter: numberFormatter,
        },
    ];
};

export const calculateModulos = (modulosValue) => {
    let total = 0;
    modulosValue.forEach((modulo) => {
        total += Number(modulo.horas_ideales) || 0;
    });
    return total;
};

export const calculateActividades = (actividadesValue) => {
    let total = 0;
    actividadesValue.forEach((actividad) => {
        total += Number(actividad.total_horas) || 0;
    });
    return total;
};

export const calculateGastos = (gastosValue) => {
    let total = 0;
    gastosValue.forEach((gasto) => {
        total += Number(gasto.total) || 0;
    });
    return total;
};

export const getFasesFromPlantilla = (plantilla = {}) => {
    const { fases_puestos = [] } = plantilla;
    const fases = {};
    fases_puestos.forEach((f) => {
        fases[f.fase] = { value: f.fase, label: f.fase_label };
    });

    return Object.values(fases);
};

export const calculateTime = ({
    modulosValue,
    currentPlantilla = {},
    horasSemanalesDisenador,
    horasSemanalesScrum,
    actividadesValue,
    personalValue,
}) => {
    let horas = {
        // horas_backlog: { label: "Horas Backlog", value: 0 },
        horas_desarollo: { label: "Horas desarollo", value: 0 },
        // horas_qa: { label: "Horas QA", value: 0 },
        horas_totales: { label: "Horas totales", value: 0, highlight: true },
        horas_scrum: { label: "Horas SCRUM", value: 0 },
        horas_diseno: { label: "Horas diseño", value: 0 },
        margen_error: { label: "Margen de error", value: 0 },
        horas_entrega: { label: "Horas entrega", value: 0 },
        /* horas_reales_desarollo: {
            label: "Horas reales desarrollo",
            value: 0,
        }, */
    };

    let semanas = {
        total_semanas: { label: "Total semanas", value: 0, highlight: true },
    };

    const { part_margen_error = 0, porcentajes = [] } = currentPlantilla;

    /*
     * Horas de desarollo
     */

    horas.horas_desarollo.value += calculateModulos(modulosValue);
    // Agregar las horas de actividades a desarrollo
    actividadesValue.forEach((actividad) => {
        if (actividad.fase === FASES.DESAROLLO) {
            horas.horas_desarollo.value += Number(actividad.total_horas);
        }
    });


    /*
     * Calcular margen de error
     */
    horas.margen_error.value =
        horas.horas_desarollo.value * (part_margen_error / 100);

    /*
     * Agregar margen de error a las horas de desarollo
     */
    horas.horas_desarollo.value += horas.margen_error.value;

    /*
     * Obtener las fases de la plantilla
     */
    const fases = getFasesFromPlantilla(currentPlantilla);

    /*
     * Calcular horas
     * - Horas de cada fase = porcentaje_fase * horas_desarollo
     * - No contar desarrollo
     * - Las fases que no tienen porcentaje se toman de las actividades de entrega
     */
    const horasCalculadas = {};
    fases.forEach((fase) => {
        if (fase.value === FASES.DESAROLLO) return;
        horasCalculadas[fase.value] = {
            label: fase.label,
            value: 0,
        };
        const porcentaje = porcentajes.find((p) => p.fase === fase.value);
        if (porcentaje) {
            horasCalculadas[porcentaje.fase].value +=
                horas.horas_desarollo.value * (porcentaje.porcentaje / 100);
        }

        let totalHorasActividades = 0;
        actividadesValue.forEach((actividad) => {
            if (actividad.fase === fase.value) {
                totalHorasActividades += Number(actividad.total_horas);
            }
        });
        horasCalculadas[fase.value].value += totalHorasActividades;
    });


    horas = { ...horasCalculadas, ...horas };

    /*
     * Calcular horas totales y agregar horas_desarollo
     */
    const horasCalculadasValues = Object.values(horasCalculadas);
    horasCalculadasValues.forEach((h) => {
        horas.horas_totales.value += h.value;
    });

    horas.horas_totales.value += horas.horas_desarollo.value;


    /*
     * Calcular horas de actividades de entrega
     */
    horas.horas_entrega.value += calculateActividades(actividadesValue);

    /*
     * Horas en la semana por fase y semanas por fase
     */
    const { fases_puestos = [] } = currentPlantilla;
    const horasSemanaPorFase = {};

    personalValue.forEach((personalAsignado) => {
        const puestoPlantilla = fases_puestos.find(
            (fasePuesto) =>
                Number(personalAsignado.puesto) === fasePuesto.puesto &&
                personalAsignado.fase === fasePuesto.fase
        );
        if (!horasSemanaPorFase[personalAsignado.fase]) {
            horasSemanaPorFase[personalAsignado.fase] = 0;
        }
        if (puestoPlantilla && puestoPlantilla.suma_semana) {
            horasSemanaPorFase[personalAsignado.fase] +=
                personalAsignado.cantidad * puestoPlantilla.horas_semanales;
        }
    });

    const semanasPorFase = {};
    fases.forEach((f) => {
        const fase = f.value;
        const cantidadHoras = horasSemanaPorFase[fase] || 0;

        semanasPorFase[fase] = {
            label: `Semanas ${f.label}`,
            value: 0,
        };

        if (cantidadHoras > 0) {
            if (fase === FASES.DESAROLLO) {
                semanasPorFase[fase].value =
                    horas.horas_desarollo.value / cantidadHoras;
            } else {
                semanasPorFase[fase].value =
                    horasCalculadas[fase].value / cantidadHoras;
            }
        }
    });


    Object.values(semanasPorFase).forEach((s) => {
        semanas.total_semanas.value += s.value;
    });

    semanas = { ...semanasPorFase, ...semanas };
    /*
     * Horas totales scrum = horasSemanalesScrum * total_semanas
     * Horas totales diseñador = horasSemanalesDisenador * total_semanas
     */
    horas.horas_scrum.value = horasSemanalesScrum * semanas.total_semanas.value;

    horas.horas_diseno.value =
        horasSemanalesDisenador * semanas.total_semanas.value;

    return [horas, semanas];
};

export const calculateFinances = ({
    semanas,
    horas,
    currentPlantilla = {},
    gastosValue,
    personalValue,
    impuestosAdicionales,
    margenNegocio,
    gananciaAdicional,
    comisionVenta,
}) => {
    let finanzas = {
        project_manager: { label: "Project Manager", value: 0 },
        desarrollo: {
            label: "Precio desarrollo con impuesto",
            value: 0,
            highlight: true,
        },
        desarrollo_sin_impuestos: {
            label: "Precio desarrollo sin impuesto (17%)",
            value: 0,
            highlight: true,
            className: "text-danger",
        },
        administrativos: { label: "Gastos administrativos", value: 0 },
        garantia: { label: "Garantía", value: 0 },
        comision_venta: { label: "Comisión venta", value: 0 },
        margen_negociacion: { label: "Margen negociación", value: 0 },
        ganancia_adicional: { label: "Ganancia adicional", value: 0 },
        impuestos_adicionales: { label: "Impuestos adicionales", value: 0 },
        total: { label: "Total", value: 0, highlight: true },
        total_sin_iva: {
            label: "Total sin IVA (12%)",
            value: 0,
            highlight: true,
            className: "text-danger",
        },
    };

    const { fases_puestos = [] } = currentPlantilla;

    /*
     * Calcular las tarifas de cada fase por semana y también de todos los SCRUMS
     * cantidad de recursos * tarifa * horas semanales
     * cantidad scrums * tarifa * horas semanales
     */
    const tarifaSemanaPorFase = {};
    let tarifaSemanaScrum = 0;
    personalValue.forEach((personalAsignado) => {
        const puestoPlantilla = fases_puestos.find(
            (fasePuesto) =>
                Number(personalAsignado.puesto) === fasePuesto.puesto &&
                personalAsignado.fase === fasePuesto.fase
        );

        if (!tarifaSemanaPorFase[personalAsignado.fase]) {
            tarifaSemanaPorFase[personalAsignado.fase] = 0;
        }

        if (
            personalAsignado.puesto === PUESTOS.SCRUM_MASTER &&
            puestoPlantilla
        ) {
            tarifaSemanaScrum +=
                Number(personalAsignado.cantidad) *
                Number(personalAsignado.tarifa) *
                puestoPlantilla.horas_semanales;
        } else if (puestoPlantilla && puestoPlantilla.suma_semana) {
            tarifaSemanaPorFase[personalAsignado.fase] +=
                Number(personalAsignado.cantidad) *
                Number(personalAsignado.tarifa) *
                puestoPlantilla.horas_semanales;
        }
    });

    /*
     * Total tarifas
     * tarifa semana de cada fase * semanas de cada fase
     * Total tarifas Project Manager (Scrum Master)
     * tarifa semana srcum * semanas desarrollo
     */
    const totalTarifas = {};
    const fases = getFasesFromPlantilla(currentPlantilla);
    fases.forEach((f) => {
        const label = f.label;
        const fase = f.value;
        totalTarifas[fase] = { label, value: 0 };
        const tarifaSemana = tarifaSemanaPorFase[fase] || 0;
        totalTarifas[fase].value = tarifaSemana * semanas[fase].value;
    });

    let semanasDesarrollo = semanas.total_semanas;
    semanasDesarrollo = semanasDesarrollo ? semanasDesarrollo.value : 0;
    finanzas.project_manager.value += tarifaSemanaScrum * semanasDesarrollo;

    /*
     * Agregar los gastos de los puestos que no "cuentan semana".
     * cantidad de recursos * tarifa por hora * total de horas de la fase
     */
    personalValue.forEach((personalAsignado) => {
        // Excluir si es Scrum master
        if (personalAsignado.puesto === PUESTOS.SCRUM_MASTER) return;
        const puestoPlantilla = fases_puestos.find(
            (fasePuesto) =>
                Number(personalAsignado.puesto) === fasePuesto.puesto &&
                personalAsignado.fase === fasePuesto.fase
        );

        if (puestoPlantilla && !puestoPlantilla.suma_semana) {
            totalTarifas[personalAsignado.fase].value +=
                Number(personalAsignado.cantidad) *
                Number(personalAsignado.tarifa) *
                horas[personalAsignado.fase].value;
        }
    });

    /*
     * Sumar total
     * Agregar total Project Manager (SCRUM MASTER)
     * Y total sin impuestos
     */
    Object.values(totalTarifas).forEach((tarifa) => {
        finanzas.desarrollo.value += tarifa.value;
    });

    finanzas.desarrollo.value += finanzas.project_manager.value;

    finanzas.desarrollo_sin_impuestos.value =
        finanzas.desarrollo.value - finanzas.desarrollo.value * 0.17;

    finanzas.total.value += finanzas.desarrollo.value;

    finanzas = { ...totalTarifas, ...finanzas };

    /*
     * Gastos administrativos
     */

    finanzas.administrativos.value += calculateGastos(gastosValue);
    finanzas.total.value += finanzas.administrativos.value;

    /* Calcular garantía y sumar al total */
    finanzas.total.value += finanzas.garantia.value;

    /* Calcular comisión de venta y sumar al total
     *  Total fases sin impuestos * porcentaje comision de venta
     */
    finanzas.comision_venta.value =
        finanzas.desarrollo_sin_impuestos.value * (Number(comisionVenta) / 100);
    finanzas.total.value += finanzas.comision_venta.value;

    /* Calcular margen de negociación y sumar al total
     *  (Total fases + gastos administrativos + garantia) * porcentaje margen de negociación
     */
    finanzas.margen_negociacion.value =
        (finanzas.desarrollo.value +
            finanzas.administrativos.value +
            finanzas.garantia.value) *
        (Number(margenNegocio) / 100);
    finanzas.total.value += finanzas.margen_negociacion.value;

    /* Calcular ganancia adicional y sumar al total
     *  (Total fases + gastos administrativos + garantia) * porcentaje ganancia adicional
     */
    finanzas.ganancia_adicional.value =
        (finanzas.desarrollo.value +
            finanzas.administrativos.value +
            finanzas.garantia.value) *
        (Number(gananciaAdicional) / 100);
    finanzas.total.value += finanzas.ganancia_adicional.value;

    /* Calcular impuestos_adicionales y sumar al total
     *  (
        Total fases + gastos administrativos + garantia +
        comision de venta + margen de negociación + ganancia adicional
        ) * porcentaje impuestos_adicionales
     */
    finanzas.impuestos_adicionales.value =
        (finanzas.desarrollo.value +
            finanzas.administrativos.value +
            finanzas.garantia.value +
            finanzas.comision_venta.value +
            finanzas.margen_negociacion.value +
            finanzas.ganancia_adicional.value) *
        (Number(impuestosAdicionales) / 100);
    finanzas.total.value += finanzas.impuestos_adicionales.value;

    finanzas.total_sin_iva.value =
        finanzas.total.value - finanzas.total.value * 0.12;

    return finanzas;
};
