import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";
import { reducer as formReducer } from "redux-form";
import login from "./modules/cuenta/login";
import recursos from "./modules/recursos/recursos";
import proyectos from "./modules/proyectos/proyectos";
import gantt from "./modules/gantt/gantt";
import puestos from "./modules/puestos/puestos";
import fases_proyecto from "./modules/fases_proyecto/fases_proyecto";
import users from "./modules/users/users";
import statistics from "./modules/statistics/statistics";
import clientes from "./modules/clientes/clientes";
import tipos_proyecto from "./modules/tipos_proyecto/tipos_proyecto";
import departamento_orgnizacion from "./modules/departamento_organizacion/departamento_organizacion";
import paises from "./modules/paises/paises";
import historial_economicos from "./modules/historial_economicos/historial_economicos";
import asuetos from "./modules/asuetos/asuetos";
import tipos_permiso from "./modules/tipo_permiso/tipo_permiso";
import permisos from "./modules/permisos/permisos";
import skills from "./modules/skills/skills";
import seniority from "./modules/seniority/seniority";
import complejidad_proyectos from "./modules/complejidad_proyectos/complejidad_proyectos";
import actividades_entrega from "./modules/actividades_entrega/actividades_entrega";
import plantillas_proyectos from "./modules/plantillas_proyectos/plantillas_proyectos";
import gastos_administrativos from "./modules/gastos_administrativos/gastos_administrativos";
import proyectos_operaciones from "./modules/proyectos_operaciones/proyectos_operaciones";
import amonestaciones from "./modules/amonestaciones/amonestaciones";
import tipo_contrato_vacaciones from "./modules/tipo_contrato_vacaciones/tipo_contrato_vacaciones";
import solicitud_personal from "./modules/solicitud_personal/solicitud_personal";
import historial_interno from "./modules/historial_interno/historial_interno";

export default combineReducers({
    form: formReducer,
    login,
    gantt,
    routing,
    recursos,
    proyectos,
    puestos,
    fases_proyecto,
    users,
    statistics,
    clientes,
    tipos_proyecto,
    departamento_orgnizacion,
    paises,
    historial_economicos,
    asuetos,
    tipos_permiso,
    permisos,
    skills,
    seniority,
    complejidad_proyectos,
    actividades_entrega,
    plantillas_proyectos,
    gastos_administrativos,
    proyectos_operaciones,
    amonestaciones,
    tipo_contrato_vacaciones,
    solicitud_personal,
    historial_interno,  
});
