import React, { useState } from 'react';
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import moment from "moment";
import './clientes.css';

const Clientes = (props) => {

    const [show_popup, setShowPopup] = useState(false);
    const [data_popup, setDataPopup] = useState([]);

    return (
        <React.Fragment>
            <Popup open={show_popup} modal onClose={() => {setShowPopup(false); setDataPopup([])}}>
                <div className="row" style={{overflowX: 'auto'}}>
                    <div className="col-12">
                        <table className="content--table table--fixed" style={{margin: '0', borderRadius: '0'}}>
                            <thead className="thead--fixed">
                                <tr>
                                    <th scope="col" className='min--width'>Código</th>
                                    <th scope="col" className='min--width'>Nombre</th>
                                    <th scope="col" className='min--width'>Puesto</th>
                                    <th scope="col" className='min--width'>Fechas de inicio</th>
                                    <th scope="col" className='min--width'>Trabajando</th>
                                    <th scope="col" className='min--width'>Proyecto{"(s)"}</th>
                                </tr>
                            </thead>
                            <tbody className='tbody--fixed'>
                                {data_popup.map((e, i) => (
                                    <tr key={i}>
                                        <td className='min--width'>{e.codigo}</td>
                                        <td className='min--width'>{e.nombre}</td>
                                        <td className='min--width'>{e.puesto}</td>
                                        <td className='min--width'>{moment(e.fecha_inicio).format("DD/MM/YYYY")}</td>
                                        <td className='min--width'>
                                            <p style={{marginTop: '10%'}}>
                                                {e.anios && e.anios > 0 ? <span>{e.anios} año{"(s) "}</span> : null}
                                                {e.meses && e.meses > 0 ? <span>{e.meses} mes{"(es) "}</span> : null}
                                                {!e.meses && !e.anios && e.dias > 0 ? <span>{e.dias} día{"(s)"}</span> : null}
                                                {e.dias <= 0 ? <span>0 días</span> : null}
                                            </p>
                                        </td>
                                        <td className='min--width'>
                                            <ul>
                                                {e.proyectos.map((f, j) => (
                                                    <li key={j}>{f}</li>
                                                ))}
                                            </ul>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Popup>
            <div className='col-12'>
                <h4 className='title--blue--1'>{props.title}</h4>
                <hr />
                {props.data.clientes ? <div style={{overflowX: 'auto'}}>
                <table className="content--table">
                    <thead>
                        <tr>
                            <th>Cliente</th>
                            <th>Cantidad de recursos</th>
                            <th>Detalles</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th style={{backgroundColor: "#D4DBED"}}>Puestos</th>
                            <th style={{backgroundColor: "#D4DBED"}}>
                                <div style={{display: "flex", justifyContent: "space-around"}}>
                                    {props.data.puestos.map((e, i) => (
                                        <div key={i}>{e}</div>
                                    ))}
                                </div>
                            </th>
                            <th style={{backgroundColor: "#D4DBED"}}></th>
                        </tr>
                        {props.data.clientes.map((e, i) => (
                            <tr key={i}>
                                <td
                                    className={`${
                                        e.strong ? "text--strong" : ""
                                    }`}
                                >
                                    {e.cliente}
                                </td>
                                <td
                                    className={`${
                                        e.strong ? "text--strong" : ""
                                    }`}
                                >
                                    <div style={{display: "flex", justifyContent: "space-around"}}>
                                        {e.puestos.map((f, j) => (
                                            <div key={j}>{f}</div>
                                        ))}
                                        <div>{e.cantidad}</div>
                                    </div>
                                </td>
                                <td>
                                <button className="btn btn-info" onClick={() => {setShowPopup(true); setDataPopup(e.recursos)}}>
                                    <i className="material-icons" style={{fontSize: '10px'}}>
                                        visibility
                                    </i>
                                </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table></div> : null}
            </div>
        </React.Fragment>
    );
};

export default Clientes;