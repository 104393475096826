import { push } from "react-router-redux";
import { handleActions } from "redux-actions";
import Swal from "sweetalert2";
import { api } from "api";

const LOADER = "HISTORIAL_ECONOMICO_LOADER";
const DATA = "HISTORIAL_ECONOMICO_DATA";
const SUBMIT = "HISTORIAL_ECONOMICO_SUBMIT";

const ENDPOINT = "historial_economico";
const ENDPOINT2 = "recurso";

export const constants = {
    SUBMIT,
};

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const onSubmit = (data = {}) => (dispatch, getStore) => {

    dispatch(setLoader(true));
    api.post(`${ENDPOINT}/agregar_aumento`, data)
        .then(() => {
            Swal.fire({
                title: "Éxito",
                text: "Se ha guardado correctamente",
                type: "success",
            });
            dispatch(push("/historial_economico"));
        })
        .catch((error) => {
            let textError = "Error desconocido"; // Mensaje por defecto en caso de que no haya mensaje de error del backend
            if (error.response && error.response.data && error.response.data.error) {
                textError = error.response.data.error; // Usar el mensaje de error del backend si está disponible
            }
            Swal.fire({
                title: "Error",
                text: error.error,
                type: "error",
            });
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const historial =
    (id, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}`, { empleado_id: id, ...params }) 
            .then((response) => {
                dispatch(setData(response));
            })
            .catch((error) => {
                console.error('Error al obtener los datos:', error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };


export const listar =
    (page = 1, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT2}/empleados_activos`, { page, ...params })
            .then((response) => {

                dispatch(setData(response));
            })
            .catch((error) => {
                console.error('Error al obtener los datos:', error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };



//cambio

export const obtenerHistorialEconomico =
    (id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}/${id}`)
            .then((response) => {
                dispatch(initializeForm("CrearEditarForm", response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const actualizarHistorialEconomico =
    (data = {}, id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.put(`${ENDPOINT}/${id}`, data)
            .then(() => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha actualizado correctamente",
                    type: "success",
                });
                dispatch(push("/historial_economicos"));
            })
            .catch((error) => {
                const textError = error && error.nombre
                ? "\nEste historial ya existe"
                : "Ha ocurrido un error, inténtelo nuevamente."
                ;
                Swal.fire({
                    title: "Error",
                    text: textError,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${ENDPOINT}/${id}`)
        .then(() => {
            Swal.fire({
                title: "Éxito",
                text: "Se ha eliminado correctamente",
                type: "success",
            });
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};




export const actions = {
    onSubmit,
    listar,
    historial,
    obtenerHistorialEconomico,
    actualizarHistorialEconomico,
    eliminar,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
};

export const initialState = {
    loader: false,
    data: [],
};

export default handleActions(reducers, initialState);
